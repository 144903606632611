var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { FeatureRow } from './FeatureRow/FeatureRow';
import styles from './InfoFeatureTable.module.scss';
export function InfoFeatureTable(_a) {
    var features = _a.features, productVersion = _a.productVersion;
    var createFeaturesMap = function (feats) {
        var map = {};
        feats === null || feats === void 0 ? void 0 : feats.forEach(function (feat) {
            map["".concat(feat.featureId)] = feat;
        });
        return map;
    };
    return (_jsx(TableContainer, __assign({ className: styles.infoFeatureTableContainer, sx: { width: '65%' } }, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Step No." }), _jsx(TableCell, { children: "Feature Id" }), _jsx(TableCell, { children: "Description" })] }) }), _jsx(TableBody, { children: features === null || features === void 0 ? void 0 : features.map(function (feature, i) { return (_jsx(FeatureRow, { feature: feature, productVersion: productVersion, showBorderBottom: i !== features.length - 1, bgColor: i % 2 === 0 ? 'rgb(229, 229, 229)' : 'white', featuresMap: createFeaturesMap(features) }, feature.featureId)); }) })] }) })));
}

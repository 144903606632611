import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useLayoutEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { PubTabs } from 'components/Shared/PubTabs/PubTabs';
import { useCpaProduct } from '../CpaProduct/CpaProductContextProvider';
import { getTabsDefinition } from '../CpaDefinitions';
export function CpaProductBody() {
    var searchParams = useSearchParams()[0];
    var cpaProductContext = useCpaProduct();
    var location = useLocation();
    var _a = useState(0), activeIndex = _a[0], setActiveIndex = _a[1];
    var tabsDefinition = getTabsDefinition(cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.productType, location.pathname, cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.mode);
    var key = 'tab';
    var handleTabChange = useCallback(function (index) {
        setActiveIndex(index);
        searchParams.set(key, tabsDefinition.tabs[index].tab.tabKey);
    }, [searchParams, tabsDefinition.tabs]);
    useLayoutEffect(function () {
        var activeTab = searchParams.get(key);
        var index = tabsDefinition.tabs.findIndex(function (t) { var _a; return (_a = t.tab.href) === null || _a === void 0 ? void 0 : _a.includes(activeTab !== null && activeTab !== void 0 ? activeTab : '!@#'); });
        setActiveIndex(index === -1 ? 0 : index);
    }, [searchParams, tabsDefinition.tabs]);
    return _jsx(PubTabs, { tabsDefinition: tabsDefinition, activeIndex: activeIndex, setActiveIndex: handleTabChange });
}

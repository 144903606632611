var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/rules-of-hooks */
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { noSpaces } from 'services/helperFunctions/handleRegex';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useCheckAuthScope } from 'services/helperFunctions/useCheckAuthScope';
import { UI_WRITE_SCOPES } from 'utils/constants';
import { useLanguageCode } from 'services/helperFunctions/useLanguageCode';
import styles from './OptionsInputSection.module.scss';
export var OptionsInputSection = forwardRef(function (_a, ref) {
    var isEditable = _a.isEditable, handleOptionCodeValueChange = _a.handleOptionCodeValueChange, derivedFeatureToInject = _a.derivedFeatureToInject, handleDerivedOptionFieldEdit = _a.handleDerivedOptionFieldEdit, disableSaveProgrammatically = _a.disableSaveProgrammatically, existingDerivedFeature = _a.existingDerivedFeature, _b = _a.disableOptionCode, disableOptionCode = _b === void 0 ? false : _b;
    var languageCode = useLanguageCode();
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var userHasEditPrivileges = useCheckAuthScope(UI_WRITE_SCOPES);
    var _c = useState(''), optionCode = _c[0], setOptionCode = _c[1];
    var _d = useState(''), description = _d[0], setDescription = _d[1];
    var _e = useState(''), mfgOptionModelCode = _e[0], setMfgOptionModelCode = _e[1];
    var _f = useState(''), prcOptionModelCode = _f[0], setPrcOptionModelCode = _f[1];
    var _g = useState(true), disableSaveFromChild = _g[0], setDisableSaveFromChild = _g[1];
    useImperativeHandle(ref, function () { return ({
        // these functions are called from FeatureModal
        emitNewOptionData: function () {
            // this sends data to the parent
            return {
                optionCode: optionCode,
                desc: description,
                mfgOptMdlCd: mfgOptionModelCode,
                prcOptMdlCd: prcOptionModelCode,
            };
        },
        receiveNewOptionData: function (feature) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            // this is used for copying option data
            if (feature) {
                if (feature.options) {
                    var arrayifiedOption = (_a = Object.entries(feature.options)) === null || _a === void 0 ? void 0 : _a[0];
                    setOptionCode(arrayifiedOption === null || arrayifiedOption === void 0 ? void 0 : arrayifiedOption[0]);
                    setDescription((_d = (_c = (_b = arrayifiedOption === null || arrayifiedOption === void 0 ? void 0 : arrayifiedOption[1].desc) === null || _b === void 0 ? void 0 : _b["".concat(languageCode)]) === null || _c === void 0 ? void 0 : _c.desc) !== null && _d !== void 0 ? _d : '');
                    setMfgOptionModelCode((_f = (_e = arrayifiedOption === null || arrayifiedOption === void 0 ? void 0 : arrayifiedOption[1]) === null || _e === void 0 ? void 0 : _e.mfgOptMdlCd) !== null && _f !== void 0 ? _f : '');
                    setPrcOptionModelCode((_h = (_g = arrayifiedOption === null || arrayifiedOption === void 0 ? void 0 : arrayifiedOption[1]) === null || _g === void 0 ? void 0 : _g.prcOptMdlCd) !== null && _h !== void 0 ? _h : '');
                }
                else {
                    setOptionCode(feature.optionCode);
                    setDescription((_l = (_k = (_j = feature.optionDescription) === null || _j === void 0 ? void 0 : _j["".concat(languageCode)]) === null || _k === void 0 ? void 0 : _k.desc) !== null && _l !== void 0 ? _l : '');
                    setMfgOptionModelCode((_m = feature === null || feature === void 0 ? void 0 : feature.mfgOptMdlCd) !== null && _m !== void 0 ? _m : '');
                    setPrcOptionModelCode((_o = feature === null || feature === void 0 ? void 0 : feature.prcOptMdlCd) !== null && _o !== void 0 ? _o : '');
                }
            }
        },
        receiveDerivedFeature: function (derivedFeature) {
            var _a, _b, _c, _d;
            // this is used to inject derived feature data into TableDataRowOption
            setOptionCode(derivedFeature.optionCode);
            setDescription((_b = (_a = derivedFeature === null || derivedFeature === void 0 ? void 0 : derivedFeature.optionDescription) === null || _a === void 0 ? void 0 : _a["".concat(languageCode)].desc) !== null && _b !== void 0 ? _b : '');
            setMfgOptionModelCode((_c = derivedFeature === null || derivedFeature === void 0 ? void 0 : derivedFeature.mfgOptMdlCd) !== null && _c !== void 0 ? _c : '');
            setPrcOptionModelCode((_d = derivedFeature === null || derivedFeature === void 0 ? void 0 : derivedFeature.prcOptMdlCd) !== null && _d !== void 0 ? _d : '');
        },
        receiveNewDerivedFeatureData: function () {
            setOptionCode('');
            setDescription('');
            setMfgOptionModelCode('');
            setPrcOptionModelCode('');
        },
        receiveApiResponse: function (response, message) {
            if (response.status !== 200) {
                setSnackbarAlert({
                    message: (response === null || response === void 0 ? void 0 : response.body).message || '',
                    type: 'error',
                });
            }
            else {
                setSnackbarAlert({
                    message: "".concat(message, " saved"),
                    type: 'success',
                });
            }
        },
        getOptionCode: function () {
            return optionCode;
        },
        getMOptionModelCode: function () {
            return mfgOptionModelCode;
        },
        getPOptionModelCode: function () {
            return prcOptionModelCode;
        },
    }); });
    useEffect(function () {
        if (optionCode === '' || optionCode === undefined) {
            setDisableSaveFromChild(true);
        }
        else {
            setDisableSaveFromChild(false);
        }
        handleOptionCodeValueChange(disableSaveFromChild); // passes value to parent component to enable or disable save button
    }, [disableSaveFromChild, handleOptionCodeValueChange, optionCode]);
    return (_jsxs(Box, __assign({ className: styles.inputsContainer }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'row' } }, { children: [_jsx(Box, __assign({ className: styles.labelAndInputRow, sx: { marginRight: '20px' } }, { children: _jsx(TextField, { size: "small", label: "Option Code", variant: "outlined", value: optionCode !== null && optionCode !== void 0 ? optionCode : '', onChange: function (e) {
                                if (isEditable && userHasEditPrivileges) {
                                    setOptionCode(function () { return e.target.value; });
                                    if (!/^$/g.test(e.target.value)) {
                                        disableSaveProgrammatically === null || disableSaveProgrammatically === void 0 ? void 0 : disableSaveProgrammatically();
                                    }
                                }
                            }, onBlur: function () { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (!/^$/g.test(optionCode) && existingDerivedFeature) {
                                        if (derivedFeatureToInject &&
                                            handleDerivedOptionFieldEdit &&
                                            userHasEditPrivileges) {
                                            handleDerivedOptionFieldEdit(optionCode, derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.optionCode, 'optionCode');
                                        }
                                    }
                                    else if (existingDerivedFeature) {
                                        setSnackbarAlert({
                                            message: "Save cancelled - Option Code is a required field",
                                            type: 'error',
                                        });
                                    }
                                    return [2 /*return*/];
                                });
                            }); }, error: !noSpaces(optionCode), required: true, inputProps: { style: { height: '20px' } }, disabled: !userHasEditPrivileges || !isEditable || disableOptionCode }) })), _jsx(Box, __assign({ className: styles.labelAndInputRow, sx: { width: '100%' } }, { children: _jsx(TextField, { size: "small", label: "Option Description", variant: "outlined", value: description !== null && description !== void 0 ? description : '', onChange: function (e) {
                                if (isEditable && userHasEditPrivileges) {
                                    setDescription(function () { return e.target.value; });
                                }
                            }, onBlur: function () {
                                var _a, _b, _c;
                                if (derivedFeatureToInject && handleDerivedOptionFieldEdit && userHasEditPrivileges) {
                                    handleDerivedOptionFieldEdit(description, (_c = (_b = (_a = derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.optionDescription) === null || _a === void 0 ? void 0 : _a["".concat(languageCode)]) === null || _b === void 0 ? void 0 : _b.desc) !== null && _c !== void 0 ? _c : '', 'optionDescription');
                                }
                            }, sx: {
                                width: { sm: 'inherit' },
                                '& .MuiInputBase-root': {},
                            }, inputProps: { style: { height: '20px' } }, disabled: !userHasEditPrivileges || !isEditable }) }))] })), _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'row' } }, { children: [_jsx(Box, __assign({ className: styles.labelAndInputRow, sx: { marginRight: '20px' } }, { children: _jsx(TextField, { size: "small", label: "Manufacturing Option Model Code", variant: "outlined", value: mfgOptionModelCode !== null && mfgOptionModelCode !== void 0 ? mfgOptionModelCode : '', onChange: function (e) {
                                if (isEditable && userHasEditPrivileges) {
                                    setMfgOptionModelCode(function () { return e.target.value; });
                                    if (!/^$/g.test(e.target.value)) {
                                        disableSaveProgrammatically === null || disableSaveProgrammatically === void 0 ? void 0 : disableSaveProgrammatically();
                                    }
                                }
                            }, onBlur: function () {
                                var _a;
                                if (derivedFeatureToInject && handleDerivedOptionFieldEdit && userHasEditPrivileges) {
                                    handleDerivedOptionFieldEdit(mfgOptionModelCode, (_a = derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.mfgOptMdlCd) !== null && _a !== void 0 ? _a : '', 'mfgOptionModelCode');
                                }
                            }, inputProps: { style: { width: '210px', height: '20px' } }, error: !noSpaces(mfgOptionModelCode), disabled: !userHasEditPrivileges || !isEditable }) })), _jsx(Box, __assign({ className: styles.labelAndInputRow }, { children: _jsx(TextField, { size: "small", label: "Price Option Model Code", variant: "outlined", value: prcOptionModelCode !== null && prcOptionModelCode !== void 0 ? prcOptionModelCode : '', onChange: function (e) {
                                if (isEditable && userHasEditPrivileges) {
                                    setPrcOptionModelCode(function () { return e.target.value; });
                                    if (!/^$/g.test(e.target.value)) {
                                        disableSaveProgrammatically === null || disableSaveProgrammatically === void 0 ? void 0 : disableSaveProgrammatically();
                                    }
                                }
                            }, onBlur: function () {
                                var _a;
                                if (derivedFeatureToInject && handleDerivedOptionFieldEdit && userHasEditPrivileges) {
                                    handleDerivedOptionFieldEdit(prcOptionModelCode, (_a = derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.prcOptMdlCd) !== null && _a !== void 0 ? _a : '', 'prcOptionModelCode');
                                }
                            }, inputProps: { style: { width: '200px', height: '20px' } }, error: !noSpaces(prcOptionModelCode), disabled: !userHasEditPrivileges || !isEditable }) }))] }))] })));
});

import { jsx as _jsx } from "react/jsx-runtime";
import { CPA_TABS, CpaProductType } from 'utils/cpa-utils';
import { CpaDescription } from './CpaProductBody/CpaDescription/CpaDescription';
import { CpaPricing } from './CpaProductBody/CpaPricing/CpaPricing';
import { CpaExtraData } from './CpaProductBody/CpaExtraData/CpaExtraData';
import { CpaNotes } from './CpaProductBody/CpaNotes/CpaNotes';
import { CpaDrawing } from './CpaProductBody/CpaDrawing/CpaDrawing';
import { CpaProductMode } from './CpaProduct/CpaProductReducer';
export function getTabsDefinition(productType, pathname, mode) {
    switch (productType) {
        case CpaProductType.SPECIAL_ORDER:
            return {
                tabs: [
                    {
                        tab: {
                            labelText: CPA_TABS.description.display,
                            tabKey: CPA_TABS.description.key,
                            href: "".concat(pathname, "?tab=").concat(CPA_TABS.description.key),
                        },
                        tabPanel: { component: _jsx(CpaDescription, {}) },
                    },
                    {
                        tab: {
                            labelText: CPA_TABS.pricing.display,
                            tabKey: CPA_TABS.pricing.key,
                            href: "".concat(pathname, "?tab=").concat(CPA_TABS.pricing.key),
                            disabled: mode !== CpaProductMode.EDIT,
                            disabledReason: 'Disabled pending product save',
                        },
                        tabPanel: { component: _jsx(CpaPricing, {}) },
                    },
                    {
                        tab: {
                            labelText: CPA_TABS.notes.display,
                            tabKey: CPA_TABS.notes.key,
                            href: "".concat(pathname, "?tab=").concat(CPA_TABS.notes.key),
                        },
                        tabPanel: { component: _jsx(CpaNotes, {}) },
                    },
                    {
                        tab: {
                            labelText: CPA_TABS.drawing.display,
                            tabKey: CPA_TABS.drawing.key,
                            href: "".concat(pathname, "?tab=").concat(CPA_TABS.drawing.key),
                        },
                        tabPanel: { component: _jsx(CpaDrawing, {}) },
                    },
                    {
                        tab: {
                            labelText: CPA_TABS.extraData.display,
                            tabKey: CPA_TABS.extraData.key,
                            href: "".concat(pathname, "?tab=").concat(CPA_TABS.extraData.key),
                        },
                        tabPanel: { component: _jsx(CpaExtraData, {}) },
                    },
                ],
            };
        case CpaProductType.WORK_ORDER:
            return {
                tabs: [
                    {
                        tab: {
                            labelText: CPA_TABS.description.display,
                            tabKey: CPA_TABS.description.key,
                            href: "".concat(pathname, "?tab=").concat(CPA_TABS.description.key),
                        },
                        tabPanel: { component: _jsx(CpaDescription, {}) },
                    },
                    {
                        tab: {
                            labelText: CPA_TABS.notes.display,
                            tabKey: CPA_TABS.notes.key,
                            href: "".concat(pathname, "?tab=").concat(CPA_TABS.notes.key),
                        },
                        tabPanel: { component: _jsx(CpaNotes, {}) },
                    },
                    {
                        tab: {
                            labelText: CPA_TABS.extraData.display,
                            tabKey: CPA_TABS.extraData.key,
                            href: "".concat(pathname, "?tab=").concat(CPA_TABS.extraData.key),
                        },
                        tabPanel: { component: _jsx(CpaExtraData, {}) },
                    },
                ],
            };
        default:
            return { tabs: [] };
    }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useLoaderData, useNavigate } from 'react-router-dom';
import { StyledSearchResultsTable } from 'components/Shared/StyledComponents/StyledSearchResultsTable/StyledSearchResultsTable';
import { StyledProductLimitReachedText } from 'components/Shared/StyledComponents/StyledProductLimitReachedText';
import { getCurrentProducts } from 'services/api/search-product-service';
import styles from './EndDataSearchResults.module.scss';
export function EngDataSearchResults() {
    var data = useLoaderData();
    var engProductSearchResult = data.engProductSearchResult, searchText = data.searchText;
    var navigate = useNavigate();
    var numberOfProductsDisplayed = engProductSearchResult.products.length;
    var totalNumberOfProducts = engProductSearchResult.resultsCount;
    return (_jsxs(_Fragment, { children: [numberOfProductsDisplayed === 500 && totalNumberOfProducts > 500 && (_jsx(StyledProductLimitReachedText, { amountRemaining: totalNumberOfProducts - numberOfProductsDisplayed })), engProductSearchResult.products.length > 0 ? (_jsx(StyledSearchResultsTable, { passedSearchResults: engProductSearchResult.products, handleSearchResultClick: function (productId) {
                    return navigate("../../product/".concat(productId), { state: { searchText: searchText } });
                } })) : (_jsx("h4", __assign({ className: styles.noDataText }, { children: "No results were found for '".concat(searchText, "'") })))] }));
}
export function engDataSearchResultsLoader(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var params = _a.params;
    return __awaiter(this, void 0, void 0, function () {
        var searchText, engProductSearchResult, searchResultRsp, productsFromSearch;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0:
                    searchText = params.searchText;
                    if (!searchText) return [3 /*break*/, 2];
                    return [4 /*yield*/, getCurrentProducts(searchText, true)];
                case 1:
                    searchResultRsp = _k.sent();
                    productsFromSearch = ((_c = (_b = searchResultRsp.data.data) === null || _b === void 0 ? void 0 : _b.map(function (el) {
                        var _a, _b, _c, _d, _e;
                        return ({
                            id: (_a = el.productVersion.substring(0, el.productVersion.indexOf('_', 6))) !== null && _a !== void 0 ? _a : '',
                            name: (_d = (_c = (_b = el.productName) === null || _b === void 0 ? void 0 : _b.find(function (n) { return n.langCode === 'en-US'; })) === null || _c === void 0 ? void 0 : _c.desc) !== null && _d !== void 0 ? _d : '',
                            prefix: (_e = el.prefix) !== null && _e !== void 0 ? _e : '',
                            version: el.productVersion,
                            startDate: el.startDate,
                        });
                    })) !== null && _c !== void 0 ? _c : []);
                    engProductSearchResult = {
                        pageNumber: (_e = (_d = searchResultRsp.data) === null || _d === void 0 ? void 0 : _d.pageNumber) !== null && _e !== void 0 ? _e : 0,
                        size: (_g = (_f = searchResultRsp.data) === null || _f === void 0 ? void 0 : _f.pageSize) !== null && _g !== void 0 ? _g : 0,
                        resultsCount: (_j = (_h = searchResultRsp.data) === null || _h === void 0 ? void 0 : _h.resultsCount) !== null && _j !== void 0 ? _j : 0,
                        products: __spreadArray([], productsFromSearch, true),
                    };
                    return [2 /*return*/, { engProductSearchResult: engProductSearchResult, searchText: searchText }];
                case 2: return [2 /*return*/, { engProductSearchResult: {}, searchText: searchText }];
            }
        });
    });
}

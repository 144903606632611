var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable no-underscore-dangle */
import { CatalogEventPublishRequest } from 'models/catalogs/CatalogEventPublishRequest';
import { getUserOrSignIn } from 'services/auth/AuthService';
import { CATALOGS_SORT_VALUES, fileTypesDisplayText } from './constants';
/**
 *
 * @param dateString date string in yyyy-mm-dd format
 */
var setDateToActualValue = function (dateString) {
    var _a = dateString.split('-'), year = _a[0], month = _a[1], day = _a[2];
    var date = new Date();
    date.setFullYear(+year, +month - 1, +day);
    date.setHours(0, 0, 0, 0);
    return date;
};
var setDateToActualValueWithTime = function (dateString, hourString) {
    var _a = dateString.split('-'), year = _a[0], month = _a[1], day = _a[2];
    var date = new Date();
    date.setFullYear(+year, +month - 1, +day);
    var _b = hourString.split(':'), hours = _b[0], minutes = _b[1];
    date.setHours(Number(hours), Number(minutes), 0, 0);
    return date;
};
var msToMinutesAndSeconds = function (milliSeconds) {
    if (!milliSeconds)
        return '';
    var minutes = Math.floor(milliSeconds / 60000);
    var seconds = +((milliSeconds % 60000) / 1000).toFixed(0);
    return "".concat(minutes, ":").concat(seconds < 10 ? '0' : '').concat(seconds);
};
var formatDateString = function (dateString) {
    if (!dateString)
        return '';
    var d = new Date(dateString);
    var date = d.toLocaleDateString(undefined, { dateStyle: 'medium' });
    var time = d.toLocaleTimeString(undefined, { timeStyle: 'short' });
    return "".concat(date, " ").concat(time);
};
var getFileTypeDisplayText = function (fileType) { var _a; return (_a = fileTypesDisplayText === null || fileTypesDisplayText === void 0 ? void 0 : fileTypesDisplayText[fileType]) !== null && _a !== void 0 ? _a : fileType; };
var buildCatalogExtractEvents = function (catalogs, effectiveDate, effectiveTime, includeNonPrmtbl, descriptionAppendText, priceListDate, user) { return __awaiter(void 0, void 0, Promise, function () {
    var events;
    return __generator(this, function (_a) {
        events = [];
        catalogs.forEach(function (catalog) {
            var _a, _b, _c;
            var entity = {
                effectiveDate: effectiveDate && effectiveDate !== '' && effectiveTime && effectiveTime !== ''
                    ? setDateToActualValueWithTime(effectiveDate, effectiveTime).toISOString()
                    : new Date().toISOString(),
                userName: (_a = user === null || user === void 0 ? void 0 : user.email) !== null && _a !== void 0 ? _a : 'No User',
                catalogNbr: +catalog.key._id,
                leadTime: (_b = catalog.body) === null || _b === void 0 ? void 0 : _b.leadTime,
                catalogDesc: catalog.body.catalogDesc,
                fileNamePrefix: catalog.body.fileNamePrefix,
                catalogType: catalog.body.catalogType,
                includeNonPrmtbl: includeNonPrmtbl,
                channels: (_c = catalog.body) === null || _c === void 0 ? void 0 : _c.channels,
                priceListDate: priceListDate && priceListDate !== '' ? setDateToActualValue(priceListDate).toISOString() : undefined,
                descriptionAppendText: descriptionAppendText && descriptionAppendText !== '' ? descriptionAppendText : undefined,
            };
            events.push({
                eventType: CatalogEventPublishRequest.eventType.CATALOG_EXTRACT,
                eventSubType: CatalogEventPublishRequest.eventSubType.REQUESTED,
                entity: entity,
            });
        });
        return [2 /*return*/, events];
    });
}); };
var getDashboardUrlByRequest = function (requestId) {
    var domain = process.env.REACT_APP_OPENSEARCH_DOMAIN;
    var dashPath = "_dashboards/app/dashboards#/view/0609e410-8383-11ee-bc2f-efce68ae92ff";
    var pathParam1 = "?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-3M,to:now))";
    var pathParamSet = [
        "&_a=(description:'Displays%20the%20statistics%20of%20extract%20process%20for%20a%20catalog',filters:!(('$state':(store:appState),",
        "meta:(alias:!n,disabled:!f,index:'08402130-b931-11ed-a623-5987f85c5a82',key:eventInfo.id,negate:!f,params:(query:",
        "'".concat(requestId, "'),type:phrase),query:(match_phrase:(eventInfo.id:'").concat(requestId, "'"),
        ")))),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Catalog%20Extract',viewMode:view)",
    ];
    return "".concat(domain).concat(dashPath).concat(pathParam1).concat(pathParamSet.join(''));
};
/**
 * Get today's date in yyyy-mm-dd format. ex: 2023-01-01
 */
var getTodayLocalDateString = function () {
    var _a = new Date().toLocaleDateString().split('/'), month = _a[0], day = _a[1], year = _a[2];
    return "".concat(year, "-").concat(month.padStart(2, '0'), "-").concat(day.padStart(2, '0'));
};
var getProductNumberFromVersion = function (productVersion) { return +productVersion.split('_')[1]; };
var getProductHierarchy = function (hierarchies) {
    var _a;
    var primary = hierarchies.find(function (h) { return h === null || h === void 0 ? void 0 : h.isPrimary; });
    if (primary)
        return primary;
    if (hierarchies.length === 1)
        return hierarchies[0];
    var lowestSeq = (_a = hierarchies[0]) === null || _a === void 0 ? void 0 : _a.displaySequence;
    var lowestIndex = 0;
    hierarchies.slice(1).forEach(function (h, i) {
        if (lowestSeq && (h === null || h === void 0 ? void 0 : h.displaySequence) && h.displaySequence < lowestSeq) {
            lowestSeq = h.displaySequence;
            lowestIndex = i;
        }
    });
    return hierarchies[lowestIndex];
};
var buildCatalogEvent = function (catalogData) { return __awaiter(void 0, void 0, Promise, function () {
    var event;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                event = {
                    eventType: CatalogEventPublishRequest.eventType.CATALOG_EXTRACT,
                    eventSubType: CatalogEventPublishRequest.eventSubType.REQUESTED,
                    entity: {},
                };
                return [4 /*yield*/, getUserOrSignIn().then(function (u) {
                        event.entity = {
                            hierarchy: catalogData === null || catalogData === void 0 ? void 0 : catalogData.hierarchies,
                            userName: u.email,
                            effectiveDate: setDateToActualValueWithTime(catalogData.effectiveDate, catalogData.effectiveTime).toISOString(),
                            catalogDesc: catalogData.catalogDesc,
                            catalogType: catalogData.catalogType,
                            channels: catalogData.channel ? [{ channelCode: catalogData.channel }] : undefined,
                            fileNamePrefix: catalogData.fileNamePrefix,
                            includeNonPrmtbl: catalogData.includeNonPrmtbl,
                            leadTime: catalogData.leadTime,
                            priceListDate: catalogData.priceListDate,
                            currency: catalogData.currency,
                        };
                    })];
            case 1:
                _a.sent();
                return [2 /*return*/, event];
        }
    });
}); };
var getCatalogSortValues = function () {
    var values = [];
    CATALOGS_SORT_VALUES.forEach(function (value) {
        if (value.key === 'relevance') {
            values = values.concat([
                {
                    key: value.key,
                    display: value.display,
                    direction: 'asc',
                    directionDisplay: 'Asc',
                },
            ]);
        }
        else {
            values = values.concat([
                {
                    key: value.key,
                    display: value.display,
                    direction: 'asc',
                    directionDisplay: 'Asc',
                },
                {
                    key: value.key,
                    display: value.display,
                    direction: 'desc',
                    directionDisplay: 'Desc',
                },
            ]);
        }
    });
    return values;
};
var convertToHierarchyType = function (h, id) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return ({
        productId: id,
        sequence: h === null || h === void 0 ? void 0 : h.displaySequence,
        isPrimary: h === null || h === void 0 ? void 0 : h.isPrimary,
        level1Desc: (_a = h === null || h === void 0 ? void 0 : h.levelOne) === null || _a === void 0 ? void 0 : _a[0].desc,
        level2Desc: (_b = h === null || h === void 0 ? void 0 : h.levelTwo) === null || _b === void 0 ? void 0 : _b[0].desc,
        level3Desc: (_c = h === null || h === void 0 ? void 0 : h.levelThree) === null || _c === void 0 ? void 0 : _c[0].desc,
        level4Desc: (_d = h === null || h === void 0 ? void 0 : h.levelFour) === null || _d === void 0 ? void 0 : _d[0].desc,
        level5Desc: (_e = h === null || h === void 0 ? void 0 : h.levelFive) === null || _e === void 0 ? void 0 : _e[0].desc,
        level6Desc: (_f = h === null || h === void 0 ? void 0 : h.levelSix) === null || _f === void 0 ? void 0 : _f[0].desc,
        level7Desc: (_g = h === null || h === void 0 ? void 0 : h.levelSeven) === null || _g === void 0 ? void 0 : _g[0].desc,
        level8Desc: (_h = h === null || h === void 0 ? void 0 : h.levelEight) === null || _h === void 0 ? void 0 : _h[0].desc,
        level9Desc: (_j = h === null || h === void 0 ? void 0 : h.levelNine) === null || _j === void 0 ? void 0 : _j[0].desc,
        level10Desc: (_k = h === null || h === void 0 ? void 0 : h.levelTen) === null || _k === void 0 ? void 0 : _k[0].desc,
    });
};
var convertSearchHierarchyRequestToHierarchyType = function (h) { return ({
    level1Desc: h === null || h === void 0 ? void 0 : h.level1,
    level2Desc: h === null || h === void 0 ? void 0 : h.level2,
    level3Desc: h === null || h === void 0 ? void 0 : h.level3,
    level4Desc: h === null || h === void 0 ? void 0 : h.level4,
    level5Desc: h === null || h === void 0 ? void 0 : h.level5,
    level6Desc: h === null || h === void 0 ? void 0 : h.level6,
    level7Desc: h === null || h === void 0 ? void 0 : h.level7,
    level8Desc: h === null || h === void 0 ? void 0 : h.level8,
    level9Desc: h === null || h === void 0 ? void 0 : h.level9,
    level10Desc: h === null || h === void 0 ? void 0 : h.level10,
    isPrimary: h === null || h === void 0 ? void 0 : h.primaryOnly,
}); };
var convertHierarchyRequestToSearchHierarchType = function (h) { return ({
    levelOne: (h === null || h === void 0 ? void 0 : h.level1) ? [{ desc: h.level1 }] : undefined,
    levelTwo: (h === null || h === void 0 ? void 0 : h.level2) ? [{ desc: h.level2 }] : undefined,
    levelThree: (h === null || h === void 0 ? void 0 : h.level3) ? [{ desc: h.level3 }] : undefined,
    levelFour: (h === null || h === void 0 ? void 0 : h.level4) ? [{ desc: h.level4 }] : undefined,
    levelFive: (h === null || h === void 0 ? void 0 : h.level5) ? [{ desc: h.level5 }] : undefined,
    levelSix: (h === null || h === void 0 ? void 0 : h.level6) ? [{ desc: h.level6 }] : undefined,
    levelSeven: (h === null || h === void 0 ? void 0 : h.level7) ? [{ desc: h.level7 }] : undefined,
    levelEight: (h === null || h === void 0 ? void 0 : h.level8) ? [{ desc: h.level8 }] : undefined,
    levelNine: (h === null || h === void 0 ? void 0 : h.level9) ? [{ desc: h.level9 }] : undefined,
    levelTen: (h === null || h === void 0 ? void 0 : h.level10) ? [{ desc: h.level10 }] : undefined,
    isPrimary: h === null || h === void 0 ? void 0 : h.primaryOnly,
}); };
var convertHierarchyToSearchHierarchyType = function (h) { return ({
    levelOne: (h === null || h === void 0 ? void 0 : h.level1Desc) ? [{ desc: h.level1Desc }] : undefined,
    levelTwo: (h === null || h === void 0 ? void 0 : h.level2Desc) ? [{ desc: h.level2Desc }] : undefined,
    levelThree: (h === null || h === void 0 ? void 0 : h.level3Desc) ? [{ desc: h.level3Desc }] : undefined,
    levelFour: (h === null || h === void 0 ? void 0 : h.level4Desc) ? [{ desc: h.level4Desc }] : undefined,
    levelFive: (h === null || h === void 0 ? void 0 : h.level5Desc) ? [{ desc: h.level5Desc }] : undefined,
    levelSix: (h === null || h === void 0 ? void 0 : h.level6Desc) ? [{ desc: h.level6Desc }] : undefined,
    levelSeven: (h === null || h === void 0 ? void 0 : h.level7Desc) ? [{ desc: h.level7Desc }] : undefined,
    levelEight: (h === null || h === void 0 ? void 0 : h.level8Desc) ? [{ desc: h.level8Desc }] : undefined,
    levelNine: (h === null || h === void 0 ? void 0 : h.level9Desc) ? [{ desc: h.level9Desc }] : undefined,
    levelTen: (h === null || h === void 0 ? void 0 : h.level10Desc) ? [{ desc: h.level10Desc }] : undefined,
    isPrimary: h === null || h === void 0 ? void 0 : h.isPrimary,
    displaySequence: h === null || h === void 0 ? void 0 : h.sequence,
}); };
var searchHierarchyToKey = function (h) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var key = '';
    if ((_a = h === null || h === void 0 ? void 0 : h.levelOne) === null || _a === void 0 ? void 0 : _a[0].desc)
        key = "".concat(h.levelOne[0].desc).concat(hierarchyKeyLevelDelimiter);
    if ((_b = h === null || h === void 0 ? void 0 : h.levelTwo) === null || _b === void 0 ? void 0 : _b[0].desc)
        key = "".concat(key, "_").concat(h.levelTwo[0].desc).concat(hierarchyKeyLevelDelimiter);
    if ((_c = h === null || h === void 0 ? void 0 : h.levelThree) === null || _c === void 0 ? void 0 : _c[0].desc)
        key = "".concat(key, "_").concat(h.levelThree[0].desc).concat(hierarchyKeyLevelDelimiter);
    if ((_d = h === null || h === void 0 ? void 0 : h.levelFour) === null || _d === void 0 ? void 0 : _d[0].desc)
        key = "".concat(key, "_").concat(h.levelFour[0].desc).concat(hierarchyKeyLevelDelimiter);
    if ((_e = h === null || h === void 0 ? void 0 : h.levelFive) === null || _e === void 0 ? void 0 : _e[0].desc)
        key = "".concat(key, "_").concat(h.levelFive[0].desc).concat(hierarchyKeyLevelDelimiter);
    if ((_f = h === null || h === void 0 ? void 0 : h.levelSix) === null || _f === void 0 ? void 0 : _f[0].desc)
        key = "".concat(key, "_").concat(h.levelSix[0].desc).concat(hierarchyKeyLevelDelimiter);
    if ((_g = h === null || h === void 0 ? void 0 : h.levelSeven) === null || _g === void 0 ? void 0 : _g[0].desc)
        key = "".concat(key, "_").concat(h.levelSeven[0].desc).concat(hierarchyKeyLevelDelimiter);
    if ((_h = h === null || h === void 0 ? void 0 : h.levelEight) === null || _h === void 0 ? void 0 : _h[0].desc)
        key = "".concat(key, "_").concat(h.levelEight[0].desc).concat(hierarchyKeyLevelDelimiter);
    if ((_j = h === null || h === void 0 ? void 0 : h.levelNine) === null || _j === void 0 ? void 0 : _j[0].desc)
        key = "".concat(key, "_").concat(h.levelNine[0].desc).concat(hierarchyKeyLevelDelimiter);
    if ((_k = h === null || h === void 0 ? void 0 : h.levelTen) === null || _k === void 0 ? void 0 : _k[0].desc)
        key = "".concat(key, "_").concat(h.levelTen[0].desc).concat(hierarchyKeyLevelDelimiter);
    return key;
};
var hierarchyKeyLevelDelimiter = '#@>';
var catalogHelper = {
    hierarchyKeyLevelDelimiter: hierarchyKeyLevelDelimiter,
    buildCatalogExtractEvents: buildCatalogExtractEvents,
    getDashboardUrlByRequest: getDashboardUrlByRequest,
    getTodayLocalDateString: getTodayLocalDateString,
    setDateToActualValue: setDateToActualValue,
    setDateToActualValueWithTime: setDateToActualValueWithTime,
    buildCatalogEvent: buildCatalogEvent,
    formatDateString: formatDateString,
    getCatalogSortValues: getCatalogSortValues,
    getFileTypeDisplayText: getFileTypeDisplayText,
    getProductNumberFromVersion: getProductNumberFromVersion,
    getProductHierarchy: getProductHierarchy,
    msToMinutesAndSeconds: msToMinutesAndSeconds,
    convertToHierarchyType: convertToHierarchyType,
    convertSearchHierarchyRequestToHierarchyType: convertSearchHierarchyRequestToHierarchyType,
    convertHierarchyRequestToSearchHierarchType: convertHierarchyRequestToSearchHierarchType,
    convertHierarchyToSearchHierarchyType: convertHierarchyToSearchHierarchyType,
    searchHierarchyToKey: searchHierarchyToKey,
};
export { catalogHelper };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useState } from 'react';
import { CpaAssetType } from 'utils/cpa-utils';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ActionModal } from 'components/Shared/ActionModal/ActionModal';
import styles from './DrawingUploadModal.module.scss';
export function DrawingUploadModal(_a) {
    var _this = this;
    var cpn = _a.cpn, imageFile = _a.imageFile, isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, handleFileAction = _a.handleFileAction;
    var _b = useState(), image = _b[0], setImage = _b[1];
    useLayoutEffect(function () {
        var fileReader = new FileReader();
        fileReader.onload = function (e) {
            var _a;
            setImage((_a = e.target) === null || _a === void 0 ? void 0 : _a.result);
        };
        fileReader.readAsDataURL(imageFile);
        setIsOpen(true);
    }, [imageFile, setIsOpen]);
    var handleModalClose = function () {
        setIsOpen(false);
    };
    var handleConfirm = function () { return __awaiter(_this, void 0, void 0, function () {
        var imageAsset;
        return __generator(this, function (_a) {
            imageAsset = {
                fileName: imageFile.name,
                imageType: imageFile.type,
                type: CpaAssetType.TI,
                url: "".concat(cpn, "/images/").concat(imageFile.name),
            };
            handleFileAction(imageAsset);
            handleModalClose();
            return [2 /*return*/];
        });
    }); };
    return (_jsx(ActionModal, __assign({ isOpen: isOpen, setIsOpen: handleModalClose, buttonProps: [
            {
                labelText: 'Confirm Drawing',
                backgroundColorVariation: 'primary',
                buttonAction: function () { return handleConfirm(); },
            },
            {
                labelText: 'Cancel',
                backgroundColorVariation: 'secondary',
                buttonAction: function () {
                    handleFileAction();
                    handleModalClose();
                },
            },
        ], title: "Confirm Drawing", sizeProps: { width: '25%', height: 'fit-content' } }, { children: _jsxs(Box, __assign({ className: styles.contentContainer }, { children: [_jsx(Box, __assign({ className: styles.imageContainer }, { children: _jsx("img", { src: "".concat(image), alt: imageFile.name }) })), _jsx(Typography, { children: "Click confirm below to export the image to attach this drawing and export to the Amazon S3 server." }), _jsxs(Box, { children: [_jsx(Typography, __assign({ fontFamily: "UntitledSansMedium, sans-serif, system-ui" }, { children: "Drawing to upload:" })), _jsx(Typography, { children: imageFile.name })] })] })) })));
}

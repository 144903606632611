var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { Navbar } from 'components/Navbar/Navbar';
import { Outlet } from 'react-router-dom';
import styles from './RouteLayout.module.scss';
export function RouteLayout() {
    return (_jsxs(Box, __assign({ className: styles.holder }, { children: [_jsx(Navbar, {}), _jsx(Box, __assign({ className: styles.content }, { children: _jsx(Outlet, {}) })), _jsx(Box, __assign({ className: styles.footer }, { children: _jsx("h1", __assign({ style: {
                        textAlign: 'right',
                        color: 'white',
                        fontSize: '16px',
                        fontFamily: 'UntitledSansMedium, sans-serif, system-ui',
                        paddingRight: '80px',
                    } }, { children: "MillerKnoll Publication Layer" })) }))] })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import { Link } from 'react-router-dom';
import styles from './PubTableActionCell.module.scss';
export function PubTableActionCell(_a) {
    var icon = _a.icon, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.isAddRow, isAddRow = _c === void 0 ? false : _c, extraIconIsAction = _a.extraIconIsAction, tableData = _a.tableData, indexOfItem = _a.indexOfItem, isHovering = _a.isHovering, def = _a.def, origin = _a.origin, handleActionClick = _a.handleActionClick;
    var labels = useMemo(function () {
        var _a, _b;
        var arr = [];
        if (tableData && ((_b = (_a = tableData[indexOfItem]) === null || _a === void 0 ? void 0 : _a.version) === null || _b === void 0 ? void 0 : _b.includes('KNOLL'))) {
            arr.push('Eng Info');
        }
        arr.push('Build', 'Info');
        return arr;
    }, [indexOfItem, tableData]);
    var buildRoute = function (label, i) {
        var _a;
        var _b = (_a = def === null || def === void 0 ? void 0 : def.extraIcon) === null || _a === void 0 ? void 0 : _a.dateAndTimeStrings, date = _b.date, time = _b.time;
        var routeString = '';
        switch (label) {
            case 'Build':
                routeString = "/product-build-v2/build?productNbr=".concat(tableData[indexOfItem].prefix, "&date=").concat(date, "&time=").concat(time, "&channel=Commercial&pid=").concat(tableData[indexOfItem].id, "&productVersion=").concat(tableData[indexOfItem].version, "&origin=").concat(origin);
                break;
            case 'Info':
                routeString = "/product-info-v2/info?prefix=".concat(tableData[indexOfItem].prefix, "&date=").concat(date, "&time=").concat(time, "&channel=Commercial&pid=").concat(tableData[indexOfItem].id, "&productVersion=").concat(tableData[indexOfItem].version, "&origin=").concat(origin);
                break;
            case 'Eng Info':
                routeString = "/engdatamaint/product/".concat(tableData[indexOfItem].id);
                break;
            default:
                break;
        }
        return routeString;
    };
    var handleLinkClick = function () {
        sessionStorage.setItem('infoPageOrigin', origin);
    };
    return (_jsx(TableCell, __assign({ sx: {
            paddingX: '4px',
            backgroundColor: isAddRow ? 'white' : 'inherit',
            position: 'relative',
        } }, { children: handleActionClick !== undefined && !extraIconIsAction ? (_jsx(IconButton, __assign({ disabled: disabled, onClick: function () { return handleActionClick(); }, sx: {
                position: 'float',
                left: isHovering ? '15px' : '0',
            } }, { children: icon }))) : (_jsx(Box, __assign({ className: styles.actionLinksContainer }, { children: labels &&
                labels.length > 0 &&
                labels.map(function (label, i) { return (_jsx(Box, __assign({ sx: {
                        width: label !== 'Eng Info' ? '50px' : '70px',
                        textAlign: 'right',
                    } }, { children: _jsx(Link, __assign({ className: styles.link, to: buildRoute(label, i), onClick: function () { return handleLinkClick(); }, target: "_blank" }, { children: label })) }), label)); }) }))) })));
}

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { parseDate } from 'services/helperFunctions/parseDate';
import { convertDateToLocalTime } from 'services/helperFunctions/convertDateToLocalTime';
import { toUTCTimestamp } from 'services/helperFunctions/toUTCTimestamp';
import { StyledButton } from '../StyledButton';
import styles from './StyledSearchWithDateAndTimeInput.module.scss';
var DateAndTimeSearchWithStyling = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    margin-left: 20px;\n"], ["\n    display: flex;\n    flex-direction: column;\n    margin-left: 20px;\n"])));
export function StyledSearchWithDateAndTimeInput(_a) {
    var _b, _c;
    var searchTerm = _a.searchTerm, cspOnDemand = _a.cspOnDemand, productBuild = _a.productBuild, disableClick = _a.disableClick, parsedEndDate = _a.parsedEndDate, fieldIsInError = _a.fieldIsInError, isProductExpire = _a.isProductExpire, parsedStartDate = _a.parsedStartDate, disableResetButton = _a.disableResetButton, resetProductExpire = _a.resetProductExpire, fireProductExpireApi = _a.fireProductExpireApi, handleInputValueChange = _a.handleInputValueChange, handleSearchButtonClick = _a.handleSearchButtonClick, disableExpireSubmitButton = _a.disableExpireSubmitButton, handleProductBuildResetClick = _a.handleProductBuildResetClick, handleEndDateChangeInProductExpire = _a.handleEndDateChangeInProductExpire, handleEndTimeChangeInProductExpire = _a.handleEndTimeChangeInProductExpire;
    var _d = useState(), currentSelectedDate = _d[0], setCurrentSelectedDate = _d[1];
    useEffect(function () {
        if (!currentSelectedDate) {
            setCurrentSelectedDate(convertDateToLocalTime(new Date()));
        }
    }, [currentSelectedDate]);
    var parsedCurrentSelectedDate = parseDate(currentSelectedDate);
    var productExpireStartDate = parsedStartDate === null || parsedStartDate === void 0 ? void 0 : parsedStartDate.parsedDateString;
    var productExpireStartTime = parsedStartDate === null || parsedStartDate === void 0 ? void 0 : parsedStartDate.parsedTimeString;
    var productExpireEndDate = parsedEndDate === null || parsedEndDate === void 0 ? void 0 : parsedEndDate.parsedDateString;
    var productExpireEndTime = parsedEndDate === null || parsedEndDate === void 0 ? void 0 : parsedEndDate.parsedTimeString;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var _e = useState(), startDateData = _e[0], setStartDateData = _e[1];
    useEffect(function () {
        if (!isProductExpire && !startDateData) {
            setStartDateData(parsedCurrentSelectedDate);
        }
    }, [isProductExpire, parsedCurrentSelectedDate, startDateData]);
    var handleStartDateChange = function (event) {
        setStartDateData(function (prevVal) {
            var useablePartOfTimestamp = "T".concat(prevVal.parsedTimeStamp.split('T')[1]);
            var newTimeStamp = "".concat(event.target.value).concat(useablePartOfTimestamp);
            return parseDate(newTimeStamp);
        });
    };
    var handleStartTimeChange = function (event) {
        setStartDateData(function (prevVal) {
            var useablePartOfTimestamp = prevVal === null || prevVal === void 0 ? void 0 : prevVal.parsedTimeStamp.split('T')[0];
            var newTimeStamp = "".concat(useablePartOfTimestamp, "T").concat(event.target.value, ".000Z");
            return parseDate(newTimeStamp);
        });
    };
    var handleResetButtonClick = function (timestamp) {
        if (searchTerm) {
            var newParsedDateData = toUTCTimestamp(timestamp);
            handleProductBuildResetClick === null || handleProductBuildResetClick === void 0 ? void 0 : handleProductBuildResetClick(newParsedDateData.parsedTimeStamp, searchTerm);
        }
    };
    return (_jsxs(DateAndTimeSearchWithStyling, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'row' } }, { children: [!isProductExpire && (_jsx(TextField, { type: "text", label: "Search by full or partial product number", sx: { width: '40%', marginRight: '15px' }, size: "small", value: searchTerm, onChange: function (e) { return handleInputValueChange === null || handleInputValueChange === void 0 ? void 0 : handleInputValueChange(e.target.value); }, onKeyDown: function (e) {
                            var _a;
                            if (e.key === 'Enter' && searchTerm && searchTerm.length > 0) {
                                handleSearchButtonClick === null || handleSearchButtonClick === void 0 ? void 0 : handleSearchButtonClick({
                                    searchTerm: searchTerm,
                                    timestamp: (_a = startDateData.parsedTimeStamp) !== null && _a !== void 0 ? _a : '',
                                });
                            }
                        }, InputProps: {
                            style: {
                                fontFamily: "UntitledSansMedium, sans-serif, system-ui",
                            },
                            endAdornment: _jsx(SearchIcon, { sx: { color: 'rgba(196, 196, 196, 1)' } }),
                        }, error: fieldIsInError })), _jsx(Box, { children: _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'row' } }, { children: [!isProductExpire && searchTerm && (_jsx(StyledButton, { label: "Submit", disabledFromProps: (searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.length) === 0 || disableClick, handleClick: function () {
                                        var _a;
                                        return handleSearchButtonClick === null || handleSearchButtonClick === void 0 ? void 0 : handleSearchButtonClick({
                                            searchTerm: searchTerm,
                                            timestamp: (_a = startDateData.parsedTimeStamp) !== null && _a !== void 0 ? _a : '',
                                        });
                                    }, widthFromProps: 100, heightFromProps: 40, widthIsPercentage: true })), productBuild && (_jsx(StyledButton, { label: "Reset", isResetButton: true, disabledFromProps: ((searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.length) === 0 && disableClick) || disableResetButton, handleClick: function () { var _a; return handleResetButtonClick((_a = startDateData.parsedTimeStamp) !== null && _a !== void 0 ? _a : ''); }, widthFromProps: 100, heightFromProps: 40, widthIsPercentage: true }))] })) })] })), !cspOnDemand && (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: [_jsxs(Box, __assign({ className: styles.dateAndTimeInputRow }, { children: [_jsx(TextField, { className: styles.spaceBetweenInputs, value: isProductExpire ? productExpireStartDate : (_b = startDateData === null || startDateData === void 0 ? void 0 : startDateData.parsedDateString) !== null && _b !== void 0 ? _b : '', onChange: function (e) { return handleStartDateChange(e); }, label: isProductExpire ? 'Start Date' : '', disabled: isProductExpire, InputProps: {
                                    type: 'date',
                                    style: {
                                        height: '40px',
                                        fontFamily: "UntitledSansMedium, sans-serif, system-ui",
                                    },
                                } }), _jsx(TextField, { value: isProductExpire ? productExpireStartTime : (_c = startDateData === null || startDateData === void 0 ? void 0 : startDateData.parsedTimeString) !== null && _c !== void 0 ? _c : '', onChange: function (e) { return handleStartTimeChange(e); }, disabled: isProductExpire, InputProps: {
                                    type: 'time',
                                    style: {
                                        height: '40px',
                                        fontFamily: "UntitledSansMedium, sans-serif, system-ui",
                                    },
                                } })] })), isProductExpire && (_jsxs(Box, __assign({ className: styles.dateAndTimeInputRow }, { children: [_jsx(TextField, { className: styles.spaceBetweenInputs, value: productExpireEndDate, onChange: function (e) {
                                    handleEndDateChangeInProductExpire === null || handleEndDateChangeInProductExpire === void 0 ? void 0 : handleEndDateChangeInProductExpire(e.target.value);
                                }, label: "Expiration Date", InputProps: {
                                    type: 'date',
                                    style: {
                                        height: '40px',
                                    },
                                } }), _jsx(TextField, { value: productExpireEndTime, onChange: function (e) {
                                    handleEndTimeChangeInProductExpire === null || handleEndTimeChangeInProductExpire === void 0 ? void 0 : handleEndTimeChangeInProductExpire(e.target.value);
                                }, InputProps: {
                                    type: 'time',
                                    style: {
                                        height: '40px',
                                    },
                                }, sx: { marginRight: '10px' } }), _jsx(Box, __assign({ sx: { alignSelf: 'center', marginRight: '10px' } }, { children: _jsx(StyledButton, { label: "Submit", heightFromProps: 40, handleClick: fireProductExpireApi, disabledFromProps: disableExpireSubmitButton }) })), _jsx(Box, __assign({ sx: { alignSelf: 'center' } }, { children: _jsx(StyledButton, { label: "Reset Expiration Date", heightFromProps: 40, widthFromProps: 170, handleClick: resetProductExpire, disabledFromProps: (parsedEndDate === null || parsedEndDate === void 0 ? void 0 : parsedEndDate.parsedDateString) === '3099-12-31' }) }))] })))] })))] }));
}
var templateObject_1;

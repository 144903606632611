var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { CURRENCY_LIST } from 'utils/constants';
import { StyledDialogV2 } from '../StyledDialogV2/StyledDialogV2';
import { QuickPriceDetails } from './QuickPriceDetails';
export function QuickPriceModal(_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, effectiveDate = _a.effectiveDate, productReference = _a.productReference, validationResp = _a.validationResp;
    var titleText = 'Quick Price';
    return (_jsx(StyledDialogV2, __assign({ isOpen: isOpen, setIsOpen: setIsOpen, sizeProps: { width: '90%', maxWidth: '1800px' }, rowGap: "16px", padding: "28px", title: titleText }, { children: _jsx(QuickPriceDetails, { validationResp: validationResp, effectiveDate: effectiveDate, productReference: productReference, showPriceTable: true, modalName: "QuickPrice", currency: CURRENCY_LIST.USD }) })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import { PubInputField } from 'components/Shared/PubInputField/PubInputField';
import { InputFieldType } from 'models/Input/InputModels';
import HelpIcon from '@mui/icons-material/Help';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { PubTableActionCell } from '../PubTableActionCell/PubTableActionCell';
export function PubTableEditRow(_a) {
    var def = _a.def, inputRow = _a.inputRow, validateRow = _a.validateRow, doneHandler = _a.doneHandler, closeHandler = _a.closeHandler;
    var _b = useState(__assign({}, inputRow)), rowCopy = _b[0], setRowCopy = _b[1];
    var _c = useState({}), fieldErrors = _c[0], setFieldErrors = _c[1];
    var _d = useState({}), rowErrors = _d[0], setRowErrors = _d[1];
    useEffect(function () {
        if (validateRow) {
            var errorRsp = validateRow(rowCopy);
            setRowErrors(errorRsp !== null && errorRsp !== void 0 ? errorRsp : {});
        }
    }, [rowCopy, validateRow]);
    return (_jsxs(_Fragment, { children: [def.rowDecorator && (_jsx(TableCell, __assign({ sx: { backgroundColor: def.showAddRow ? 'white' : 'inherit' } }, { children: " " }))), def.columns.map(function (column, i) {
                var _a, _b, _c, _d, _e, _f;
                return (_jsx(TableCell, __assign({ sx: {
                        paddingLeft: "".concat(!def.rowDecorator && i === 0 ? 16 : 0, "px !important"),
                        backgroundColor: def.showAddRow ? 'white' : 'inherit',
                    } }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '8px' } }, { children: [_jsx(PubInputField, { fieldType: column.fieldType, fieldValue: column.fieldType === InputFieldType.READONLY && column.formatter
                                    ? column.formatter((_b = (_a = rowCopy[column.dataAttribute]) !== null && _a !== void 0 ? _a : column.defaultValue) !== null && _b !== void 0 ? _b : undefined)
                                    : (_d = (_c = rowCopy[column.dataAttribute]) !== null && _c !== void 0 ? _c : column.defaultValue) !== null && _d !== void 0 ? _d : undefined, originalValue: (_f = (_e = inputRow[column.dataAttribute]) !== null && _e !== void 0 ? _e : column.defaultValue) !== null && _f !== void 0 ? _f : undefined, fieldOptions: column.allowedFieldValues, isUnique: column.isUnique, customErrorMsg: rowErrors[column.dataAttribute], fieldValueUpdateHandler: function (newRowValue) {
                                    setRowCopy(function (prevRowValue) {
                                        prevRowValue[column.dataAttribute] = newRowValue;
                                        return __assign({}, prevRowValue);
                                    });
                                }, inError: function (error) {
                                    var _a;
                                    if (fieldErrors[column.heading] !== error)
                                        setFieldErrors(__assign(__assign({}, fieldErrors), (_a = {}, _a[column.heading] = error, _a)));
                                }, validations: column.validations }, column.heading), column.showHelpTooltip && (_jsx(Tooltip, __assign({ title: "test help text", arrow: true, placement: "top" }, { children: _jsx(HelpIcon, { fontSize: "small" }) })))] })) }), column.heading));
            }), def.extraIcon && _jsx(TableCell, {}), _jsx(PubTableActionCell, { icon: _jsx(DoneIcon, {}), handleActionClick: function () { return doneHandler(rowCopy); }, disabled: Object.values(fieldErrors).find(function (el) { return el === true; }), isAddRow: def.showAddRow }), _jsx(PubTableActionCell, { icon: _jsx(CloseIcon, {}), handleActionClick: function () { return closeHandler(); }, isAddRow: def.showAddRow })] }));
}

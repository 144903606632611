function snakeCaseToNormalText(snakeCaseString, capitalizeAllWords) {
    var normalString = snakeCaseString.replace(/_/g, ' ').toLowerCase();
    if (capitalizeAllWords) {
        normalString = normalString
            .split(' ')
            .map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); })
            .join(' ');
    }
    return normalString;
}
function normalTextToSnakeCase(normalText, upperCase) {
    var snakeCaseString = normalText.replace(/\s+/g, '_');
    if (upperCase) {
        snakeCaseString = snakeCaseString.toUpperCase();
    }
    return snakeCaseString;
}
function removeHyphens(text) {
    return text.replace(/-/g, '');
}
var textFormatHelper = { snakeCaseToNormalText: snakeCaseToNormalText, normalTextToSnakeCase: normalTextToSnakeCase, removeHyphens: removeHyphens };
export { textFormatHelper };

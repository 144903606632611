var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { StyledButtonV2 } from 'components/Shared/StyledButtonV2/StyledButtonV2';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import uploadImageIconBold from 'assets/uploadImageIconBold.svg';
import IconButton from '@mui/material/IconButton';
import { FileSelectInput } from 'components/Shared/FileSelectInput/FileSelectInput';
import { fileUploadHelper } from 'utils/file-upload-helper';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import styles from './DrawingDisplay.module.scss';
export function DrawingDisplay(_a) {
    var imageFile = _a.imageFile, fileSelectProps = _a.fileSelectProps, handleFileSelect = _a.handleFileSelect, handleDelete = _a.handleDelete;
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var _b = useState(), image = _b[0], setImage = _b[1];
    var selectFile = function (dataTransferFiles) {
        var fileSelect = fileUploadHelper.fileSelectHandler(dataTransferFiles, fileSelectProps.allowedFileTypes, fileSelectProps.maxFileSizeMb);
        if (fileSelect.message) {
            setSnackbarAlert({ message: fileSelect.message, type: 'error' });
        }
        if (fileSelect.file) {
            handleFileSelect(fileSelect.file);
        }
    };
    useLayoutEffect(function () {
        if (typeof imageFile !== 'string') {
            var fileReader = new FileReader();
            fileReader.onload = function (e) {
                var _a;
                setImage((_a = e.target) === null || _a === void 0 ? void 0 : _a.result);
            };
            fileReader.readAsDataURL(imageFile);
        }
    }, [imageFile]);
    return (_jsxs(Box, __assign({ className: styles.container }, { children: [_jsx(Box, __assign({ className: styles.imageContainer }, { children: _jsxs(Box, __assign({ className: styles.image }, { children: [typeof imageFile === 'string' ? (_jsx("img", { src: imageFile, alt: "Drawing Asset" })) : (_jsx("img", { src: "".concat(image), alt: imageFile.name })), _jsx(IconButton, __assign({ className: styles.trashIcon, onClick: function () { return handleDelete(); } }, { children: _jsx(DeleteOutlinedIcon, {}) }))] })) })), _jsx(Box, __assign({ className: styles.buttonColumnContainer }, { children: _jsx(Box, __assign({ className: styles.buttons }, { children: _jsx(StyledButtonV2, __assign({ componentOverride: "label", roleOverride: undefined, labelText: "Replace Current", backgroundColorVariation: "secondary", adornment: {
                            position: 'right',
                            icon: (_jsx("img", { className: styles.uploadIcon, src: uploadImageIconBold, alt: "Upload Drawing Icon" })),
                        }, 
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        buttonAction: function (e) {
                            e.target.value = null;
                        } }, { children: _jsx(FileSelectInput, { fileSelectHandler: selectFile, allowedFileTypes: fileSelectProps.allowedFileTypes }) })) })) }))] })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FeatureLookupModal } from 'components/Shared/FeatureModalLookup/FeatureLookupModal';
import { InputFieldType } from 'models/Input/InputModels';
import { useMemo, useState } from 'react';
import { getNonStandardOptions } from 'services/api/search-nso';
import styles from './NonStdLookupModal.module.scss';
export function NonStdsLookupModal(_a) {
    var _this = this;
    var _b = _a.isEditMode, isEditMode = _b === void 0 ? true : _b, isOpen = _a.isOpen, rowData = _a.rowData, featureDescription = _a.featureDescription, featureStep = _a.featureStep, nsoMetadata = _a.nsoMetadata, showOptionalColumns = _a.showOptionalColumns, widthOverride = _a.widthOverride, setIsOpen = _a.setIsOpen, changeCodeHandler = _a.changeCodeHandler, updateRowOptions = _a.updateRowOptions;
    var _c = useState(nsoMetadata !== null && nsoMetadata !== void 0 ? nsoMetadata : JSON.parse(sessionStorage.getItem('nsoMetadata'))), metadataForNso = _c[0], _ = _c[1];
    var _d = useState(rowData), data = _d[0], setData = _d[1];
    var _e = useState(), modArray = _e[0], setModArray = _e[1];
    var _f = useState(), searchText = _f[0], setSearchText = _f[1];
    var tableData = useMemo(function () {
        return data === null || data === void 0 ? void 0 : data.reduce(function (agg, el, index) {
            var _a;
            return (__assign(__assign({}, agg), (_a = {}, _a[index] = {
                code: "".concat(el.optionCode, " - ").concat(el.optionDescription, " (").concat(el.nsoGroupCode, ")"),
                leadTime: el.minLeadTime ? el.minLeadTime.toString() : null,
                gsaInd: el.gsaInd,
                isLast: data.length - 250 === index,
                prcOptionModelCode: el.prcOptionModelCode,
                mfgOptionModelCode: el.mfgOptionModelCode,
            }, _a)));
        }, {});
    }, [data]);
    var tableDefinition = useMemo(function () {
        var def = {
            noDataMessage: 'No Options met the search criteria',
            columns: [
                {
                    dataAttribute: 'code',
                    fieldType: InputFieldType.READONLY,
                    heading: 'Option Code - Description',
                    width: showOptionalColumns === 'Show None' ? '30%' : '20%',
                },
                {
                    dataAttribute: 'leadTime',
                    fieldType: InputFieldType.READONLY,
                    heading: 'Lead Time',
                    width: showOptionalColumns === 'Show None' ? '20%' : '10%',
                },
                {
                    dataAttribute: 'gsaInd',
                    fieldType: InputFieldType.READONLY,
                    heading: 'GSA',
                    width: showOptionalColumns === 'Show None' ? '20%' : '10%',
                },
            ],
            showHover: isEditMode,
            underlineColumnRow: ['Option Code - Description'],
        };
        if (showOptionalColumns !== 'Show None') {
            var optionalCol = {
                dataAttribute: showOptionalColumns === 'Engineering Model Codes' ? 'mfgOptionModelCode' : 'prcOptionModelCode',
                fieldType: InputFieldType.READONLY,
                heading: 'Option Model Code',
                width: '40%',
            };
            def.columns = __spreadArray(__spreadArray(__spreadArray([], def.columns.slice(0, 1), true), [optionalCol], false), def.columns.slice(1), true);
        }
        return def;
    }, [isEditMode, showOptionalColumns]);
    var fireNsoApi = function (metadata) { return __awaiter(_this, void 0, void 0, function () {
        var request, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = {
                        productVersion: metadata.productVersion,
                        productNbr: metadata.productNbr,
                        groupCode: !metadata.groupCodes ? metadata.groupCode : undefined,
                        groupCodes: metadata.groupCodes && metadata.groupCodes,
                        featureId: metadata.featureId,
                        effectiveDate: metadata.effectiveDate,
                        startAt: metadata.startAt,
                        pageSize: 500,
                        businessUnitCode: metadata.businessUnitCode,
                    };
                    if (searchText) {
                        request.filterText = searchText;
                    }
                    return [4 /*yield*/, getNonStandardOptions(request)];
                case 1:
                    response = _a.sent();
                    metadataForNso.startAt = response.body.startAt;
                    setData(function (prevVal) {
                        var newArray = __spreadArray(__spreadArray([], prevVal, true), response.body.data, true);
                        updateRowOptions === null || updateRowOptions === void 0 ? void 0 : updateRowOptions(newArray);
                        var reducedArray = newArray === null || newArray === void 0 ? void 0 : newArray.reduce(function (agg, el, index) {
                            var _a;
                            return (__assign(__assign({}, agg), (_a = {}, _a[index] = {
                                code: "".concat(el.optionCode, " - ").concat(el.optionDescription, " (").concat(el.nsoGroupCode, ")"),
                                leadTime: el.minLeadTime ? el.minLeadTime.toString() : null,
                                gsaInd: el.gsaInd ? 'Y' : 'N',
                                isLast: newArray.length - 250 === index,
                                prcOptionModelCode: el.prcOptionModelCode,
                                mfgOptionModelCode: el.mfgOptionModelCode,
                            }, _a)));
                        }, {});
                        setModArray(reducedArray);
                        return newArray;
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var updateRowOptionsInLookupModal = function (e) {
        setData(e);
        updateRowOptions === null || updateRowOptions === void 0 ? void 0 : updateRowOptions(e);
    };
    return (_jsx(FeatureLookupModal, __assign({ isOpen: isOpen, setIsOpen: function () {
            setIsOpen(!isOpen);
        }, title: "".concat(isEditMode ? 'Select' : 'View', " Non-Standard Options"), tableDefinition: tableDefinition, tableData: modArray !== null && modArray !== void 0 ? modArray : tableData, filterLabel: "Search by Code or Keyword", changeCodeHandler: changeCodeHandler, tooltip: "Select a Non-Standard Option from the table below", isNso: true, nsoMetadata: metadataForNso, fireApiInModal: fireNsoApi, updateRowOptions: updateRowOptionsInLookupModal, updateSearchTextInParent: function (e) { return setSearchText(e); }, widthOverride: widthOverride }, { children: _jsxs(Box, __assign({ className: styles.labelContainer }, { children: [_jsxs(Box, __assign({ className: styles.labelRow, sx: { paddingBottom: '6px' } }, { children: [_jsx(Box, __assign({ className: styles.labelColumn }, { children: _jsx(Typography, __assign({ className: styles.labelFont }, { children: "Product Feature Step" })) })), _jsx(Typography, { children: featureStep })] })), _jsxs(Box, __assign({ className: styles.labelRow }, { children: [_jsx(Box, __assign({ className: styles.labelColumn }, { children: _jsx(Typography, __assign({ className: styles.labelFont }, { children: "Feature" })) })), _jsx(Typography, { children: featureDescription })] }))] })) })));
}

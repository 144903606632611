var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { StyledDialogV2 } from '../StyledDialogV2/StyledDialogV2';
import { StyledButtonV2 } from '../StyledButtonV2/StyledButtonV2';
export function ActionModal(_a) {
    var children = _a.children, buttonProps = _a.buttonProps, title = _a.title, messages = _a.messages, isOpen = _a.isOpen, sizeProps = _a.sizeProps, setIsOpen = _a.setIsOpen;
    return (_jsxs(StyledDialogV2, __assign({ isOpen: isOpen, setIsOpen: setIsOpen, sizeProps: sizeProps !== null && sizeProps !== void 0 ? sizeProps : { width: '40%' }, rowGap: "16px", padding: "28px", title: title }, { children: [children && _jsx(Box, { children: children }), messages && (_jsx(Box, __assign({ marginY: "32px" }, { children: messages.map(function (message, i) { return (
                // eslint-disable-next-line react/no-array-index-key
                _jsxs(Typography, { children: [message, message.trim() === '' && _jsx("br", {})] }, "".concat(message, "_").concat(i))); }) }))), _jsx(Box, __assign({ width: "100%", display: "flex", columnGap: "16px", justifyContent: "flex-end" }, { children: buttonProps.map(function (buttonProp) { return (_jsx(StyledButtonV2, { labelText: buttonProp.labelText, backgroundColorVariation: buttonProp.backgroundColorVariation, buttonAction: function () {
                        if (buttonProp.buttonAction)
                            buttonProp.buttonAction();
                    }, colorOverride: buttonProp.colorOverride, disabled: buttonProp.disabled, disabledColorOverride: buttonProp.disabledColorOverride, height: buttonProp.height, hoverColorOverride: buttonProp.hoverColorOverride, width: buttonProp.width }, buttonProp.labelText)); }) }))] })));
}

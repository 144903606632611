var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { StyledDialogV2 } from '../StyledDialogV2/StyledDialogV2';
import { PubTable } from '../PubTable/PubTable';
export function LookupModal(_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, title = _a.title, tableDefinition = _a.tableDefinition, tableData = _a.tableData;
    var _b = useState(), tableDataValues = _b[0], setTableDataValues = _b[1];
    useEffect(function () {
        if (!tableDataValues && tableData && Object.keys(tableData).length > 0)
            setTableDataValues(tableData);
    }, [tableData, tableDataValues]);
    var handleSearchInput = function (text) {
        var filteredData = Object.values(tableData)
            .filter(function (item) {
            var _a, _b;
            return ((_a = item.code) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(text.toLowerCase())) ||
                ((_b = item.description) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(text.toLowerCase()));
        })
            .reduce(function (prev, item) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[item.code] = item, _a)));
        }, {});
        setTableDataValues(filteredData);
    };
    return (_jsxs(StyledDialogV2, __assign({ isOpen: isOpen, setIsOpen: setIsOpen, sizeProps: { width: '40%' }, rowGap: "16px", padding: "28px", title: title }, { children: [_jsx(TextField, { size: "small", label: "Enter text to search Code or Description", onInput: function (event) {
                    handleSearchInput(event.target.value);
                } }), _jsx(PubTable, { def: tableDefinition, tableData: tableDataValues !== null && tableDataValues !== void 0 ? tableDataValues : {}, alternateRowBackgroundColor: false })] })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CircularProgress from '@mui/material/CircularProgress';
import { handleOptionSave } from 'services/helperFunctions/handleOptionSave';
import { noSpaces, noSpacesAll } from 'services/helperFunctions/handleRegex';
import { EngineeringDerivedFeature, EngineeringFeature, SaveDraftRequest, } from '@millerknoll/pub-api-schema';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { saveDraft } from 'services/api/engineering-service';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { useCheckAuthScope } from 'services/helperFunctions/useCheckAuthScope';
import { useLanguageCode } from 'services/helperFunctions/useLanguageCode';
import { UI_WRITE_SCOPES } from 'utils/constants';
import { OptionsInputSection } from './OptionsInputSection/OptionsInputSection';
import { StyledButton } from '../StyledComponents/StyledButton';
import styles from './FeatureModal.module.scss';
export var FeatureModal = forwardRef(function (_a, ref) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    var isEditable = _a.isEditable, showMessageForDuplicateIds = _a.showMessageForDuplicateIds, setShowMessageForDuplicateIds = _a.setShowMessageForDuplicateIds, saveButtonClick = _a.saveButtonClick, featureToInject = _a.featureToInject, addFeatureModal = _a.addFeatureModal, isModalLoaded = _a.isModalLoaded, derivedFeatureToInject = _a.derivedFeatureToInject, derivedFeatureIdToDelete = _a.derivedFeatureIdToDelete, featureData = _a.featureData, optionCode = _a.optionCode, productId = _a.productId, productVersion = _a.productVersion, sendDerivedFeatureToParent = _a.sendDerivedFeatureToParent, existingDerivedFeature = _a.existingDerivedFeature, handleCopyButtonClick = _a.handleCopyButtonClick, programmaticallyInjectNewDerivedFeature = _a.programmaticallyInjectNewDerivedFeature, handleDeleteButtonClick = _a.handleDeleteButtonClick, addToSequence = _a.addToSequence, _v = _a.disableOptionCode, disableOptionCode = _v === void 0 ? false : _v;
    var languageCode = useLanguageCode();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var optionInputSectionRef = useRef(null);
    var userHasEditPrivileges = useCheckAuthScope(UI_WRITE_SCOPES);
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var _w = useState(existingDerivedFeature !== null && existingDerivedFeature !== void 0 ? existingDerivedFeature : false), isDerivedFeature = _w[0], setIsDerivedFeature = _w[1];
    var _x = useState(false), isNewDerivedFeature = _x[0], setIsNewDerivedFeature = _x[1];
    var _y = useState(false), derivedFeatureWithDuplicateId = _y[0], setDerivedFeatureWithDuplicateId = _y[1];
    var _z = useState(), disableSaveFromChild = _z[0], setDisableSaveFromChild = _z[1];
    var _0 = useState((_c = (_b = featureToInject === null || featureToInject === void 0 ? void 0 : featureToInject.featureId) !== null && _b !== void 0 ? _b : derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.featureId) !== null && _c !== void 0 ? _c : ''), newFeatureId = _0[0], setNewFeatureId = _0[1];
    var _1 = useState((_j = (_f = (_e = (_d = featureToInject === null || featureToInject === void 0 ? void 0 : featureToInject.desc) === null || _d === void 0 ? void 0 : _d["".concat(languageCode)]) === null || _e === void 0 ? void 0 : _e.desc) !== null && _f !== void 0 ? _f : (_h = (_g = derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.featureDescription) === null || _g === void 0 ? void 0 : _g["".concat(languageCode)]) === null || _h === void 0 ? void 0 : _h.desc) !== null && _j !== void 0 ? _j : ''), newFeatureDescription = _1[0], setNewFeatureDescription = _1[1];
    var _2 = useState((_l = (_k = featureToInject === null || featureToInject === void 0 ? void 0 : featureToInject.mfgMdlCd) !== null && _k !== void 0 ? _k : derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.mfgMdlCd) !== null && _l !== void 0 ? _l : ''), newMFeatureModelCode = _2[0], setNewMFeatureModelCode = _2[1];
    var _3 = useState((_o = (_m = featureToInject === null || featureToInject === void 0 ? void 0 : featureToInject.prcMdlCd) !== null && _m !== void 0 ? _m : derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.prcMdlCd) !== null && _o !== void 0 ? _o : ''), newPFeatureModelCode = _3[0], setNewPFeatureModelCode = _3[1];
    var _4 = useState((_q = (_p = featureToInject === null || featureToInject === void 0 ? void 0 : featureToInject.mtrlCd) !== null && _p !== void 0 ? _p : derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.mtrlCd) !== null && _q !== void 0 ? _q : ''), newMaterialCode = _4[0], setNewMaterialCode = _4[1];
    var _5 = useState(false), isSaveButtonLoading = _5[0], setIsSaveButtonLoading = _5[1];
    var codeArray = [
        newFeatureId,
        newMFeatureModelCode,
        newPFeatureModelCode,
        newMaterialCode,
        (_r = optionInputSectionRef.current) === null || _r === void 0 ? void 0 : _r.getOptionCode(),
        (_s = optionInputSectionRef.current) === null || _s === void 0 ? void 0 : _s.getMOptionModelCode(),
        (_t = optionInputSectionRef.current) === null || _t === void 0 ? void 0 : _t.getPOptionModelCode(),
    ];
    var addFeatureModalRef = useRef(null);
    useEffect(function () {
        if (addFeatureModal) {
            isModalLoaded === null || isModalLoaded === void 0 ? void 0 : isModalLoaded(true);
        }
    }, [addFeatureModal, isModalLoaded]);
    useEffect(function () {
        if (derivedFeatureToInject && (derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.optionCode) !== '') {
            setIsDerivedFeature(true);
            optionInputSectionRef.current.receiveDerivedFeature(derivedFeatureToInject);
        }
        if ((derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.optionCode) === '') {
            setIsNewDerivedFeature(true);
        }
    }, [derivedFeatureToInject, featureData, optionCode]);
    useImperativeHandle(ref, function () { return ({
        injectValuesByForce: function (data) {
            var _a, _b, _c, _d, _e, _f, _g;
            // triggered by clicking copy in TableDataRow
            setDerivedFeatureWithDuplicateId(false);
            if (data.featureId.includes('Implied')) {
                setNewFeatureId(data === null || data === void 0 ? void 0 : data.featureId);
            }
            setNewFeatureDescription((_d = (_b = (_a = data === null || data === void 0 ? void 0 : data.desc) === null || _a === void 0 ? void 0 : _a["".concat(languageCode)].desc) !== null && _b !== void 0 ? _b : (_c = data === null || data === void 0 ? void 0 : data.featureDescription) === null || _c === void 0 ? void 0 : _c["".concat(languageCode)].desc) !== null && _d !== void 0 ? _d : '');
            setNewMFeatureModelCode((_e = data === null || data === void 0 ? void 0 : data.mfgMdlCd) !== null && _e !== void 0 ? _e : '');
            setNewPFeatureModelCode((_f = data === null || data === void 0 ? void 0 : data.prcMdlCd) !== null && _f !== void 0 ? _f : '');
            setNewMaterialCode((_g = data === null || data === void 0 ? void 0 : data.mtrlCd) !== null && _g !== void 0 ? _g : '');
            optionInputSectionRef.current.receiveNewOptionData(data);
        },
        scrollToAddFeatureModal: function () {
            var _a;
            (_a = addFeatureModalRef === null || addFeatureModalRef === void 0 ? void 0 : addFeatureModalRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth', block: 'center' });
        },
        injectNewDerivedFeatureData: function (data) {
            var _a, _b, _c, _d, _e;
            setIsNewDerivedFeature(false);
            setNewFeatureId(data === null || data === void 0 ? void 0 : data.featureId);
            setNewFeatureDescription((_b = (_a = data === null || data === void 0 ? void 0 : data.featureDescription) === null || _a === void 0 ? void 0 : _a["".concat(languageCode)].desc) !== null && _b !== void 0 ? _b : '');
            setNewMFeatureModelCode((_c = data === null || data === void 0 ? void 0 : data.mfgMdlCd) !== null && _c !== void 0 ? _c : '');
            setNewPFeatureModelCode((_d = data === null || data === void 0 ? void 0 : data.prcMdlCd) !== null && _d !== void 0 ? _d : '');
            setNewMaterialCode((_e = data === null || data === void 0 ? void 0 : data.mtrlCd) !== null && _e !== void 0 ? _e : '');
            optionInputSectionRef.current.receiveNewDerivedFeatureData(data);
        },
    }); });
    var handleAddFeatureInputFieldChange = function (fnc, event, input) {
        if (userHasEditPrivileges) {
            if (isEditable) {
                fnc(event.target.value);
            }
            if (input === 'id') {
                if (showMessageForDuplicateIds || derivedFeatureWithDuplicateId) {
                    setShowMessageForDuplicateIds === null || setShowMessageForDuplicateIds === void 0 ? void 0 : setShowMessageForDuplicateIds(false);
                    setDerivedFeatureWithDuplicateId(false);
                }
            }
            if (input === 'description' && !event.target.value) { // Added this check for description
                if (!/^$/g.test(event.target.value)) {
                    disableSaveProgrammatically === null || disableSaveProgrammatically === void 0 ? void 0 : disableSaveProgrammatically();
                }
            }
        }
    };
    var saveNewDerivedFeature = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    setIsSaveButtonLoading(true);
                    return [4 /*yield*/, saveDraft({
                            productId: productId !== null && productId !== void 0 ? productId : '',
                            productVersion: productVersion !== null && productVersion !== void 0 ? productVersion : '',
                            feature: {
                                featureId: (_a = featureData === null || featureData === void 0 ? void 0 : featureData.featureId) !== null && _a !== void 0 ? _a : '',
                                step: (_b = featureData === null || featureData === void 0 ? void 0 : featureData.step) !== null && _b !== void 0 ? _b : 0,
                                type: (_c = featureData === null || featureData === void 0 ? void 0 : featureData.type) !== null && _c !== void 0 ? _c : EngineeringFeature.type.NON_BASE,
                                desc: featureData === null || featureData === void 0 ? void 0 : featureData.desc,
                                mtrlCd: featureData === null || featureData === void 0 ? void 0 : featureData.mtrlCd,
                                mfgMdlCd: featureData === null || featureData === void 0 ? void 0 : featureData.mfgMdlCd,
                                prcMdlCd: featureData === null || featureData === void 0 ? void 0 : featureData.prcMdlCd,
                                options: featureData === null || featureData === void 0 ? void 0 : featureData.options,
                            },
                            status: SaveDraftRequest.status.DRAFT,
                        })];
                case 1:
                    response = _d.sent();
                    if (response.status !== 200) {
                        setSnackbarAlert({
                            message: response.body.message || '',
                            type: 'error',
                        });
                    }
                    else {
                        setSnackbarAlert({
                            message: "Derived feature with ID '".concat(newFeatureId, "' saved"),
                            type: 'success',
                        });
                        setIsSaveButtonLoading(false);
                    }
                    if (addToSequence)
                        addToSequence();
                    return [2 /*return*/, response];
            }
        });
    }); };
    var handleSaveButtonClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newFeatureValues, featureDataWithNewDerivedFeature, featureOptions;
        var _a, _b, _c;
        var _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    newFeatureValues = optionInputSectionRef.current.emitNewOptionData();
                    if (!isNewDerivedFeature) {
                        saveButtonClick === null || saveButtonClick === void 0 ? void 0 : saveButtonClick(newFeatureValues, {
                            newFeatureId: newFeatureId,
                            newFeatureDescription: newFeatureDescription,
                            newMFeatureModelCode: newMFeatureModelCode,
                            newPFeatureModelCode: newPFeatureModelCode,
                            newMaterialCode: newMaterialCode,
                        });
                        if (addToSequence)
                            addToSequence();
                        return [2 /*return*/];
                    }
                    if (!featureData) return [3 /*break*/, 4];
                    featureDataWithNewDerivedFeature = __assign((_a = {}, _a["".concat(newFeatureId)] = {
                        featureId: newFeatureId,
                        step: featureData.step,
                        type: EngineeringDerivedFeature.type.DERIVED,
                        featureDescription: (_b = {}, _b["".concat(languageCode)] = { desc: newFeatureDescription }, _b),
                        mtrlCd: newMaterialCode,
                        mfgMdlCd: newMFeatureModelCode,
                        prcMdlCd: newPFeatureModelCode,
                        optionCode: newFeatureValues.optionCode,
                        optionDescription: (_c = {}, _c["".concat(languageCode)] = { desc: newFeatureValues.desc }, _c),
                        mfgOptMdlCd: newFeatureValues.mfgOptMdlCd,
                        prcOptMdlCd: newFeatureValues.prcOptMdlCd,
                    }, _a), ((_d = featureData.options) === null || _d === void 0 ? void 0 : _d[optionCode]).derivedFeatures);
                    featureOptions = (_e = featureData.options) === null || _e === void 0 ? void 0 : _e[optionCode];
                    ((_f = featureData.options) === null || _f === void 0 ? void 0 : _f[optionCode]).derivedFeatures =
                        featureDataWithNewDerivedFeature;
                    if (!featureOptions.derivedFeatures) return [3 /*break*/, 2];
                    return [4 /*yield*/, saveNewDerivedFeature().then(function (res) {
                            var _a, _b;
                            if (res.status === 200 && !isSaveButtonLoading) {
                                if ((_a = featureData === null || featureData === void 0 ? void 0 : featureData.options) === null || _a === void 0 ? void 0 : _a[optionCode]) {
                                    sendDerivedFeatureToParent === null || sendDerivedFeatureToParent === void 0 ? void 0 : sendDerivedFeatureToParent((_b = featureData.options) === null || _b === void 0 ? void 0 : _b[optionCode].derivedFeatures);
                                    if (isNewDerivedFeature) {
                                        programmaticallyInjectNewDerivedFeature === null || programmaticallyInjectNewDerivedFeature === void 0 ? void 0 : programmaticallyInjectNewDerivedFeature();
                                    }
                                }
                            }
                        })];
                case 1:
                    _h.sent();
                    return [3 /*break*/, 4];
                case 2:
                    ((_g = featureData.options) === null || _g === void 0 ? void 0 : _g[optionCode]).derivedFeatures =
                        featureDataWithNewDerivedFeature;
                    return [4 /*yield*/, saveNewDerivedFeature().then(function (res) {
                            var _a, _b;
                            if (res.status === 200 && !isSaveButtonLoading) {
                                if ((_a = featureData === null || featureData === void 0 ? void 0 : featureData.options) === null || _a === void 0 ? void 0 : _a[optionCode]) {
                                    sendDerivedFeatureToParent === null || sendDerivedFeatureToParent === void 0 ? void 0 : sendDerivedFeatureToParent((_b = featureData.options) === null || _b === void 0 ? void 0 : _b[optionCode].derivedFeatures);
                                }
                            }
                        })];
                case 3:
                    _h.sent();
                    _h.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var checkForModalInputErrors = function (arr) {
        var returnVal;
        arr.every(function (val) {
            if (!noSpaces(val)) {
                returnVal = false;
                return false;
            }
            returnVal = true;
            return true;
        });
        return returnVal;
    };
    var handleOptionCodeValueChange = function (val) {
        setDisableSaveFromChild(val);
    };
    var setModalBackgroundColor = function (value) {
        if (featureToInject) {
            if (showMessageForDuplicateIds || !checkForModalInputErrors(codeArray)) {
                // copy of new implied feature
                return 'rgba(255, 0, 0, 0.1)';
            }
        }
        if (derivedFeatureToInject && (derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.optionCode) !== '') {
            // existing derived features shouldn't have the same style as new implied features;
            return "".concat(value ? 'rgba(255, 255, 255, 1)' : 'rgba(178, 178, 178, .5)');
        }
        if (derivedFeatureToInject && derivedFeatureWithDuplicateId) {
            return 'rgba(255, 0, 0, 0.1)';
        }
        if (showMessageForDuplicateIds) {
            return 'rgba(255, 0, 0, 0.1)';
        }
        return "rgba(0, 255, 0, ".concat(value ? 0.5 : 0.2, ")");
    };
    var handleDerivedOptionFieldEdit = function (newVal, oldVal, param) { return __awaiter(void 0, void 0, void 0, function () {
        var prettyMessage, response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(newVal !== oldVal && !isNewDerivedFeature)) return [3 /*break*/, 2];
                    prettyMessage = '';
                    if (param === 'description' && (derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.featureDescription) && newVal.trim().length > 0) {
                        derivedFeatureToInject.featureDescription["".concat(languageCode)].desc = newVal;
                    }
                    if (param === 'mfgModelCode') {
                        derivedFeatureToInject.mfgMdlCd = newVal;
                    }
                    if (param === 'prcModelCode') {
                        derivedFeatureToInject.prcMdlCd = newVal;
                    }
                    if (param === 'materialCode') {
                        derivedFeatureToInject.mtrlCd = newVal;
                    }
                    if (param === 'optionDescription' && (derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.optionDescription)) {
                        derivedFeatureToInject.optionDescription["".concat(languageCode)].desc = newVal;
                        prettyMessage = 'Option Description';
                    }
                    if (param === 'optionCode') {
                        derivedFeatureToInject.optionCode = newVal;
                        prettyMessage = 'Option Code';
                    }
                    if (param === 'mfgOptionModelCode') {
                        derivedFeatureToInject.mfgOptMdlCd = newVal;
                        prettyMessage = 'Manufacturing Option Model Code';
                    }
                    if (param === 'prcOptionModelCode') {
                        derivedFeatureToInject.prcOptMdlCd = newVal;
                        prettyMessage = 'Price Option Model Code';
                    }
                    if (!featureData) return [3 /*break*/, 2];
                    return [4 /*yield*/, handleOptionSave(isEditable, featureData === null || featureData === void 0 ? void 0 : featureData.options, productId !== null && productId !== void 0 ? productId : '', productVersion !== null && productVersion !== void 0 ? productVersion : '', featureData)];
                case 1:
                    response = _b.sent();
                    if (prettyMessage) {
                        (_a = optionInputSectionRef === null || optionInputSectionRef === void 0 ? void 0 : optionInputSectionRef.current) === null || _a === void 0 ? void 0 : _a.receiveApiResponse(response, prettyMessage);
                    }
                    return [2 /*return*/, response];
                case 2: return [2 /*return*/, undefined];
            }
        });
    }); };
    var disableSaveProgrammatically = function () {
        setDisableSaveFromChild(function (prevVal) { return !prevVal; });
    };
    return (_jsxs(Box, __assign({ sx: {
            // optional styling for existing derived features
            borderRadius: '5px',
            border: existingDerivedFeature ? 'dashed black 1px' : '',
            backgroundColor: 'rgba(255, 255, 255, 1)',
        } }, { children: [existingDerivedFeature && isEditable && (_jsx(Box, __assign({ hidden: !userHasEditPrivileges }, { children: _jsx(Box, __assign({ sx: { display: 'flex', flexDirection: 'row' } }, { children: _jsxs(Box, __assign({ sx: { marginLeft: 'auto' } }, { children: [_jsx(IconButton, __assign({ onClick: function () { return handleCopyButtonClick === null || handleCopyButtonClick === void 0 ? void 0 : handleCopyButtonClick(derivedFeatureToInject); } }, { children: _jsx(ContentCopyIcon, {}) })), (derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.featureId) !== (derivedFeatureIdToDelete === null || derivedFeatureIdToDelete === void 0 ? void 0 : derivedFeatureIdToDelete[0]) ? (_jsx(IconButton, __assign({ onClick: function () {
                                    handleDeleteButtonClick === null || handleDeleteButtonClick === void 0 ? void 0 : handleDeleteButtonClick(derivedFeatureToInject);
                                } }, { children: _jsx(DeleteIcon, {}) }))) : (_jsx(IconButton, __assign({ className: styles.copyButton }, { children: _jsx(CircularProgress, { size: "1.5rem" }) })))] })) })) }))), _jsx(Box, __assign({ className: styles.modalContainer, sx: {
                    backgroundColor: setModalBackgroundColor(),
                }, ref: addFeatureModalRef }, { children: _jsxs(Box, __assign({ className: styles.inputsContainer }, { children: [((derivedFeatureWithDuplicateId && isNewDerivedFeature) || showMessageForDuplicateIds) && (_jsx(Typography, __assign({ className: styles.errorMessage }, { children: "".concat(isNewDerivedFeature ? 'Derived feature' : 'Feature', " with ID '").concat((_u = featureToInject === null || featureToInject === void 0 ? void 0 : featureToInject.featureId) !== null && _u !== void 0 ? _u : newFeatureId, "' already exists for this ").concat(isNewDerivedFeature ? 'feature' : 'product', " - please specify a new ID") }))), _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'row' } }, { children: [_jsx(Box, __assign({ className: styles.labelAndInputRow, sx: { marginRight: derivedFeatureToInject ? '0' : '15px' } }, { children: _jsx(TextField, { label: "Feature ID", variant: "outlined", size: "small", inputProps: { style: { width: '270px', height: '20px' } }, value: newFeatureId !== null && newFeatureId !== void 0 ? newFeatureId : '', onChange: function (e) { return handleAddFeatureInputFieldChange(setNewFeatureId, e, 'id'); }, disabled: true, error: !noSpaces(newFeatureId) || showMessageForDuplicateIds, required: true }) })), _jsx(Box, __assign({ className: styles.labelAndInputRow, sx: { marginLeft: 'auto !important', width: '100%', marginRight: '20px' } }, { children: _jsx(TextField, { size: "small", label: "Description", variant: "outlined", sx: {
                                            width: { sm: 'inherit' },
                                        }, onChange: function (e) {
                                            return handleAddFeatureInputFieldChange(setNewFeatureDescription, e, 'description');
                                        }, onBlur: function () { return __awaiter(void 0, void 0, void 0, function () {
                                            var response;
                                            var _a, _b, _c;
                                            return __generator(this, function (_d) {
                                                switch (_d.label) {
                                                    case 0:
                                                        if (!isDerivedFeature) return [3 /*break*/, 2];
                                                        return [4 /*yield*/, handleDerivedOptionFieldEdit(newFeatureDescription, (_c = (_b = (_a = derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.featureDescription) === null || _a === void 0 ? void 0 : _a["".concat(languageCode)]) === null || _b === void 0 ? void 0 : _b.desc) !== null && _c !== void 0 ? _c : '', 'description')];
                                                    case 1:
                                                        response = _d.sent();
                                                        if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                                                            setSnackbarAlert({
                                                                message: (response === null || response === void 0 ? void 0 : response.body).message || '',
                                                                type: 'error',
                                                            });
                                                        }
                                                        else {
                                                            setSnackbarAlert({
                                                                message: "Derived Feature Description saved",
                                                                type: 'success',
                                                            });
                                                        }
                                                        _d.label = 2;
                                                    case 2: return [2 /*return*/];
                                                }
                                            });
                                        }); }, required: true, inputProps: { style: { height: '20px' } }, value: newFeatureDescription, error: !noSpacesAll(newFeatureDescription), disabled: !userHasEditPrivileges || !isEditable }) }))] })), _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'row', position: 'relative' } }, { children: [_jsxs(Box, __assign({ sx: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginRight: isDerivedFeature ? '' : '35px',
                                        paddingRight: isDerivedFeature ? '18px' : '',
                                    } }, { children: [_jsx(Box, __assign({ className: styles.labelAndInputRow }, { children: _jsx(TextField, { label: "Manufacturing Feature Model Code", variant: "outlined", size: "small", value: newMFeatureModelCode !== null && newMFeatureModelCode !== void 0 ? newMFeatureModelCode : '', onChange: function (e) {
                                                    return handleAddFeatureInputFieldChange(setNewMFeatureModelCode, e, 'mfgModelCode');
                                                }, onBlur: function () { return __awaiter(void 0, void 0, void 0, function () {
                                                    var response;
                                                    var _a;
                                                    return __generator(this, function (_b) {
                                                        switch (_b.label) {
                                                            case 0:
                                                                if (!isDerivedFeature) return [3 /*break*/, 2];
                                                                return [4 /*yield*/, handleDerivedOptionFieldEdit(newMFeatureModelCode, (_a = derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.mfgMdlCd) !== null && _a !== void 0 ? _a : '', 'mfgModelCode')];
                                                            case 1:
                                                                response = _b.sent();
                                                                if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                                                                    setSnackbarAlert({
                                                                        message: (response === null || response === void 0 ? void 0 : response.body).message || '',
                                                                        type: 'error',
                                                                    });
                                                                }
                                                                else {
                                                                    setSnackbarAlert({
                                                                        message: "Manufacturing Feature Model Code saved",
                                                                        type: 'success',
                                                                    });
                                                                }
                                                                _b.label = 2;
                                                            case 2: return [2 /*return*/];
                                                        }
                                                    });
                                                }); }, error: !noSpaces(newMFeatureModelCode), inputProps: { style: { width: '270px', height: '20px' } }, disabled: !userHasEditPrivileges || !isEditable }) })), _jsx(Box, __assign({ className: styles.labelAndInputRow }, { children: _jsx(TextField, { label: "Price Feature Model Code", variant: "outlined", size: "small", value: newPFeatureModelCode !== null && newPFeatureModelCode !== void 0 ? newPFeatureModelCode : '', onChange: function (e) {
                                                    return handleAddFeatureInputFieldChange(setNewPFeatureModelCode, e, 'mfgModelCode');
                                                }, onBlur: function () { return __awaiter(void 0, void 0, void 0, function () {
                                                    var response;
                                                    var _a;
                                                    return __generator(this, function (_b) {
                                                        switch (_b.label) {
                                                            case 0:
                                                                if (!isDerivedFeature) return [3 /*break*/, 2];
                                                                return [4 /*yield*/, handleDerivedOptionFieldEdit(newPFeatureModelCode, (_a = derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.prcMdlCd) !== null && _a !== void 0 ? _a : '', 'prcModelCode')];
                                                            case 1:
                                                                response = _b.sent();
                                                                if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                                                                    setSnackbarAlert({
                                                                        message: (response === null || response === void 0 ? void 0 : response.body).message || '',
                                                                        type: 'error',
                                                                    });
                                                                }
                                                                else {
                                                                    setSnackbarAlert({
                                                                        message: "Price Feature Model Code saved",
                                                                        type: 'success',
                                                                    });
                                                                }
                                                                _b.label = 2;
                                                            case 2: return [2 /*return*/];
                                                        }
                                                    });
                                                }); }, inputProps: { style: { width: '270px', height: '20px' } }, error: !noSpaces(newPFeatureModelCode), disabled: !userHasEditPrivileges || !isEditable }) })), _jsx(Box, __assign({ className: styles.labelAndInputRow }, { children: _jsx(TextField, { size: "small", label: "Material Code", variant: "outlined", value: newMaterialCode !== null && newMaterialCode !== void 0 ? newMaterialCode : '', onChange: function (e) {
                                                    return handleAddFeatureInputFieldChange(setNewMaterialCode, e, 'materialCode');
                                                }, onBlur: function () { return __awaiter(void 0, void 0, void 0, function () {
                                                    var response;
                                                    var _a;
                                                    return __generator(this, function (_b) {
                                                        switch (_b.label) {
                                                            case 0:
                                                                if (!isDerivedFeature) return [3 /*break*/, 2];
                                                                return [4 /*yield*/, handleDerivedOptionFieldEdit(newMaterialCode, (_a = derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.mtrlCd) !== null && _a !== void 0 ? _a : '', 'materialCode')];
                                                            case 1:
                                                                response = _b.sent();
                                                                if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                                                                    setSnackbarAlert({
                                                                        message: (response === null || response === void 0 ? void 0 : response.body).message || '',
                                                                        type: 'error',
                                                                    });
                                                                }
                                                                else {
                                                                    setSnackbarAlert({
                                                                        message: "Material Code saved",
                                                                        type: 'success',
                                                                    });
                                                                }
                                                                _b.label = 2;
                                                            case 2: return [2 /*return*/];
                                                        }
                                                    });
                                                }); }, error: !noSpaces(newMaterialCode), inputProps: { style: { width: '270px', height: '20px' } }, disabled: !userHasEditPrivileges || !isEditable }) }))] })), _jsx(Box, __assign({ sx: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        backgroundColor: setModalBackgroundColor('value'),
                                        borderRadius: '5px',
                                        border: 'dashed 1px',
                                        position: 'relative',
                                        right: !isDerivedFeature ? 20 : 0,
                                        paddingTop: '20px',
                                    } }, { children: _jsx(OptionsInputSection, { isEditable: isEditable, handleOptionCodeValueChange: handleOptionCodeValueChange, derivedFeatureToInject: derivedFeatureToInject, handleDerivedOptionFieldEdit: handleDerivedOptionFieldEdit, existingDerivedFeature: existingDerivedFeature, disableSaveProgrammatically: disableSaveProgrammatically, ref: optionInputSectionRef, disableOptionCode: disableOptionCode }) }))] })), (!isDerivedFeature || (derivedFeatureToInject === null || derivedFeatureToInject === void 0 ? void 0 : derivedFeatureToInject.optionCode) === '') && (_jsx(Box, __assign({ className: styles.saveButtonContainer, sx: { paddingRight: '20px', marginBottom: '10px' } }, { children: _jsx(StyledButton, { label: "Save", handleClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    var listOfIds;
                                    var _a, _b;
                                    return __generator(this, function (_c) {
                                        switch (_c.label) {
                                            case 0:
                                                if (!((_b = (_a = featureData === null || featureData === void 0 ? void 0 : featureData.options) === null || _a === void 0 ? void 0 : _a[optionCode]) === null || _b === void 0 ? void 0 : _b.derivedFeatures)) return [3 /*break*/, 7];
                                                listOfIds = Object.keys(featureData.options[optionCode].derivedFeatures);
                                                if (!(listOfIds.length > 0)) return [3 /*break*/, 4];
                                                if (!listOfIds.includes(newFeatureId)) return [3 /*break*/, 1];
                                                // set error state and don't save
                                                setDerivedFeatureWithDuplicateId(true);
                                                return [3 /*break*/, 3];
                                            case 1: return [4 /*yield*/, handleSaveButtonClick()];
                                            case 2:
                                                _c.sent();
                                                _c.label = 3;
                                            case 3: return [3 /*break*/, 6];
                                            case 4: return [4 /*yield*/, handleSaveButtonClick()];
                                            case 5:
                                                _c.sent(); // if there is no list to check against
                                                _c.label = 6;
                                            case 6: return [3 /*break*/, 9];
                                            case 7: return [4 /*yield*/, handleSaveButtonClick()];
                                            case 8:
                                                _c.sent();
                                                _c.label = 9;
                                            case 9: return [2 /*return*/];
                                        }
                                    });
                                }); }, disabledFromProps: newFeatureId === '' ||
                                    !newFeatureId ||
                                    showMessageForDuplicateIds ||
                                    newFeatureDescription === '' ||
                                    !newFeatureDescription ||
                                    disableSaveFromChild ||
                                    !checkForModalInputErrors(codeArray), loading: isSaveButtonLoading }) })))] })) }))] })));
});

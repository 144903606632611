var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useCpaProduct } from 'components/Cpa/CpaProduct/CpaProductContextProvider';
import Typography from '@mui/material/Typography';
export function CpaUnsavedEditCheckLabel() {
    var cpaProductContext = useCpaProduct();
    var _a = useState(false), hasChange = _a[0], setHasChange = _a[1];
    useEffect(function () {
        if (!cpaProductContext || !cpaProductContext.data.currentProduct)
            return;
        setHasChange(JSON.stringify(cpaProductContext.data.currentProductOriginal) !==
            JSON.stringify(cpaProductContext.data.currentProduct) ||
            JSON.stringify(cpaProductContext.data.pricingTableDataOriginal) !==
                JSON.stringify(cpaProductContext.data.pricingTableData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct,
        cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProductOriginal,
        cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.pricingTableData,
        cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.pricingTableDataOriginal,
    ]);
    return (_jsx(Typography, __assign({ position: "absolute", bottom: "-2em", width: "100%", textAlign: "center", color: "rgb(208, 122, 44)", display: hasChange ? 'block' : 'none' }, { children: "Unsaved Changes" })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useCallback } from 'react';
import { StyledDialog } from 'components/Shared/StyledDialog/StyledDialog';
import { getTrackerForPartialKey } from 'services/api/search-catalogs';
import { StyledTableContainer } from 'components/Shared/StyledTableContainer/StyledTableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { catalogHelper } from 'utils/catalog-helper';
import { ExtractTracker } from 'models/catalogs/ExtractTracker';
import { CatalogRequestsModelHeader } from '../CatalogRequestsModalHeader/CatalogRequestsModalHeader';
export function CatalogTimelineModal(_a) {
    var _this = this;
    var isOpen = _a.isOpen, requestId = _a.requestId, closeModal = _a.closeModal;
    var _b = useState([]), timeline = _b[0], setTimeline = _b[1];
    var _c = useState(), metadata = _c[0], setMetadata = _c[1];
    var _d = useState(true), timelineLoading = _d[0], setTimelineLoading = _d[1];
    var getTimeline = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var body;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTrackerForPartialKey(requestId, 'ACT_INF')];
                case 1:
                    body = (_a.sent()).body;
                    setTimeline(body.map(function (el) { return el.body; }));
                    setTimelineLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [requestId]);
    var getMetadata = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var body;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTrackerForPartialKey(requestId, 'METADATA')];
                case 1:
                    body = (_a.sent()).body;
                    setMetadata(body === null || body === void 0 ? void 0 : body[0].body);
                    return [2 /*return*/];
            }
        });
    }); }, [requestId]);
    useEffect(function () {
        getMetadata();
        getTimeline();
    }, [getMetadata, getTimeline]);
    useEffect(function () {
        var interval = setInterval(function () {
            if ((metadata === null || metadata === void 0 ? void 0 : metadata.status) !== ExtractTracker.status.COMPLETED &&
                (metadata === null || metadata === void 0 ? void 0 : metadata.status) !== ExtractTracker.status.FAILED) {
                getMetadata();
                getTimeline();
            }
        }, 5000);
        return function () { return clearInterval(interval); };
    }, [getMetadata, getTimeline, metadata === null || metadata === void 0 ? void 0 : metadata.status]);
    return (_jsxs(StyledDialog, __assign({ isOpen: isOpen, closeModal: closeModal }, { children: [metadata && _jsx(CatalogRequestsModelHeader, { requestBody: metadata }), !timelineLoading && (_jsxs(StyledTableContainer, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Description" }), _jsx(TableCell, { children: "Requested Date" }), _jsx(TableCell, { children: "Total Time" })] }) }), _jsxs(TableBody, { children: [timeline.map(function (el) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: el.description }), _jsx(TableCell, { children: catalogHelper.formatDateString(el.requestedDate) }), _jsx(TableCell, { children: catalogHelper.msToMinutesAndSeconds(el.totalTimeTaken) })] }, el.sk)); }), timeline.length === 0 && (_jsx(TableRow, { children: _jsx(TableCell, __assign({ colSpan: 3, sx: { textAlign: 'center' } }, { children: "No timeline activity" })) }))] })] }))] })));
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { InputFieldsContainer } from 'components/Shared/InputFieldsContainer/InputFieldsContainer';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import { PubTable } from 'components/Shared/PubTable/PubTable';
import { InputFieldType } from 'models/Input/InputModels';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { info } from 'services/api/product-service';
export function ChildKitsModal(_a) {
    var _this = this;
    var isChild = _a.isChild, kitData = _a.kitData, metadata = _a.metadata;
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(), tableData = _c[0], setTableData = _c[1];
    var fieldValues = useMemo(function () {
        var buildFieldValues = function (md) { return ({
            productNbrOut: {
                value: md.pn,
            },
            productName: {
                value: md.desc,
            },
        }); };
        return buildFieldValues(metadata);
    }, [metadata]);
    var searchParams = useSearchParams()[0];
    var params = useMemo(function () {
        var _a, _b, _c, _d, _e, _f;
        var p = {
            text: (_a = searchParams.get('productNbr')) !== null && _a !== void 0 ? _a : '',
            date: (_b = searchParams.get('date')) !== null && _b !== void 0 ? _b : '',
            time: (_c = searchParams.get('time')) !== null && _c !== void 0 ? _c : '',
            rbv: (_d = searchParams.get('channel')) !== null && _d !== void 0 ? _d : '',
            id: (_e = searchParams.get('pid')) !== null && _e !== void 0 ? _e : '',
            version: (_f = searchParams.get('productVerson')) !== null && _f !== void 0 ? _f : '',
        };
        if (searchParams.has('parsedProductId')) {
            var newDate = new Date(searchParams.get('parsedTimestamp'));
            var date = newDate.getDate().toString().length > 1 ? newDate.getDate() : "0".concat(newDate.getDate());
            var month = (newDate.getMonth() + 1).toString().length > 1 ? newDate.getMonth() + 1 : "0".concat(newDate.getMonth() + 1);
            var year = newDate.getFullYear();
            var splitDate = newDate.toString().split(' ');
            p.date = "".concat(year, "-").concat(month, "-").concat(date);
            // eslint-disable-next-line prefer-destructuring
            p.time = splitDate[4];
            p.rbv = 'Commercial';
        }
        return p;
    }, [searchParams]);
    var colDef = {
        rowGap: '2px',
        fields: {
            productNbrOut: {
                fieldName: 'Product Number:',
                displaySeq: 1,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            productName: {
                fieldName: 'Description:',
                displaySeq: 2,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
        },
    };
    var kits = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var map, promises, _a, kitData_1, kitData_1_1, k, e_1_1, resolvedProms_1;
        var _b, e_1, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!!isChild) return [3 /*break*/, 14];
                    promises = [];
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 6, 7, 12]);
                    _a = true, kitData_1 = __asyncValues(kitData);
                    _e.label = 2;
                case 2: return [4 /*yield*/, kitData_1.next()];
                case 3:
                    if (!(kitData_1_1 = _e.sent(), _b = kitData_1_1.done, !_b)) return [3 /*break*/, 5];
                    _d = kitData_1_1.value;
                    _a = false;
                    try {
                        k = _d;
                        promises.push(info({ productId: k.childProductId, queryStringParameters: { $select: 'header' } }));
                    }
                    finally {
                        _a = true;
                    }
                    _e.label = 4;
                case 4: return [3 /*break*/, 2];
                case 5: return [3 /*break*/, 12];
                case 6:
                    e_1_1 = _e.sent();
                    e_1 = { error: e_1_1 };
                    return [3 /*break*/, 12];
                case 7:
                    _e.trys.push([7, , 10, 11]);
                    if (!(!_a && !_b && (_c = kitData_1.return))) return [3 /*break*/, 9];
                    return [4 /*yield*/, _c.call(kitData_1)];
                case 8:
                    _e.sent();
                    _e.label = 9;
                case 9: return [3 /*break*/, 11];
                case 10:
                    if (e_1) throw e_1.error;
                    return [7 /*endfinally*/];
                case 11: return [7 /*endfinally*/];
                case 12: return [4 /*yield*/, Promise.all(promises)];
                case 13:
                    resolvedProms_1 = _e.sent();
                    map = kitData.map(function (k, i) { return ({
                        childProductNbr: k.childProductNbr,
                        childProductId: k.childProductId,
                        qty: k.quantity,
                        desc: resolvedProms_1[i].body.details.header.name['en-US'].desc,
                        version: resolvedProms_1[i].body.details.header.productVersion,
                    }); });
                    return [3 /*break*/, 15];
                case 14:
                    map = kitData;
                    _e.label = 15;
                case 15:
                    setIsLoading(false);
                    setTableData(map);
                    return [2 /*return*/];
            }
        });
    }); }, [kitData, isChild]);
    useEffect(function () {
        kits();
    }, [kits]);
    var tableDataDef = {
        firstColumnIndent: true,
        columns: !isChild
            ? [
                {
                    dataAttribute: 'childProductNbr',
                    fieldType: InputFieldType.STRING,
                    heading: 'Product',
                    width: '20%',
                    clickToFireAction: true,
                    params: { date: params.date, time: params.time, channel: params.rbv },
                },
                {
                    dataAttribute: 'qty',
                    fieldType: InputFieldType.STRING,
                    heading: 'Qty Per',
                    width: '10%',
                },
                {
                    dataAttribute: 'desc',
                    fieldType: InputFieldType.STRING,
                    heading: 'Description',
                    width: '70%',
                },
            ]
            : [
                {
                    dataAttribute: 'prefix',
                    fieldType: InputFieldType.STRING,
                    heading: 'Product',
                    width: '20%',
                    clickToFireAction: true,
                    params: { date: params.date, time: params.time, channel: params.rbv },
                },
                {
                    dataAttribute: 'desc',
                    fieldType: InputFieldType.STRING,
                    heading: 'Description',
                    width: '80%',
                },
            ],
    };
    return (_jsxs(_Fragment, { children: [_jsx(InputFieldsContainer, { colDef: colDef, fieldValues: fieldValues }), !isLoading && tableData ? (_jsx(PubTable, { def: tableDataDef, tableData: tableData })) : (_jsx(LoadingSpinner, { indicatorSize: 5 }))] }));
}

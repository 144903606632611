var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
var ButtonWithStyling = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: black;\n    height: 35px;\n    width: 80px;\n    color: white;\n    border-radius: 4px;\n    border: none;\n    font-weight: medium;\n    font-size: 14px;\n    self-align: center;\n    padding: 0 15px;\n    font-family: UntitledSansMedium, sans-serif, system-ui;\n    transition: background-color 0.3s;\n    transition: color 0.3s;\n    &:hover {\n        background-color: rgba(229, 229, 229, 1);\n        color: black;\n        cursor: pointer;\n    }\n"], ["\n    background-color: black;\n    height: 35px;\n    width: 80px;\n    color: white;\n    border-radius: 4px;\n    border: none;\n    font-weight: medium;\n    font-size: 14px;\n    self-align: center;\n    padding: 0 15px;\n    font-family: UntitledSansMedium, sans-serif, system-ui;\n    transition: background-color 0.3s;\n    transition: color 0.3s;\n    &:hover {\n        background-color: rgba(229, 229, 229, 1);\n        color: black;\n        cursor: pointer;\n    }\n"])));
export function StyledButton(_a) {
    var label = _a.label, loading = _a.loading, disabledFromProps = _a.disabledFromProps, widthFromProps = _a.widthFromProps, heightFromProps = _a.heightFromProps, widthIsPercentage = _a.widthIsPercentage, isResetButton = _a.isResetButton, handleClick = _a.handleClick, handleSubmit = _a.handleSubmit, loadingSpinnerSize = _a.loadingSpinnerSize, buttonType = _a.buttonType, id = _a.id, className = _a.className, hideButton = _a.hideButton;
    return (_jsx(ButtonWithStyling, __assign({ onClick: handleClick, onSubmit: handleSubmit, type: buttonType, disabled: disabledFromProps === true, id: id, className: className, style: {
            backgroundColor: disabledFromProps ? 'rgba(229, 229, 229, 1)' : '',
            color: disabledFromProps ? 'white' : '',
            cursor: disabledFromProps ? 'unset' : 'pointer',
            width: widthFromProps ? "".concat(widthIsPercentage ? "".concat(widthFromProps, "%") : "".concat(widthFromProps, "px")) : '',
            height: heightFromProps ? "".concat(heightFromProps, "px") : '',
            marginLeft: isResetButton ? '10px' : 0,
            display: hideButton ? 'none' : 'initial',
        } }, { children: loading ? (_jsx(CircularProgress, { sx: { color: 'white', alignSelf: 'center', cursor: 'auto' }, size: loadingSpinnerSize ? "".concat(loadingSpinnerSize, "px") : '16px' })) : (label) })));
}
var templateObject_1;

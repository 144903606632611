var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { InputFieldType } from 'models/Input/InputModels';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { JsonViewer } from 'components/Shared/JsonViewer/JsonViewer';
import { InputFieldsContainer } from 'components/Shared/InputFieldsContainer/InputFieldsContainer';
import styles from './ProductDetails.module.scss';
var PRODUCT_PREFIX = 'productPrefix';
var CHANNELS = 'channels';
var MIN_LEAD_TIME = 'minLeadtime';
var KIT_PARENT = 'kitParent';
var ORDERABLEIND = 'orderableInd';
var PRICE_MATRIX_ID = 'priceMatrixId';
var PRODUCT_VERSION = 'productVersion';
var PRODUCT_START_DATE = 'productStartDate';
var PRODUCT_END_DATE = 'productEndDate';
var BASE_PRODUCT_DESCRIPTION = 'baseProductDescription';
export function ProductDetails(_a) {
    var _b;
    var _c, _d, _e;
    var infoResponse = _a.infoResponse, pid = _a.pid, version = _a.version, kitParentArray = _a.kitParentArray;
    var channelsData = (_e = (_d = (_c = infoResponse.details) === null || _c === void 0 ? void 0 : _c.header) === null || _d === void 0 ? void 0 : _d.channels) !== null && _e !== void 0 ? _e : {};
    var channelValue = Object.keys(channelsData).join(', ').replace('NA Contract', 'Commercial');
    var fieldValues = useState(buildFieldValues(infoResponse, channelValue, version, kitParentArray))[0];
    var _f = useState(false), isJsonViewerOpen = _f[0], setJsonViewerOpen = _f[1];
    var colDef = {
        rowGap: '2px',
        title: { displayText: 'Product Details', marginBottom: '16px' },
        hasJSONLink: true,
        showActions: false,
        preventReadOnly: true,
        fields: (_b = {},
            _b[PRODUCT_PREFIX] = {
                fieldName: 'Prefix',
                displaySeq: 0,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[CHANNELS] = {
                fieldName: 'Channels',
                displaySeq: 1,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[MIN_LEAD_TIME] = {
                fieldName: 'Defined Minimum Lead Time',
                displaySeq: 2,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[KIT_PARENT] = {
                fieldName: 'Kit',
                displaySeq: 3,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[ORDERABLEIND] = {
                fieldName: 'Orderable',
                displaySeq: 4,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[PRICE_MATRIX_ID] = {
                fieldName: 'Price Matrix ID',
                displaySeq: 5,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[PRODUCT_VERSION] = {
                fieldName: 'Product Version',
                displaySeq: 6,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[PRODUCT_START_DATE] = {
                fieldName: 'Product Start Date',
                displaySeq: 7,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[PRODUCT_END_DATE] = {
                fieldName: 'Product End Date',
                displaySeq: 8,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[BASE_PRODUCT_DESCRIPTION] = {
                fieldName: 'Description:',
                displaySeq: 9,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b),
    };
    var handleModalClose = function () {
        setJsonViewerOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(JsonViewer, { isOpen: isJsonViewerOpen, closeModal: function () { return handleModalClose(); }, stringifiedJson: JSON.stringify(infoResponse, null, 4) }), _jsx(Box, __assign({ className: styles.headerLeftContainer }, { children: _jsx(InputFieldsContainer, { colDef: colDef, fieldValues: fieldValues, labelFieldNameWidth: "190px", handleJSONViewerOpen: function () {
                        setJsonViewerOpen(true);
                    }, topBottomPadding: "2px", isCompleteProduct: true }) }))] }));
}
var buildFieldValues = function (infoDetail, channelValue, productVersion, kitParentArray) {
    var _a;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    return (_a = {},
        _a[PRODUCT_PREFIX] = {
            value: (_c = (_b = infoDetail.details) === null || _b === void 0 ? void 0 : _b.header) === null || _c === void 0 ? void 0 : _c.prefix,
        },
        _a[CHANNELS] = {
            value: channelValue,
        },
        _a[MIN_LEAD_TIME] = {
            value: ((_e = (_d = infoDetail.details) === null || _d === void 0 ? void 0 : _d.header) === null || _e === void 0 ? void 0 : _e.minLeadtime) !== 99
                ? "".concat((_g = (_f = infoDetail.details) === null || _f === void 0 ? void 0 : _f.header) === null || _g === void 0 ? void 0 : _g.minLeadtime, " Days")
                : 'Assigned',
        },
        _a[KIT_PARENT] = {
            // eslint-disable-next-line no-nested-ternary
            value: ((_j = (_h = infoDetail.details) === null || _h === void 0 ? void 0 : _h.header) === null || _j === void 0 ? void 0 : _j.kitParent)
                ? 'Parent'
                : kitParentArray && kitParentArray.length > 0
                    ? 'Child'
                    : '',
        },
        _a[ORDERABLEIND] = {
            value: ((_l = (_k = infoDetail.details) === null || _k === void 0 ? void 0 : _k.header) === null || _l === void 0 ? void 0 : _l.orderableInd) ? 'Yes' : 'No',
        },
        _a[PRICE_MATRIX_ID] = {
            value: (_o = (_m = infoDetail.details) === null || _m === void 0 ? void 0 : _m.header) === null || _o === void 0 ? void 0 : _o.priceMatrixId,
        },
        _a[PRODUCT_VERSION] = {
            value: productVersion,
        },
        _a[PRODUCT_START_DATE] = {
            value: DateTime.fromISO((_r = (_q = (_p = infoDetail === null || infoDetail === void 0 ? void 0 : infoDetail.details) === null || _p === void 0 ? void 0 : _p.header) === null || _q === void 0 ? void 0 : _q.startDate) !== null && _r !== void 0 ? _r : '').toFormat('MM/dd/yyyy'),
        },
        _a[PRODUCT_END_DATE] = {
            value: DateTime.fromISO((_u = (_t = (_s = infoDetail === null || infoDetail === void 0 ? void 0 : infoDetail.details) === null || _s === void 0 ? void 0 : _s.header) === null || _t === void 0 ? void 0 : _t.endDate) !== null && _u !== void 0 ? _u : '').toFormat('MM/dd/yyyy'),
        },
        _a[BASE_PRODUCT_DESCRIPTION] = {
            value: (_y = (_x = (_w = (_v = infoDetail === null || infoDetail === void 0 ? void 0 : infoDetail.details) === null || _v === void 0 ? void 0 : _v.header) === null || _w === void 0 ? void 0 : _w.name) === null || _x === void 0 ? void 0 : _x['en-US']) === null || _y === void 0 ? void 0 : _y.desc,
        },
        _a);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CpaPrice, ProductSpecialRestrictionCode } from '@millerknoll/pub-api-schema';
import { useCpaProduct } from 'components/Cpa/CpaProduct/CpaProductContextProvider';
import { Loading } from 'components/Shared/Loading/AppLoading/Loading/Loading';
import { PubTable } from 'components/Shared/PubTable/PubTable';
import { WorkflowModal } from 'components/Shared/WorkflowModal/WorkFlowModal';
import { InputFieldType } from 'models/Input/InputModels';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useAuth } from 'services/auth/AuthProvider';
import { PRICING_CHANGES_REMOVE_HOLD_RESTRICTION_COMMENT } from 'utils/cpa-utils';
import { textFormatHelper } from 'utils/text-format-helper';
import { useGetPriceListDates } from 'hooks/useGetPriceListDates';
import { DeleteRowWorkflowModal } from '../DeleteRowWorkflowModal/DeleteRowWorkflowModal';
export function PricingTable(_a) {
    var _b, _c, _d, _e;
    var _f = _a.showAddRow, showAddRow = _f === void 0 ? false : _f, showAddRowAction = _a.showAddRowAction;
    var user = useAuth();
    var cpaProductContext = useCpaProduct();
    var OVERRIDE_NO_ZERO_NEGATIVE = 'Zero and negative values are not allowed';
    var _g = useState({}), tableData = _g[0], setTableData = _g[1];
    var _h = useState(), tableDataOriginal = _h[0], setTableDataOriginal = _h[1];
    var _j = useState(false), isModalOpen = _j[0], setIsModalOpen = _j[1];
    var _k = useState(false), isDeleteModalOpen = _k[0], setIsDeleteModalOpen = _k[1];
    var selectedRow = useRef();
    var _l = useGetPriceListDates(), datesLoading = _l.loading, dateList = _l.dateList;
    useLayoutEffect(function () {
        if (cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.pricingTableData) {
            setTableData(cpaProductContext.data.pricingTableData);
            if (!tableDataOriginal)
                setTableDataOriginal(cpaProductContext.data.pricingTableData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.pricingTableData]);
    var tableDataDefinition = useMemo(function () {
        var _a;
        var startDates = __spreadArray([], (dateList !== null && dateList !== void 0 ? dateList : []), true).filter(function (date) { return !Object.keys(tableData).includes(textFormatHelper.removeHyphens(date)); });
        var priceType = +"".concat((_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.standardProductPrice) === null || _a === void 0 ? void 0 : _a.returnCode) !== 0
            ? textFormatHelper.snakeCaseToNormalText(CpaPrice.priceType.OVERRIDE, true)
            : textFormatHelper.snakeCaseToNormalText(CpaPrice.priceType.ADJUSTMENT, true);
        var defaultRow = {
            row: {
                startDate: Object.keys(tableData).length === 0
                    ? startDates[0]
                    : __spreadArray([], startDates, true).reverse()
                        .find(function (date) { return new Date(date).getTime() <= new Date().getTime(); }),
                priceType: priceType,
                priceAdjustment: 0,
                reviewIndicator: 'No',
                lastReviewDate: __spreadArray([], (dateList !== null && dateList !== void 0 ? dateList : []), true).reverse()
                    .find(function (date) { return new Date(date).getTime() <= new Date().getTime(); }),
            },
            key: 'default',
        };
        return {
            firstColumnIndent: true,
            noDataMessage: 'No Pricing Data',
            addRowWhenNoData: true,
            defaultRow: defaultRow,
            sortProps: { sortByKey: true, keyType: 'DATE', sortOrder: 'desc' },
            columns: [
                {
                    dataAttribute: 'startDate',
                    fieldType: InputFieldType.ENUM,
                    heading: 'Start Date',
                    allowedFieldValues: startDates,
                    defaultValue: defaultRow.row.startDate,
                    required: true,
                    isUnique: true,
                    width: '200px',
                },
                {
                    dataAttribute: 'priceType',
                    fieldType: InputFieldType.ENUM,
                    allowedFieldValues: Object.values(CpaPrice.priceType).map(function (type) {
                        return textFormatHelper.snakeCaseToNormalText(type, true);
                    }),
                    heading: 'Price Type',
                    defaultValue: defaultRow.row.priceType,
                    required: true,
                    width: '200px',
                },
                {
                    dataAttribute: 'priceAdjustment',
                    fieldType: InputFieldType.NUMBER,
                    heading: 'Price Value',
                    defaultValue: defaultRow.row.priceAdjustment,
                    required: true,
                    validations: {
                        required: 'Price Value required',
                        wholeNumbersOnly: 'Decimals are not allowed',
                    },
                    width: '200px',
                },
                {
                    dataAttribute: 'reviewIndicator',
                    fieldType: InputFieldType.ENUM,
                    allowedFieldValues: ['Yes', 'No'],
                    heading: 'Needs Review?',
                    defaultValue: defaultRow.row.reviewIndicator,
                    required: true,
                    width: '200px',
                },
                {
                    dataAttribute: 'lastReviewDate',
                    fieldType: InputFieldType.ENUM,
                    heading: 'Last Reviewed',
                    allowedFieldValues: dateList !== null && dateList !== void 0 ? dateList : [],
                    defaultValue: defaultRow.row.lastReviewDate,
                    required: true,
                    width: '200px',
                },
            ],
            showAddRow: showAddRow,
            showAddRowHandler: showAddRowAction,
            editHandler: function (key, row) {
                selectedRow.current = { row: row, key: key };
                setIsModalOpen(true);
            },
            validateRow: function (row) {
                var errors = {};
                if (row.priceType.toUpperCase() === CpaPrice.priceType.OVERRIDE && row.priceAdjustment <= 0) {
                    errors.priceAdjustment = OVERRIDE_NO_ZERO_NEGATIVE;
                }
                return errors;
            },
            addHandler: function (row) {
                selectedRow.current = { row: row, key: '' };
                setIsModalOpen(true);
            },
            deleteProps: {
                deleteHandler: function (key, row) {
                    if (!(cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) || !cpaProductContext.data.cpn)
                        return;
                    setIsDeleteModalOpen(true);
                    selectedRow.current = { row: row, key: key };
                },
            },
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data, showAddRow, tableData, dateList]);
    function setCpaPriceEdit(row, key) {
        var _a, _b, _c;
        var startDate = textFormatHelper.removeHyphens(row.startDate);
        var price = {
            insertId: (_a = row.insertId) !== null && _a !== void 0 ? _a : (_b = user.user) === null || _b === void 0 ? void 0 : _b.userId,
            insertTimestamp: row.insertTimestamp,
            updateId: (_c = user.user) === null || _c === void 0 ? void 0 : _c.userId,
            updateTimestamp: row.updateTimestamp,
            priceAdjustment: +row.priceAdjustment,
            reviewIndicator: row.reviewIndicator,
            priceType: row.priceType,
            startDate: row.startDate,
            lastReviewDate: row.lastReviewDate,
            workflow: row.workflow,
        };
        if (startDate !== key) {
            delete tableData[key];
        }
        setTableData(function (prev) {
            prev[startDate] = price;
            cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.dispatch({ type: 'UPDATE_PRICING_TABLE_DATA', payload: __assign({}, prev) });
            return prev;
        });
    }
    function setCpaPriceAdd(row) {
        var _a, _b;
        var startDate = textFormatHelper.removeHyphens(row.startDate);
        var timestamp = new Date().toISOString();
        var price = {
            insertId: (_a = user.user) === null || _a === void 0 ? void 0 : _a.userId,
            insertTimestamp: timestamp,
            updateId: (_b = user.user) === null || _b === void 0 ? void 0 : _b.userId,
            updateTimestamp: timestamp,
            priceAdjustment: +row.priceAdjustment,
            reviewIndicator: row.reviewIndicator,
            priceType: row.priceType,
            startDate: row.startDate,
            lastReviewDate: row.lastReviewDate,
            workflow: row.workflow,
        };
        setTableData(function (prev) {
            var _a;
            prev[startDate] = price;
            cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.dispatch({
                type: 'UPDATE_PRICING_TABLE_DATA',
                payload: __assign(__assign({}, prev), (_a = {}, _a[startDate] = price, _a)),
            });
            return prev;
        });
    }
    function setCpaPrice(row, key) {
        if (key === '') {
            setCpaPriceAdd(row);
        }
        if (key) {
            setCpaPriceEdit(row, key);
        }
    }
    return (cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.standardProductPrice) &&
        cpaProductContext.data.standardProductPrice.returnCode !== undefined &&
        !datesLoading ? (_jsxs(_Fragment, { children: [_jsx(PubTable, { def: tableDataDefinition, tableData: tableData, alternateRowBackgroundColor: true }), isModalOpen && (_jsx(WorkflowModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, title: ((_e = (_d = (_c = (_b = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _b === void 0 ? void 0 : _b.currentProduct) === null || _c === void 0 ? void 0 : _c.restrictions) === null || _d === void 0 ? void 0 : _d[ProductSpecialRestrictionCode === null || ProductSpecialRestrictionCode === void 0 ? void 0 : ProductSpecialRestrictionCode.PRERELEASE]) === null || _e === void 0 ? void 0 : _e.isActive) === true, buttonProps: [
                    {
                        labelText: 'Confirm',
                        backgroundColorVariation: 'primary',
                        width: '120px',
                        buttonAction: function (workflow) {
                            var _a, _b, _c, _d;
                            var row = __assign(__assign({}, (_a = selectedRow.current) === null || _a === void 0 ? void 0 : _a.row), { workflow: workflow });
                            if (!cpaProductContext || !cpaProductContext.data.currentProduct || !row) {
                                return;
                            }
                            if (!((_c = (_b = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _b === void 0 ? void 0 : _b.currentProduct) === null || _c === void 0 ? void 0 : _c.restrictions)) {
                                cpaProductContext.data.currentProduct.restrictions = {};
                            }
                            cpaProductContext.data.currentProduct.restrictions[ProductSpecialRestrictionCode.PRERELEASE] = { isActive: false, comment: PRICING_CHANGES_REMOVE_HOLD_RESTRICTION_COMMENT };
                            setCpaPrice(row, (_d = selectedRow.current) === null || _d === void 0 ? void 0 : _d.key);
                            selectedRow.current = undefined;
                            setIsModalOpen(false);
                        },
                    },
                    {
                        labelText: 'Cancel',
                        backgroundColorVariation: 'secondary',
                        width: '120px',
                        buttonAction: function (workflow) {
                            var _a, _b, _c, _d, _e, _f;
                            if (((_d = (_c = (_b = (_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _a === void 0 ? void 0 : _a.currentProduct) === null || _b === void 0 ? void 0 : _b.restrictions) === null || _c === void 0 ? void 0 : _c[ProductSpecialRestrictionCode.PRERELEASE]) === null || _d === void 0 ? void 0 : _d.isActive) === true) {
                                var row = __assign(__assign({}, (_e = selectedRow.current) === null || _e === void 0 ? void 0 : _e.row), { workflow: workflow });
                                if (!cpaProductContext || !cpaProductContext.data.currentProduct || !row) {
                                    return;
                                }
                                setCpaPrice(row, (_f = selectedRow.current) === null || _f === void 0 ? void 0 : _f.key);
                            }
                            selectedRow.current = undefined;
                            setIsModalOpen(false);
                        },
                    },
                ], messages: ['Product is on Prerelease Hold. Confirm to remove the Prerelease Hold?'] })), isDeleteModalOpen && (_jsx(DeleteRowWorkflowModal, { deleteAction: function (actionType, workflow, releaseHold) {
                    var _a;
                    var _b, _c, _d, _e, _f, _g, _h, _j;
                    // conditions for which the delete was canceled or unable to be deleted
                    if (!cpaProductContext ||
                        !cpaProductContext.data.currentProduct ||
                        !cpaProductContext.data.pricingTableDataOriginal ||
                        actionType === 'cancel' ||
                        ((_b = selectedRow.current) === null || _b === void 0 ? void 0 : _b.key) === undefined) {
                        setIsDeleteModalOpen(false);
                        return;
                    }
                    // check if the row was in the table data on page load or after last product save
                    // if so, add workflow to that price row
                    var isInOriginalData = !!((_c = cpaProductContext.data.pricingTableDataOriginal) === null || _c === void 0 ? void 0 : _c[(_d = selectedRow.current) === null || _d === void 0 ? void 0 : _d.key]);
                    if (isInOriginalData) {
                        cpaProductContext.dispatch({
                            type: 'UPDATE_PRICING_TABLE_DATA_ORIGINAL',
                            payload: __assign(__assign({}, cpaProductContext.data.pricingTableDataOriginal), (_a = {}, _a[(_e = selectedRow.current) === null || _e === void 0 ? void 0 : _e.key] = __assign(__assign({}, (_f = cpaProductContext.data.pricingTableDataOriginal) === null || _f === void 0 ? void 0 : _f[(_g = selectedRow.current) === null || _g === void 0 ? void 0 : _g.key]), { workflow: workflow }), _a)),
                        });
                    }
                    // remove deleted row from current pricing table data
                    var tableCopy = __assign({}, tableData);
                    var deletedKey = selectedRow.current.key;
                    delete tableCopy[deletedKey];
                    setTableData(function (prev) {
                        delete prev[deletedKey];
                        cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.dispatch({
                            type: 'UPDATE_PRICING_TABLE_DATA',
                            payload: __assign({}, prev),
                        });
                        return prev;
                    });
                    // release prerelease hold
                    if (releaseHold) {
                        if (!((_j = (_h = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _h === void 0 ? void 0 : _h.currentProduct) === null || _j === void 0 ? void 0 : _j.restrictions)) {
                            cpaProductContext.data.currentProduct.restrictions = {};
                        }
                        cpaProductContext.data.currentProduct.restrictions[ProductSpecialRestrictionCode.PRERELEASE] = { isActive: false, comment: PRICING_CHANGES_REMOVE_HOLD_RESTRICTION_COMMENT };
                    }
                    selectedRow.current = undefined;
                    setIsDeleteModalOpen(false);
                }, deleteProps: {
                    modalProps: {
                        buttonProps: { confirmText: 'Delete Price', cancelText: 'Keep Price' },
                        bodyTextArray: [
                            'Are you sure you would like to delete this price? This action is permanent.',
                        ],
                        titleText: 'Delete Price',
                    },
                } }))] })) : (_jsx(Loading, {}));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { sortTableByKeyType } from 'utils/cpa-utils';
import { StyledTableContainer } from '../StyledTableContainer/StyledTableContainer';
import { PubTableRow } from './PubTableRow/PubTableRow';
import { PubTableEditRow } from './PubTableEditRow/PubTableEditRow';
import { LoadingSpinner } from '../Loading/LoadingSpinner/LoadingSpinner';
import styles from './PubTable.module.scss';
export function PubTable(_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var def = _a.def, tableData = _a.tableData, _j = _a.alternateRowBackgroundColor, alternateRowBackgroundColor = _j === void 0 ? true : _j, _k = _a.renderTableHeader, renderTableHeader = _k === void 0 ? true : _k, _l = _a.boldRowsOnHover, boldRowsOnHover = _l === void 0 ? false : _l, origin = _a.origin, nsoMetadata = _a.nsoMetadata, searchText = _a.searchText, lengthOfTable = _a.lengthOfTable, fireApiInInvoker = _a.fireApiInInvoker;
    return (_jsxs(_Fragment, { children: [_jsxs(StyledTableContainer, __assign({ containerClassName: styles.tableContainer }, { children: [renderTableHeader && (_jsx(TableHead, { children: _jsxs(TableRow, { children: [def.rowDecorator && (_jsx(TableCell, __assign({ width: "60px", sx: { paddingLeft: '8px !important' } }, { children: ' ' }))), def.columns.map(function (el, i) {
                                    var _a, _b;
                                    return (_jsx(TableCell, __assign({ sx: {
                                            width: (_a = el.width) !== null && _a !== void 0 ? _a : 'auto',
                                            maxWidth: (_b = el.width) !== null && _b !== void 0 ? _b : 'max-content',
                                            textAlign: el.alignRight ? 'right !important' : '',
                                        }, className: !def.rowDecorator && def.firstColumnIndent && i === 0
                                            ? styles.indentedFirstCell
                                            : '' }, { children: el.heading }), el.heading));
                                }), def.extraIcon && _jsx(TableCell, { width: "40px", className: styles.iconCell }), (((_b = def.deleteProps) === null || _b === void 0 ? void 0 : _b.deleteHandler) || def.editHandler) && (_jsx(TableCell, { width: "40px", className: styles.iconCell })), def.editHandler && _jsx(TableCell, { width: "40px", className: styles.iconCell })] }) })), _jsxs(TableBody, { children: [!def.dataLoading &&
                                (def.showAddRow || (Object.keys(tableData).length === 0 && def.addRowWhenNoData)) && (_jsx(TableRow, { children: _jsx(PubTableEditRow, { def: def, inputRow: (_d = (_c = def.defaultRow) === null || _c === void 0 ? void 0 : _c.row) !== null && _d !== void 0 ? _d : {}, closeHandler: function () {
                                        var _a;
                                        (_a = def.showAddRowHandler) === null || _a === void 0 ? void 0 : _a.call(def);
                                    }, doneHandler: function (addedRow) {
                                        var _a, _b;
                                        (_a = def.addHandler) === null || _a === void 0 ? void 0 : _a.call(def, addedRow);
                                        (_b = def.showAddRowHandler) === null || _b === void 0 ? void 0 : _b.call(def);
                                    }, validateRow: def.validateRow }) })), !def.dataLoading &&
                                (((_e = def.sortProps) === null || _e === void 0 ? void 0 : _e.sortByKey)
                                    ? sortTableByKeyType((_f = def.sortProps) === null || _f === void 0 ? void 0 : _f.keyType, Object.keys(tableData), (_g = def.sortProps) === null || _g === void 0 ? void 0 : _g.sortOrder)
                                    : Object.keys(tableData)).map(function (k, i) { return (_jsx(PubTableRow, { uuid: k, def: def, row: tableData[k], backgroundColorVariation: 
                                    // eslint-disable-next-line no-nested-ternary
                                    alternateRowBackgroundColor ? (i % 2 ? 'secondary' : 'primary') : 'secondary', extraIconIsAction: def.extraIconIsActionText, tableData: tableData, indexOfItem: i, boldRowOnHover: boldRowsOnHover, origin: origin, nsoMetadata: nsoMetadata, searchText: searchText, fireApiInParent: fireApiInInvoker, lengthOfTable: lengthOfTable }, k)); }), (def.dataLoading ||
                                (Object.keys(tableData).length === 0 && !def.addRowWhenNoData && !def.showAddRow)) && (_jsx(TableRow, { children: _jsx(TableCell, __assign({ sx: { textAlign: 'center', borderBottom: '1px solid #afa99f' }, colSpan: def.columns.length + 99 }, { children: def.dataLoading ? 'Loading...' : (_h = def.noDataMessage) !== null && _h !== void 0 ? _h : 'No Data' })) }))] })] })), def.dataLoading && _jsx(LoadingSpinner, { indicatorSize: 10 })] }));
}

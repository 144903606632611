var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
export function StyledLabel(_a) {
    var labelText = _a.labelText, labelWidth = _a.labelWidth, valueText = _a.valueText;
    return (_jsxs(Box, __assign({ display: "flex", flexDirection: "row" }, { children: [_jsx(Typography, __assign({ variant: "h4", sx: { width: labelWidth ? "".concat(labelWidth, "px") : 'fit-content', lineHeight: 1.5 } }, { children: labelText })), valueText && _jsx(Typography, { children: valueText })] })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styles from './CpaBodyContainer.module.scss';
export function CpaBodyContainer(_a) {
    var _b, _c;
    var children = _a.children, title = _a.title, buttons = _a.buttons;
    return (_jsxs(Box, __assign({ className: styles.container }, { children: [_jsxs(Box, __assign({ className: styles.titleContainer }, { children: [title && _jsx(Typography, __assign({ className: styles.title }, { children: title })), buttons && (_jsxs(Box, __assign({ className: styles.buttonContainer }, { children: [_jsx(Box, __assign({ className: styles.buttonSideContainer }, { children: (_b = buttons === null || buttons === void 0 ? void 0 : buttons.leftButtons) === null || _b === void 0 ? void 0 : _b.map(function (button) { return (_jsx(React.Fragment, { children: button }, crypto.randomUUID())); }) })), _jsx(Box, __assign({ className: styles.buttonSideContainer }, { children: (_c = buttons === null || buttons === void 0 ? void 0 : buttons.rightButtons) === null || _c === void 0 ? void 0 : _c.map(function (button) { return (_jsx(React.Fragment, { children: button }, crypto.randomUUID())); }) }))] })))] })), children] })));
}

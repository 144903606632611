var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';
import { Amplify } from '@aws-amplify/core';
import { AmplifyConfig } from 'services/auth/AmplifyConfig';
import { Home } from 'pages/Home/Home';
import { RouteLayout } from 'components/Routes/RouteLayout';
import { useAuth } from 'services/auth/AuthProvider';
import { AppLoading } from 'components/Shared/Loading/AppLoading/AppLoading';
import { ThemeProvider } from '@emotion/react';
import { materialTheme } from 'styles/theme/materialTheme';
import { Logout } from 'components/Logout/Logout';
import { EngDataHome } from 'pages/EngDataHome/EngDataHome';
import { ConfirmProvider } from 'material-ui-confirm';
import { EngDataProductPage, engDataProductPageLoader } from 'components/EngData/EngDataProduct/EngDataProductPage';
import { EngDataSearchForm, productSearchFormAction } from 'components/EngData/EngDataSearchForm/EngDataSearchForm';
import { EngDataSearchResults, engDataSearchResultsLoader, } from 'components/EngData/EngDataSearchResults/EngDataSearchResults';
import { productHeaderFormAction } from 'components/EngData/EngDataProduct/EngDataProductHeaderForm/EngDataProductHeaderForm';
import { ProductValidationPage } from 'components/ProductValidation/ProductValidationPage';
import { ProductBuild } from 'components/ProductBuild/ProductBuild';
import { productBuildFormAction, ProductBuildSearchForm, } from 'components/ProductBuild/ProductBuildSearchForm/ProductBuildSearchForm';
import { ProductBuildSearchResults, productBuildSearchResultsLoader, } from 'components/ProductBuild/ProductBuildSearchResults/ProductBuildSearchResults';
import { ProductBuildDetailPage } from 'components/ProductBuild/ProductBuildDetailPage/ProductBuildDetailPage';
import { ProductExpire } from 'components/ProductExpire/ProductExpire';
import { productExpireFormAction, ProductExpireSearchForm, } from 'components/ProductExpire/ProductExpireSearchForm/ProductExpireSearchForm';
import { ProductExpireSearchResults, productExpireSearchResultsLoader, } from 'components/ProductExpire/ProductExpireSearchResults/ProductExpireSearchResults';
import { ProductExpireDetailPage } from 'components/ProductExpire/ProductExpireDetailPage/ProductExpireDetailPage';
import { SearchProduct } from 'components/SearchProduct/SearchProduct';
import { SearchProductProvider } from 'context/searchProduct/SearchProductProvider';
import { CSPOnDemand } from 'components/CSPOnDemand/CSPOnDemand';
import { ProductInfo, productInfoLoader } from 'components/ProductInfo/ProductInfo';
import { ProductHierarchy } from 'components/ProductHierarchy/ProductHierarchy';
import { Catalogs } from 'components/Catalogs/Catalogs';
import { CatalogRun } from 'components/CatalogRun/CatalogRun';
import { CatalogRequests } from 'components/CatalogRequests/CatalogRequests';
import { CatalogRequestAdHoc } from 'components/CatalogRequestAdHoc/CatalogRequestAdHoc';
import { useCheckAuthScope } from 'services/helperFunctions/useCheckAuthScope';
import { CATALOG_SCOPES, CPA_SCOPES } from 'utils/constants';
import { CpaHome } from 'pages/CpaHome/CpaHome';
import { CpaSearch } from 'components/Cpa/CpaSearch/CpaSearch';
import { CpaProduct, cpaProductLoader } from 'components/Cpa/CpaProduct/CpaProduct';
import { CpaProductContextProvider } from 'components/Cpa/CpaProduct/CpaProductContextProvider';
import { ErrorPage } from 'components/Shared/ErrorPage/ErrorPage';
import { ProductBuildV2 } from 'components/ProductBuildNew/ProductBuildV2';
import { ProductBuildSearchFormV2 } from 'components/ProductBuildNew/ProductBuildSearchFormV2/ProductBuildSearchFormV2';
import { ProductBuildSearchFormV2Multi } from 'components/ProductBuild/ProductBuildSearchFormV2/ProductBuildSearchFormV2Multi';
import { ProductBuildPageV2, ProductBuildPageV2Loader, } from 'components/ProductBuildNew/ProductBuildPageV2/ProductBuildPageV2';
import { ProductValidateV2 } from 'components/ProductValidateNew/ProductValidateNew';
import { ProductValidateSearchFormV2 } from 'components/ProductValidateNew/ProductValidateSearchFormV2/ProductValidateSearchFormV2';
import { ProductSearchNew } from 'components/ProductSearchNew/ProductSearchNew';
import { ProductInfoPageV2, ProductInfoPageV2Loader } from 'components/ProductInfoNew/ProductInfoPageV2';
import { SearchProductV2 } from 'components/AdvancedSearch/SearchProductV2';
import { ProductSearchNewListSearch } from 'components/ProductSearchNew/ProductSearchNewListSearch/ProductSearchNewListSearch';
import { NewSearchOutlet } from 'components/NewSearch/NewSearchOutlet';
import { ProductSearchHierarchy } from 'components/ProductSearchNew/ProductSearchHierarchy/ProductSearchHierarchy';
import { ParsedProduct } from 'components/ParsedProduct/ParsedProduct';
import { ParsedProductSearchForm } from 'components/ParsedProduct/ParsedProductSearchForm/ParsedProductSearchForm';
import { ParsedProductFormMulti } from 'components/ParsedProduct/ParsedProductMulti/ParsedProductFormMulti';
import { ParsedProductSearchResults } from 'components/ParsedProduct/ParsedProductSearchResults/ParsedProductSearchResults';
Amplify.configure(AmplifyConfig);
export var router = createBrowserRouter([
    {
        path: '/',
        element: _jsx(RouteLayout, {}),
        children: [
            {
                path: '/',
                element: _jsx(Home, {}),
            },
            {
                element: _jsx(ProtectedRoute, { authScopes: CPA_SCOPES }),
                children: [
                    {
                        path: '/cpa',
                        element: _jsx(CpaHome, {}),
                        children: [
                            {
                                path: '',
                                element: _jsx(CpaSearch, {}),
                            },
                            {
                                path: 'product',
                                element: _jsx(ErrorPage, { errorMessage: "Invalid CPA URL", fallbackPath: "/cpa" }),
                            },
                            {
                                path: 'product/:cpn/:stndProdRef/:tab?',
                                element: (_jsx(CpaProductContextProvider, { children: _jsx(CpaProduct, {}) })),
                                loader: cpaProductLoader,
                                errorElement: _jsx(ErrorPage, { errorMessage: "UnKnown Custom Add Error", fallbackPath: "/cpa" }),
                            },
                            {
                                path: 'product/:cpn/:tab?',
                                element: (_jsx(CpaProductContextProvider, { children: _jsx(CpaProduct, {}) })),
                                loader: cpaProductLoader,
                                errorElement: (_jsx(ErrorPage, { errorMessage: "UnKnown Custom Edit Error", fallbackPath: "/cpa" })),
                            },
                        ],
                    },
                ],
            },
            {
                path: '/engdatamaint',
                element: _jsx(EngDataHome, {}),
                children: [
                    {
                        path: 'search',
                        element: _jsx(EngDataSearchForm, {}),
                        action: productSearchFormAction,
                        children: [
                            {
                                path: ':searchText',
                                element: _jsx(EngDataSearchResults, {}),
                                loader: engDataSearchResultsLoader,
                            },
                        ],
                    },
                    {
                        path: 'product/:productId',
                        element: _jsx(EngDataProductPage, {}),
                        loader: engDataProductPageLoader,
                        action: productHeaderFormAction,
                        children: [],
                    },
                ],
            },
            {
                path: '/product-validate',
                element: _jsx(ProductValidationPage, {}),
            },
            {
                path: '/product-build',
                element: _jsx(ProductBuild, {}),
                children: [
                    {
                        path: 'search',
                        element: _jsx(ProductBuildSearchForm, {}),
                        action: productBuildFormAction,
                        children: [
                            {
                                path: ':searchText',
                                element: _jsx(ProductBuildSearchResults, {}),
                                loader: productBuildSearchResultsLoader,
                            },
                        ],
                    },
                    {
                        path: ':productId?',
                        element: _jsx(ProductBuildDetailPage, {}),
                    },
                ],
            },
            {
                path: '/product-build-v2',
                element: _jsx(ProductBuildV2, {}),
                children: [
                    {
                        path: 'search',
                        element: _jsx(ProductBuildSearchFormV2, {}),
                    },
                    {
                        path: 'multi',
                        element: _jsx(ProductBuildSearchFormV2Multi, {}),
                    },
                    {
                        path: 'build',
                        element: _jsx(ProductBuildPageV2, {}),
                        loader: ProductBuildPageV2Loader,
                    },
                ],
            },
            {
                path: '/product-validate-v2',
                element: _jsx(ProductValidateV2, {}),
                children: [
                    { path: 'search', element: _jsx(ProductValidateSearchFormV2, {}) },
                    {
                        path: 'validate',
                        element: _jsx(ProductBuildPageV2, {}),
                        loader: ProductBuildPageV2Loader,
                    },
                ],
            },
            {
                path: '/parsed-product',
                element: _jsx(ParsedProduct, {}),
                children: [
                    {
                        path: 'search',
                        element: _jsx(ParsedProductSearchForm, {}),
                    },
                    {
                        path: 'multi',
                        element: _jsx(ParsedProductFormMulti, {}),
                        children: [
                            {
                                path: ':urlParams',
                                element: _jsx(ParsedProductSearchResults, {}),
                            },
                        ],
                    },
                    {
                        path: 'details',
                        element: _jsx(ProductBuildPageV2, {}),
                        loader: ProductBuildPageV2Loader,
                    },
                ],
            },
            {
                path: '/product-info-v2',
                children: [
                    {
                        path: 'info',
                        element: _jsx(ProductInfoPageV2, {}),
                        loader: ProductInfoPageV2Loader,
                    },
                ],
            },
            {
                path: '/product-expire',
                element: _jsx(ProductExpire, {}),
                children: [
                    {
                        path: 'search',
                        element: _jsx(ProductExpireSearchForm, {}),
                        action: productExpireFormAction,
                        children: [
                            {
                                path: ':searchText',
                                element: _jsx(ProductExpireSearchResults, {}),
                                loader: productExpireSearchResultsLoader,
                            },
                        ],
                    },
                    {
                        path: ':productId?',
                        element: _jsx(ProductExpireDetailPage, {}),
                    },
                ],
            },
            {
                path: '/search/product',
                element: (_jsx(SearchProductProvider, { children: _jsx(SearchProduct, {}) })),
            },
            {
                path: '/search-v2',
                element: _jsx(NewSearchOutlet, {}),
                children: [
                    {
                        path: 'simple/product',
                        element: _jsx(ProductSearchNew, {}),
                    },
                    {
                        path: 'simple/list',
                        element: _jsx(ProductSearchNewListSearch, {}),
                    },
                    {
                        path: 'advanced',
                        element: (_jsx(SearchProductProvider, { children: _jsx(SearchProductV2, {}) })),
                    },
                    {
                        path: 'hierarchy',
                        element: _jsx(ProductSearchHierarchy, {}),
                    },
                ],
            },
            {
                path: '/product-info/:productVersion?',
                element: _jsx(ProductInfo, {}),
                loader: productInfoLoader,
            },
            {
                path: '/search/productHierarchy',
                element: _jsx(ProductHierarchy, {}),
            },
            {
                path: '/csp-on-demand',
                element: _jsx(CSPOnDemand, {}),
            },
            {
                path: '/catalogs',
                element: _jsx(Catalogs, {}),
            },
            {
                element: _jsx(ProtectedRoute, { authScopes: CATALOG_SCOPES }),
                children: [
                    {
                        path: '/catalogs/run',
                        element: _jsx(CatalogRun, {}),
                    },
                    {
                        path: '/catalogs/adhoc',
                        element: _jsx(CatalogRequestAdHoc, {}),
                    },
                ],
            },
            {
                path: '/catalogs/requests',
                element: _jsx(CatalogRequests, {}),
            },
            {
                path: '/logout',
                element: _jsx(Logout, {}),
            },
        ],
    },
]);
export function ProtectedRoute(_a) {
    var authScopes = _a.authScopes;
    var isAuthed = useCheckAuthScope(authScopes);
    if (!isAuthed) {
        return _jsx(Navigate, { to: "/", state: { accessDenied: true } });
    }
    return _jsx(Outlet, {});
}
export function App() {
    var auth = useAuth();
    return (_jsx(ThemeProvider, __assign({ theme: materialTheme }, { children: _jsx(ConfirmProvider, { children: auth.isAuthenticated ? (_jsx(RouterProvider, { router: router, fallbackElement: _jsx(AppLoading, {}) })) : (_jsx(AppLoading, {})) }) })));
}

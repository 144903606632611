var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from './StyledDialog.module.scss';
export function StyledDialog(_a) {
    var isOpen = _a.isOpen, closeModal = _a.closeModal, children = _a.children, rowGap = _a.rowGap, padding = _a.padding;
    return (_jsx(Dialog, __assign({ open: isOpen, onClose: closeModal, slotProps: { backdrop: { sx: { backgroundColor: 'rgba(255, 255, 255, 0.65)' } } }, PaperProps: {
            sx: {
                width: '100%',
                maxWidth: '75%',
                '::-webkit-scrollbar-track': { background: 'transparent' },
                scrollbarColor: '#acacac transparent',
            },
        } }, { children: _jsx(Box, __assign({ className: styles.contentContainer, sx: { rowGap: rowGap !== null && rowGap !== void 0 ? rowGap : '', padding: padding !== null && padding !== void 0 ? padding : '24px' } }, { children: _jsxs(_Fragment, { children: [_jsx(Box, __assign({ className: styles.iconContainer }, { children: _jsx(IconButton, __assign({ className: styles.closeIcon, onClick: closeModal }, { children: _jsx(CloseIcon, { fontSize: "large" }) })) })), children] }) })) })));
}

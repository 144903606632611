var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getUTCDateData } from 'components/ProductBuild/helper';
import { determineChannelVal } from 'components/ProductBuildNew/ProductBuildSearchFormV2/ProductBuildSearchFormV2';
import { searchParamsHelper } from 'components/ProductBuildNew/searchParamsHelper';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import { PubInputField } from 'components/Shared/PubInputField/PubInputField';
import { StyledButtonV2 } from 'components/Shared/StyledButtonV2/StyledButtonV2';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { InputFieldType } from 'models/Input/InputModels';
import { useMemo, useRef, useState, useEffect } from 'react';
import { Form, useNavigate } from 'react-router-dom';
import { getProductsV2 } from 'services/api/search-product-service';
import { channelArray } from 'utils/constants';
import styles from './ProductSearchNew.module.scss';
export function ProductSearchNew() {
    var _this = this;
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var navigate = useNavigate();
    var searchInputRef = useRef(null);
    var _a = useState(searchParamsHelper()), searchData = _a[0], setSearchData = _a[1];
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(false), noProductsFound = _c[0], setNoProductsFound = _c[1];
    useEffect(function () {
        searchInputRef.current.focus();
    }, [searchInputRef]);
    var handleSearchDataValueChange = function (field, val) {
        setNoProductsFound(false);
        var data = __assign({}, searchData);
        data[field] = val;
        if (field === 'date') {
            data.date = getUTCDateData(val, data.displayTime).date;
            data.displayDate = val;
        }
        else if (field === 'time') {
            var _a = getUTCDateData(data.displayDate, val), date = _a.date, time = _a.time;
            data.time = time;
            data.date = date;
            data.displayTime = val;
        }
        setSearchData(data);
    };
    var disableButton = useMemo(function () { return !searchData.text || !searchData.date || !searchData.time || noProductsFound; }, [searchData, noProductsFound]);
    var handleSearchButtonClick = function () { return __awaiter(_this, void 0, void 0, function () {
        var text, date, time, rbv, searchResultRsp;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setNoProductsFound(false);
                    setIsLoading(true);
                    text = searchData.text, date = searchData.date, time = searchData.time, rbv = searchData.rbv;
                    return [4 /*yield*/, getProductsV2(text, date, time, determineChannelVal(rbv), 1)];
                case 1:
                    searchResultRsp = _b.sent();
                    if (searchResultRsp.status >= 500) {
                        setIsLoading(false);
                        setSnackbarAlert({
                            message: 'Server Error - Please report the error to the PLS support board/help desk with details',
                            type: 'error',
                        });
                        return [2 /*return*/];
                    }
                    if (((_a = searchResultRsp.data.data) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        navigate("/search-v2/simple/list?search=".concat(text, "&date=").concat(date, "&time=").concat(time, "&channel=").concat(rbv), {
                            state: searchResultRsp,
                        });
                    }
                    else {
                        searchInputRef.current.focus();
                        setIsLoading(false);
                        setNoProductsFound(true);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Form, { children: _jsxs(Box, __assign({ className: styles.container }, { children: [_jsx("h1", __assign({ style: { marginTop: '0' } }, { children: "Basic Search" })), _jsx(Box, __assign({ className: styles.formContainer }, { children: _jsxs(Box, { children: [_jsx(PubInputField, { formInputRef: searchInputRef, fieldType: InputFieldType.STRING, labels: {
                                    left: 'Search by Name or Partial Product Number',
                                    right: 'Required',
                                }, formInputName: "productNbr", fieldValue: searchData === null || searchData === void 0 ? void 0 : searchData.text, fieldValueUpdateHandler: function (e) { return handleSearchDataValueChange('text', e); }, borderFocus: !!searchData.text && searchData.text !== '' }), _jsxs(Box, __assign({ className: styles.dateAndTimeInputRow }, { children: [_jsx(Box, __assign({ className: styles.spaceBetweenInputs }, { children: _jsx(PubInputField, { fieldType: InputFieldType.DATE, labels: {
                                                left: 'Date',
                                                right: 'Required',
                                            }, formInputName: "date", fieldValue: searchData === null || searchData === void 0 ? void 0 : searchData.displayDate, fieldValueUpdateHandler: function (e) { return handleSearchDataValueChange('date', e); }, borderFocus: !!(searchData === null || searchData === void 0 ? void 0 : searchData.date) }) })), _jsx(Box, __assign({ className: styles.dateTimeFieldWidth }, { children: _jsx(PubInputField, { fieldType: InputFieldType.TIME, formInputName: "time", labels: {
                                                left: 'Time',
                                                right: 'Required',
                                            }, fieldValue: searchData === null || searchData === void 0 ? void 0 : searchData.displayTime, fieldValueUpdateHandler: function (e) { return handleSearchDataValueChange('time', e); }, fieldContainerWidthOverride: "100%", borderFocus: !!(searchData === null || searchData === void 0 ? void 0 : searchData.time) }) }))] })), _jsx(Box, __assign({ className: styles.radioBox }, { children: _jsx(PubInputField, { fieldType: InputFieldType.RADIO, fieldValue: searchData === null || searchData === void 0 ? void 0 : searchData.rbv, fieldValueUpdateHandler: function (e) { return handleSearchDataValueChange('rbv', e); }, labels: { left: 'Channel' }, fieldOptions: channelArray, fieldContainerWidthOverride: "fit-content" }) })), noProductsFound && (_jsx(Typography, __assign({ className: styles.noProductsMessage, variant: "h4" }, { children: "No products found." }))), _jsx(Box, __assign({ className: styles.buttonBox }, { children: _jsx(StyledButtonV2, { labelText: "Search", height: "47px", disabled: disableButton, buttonAction: function () { return handleSearchButtonClick(); }, width: "230px" }) }))] }) })), isLoading && (_jsx(Box, { children: _jsx(LoadingSpinner, { indicatorSize: 10 }) }))] })) }));
}

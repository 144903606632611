import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { StyledLabel } from 'components/Shared/StyledLabel/StyledLabel';
import { catalogHelper } from 'utils/catalog-helper';
import { ExtractTracker } from 'models/catalogs/ExtractTracker';
export function CatalogRequestsModelHeader(_a) {
    var _b;
    var requestBody = _a.requestBody;
    return (_jsxs(Box, { children: [_jsx(StyledLabel, { labelText: "Request Id:", labelWidth: 160, valueText: requestBody.requestId }), requestBody.catalogNbr && (_jsx(StyledLabel, { labelText: "Catalog Number:", labelWidth: 160, valueText: "".concat(requestBody.catalogNbr) })), _jsx(StyledLabel, { labelText: "Name:", labelWidth: 160, valueText: requestBody.description }), _jsx(StyledLabel, { labelText: "Status:", labelWidth: 160, valueText: requestBody.status }), requestBody.status === ExtractTracker.status.FAILED && (_jsx(StyledLabel, { labelText: "Error Message:", labelWidth: 160, valueText: (_b = requestBody === null || requestBody === void 0 ? void 0 : requestBody.error) === null || _b === void 0 ? void 0 : _b.message })), _jsx(StyledLabel, { labelText: "Requested Date:", labelWidth: 160, valueText: catalogHelper.formatDateString(requestBody.requestedDate) }), _jsx(StyledLabel, { labelText: "Completed Date:", labelWidth: 160, valueText: catalogHelper.formatDateString(requestBody.completedDate) }), _jsx(StyledLabel, { labelText: "Total Time:", labelWidth: 160, valueText: catalogHelper.msToMinutesAndSeconds(requestBody.totalTimeTaken) })] }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { StyledDialogV2 } from '../StyledDialogV2/StyledDialogV2';
import { DerivedFeaturesDetails } from './DerivedFeaturesDetails';
export function DerivedFeaturesModal(_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, rowFeature = _a.rowFeature, derivedOptions = _a.derivedOptions, showOptionalColumns = _a.showOptionalColumns;
    return (_jsx(StyledDialogV2, __assign({ isOpen: isOpen, setIsOpen: setIsOpen, sizeProps: { width: '65%' }, rowGap: "16px", padding: "28px", title: "Derived Features" }, { children: _jsx(DerivedFeaturesDetails, { rowFeature: rowFeature, derivedOptions: derivedOptions, showOptionalColumns: showOptionalColumns }) })));
}

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-underscore-dangle */
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';
import styles from './StyledSearchResultsTable.module.scss';
var SearchResultsTableWithStyling = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 60%;\n    margin: 20px auto;\n"], ["\n    width: 60%;\n    margin: 20px auto;\n"])));
export function StyledSearchResultsTable(_a) {
    var passedSearchResults = _a.passedSearchResults, showStartDate = _a.showStartDate, handleSearchResultClick = _a.handleSearchResultClick;
    var handleSearchResultRowClick = function (productId, productPrefix, productVersion) {
        handleSearchResultClick(productId, productPrefix, productVersion);
    };
    return (_jsx(SearchResultsTableWithStyling, { children: _jsxs(Table, { children: [_jsx(TableHead, __assign({ className: styles.tableHeaderRow }, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ className: styles.productPrefixHeaderCell }, { children: "Product Prefix" })), _jsx(TableCell, __assign({ className: styles.productNameHeaderCell }, { children: "Name" })), showStartDate && _jsx(TableCell, __assign({ className: styles.productDateHeaderCell }, { children: "Start Date" }))] }) })), _jsx(TableBody, { children: passedSearchResults &&
                        passedSearchResults.map(function (product, index) {
                            var _a;
                            return (_jsxs(TableRow, __assign({ sx: {
                                    '&.MuiTableRow-root:hover': {
                                        backgroundColor: 'rgba(0, 255, 0, 0.1)',
                                    },
                                    backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.1)' : 'white',
                                }, hover: true, onClick: function () {
                                    return handleSearchResultRowClick(product.id, product.prefix, product.version);
                                } }, { children: [_jsx(TableCell, __assign({ className: styles.productPrefixCell }, { children: (_a = product === null || product === void 0 ? void 0 : product.prefix.valueOf()) !== null && _a !== void 0 ? _a : product === null || product === void 0 ? void 0 : product.id.valueOf() })), _jsx(TableCell, { children: product === null || product === void 0 ? void 0 : product.name }), showStartDate && (_jsx(TableCell, __assign({ className: styles.productDateCell }, { children: product.startDate })))] }), product.version));
                        }) })] }) }));
}
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { useEffect, useState } from 'react';
export function SnackbarAlert() {
    var snackbarAlert = useSnackbar().snackbarAlert;
    var _a = useState(false), showAlert = _a[0], setShowAlert = _a[1];
    var handleAlertClose = function () {
        setShowAlert(function () { return false; });
    };
    useEffect(function () {
        setShowAlert(function () {
            if (snackbarAlert.message === '') {
                return false;
            }
            return true;
        });
    }, [snackbarAlert]);
    return (_jsx(Snackbar, __assign({ anchorOrigin: { vertical: 'bottom', horizontal: 'left' }, open: showAlert, autoHideDuration: snackbarAlert.type === 'error' ? 6000 : 4000, onClose: handleAlertClose }, { children: _jsx(Alert, __assign({ severity: snackbarAlert.type }, { children: snackbarAlert.message })) }), snackbarAlert.message));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useLayoutEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { CpaAssetType, defaultFileSelectProps } from 'utils/cpa-utils';
import { useCpaProduct } from 'components/Cpa/CpaProduct/CpaProductContextProvider';
import { FileUrlGenerateRequest } from '@millerknoll/pub-api-schema';
import { Loading } from 'components/Shared/Loading/AppLoading/Loading/Loading';
import { FileUploadDropZone } from 'components/Shared/FileUploadDropZone/FileUploadDropZone';
import { fileService } from 'services/api/files-service';
import { CpaBodyContainer } from '../CpaBodyContainer/CpaBodyContainer';
import { DrawingUploadModal } from './DrawingUploadModal/DrawingUploadModal';
import { DrawingDisplay } from './DrawingDisplay/DrawingDisplay';
import styles from './CpaDrawing.module.scss';
export function CpaDrawing() {
    var _this = this;
    var _a;
    var cpaProductContext = useCpaProduct();
    var _b = useState(), selectedFile = _b[0], setSelectedFile = _b[1];
    var _c = useState(), updatedSelectedFile = _c[0], setUpdatedSelectedFile = _c[1];
    var _d = useState(false), isImageSelected = _d[0], setIsImageSelected = _d[1];
    var _e = useState(false), imageLoading = _e[0], setImageLoading = _e[1];
    var _f = useState(false), uploadModalIsOpen = _f[0], setUploadModalIsOpen = _f[1];
    var handleImageSelect = function (file) {
        setUpdatedSelectedFile(file);
        setIsImageSelected(false);
        setUploadModalIsOpen(true);
    };
    var handleFileAction = function (imageAsset) { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            if (imageAsset) {
                if ((cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) && (cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct)) {
                    if (!((_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct) === null || _a === void 0 ? void 0 : _a.assets)) {
                        cpaProductContext.data.currentProduct.assets = [];
                    }
                    else {
                        cpaProductContext.data.currentProduct.assets = cpaProductContext.data.currentProduct.assets.filter(function (asset) { return asset.type !== CpaAssetType.TI; });
                    }
                    cpaProductContext.data.currentProduct.assets.push(imageAsset);
                    cpaProductContext.dispatch({
                        type: 'UPDATE_PRODUCT_ASSETS',
                        payload: {
                            productAssets: __spreadArray([], cpaProductContext.data.currentProduct.assets, true),
                            drawing: updatedSelectedFile,
                        },
                    });
                }
                setUpdatedSelectedFile(function (prev) {
                    setSelectedFile(prev);
                    return undefined;
                });
            }
            else {
                setUpdatedSelectedFile(undefined);
            }
            setIsImageSelected(imageAsset !== undefined);
            setUploadModalIsOpen(false);
            return [2 /*return*/];
        });
    }); };
    var handleDrawingDelete = function () {
        var _a, _b, _c, _d, _e;
        var tiAssetIndex = (_b = (_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct) === null || _a === void 0 ? void 0 : _a.assets) === null || _b === void 0 ? void 0 : _b.findIndex(function (asset) { return asset.type === CpaAssetType.TI; });
        if (tiAssetIndex !== undefined) {
            var assetsCopy = __spreadArray([], ((_d = (_c = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct) === null || _c === void 0 ? void 0 : _c.assets) !== null && _d !== void 0 ? _d : []), true);
            assetsCopy === null || assetsCopy === void 0 ? void 0 : assetsCopy.splice(tiAssetIndex, 1);
            cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.dispatch({
                type: 'UPDATE_PRODUCT_ASSETS',
                payload: {
                    productAssets: tiAssetIndex !== undefined
                        ? __spreadArray([], assetsCopy, true) : (_e = cpaProductContext.data.currentProductOriginal) === null || _e === void 0 ? void 0 : _e.assets,
                    drawing: undefined,
                },
            });
        }
        setIsImageSelected(false);
        setSelectedFile(undefined);
    };
    useLayoutEffect(function () {
        (function downloadFile() {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function () {
                var tiAsset, downloadedFileUrl;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            if (!(cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.drawingFile)) return [3 /*break*/, 1];
                            setSelectedFile(cpaProductContext.data.drawingFile);
                            return [3 /*break*/, 3];
                        case 1:
                            tiAsset = (_b = (_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct) === null || _a === void 0 ? void 0 : _a.assets) === null || _b === void 0 ? void 0 : _b.find(function (asset) { return asset.type === CpaAssetType.TI; });
                            if (!(tiAsset && tiAsset.url && tiAsset.type && !selectedFile && !isImageSelected)) return [3 /*break*/, 3];
                            setIsImageSelected(true);
                            setImageLoading(true);
                            return [4 /*yield*/, fileService.generateUrl({
                                    requestType: FileUrlGenerateRequest.requestType.DOWNLOAD,
                                    path: tiAsset.url,
                                })];
                        case 2:
                            downloadedFileUrl = _c.sent();
                            setSelectedFile(downloadedFileUrl.body.url);
                            setImageLoading(false);
                            _c.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        })();
    }, [
        (_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct) === null || _a === void 0 ? void 0 : _a.assets,
        cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.drawingFile,
        isImageSelected,
        selectedFile,
    ]);
    return (_jsxs(CpaBodyContainer, __assign({ title: "Drawing" }, { children: [imageLoading && _jsx(Loading, { className: styles.loading }), _jsx(Box, __assign({ className: styles.contentContainer }, { children: selectedFile ? (_jsx(DrawingDisplay, { imageFile: selectedFile, fileSelectProps: defaultFileSelectProps, handleFileSelect: handleImageSelect, handleDelete: handleDrawingDelete })) : (!isImageSelected &&
                    !imageLoading && (_jsx(FileUploadDropZone, { containerSizeProps: { width: '66%', height: '250px' }, handleFileSelect: handleImageSelect, fileSelectProps: defaultFileSelectProps }))) })), updatedSelectedFile && uploadModalIsOpen && (_jsx(DrawingUploadModal, { cpn: cpaProductContext.data.cpn, imageFile: updatedSelectedFile, isOpen: uploadModalIsOpen, setIsOpen: setUploadModalIsOpen, handleFileAction: handleFileAction }))] })));
}

export var InputFieldType;
(function (InputFieldType) {
    InputFieldType[InputFieldType["STRING"] = 0] = "STRING";
    InputFieldType[InputFieldType["NUMBER"] = 1] = "NUMBER";
    InputFieldType[InputFieldType["DATE"] = 2] = "DATE";
    InputFieldType[InputFieldType["ENUM"] = 3] = "ENUM";
    InputFieldType[InputFieldType["RADIO"] = 4] = "RADIO";
    InputFieldType[InputFieldType["READONLY"] = 5] = "READONLY";
    InputFieldType[InputFieldType["TIME"] = 6] = "TIME";
    InputFieldType[InputFieldType["SWITCH"] = 7] = "SWITCH";
    InputFieldType[InputFieldType["ENUMCHECKBOX"] = 8] = "ENUMCHECKBOX";
})(InputFieldType || (InputFieldType = {}));
export var ActionType;
(function (ActionType) {
    ActionType[ActionType["EDIT"] = 0] = "EDIT";
    ActionType[ActionType["CONFIRM"] = 1] = "CONFIRM";
    ActionType[ActionType["REVERT"] = 2] = "REVERT";
})(ActionType || (ActionType = {}));

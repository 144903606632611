var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-useless-computed-key */
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { handleOptionSave } from 'services/helperFunctions/handleOptionSave';
import { useConfirm } from 'material-ui-confirm';
import { EngineeringDerivedFeature, SaveDraftRequest, EngineeringFeature, } from '@millerknoll/pub-api-schema';
import { useEffect, useRef, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { saveDraft } from 'services/api/engineering-service';
import { StyledButton } from 'components/Shared/StyledComponents/StyledButton';
import { noSpaces, alphanumericHyphen } from 'services/helperFunctions/handleRegex';
import { useCheckAuthScope } from 'services/helperFunctions/useCheckAuthScope';
import { ExistingRoyaltyRow } from 'components/Shared/ExistingRoyaltyRow/ExistingRoyaltyRow';
import { useLanguageCode } from 'services/helperFunctions/useLanguageCode';
import { ErrorAdornment } from 'components/Shared/ErrorAdornment/ErrorAdornment';
import { UI_WRITE_SCOPES } from 'utils/constants';
import { FeatureModal } from '../../../../../Shared/FeatureModal/FeatureModal';
import styles from './TableDataRowOption.module.scss';
export function TableDataRowOption(_a) {
    var _b, _c;
    var _this = this;
    var _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var isEditable = _a.isEditable, optionsData = _a.optionsData, optionCode = _a.optionCode, data = _a.data, handleTogglePublish = _a.handleTogglePublish, option = _a.option, royalties = _a.royalties;
    var languageCode = useLanguageCode();
    var params = useParams();
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var userHasEditPrivileges = useCheckAuthScope(UI_WRITE_SCOPES);
    var confirm = useConfirm();
    var childRef = useRef(null); // this is used to reach out to functions in FeatureModal Component
    var loaderData = useLoaderData();
    var engineerOption = option === null || option === void 0 ? void 0 : option[1];
    var productId = params.productId;
    var _r = useState(undefined), derivedFeatures = _r[0], setDerivedFeatures = _r[1];
    var _s = useState((_d = engineerOption === null || engineerOption === void 0 ? void 0 : engineerOption.mfgOptMdlCd) !== null && _d !== void 0 ? _d : ''), mOptModelCode = _s[0], setMOptModelCode = _s[1];
    var _t = useState((_e = engineerOption === null || engineerOption === void 0 ? void 0 : engineerOption.prcOptMdlCd) !== null && _e !== void 0 ? _e : ''), pOptModelCode = _t[0], setPOptModelCode = _t[1];
    var optCode = useState(optionCode !== null && optionCode !== void 0 ? optionCode : '')[0];
    var _u = useState((_h = (_g = (_f = engineerOption === null || engineerOption === void 0 ? void 0 : engineerOption.desc) === null || _f === void 0 ? void 0 : _f["".concat(languageCode)]) === null || _g === void 0 ? void 0 : _g.desc) !== null && _h !== void 0 ? _h : ''), optDesc = _u[0], setOptDesc = _u[1];
    var _v = useState(option !== null && option !== void 0 ? option : undefined), optionToUse = _v[0], setOptionToUse = _v[1];
    var _w = useState(false), inputFieldChange = _w[0], setInputFieldChange = _w[1];
    var productVersion = (_k = ((_j = loaderData.details) === null || _j === void 0 ? void 0 : _j.metadata).productVersion) !== null && _k !== void 0 ? _k : ((_l = loaderData.details) === null || _l === void 0 ? void 0 : _l.header).productVersion;
    var _x = useState(false), addNewDerivedFeature = _x[0], setAddNewDerivedFeature = _x[1];
    var _y = useState(false), openModalByForce = _y[0], setOpenModalByForce = _y[1];
    var _z = useState(), derivedFeatureToInject = _z[0], setDerivedFeatureToInject = _z[1];
    var _0 = useState(0), featureSeqNumber = _0[0], setFeatureSeqNumber = _0[1];
    var _1 = useState(), newOptionRoyalty = _1[0], setNewOptionRoyalty = _1[1];
    var _2 = useState(false), isOptionRoyaltyModalOpen = _2[0], setIsOptionRoyaltyModalOpen = _2[1];
    var _3 = useState(Object.values(royalties !== null && royalties !== void 0 ? royalties : {}).sort(function (a, b) {
        var _a;
        return (_a = a.royaltyId.localeCompare(b.royaltyId, undefined, {
            numeric: true,
            sensitivity: 'base',
        })) !== null && _a !== void 0 ? _a : [];
    })), optionRoyalties = _3[0], setOptionRoyalties = _3[1];
    var _4 = useState(false), duplicateRoyaltyIdError = _4[0], setDuplicateRoyaltyIdError = _4[1];
    var _5 = useState(''), idThatIsBeingDeleted = _5[0], setIdThatIsBeingDeleted = _5[1];
    var _6 = useState(false), disableConfirmButton = _6[0], setDisableConfirmButton = _6[1];
    var _7 = useState(false), isSaveProcessConfirming = _7[0], setIsSaveProcessConfirming = _7[1];
    var listOfSeqVersions = [];
    var _8 = useState(), derivedFeatureIdToDelete = _8[0], setDerivedFeatureIdToDelete = _8[1];
    var _9 = useState(false), isNewOptionRoyaltyError = _9[0], setIsNewOptionRoyaltyError = _9[1];
    var newDerivedFeatureData = {
        featureId: "".concat(data.featureId, "-").concat(optionCode, "-").concat(featureSeqNumber),
        optionCode: '',
        step: data.step,
        type: EngineeringDerivedFeature.type.DERIVED,
        featureDescription: (_b = {}, _b["".concat(languageCode)] = { desc: '' }, _b),
        mtrlCd: '',
        mfgMdlCd: '',
        mfgOptMdlCd: '',
        optionDescription: (_c = {}, _c["".concat(languageCode)] = { desc: '' }, _c),
        prcMdlCd: '',
        prcOptMdlCd: '',
    };
    useEffect(function () {
        var _a;
        var freshOption = {
            '': {
                optionCode: '',
                desc: (_a = {}, _a["".concat(languageCode)] = { desc: '' }, _a),
                mfgOptMdlCd: '',
                prcOptMdlCd: '',
            },
        };
        if (!option) {
            setOptionToUse(freshOption);
        }
        else {
            setOptionToUse(option);
        }
    }, [languageCode, option]);
    useEffect(function () {
        if (engineerOption === null || engineerOption === void 0 ? void 0 : engineerOption.derivedFeatures) {
            setDerivedFeatures(Object.entries(engineerOption.derivedFeatures));
        }
    }, [data, engineerOption === null || engineerOption === void 0 ? void 0 : engineerOption.derivedFeatures, optionsData]);
    useEffect(function () {
        var _a, _b;
        if (openModalByForce) {
            if (childRef.current && derivedFeatureToInject) {
                (_a = childRef === null || childRef === void 0 ? void 0 : childRef.current) === null || _a === void 0 ? void 0 : _a.injectValuesByForce(derivedFeatureToInject);
                (_b = childRef === null || childRef === void 0 ? void 0 : childRef.current) === null || _b === void 0 ? void 0 : _b.scrollToAddFeatureModal();
            }
        }
    }, [derivedFeatureToInject, openModalByForce]);
    var handleOptionsFieldChangeAndBlur = function (newValue, o, param) { return __awaiter(_this, void 0, void 0, function () {
        var response, arrayifiedOption, newOptionToPass, response;
        var _a;
        var _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    if (!userHasEditPrivileges) {
                        return [2 /*return*/, undefined];
                    }
                    setInputFieldChange(true);
                    if (!option) return [3 /*break*/, 2];
                    if (param === 'description' && ((_c = (_b = o[1]) === null || _b === void 0 ? void 0 : _b.desc) === null || _c === void 0 ? void 0 : _c["".concat(languageCode)])) {
                        o[1].desc["".concat(languageCode)].desc = newValue;
                    }
                    else if (param === 'mOptionModelCode') {
                        o[1].mfgOptMdlCd = newValue;
                    }
                    else if (param === 'pOptionModelCode') {
                        o[1].prcOptMdlCd = newValue;
                    }
                    else if (param === 'optionCode') {
                        o[0] = newValue;
                    }
                    return [4 /*yield*/, handleOptionSave(isEditable, optionsData, productId !== null && productId !== void 0 ? productId : '', productVersion !== null && productVersion !== void 0 ? productVersion : '', data)];
                case 1:
                    response = _g.sent();
                    return [2 /*return*/, response];
                case 2:
                    arrayifiedOption = Object.entries(optionToUse);
                    if (!((_d = arrayifiedOption === null || arrayifiedOption === void 0 ? void 0 : arrayifiedOption[0]) === null || _d === void 0 ? void 0 : _d[1])) return [3 /*break*/, 4];
                    if (param === 'description' && ((_f = (_e = arrayifiedOption === null || arrayifiedOption === void 0 ? void 0 : arrayifiedOption[0]) === null || _e === void 0 ? void 0 : _e[1]) === null || _f === void 0 ? void 0 : _f.desc)) {
                        arrayifiedOption[0][1].desc["".concat(languageCode)].desc = newValue;
                    }
                    else if (param === 'mOptionModelCode') {
                        arrayifiedOption[0][1].mfgOptMdlCd = newValue;
                    }
                    else if (param === 'pOptionModelCode') {
                        arrayifiedOption[0][1].prcOptMdlCd = newValue;
                    }
                    else if (param === 'optionCode') {
                        arrayifiedOption[0][1].optionCode = newValue;
                    }
                    if (!arrayifiedOption[0][1].optionCode) return [3 /*break*/, 4];
                    newOptionToPass = (_a = {},
                        _a[arrayifiedOption[0][1].optionCode] = {
                            optionCode: arrayifiedOption[0][1].optionCode,
                            desc: arrayifiedOption[0][1].desc,
                            mfgOptMdlCd: arrayifiedOption[0][1].mfgOptMdlCd,
                            prcOptMdlCd: arrayifiedOption[0][1].prcOptMdlCd,
                        },
                        _a);
                    return [4 /*yield*/, handleOptionSave(isEditable, newOptionToPass, productId !== null && productId !== void 0 ? productId : '', productVersion !== null && productVersion !== void 0 ? productVersion : '', data)];
                case 3:
                    response = _g.sent();
                    return [2 /*return*/, response];
                case 4: return [2 /*return*/, undefined];
            }
        });
    }); };
    var handleFeatureDetailInputFieldChange = function (fnc, event) {
        if (isEditable && userHasEditPrivileges) {
            setInputFieldChange(true);
            fnc(event.target.value);
        }
    };
    var checkPrevSeq = function () {
        derivedFeatures === null || derivedFeatures === void 0 ? void 0 : derivedFeatures.forEach(function (e) {
            var extractSeqNumberIndex = e[0].lastIndexOf('-');
            var extractedSeqNumber = e[0].substring(extractSeqNumberIndex + 1);
            var currentSeqNumber = parseInt(extractedSeqNumber, 10);
            listOfSeqVersions.push(currentSeqNumber);
        });
        var newestVersion = Math.max.apply(Math, listOfSeqVersions);
        if (newestVersion >= featureSeqNumber) {
            setFeatureSeqNumber(newestVersion + 1);
        }
        else {
            setFeatureSeqNumber(featureSeqNumber + 1);
        }
        newDerivedFeatureData.featureId = "".concat(data.featureId, "-").concat(optionCode, "-").concat(featureSeqNumber);
    };
    var injectNewDerivedFeature = function () {
        checkPrevSeq();
        setAddNewDerivedFeature(function (prevVal) { return !prevVal; });
    };
    var addOneToSequence = function () {
        setFeatureSeqNumber(featureSeqNumber + 1);
        newDerivedFeatureData.featureId = "".concat(data.featureId, "-").concat(optionCode, "-").concat(featureSeqNumber + 1);
    };
    var receiveNewData = function (val) {
        setDerivedFeatures(Object.entries(val));
        childRef.current.injectNewDerivedFeatureData(newDerivedFeatureData); // clears the feature modal after save
    };
    var handleIsModalLoaded = function () {
        setAddNewDerivedFeature(true);
    };
    var handleCopyButtonClick = function (derivedFeature) {
        checkPrevSeq();
        setDerivedFeatureToInject(function (prevVal) {
            if (prevVal) {
                return undefined;
            }
            return undefined;
        });
        setOpenModalByForce(true);
        setDerivedFeatureToInject(function (prevVal) {
            if (!prevVal) {
                return derivedFeature;
            }
            return undefined;
        });
    };
    var handleDeleteButtonClick = function (derivedFeature) { return __awaiter(_this, void 0, void 0, function () {
        var derivedFeatureToDelete;
        var _this = this;
        return __generator(this, function (_a) {
            derivedFeatureToDelete = derivedFeatures === null || derivedFeatures === void 0 ? void 0 : derivedFeatures.find(function (df) { return df[0] === (derivedFeature === null || derivedFeature === void 0 ? void 0 : derivedFeature.featureId); });
            if (derivedFeatureToDelete) {
                confirm({
                    title: "Delete derived feature with ID '".concat(derivedFeatureToDelete[0], "'?"),
                    cancellationText: 'No',
                    confirmationText: 'Yes',
                })
                    .then(function () { return __awaiter(_this, void 0, void 0, function () {
                    var response;
                    var _a, _b;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                setDerivedFeatureIdToDelete(function (prevVal) {
                                    if (!prevVal) {
                                        return derivedFeatureToDelete;
                                    }
                                    return undefined;
                                });
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                (_b = (_a = data.options) === null || _a === void 0 ? void 0 : _a[optionCode].derivedFeatures) === null || _b === void 0 ? true : delete _b[derivedFeatureToDelete[0]];
                                return [4 /*yield*/, saveDraft({
                                        productId: productId !== null && productId !== void 0 ? productId : '',
                                        productVersion: productVersion !== null && productVersion !== void 0 ? productVersion : '',
                                        feature: {
                                            featureId: data.featureId,
                                            step: data.step,
                                            type: data.type,
                                            desc: data.desc,
                                            mtrlCd: data.mtrlCd,
                                            mfgMdlCd: data.mfgMdlCd,
                                            prcMdlCd: data.prcMdlCd,
                                            options: data.options,
                                        },
                                        status: SaveDraftRequest.status.DRAFT,
                                    })];
                            case 1:
                                response = _c.sent();
                                if (response.status !== 200) {
                                    setSnackbarAlert({
                                        message: response.body.message || '',
                                        type: 'error',
                                    });
                                }
                                else {
                                    setDerivedFeatures(function (prevVal) { return prevVal === null || prevVal === void 0 ? void 0 : prevVal.filter(function (df) { return df[0] !== (derivedFeatureToDelete === null || derivedFeatureToDelete === void 0 ? void 0 : derivedFeatureToDelete[0]); }); });
                                    setSnackbarAlert({
                                        message: "Derived feature with ID '".concat(derivedFeatureToDelete[0], "' deleted"),
                                        type: 'success',
                                    });
                                    setDerivedFeatureIdToDelete(undefined);
                                }
                                return [2 /*return*/, response];
                        }
                    });
                }); })
                    .catch();
            }
            return [2 /*return*/];
        });
    }); };
    var handleNewOptionRoyaltySave = function (newRoyalty, codeForOption) { return __awaiter(_this, void 0, void 0, function () {
        var newFeature, currOptionRoyalties, productVersionToPass, response;
        var _a, _b;
        var _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    setIsSaveProcessConfirming(true);
                    newFeature = __assign({}, data);
                    if ((_e = (_d = (_c = newFeature.options) === null || _c === void 0 ? void 0 : _c["".concat(optionCode)]) === null || _d === void 0 ? void 0 : _d.royalties) === null || _e === void 0 ? void 0 : _e["".concat(newRoyalty.royaltyId)]) {
                        setIsSaveProcessConfirming(false);
                        setDuplicateRoyaltyIdError(true);
                        setSnackbarAlert({
                            message: "Royalty with id '".concat(newRoyalty.royaltyId, "' already exists."),
                            type: 'error',
                        });
                        setDisableConfirmButton(true);
                        return [2 /*return*/];
                    }
                    if (!((_f = newFeature.options["".concat(codeForOption)]) === null || _f === void 0 ? void 0 : _f.royalties)) {
                        newFeature.options["".concat(codeForOption)].royalties = (_a = {}, _a["".concat(newRoyalty.royaltyId)] = newRoyalty, _a);
                    }
                    else {
                        currOptionRoyalties = __assign({}, newFeature.options["".concat(codeForOption)].royalties);
                        newFeature.options["".concat(codeForOption)].royalties = __assign(__assign({}, currOptionRoyalties), (_b = {}, _b["".concat(newRoyalty.royaltyId)] = newRoyalty, _b));
                    }
                    productVersionToPass = productVersion !== null && productVersion !== void 0 ? productVersion : '';
                    return [4 /*yield*/, saveDraft({
                            productVersion: productVersionToPass,
                            productId: productId,
                            status: SaveDraftRequest.status.DRAFT,
                            feature: newFeature,
                        })];
                case 1:
                    response = _g.sent();
                    if (response.status === 200) {
                        setNewOptionRoyalty({ royaltyId: '', royaltyDesc: '' });
                        setOptionRoyalties(__spreadArray(__spreadArray([], optionRoyalties, true), [newRoyalty], false));
                        setSnackbarAlert({
                            message: "Successfully saved option royalty with id '".concat(newRoyalty.royaltyId, "'"),
                            type: 'success',
                        });
                    }
                    else {
                        setSnackbarAlert({
                            message: (response === null || response === void 0 ? void 0 : response.body).message || '',
                            type: 'error',
                        });
                    }
                    setIsSaveProcessConfirming(false);
                    setDisableConfirmButton(true);
                    // eslint-disable-next-line consistent-return
                    return [2 /*return*/, response];
            }
        });
    }); };
    var handleOptionRoyaltyBlur = function (engineeringDataForFeature, action) { return __awaiter(_this, void 0, void 0, function () {
        var productVersionToPass, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    productVersionToPass = productVersion !== null && productVersion !== void 0 ? productVersion : '';
                    return [4 /*yield*/, saveDraft({
                            productVersion: productVersionToPass,
                            productId: productId,
                            status: SaveDraftRequest.status.DRAFT,
                            feature: __assign({}, engineeringDataForFeature),
                        })];
                case 1:
                    response = _a.sent();
                    if (response.status === 200) {
                        switch (action) {
                            case 'delete':
                                setSnackbarAlert({
                                    message: "Successfully deleted option royalty.",
                                    type: 'success',
                                });
                                setOptionRoyalties(function () { var _a; return Object.values((_a = engineeringDataForFeature.options["".concat(optionCode)].royalties) !== null && _a !== void 0 ? _a : []); });
                                setIdThatIsBeingDeleted('');
                                break;
                            case 'edit':
                                setSnackbarAlert({
                                    message: "Successfully edited option royalty.",
                                    type: 'success',
                                });
                                break;
                            default:
                                setSnackbarAlert({
                                    message: "Operation successful.",
                                    type: 'success',
                                });
                        }
                    }
                    return [2 /*return*/, response];
            }
        });
    }); };
    var handleRoyaltyEdit = function (newRoyalty, originalRoyalty) {
        var _a, _b, _c;
        var _d;
        var featureToUpdate = __assign({}, data);
        var currOptionRoyalties = __assign({}, featureToUpdate.options["".concat(optionCode)].royalties);
        var updateRoyalty;
        if (((_d = newRoyalty.royaltyId) === null || _d === void 0 ? void 0 : _d.trim()) === '') {
            setSnackbarAlert({
                message: "Royalty code cannot be blank.",
                type: 'error',
            });
            return;
        }
        if (newRoyalty.royaltyId !== originalRoyalty.royaltyId) {
            if (currOptionRoyalties === null || currOptionRoyalties === void 0 ? void 0 : currOptionRoyalties["".concat(newRoyalty.royaltyId)]) {
                if (currOptionRoyalties["".concat(newRoyalty.royaltyId)].royaltyDesc === newRoyalty.royaltyDesc) {
                    setSnackbarAlert({
                        message: "Royalty with royalty code '".concat(newRoyalty.royaltyId, "' already exists."),
                        type: 'error',
                    });
                    return;
                }
                // update description of original Option Royalty when Royalty with Code already exists
                featureToUpdate.options["".concat(optionCode)].royalties = __assign(__assign({}, featureToUpdate.options["".concat(optionCode)].royalties), (_a = {}, _a["".concat(originalRoyalty.royaltyId)] = {
                    royaltyId: originalRoyalty.royaltyId,
                    royaltyDesc: newRoyalty.royaltyDesc,
                }, _a));
                updateRoyalty = {
                    royaltyId: originalRoyalty.royaltyId,
                    royaltyDesc: newRoyalty.royaltyDesc,
                };
            }
            else {
                // update original Option Royalty with new Code
                delete featureToUpdate.options["".concat(optionCode)].royalties[originalRoyalty.royaltyId];
                featureToUpdate.options["".concat(optionCode)].royalties = __assign(__assign({}, featureToUpdate.options["".concat(optionCode)].royalties), (_b = {}, _b["".concat(newRoyalty.royaltyId)] = newRoyalty, _b));
                updateRoyalty = newRoyalty;
            }
        }
        else {
            // update description of original Option Royalty when Code wasn't changed
            featureToUpdate.options["".concat(optionCode)].royalties = __assign(__assign({}, featureToUpdate.options["".concat(optionCode)].royalties), (_c = {}, _c["".concat(newRoyalty.royaltyId)] = newRoyalty, _c));
            updateRoyalty = newRoyalty;
        }
        setOptionRoyalties(function (prevVal) {
            var newArrayOfRoyalties = __spreadArray([], prevVal, true);
            var indexToOverwrite = newArrayOfRoyalties.findIndex(function (opRoy) { return opRoy.royaltyId === originalRoyalty.royaltyId; });
            newArrayOfRoyalties[indexToOverwrite] = updateRoyalty;
            handleOptionRoyaltyBlur(featureToUpdate, 'edit');
            return newArrayOfRoyalties;
        });
    };
    var deleteRoyalty = function (id) {
        setIdThatIsBeingDeleted(id);
        confirm({
            title: "Delete royalty with ID '".concat(id, "'?"),
            cancellationText: 'No',
            confirmationText: 'Yes',
        })
            .then(function () { return __awaiter(_this, void 0, void 0, function () {
            var newFeature, currOptionRoyalties;
            return __generator(this, function (_a) {
                setIdThatIsBeingDeleted(id);
                newFeature = __assign({}, data);
                currOptionRoyalties = __assign({}, newFeature.options["".concat(optionCode)].royalties);
                delete currOptionRoyalties["".concat(id)];
                newFeature.options["".concat(optionCode)].royalties = currOptionRoyalties;
                handleOptionRoyaltyBlur(newFeature, 'delete');
                return [2 /*return*/];
            });
        }); })
            .catch(function () {
            setIdThatIsBeingDeleted('');
        });
    };
    var programmaticallyInjectNewDerivedFeature = function () {
        setOpenModalByForce(true);
        setDerivedFeatureToInject(undefined);
        childRef.current.injectNewDerivedFeatureData(newDerivedFeatureData);
    };
    var validateHeaderRoyaltyInput = function (input) {
        var _a;
        setNewOptionRoyalty({
            royaltyId: input,
            royaltyDesc: (_a = newOptionRoyalty === null || newOptionRoyalty === void 0 ? void 0 : newOptionRoyalty.royaltyDesc) !== null && _a !== void 0 ? _a : '',
        });
        if (input.trim() === '' || !alphanumericHyphen(input)) {
            setDuplicateRoyaltyIdError(false);
            setIsNewOptionRoyaltyError(true);
            setDisableConfirmButton(true);
            return;
        }
        setIsNewOptionRoyaltyError(false);
        if (optionRoyalties.find(function (r) { return r.royaltyId === input; })) {
            setDuplicateRoyaltyIdError(true);
            setDisableConfirmButton(true);
            return;
        }
        setDuplicateRoyaltyIdError(false);
        setDisableConfirmButton(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ className: styles.optionFieldContainer }, { children: optionCode && (_jsxs(Typography, __assign({ className: styles.existingOptionLabel }, { children: ["Option Code: ", _jsx("span", __assign({ className: styles.value }, { children: optCode }))] }))) })), _jsxs(Box, __assign({ className: styles.optionFieldContainer }, { children: [_jsx(Typography, __assign({ className: styles.newOptionFieldLabel }, { children: "Option Description:" })), _jsx(TextField, { size: "small", value: optDesc, fullWidth: true, onChange: function (e) {
                            if (data.type === EngineeringFeature.type.IMPLIED ||
                                data.type === EngineeringFeature.type.DERIVED) {
                                handleFeatureDetailInputFieldChange(setOptDesc, e);
                            }
                            else if (userHasEditPrivileges) {
                                setSnackbarAlert({
                                    message: 'Editing for Base/NonBase Option Description disabled',
                                    type: 'error',
                                });
                            }
                        }, onBlur: function () { return __awaiter(_this, void 0, void 0, function () {
                            var response;
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        if (!(inputFieldChange && optDesc !== ((_a = optionsData === null || optionsData === void 0 ? void 0 : optionsData[optionCode].desc) === null || _a === void 0 ? void 0 : _a["".concat(languageCode)].desc))) return [3 /*break*/, 2];
                                        return [4 /*yield*/, handleOptionsFieldChangeAndBlur(optDesc.trim(), optionToUse, 'description')];
                                    case 1:
                                        response = _b.sent();
                                        if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                                            setSnackbarAlert({
                                                message: (response === null || response === void 0 ? void 0 : response.body).message || '',
                                                type: 'error',
                                            });
                                        }
                                        else {
                                            setSnackbarAlert({
                                                message: "Option Description saved",
                                                type: 'success',
                                            });
                                        }
                                        setInputFieldChange(function (prevVal) { return !prevVal; });
                                        _b.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); }, disabled: !userHasEditPrivileges || !isEditable })] })), _jsxs(Box, __assign({ className: styles.optionFieldContainer }, { children: [_jsx(Typography, __assign({ className: styles.newOptionFieldLabel }, { children: "Manufacturing Option Model Code:" })), _jsx(TextField, { size: "small", value: mOptModelCode, onChange: function (e) { return handleFeatureDetailInputFieldChange(setMOptModelCode, e); }, onBlur: function () { return __awaiter(_this, void 0, void 0, function () {
                            var response;
                            var _a, _b, _c;
                            return __generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        if (!(noSpaces(mOptModelCode) &&
                                            inputFieldChange &&
                                            mOptModelCode.trim() !== ((_c = (_b = (_a = optionsData === null || optionsData === void 0 ? void 0 : optionsData[optionCode]) === null || _a === void 0 ? void 0 : _a.mfgOptMdlCd) === null || _b === void 0 ? void 0 : _b.trim()) !== null && _c !== void 0 ? _c : ''))) return [3 /*break*/, 2];
                                        return [4 /*yield*/, handleOptionsFieldChangeAndBlur(mOptModelCode, optionToUse, 'mOptionModelCode')];
                                    case 1:
                                        response = _d.sent();
                                        if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                                            setSnackbarAlert({
                                                message: (response === null || response === void 0 ? void 0 : response.body).message || '',
                                                type: 'error',
                                            });
                                        }
                                        else {
                                            setSnackbarAlert({
                                                message: "Manufacturing Option Model Code saved",
                                                type: 'success',
                                            });
                                        }
                                        setInputFieldChange(function (prevVal) { return !prevVal; });
                                        _d.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); }, fullWidth: true, error: !noSpaces(mOptModelCode), disabled: !userHasEditPrivileges || !isEditable })] })), _jsxs(Box, __assign({ className: styles.optionFieldContainer, sx: { marginBottom: '10px' } }, { children: [_jsx(Typography, __assign({ className: styles.newOptionFieldLabel }, { children: "Price Option Model Code:" })), _jsx(TextField, { size: "small", value: pOptModelCode, onChange: function (e) { return handleFeatureDetailInputFieldChange(setPOptModelCode, e); }, onBlur: function () { return __awaiter(_this, void 0, void 0, function () {
                            var response;
                            var _a, _b, _c;
                            return __generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        if (!(noSpaces(pOptModelCode) &&
                                            inputFieldChange &&
                                            pOptModelCode.trim() !== ((_c = (_b = (_a = optionsData === null || optionsData === void 0 ? void 0 : optionsData[optionCode]) === null || _a === void 0 ? void 0 : _a.prcOptMdlCd) === null || _b === void 0 ? void 0 : _b.trim()) !== null && _c !== void 0 ? _c : ''))) return [3 /*break*/, 2];
                                        return [4 /*yield*/, handleOptionsFieldChangeAndBlur(pOptModelCode, optionToUse, 'pOptionModelCode')];
                                    case 1:
                                        response = _d.sent();
                                        if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                                            setSnackbarAlert({
                                                message: (response === null || response === void 0 ? void 0 : response.body).message || '',
                                                type: 'error',
                                            });
                                        }
                                        else {
                                            setSnackbarAlert({
                                                message: "Price Option Model Code saved",
                                                type: 'success',
                                            });
                                        }
                                        setInputFieldChange(function (prevVal) { return !prevVal; });
                                        _d.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); }, fullWidth: true, error: !noSpaces(pOptModelCode), disabled: !userHasEditPrivileges || !isEditable })] })), optionRoyalties.length > 0 || isOptionRoyaltyModalOpen ? (_jsxs(Box, __assign({ sx: {
                    backgroundColor: '#D8BFD8',
                    borderRadius: '5px',
                    paddingTop: '1px',
                } }, { children: [_jsxs(Box, __assign({ sx: {
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: '10px',
                            marginLeft: '10px',
                            marginTop: '10px',
                        } }, { children: [_jsx(Typography, __assign({ className: styles.royaltyLabel, sx: { fontWeight: 900 } }, { children: optionRoyalties.length > 1 ? "Royalties:" : "Royalty:" })), _jsxs(Box, __assign({ sx: { marginLeft: 'auto', marginRight: '10px' } }, { children: [isOptionRoyaltyModalOpen && (_jsx("span", __assign({ style: { marginRight: '10px' } }, { children: _jsx(StyledButton, { label: "Confirm", heightFromProps: 30, handleClick: function () { return handleNewOptionRoyaltySave(newOptionRoyalty, optionCode); }, disabledFromProps: disableConfirmButton ||
                                                (newOptionRoyalty === null || newOptionRoyalty === void 0 ? void 0 : newOptionRoyalty.royaltyId.trim()) === '' ||
                                                ((_m = newOptionRoyalty === null || newOptionRoyalty === void 0 ? void 0 : newOptionRoyalty.royaltyDesc) === null || _m === void 0 ? void 0 : _m.trim()) === '', loading: isSaveProcessConfirming, loadingSpinnerSize: 16 }) }))), userHasEditPrivileges && isEditable && (_jsx(StyledButton, { label: isOptionRoyaltyModalOpen ? 'Close' : 'Add', heightFromProps: 30, handleClick: function () {
                                            setIsOptionRoyaltyModalOpen(function (prevVal) {
                                                if (prevVal) {
                                                    setNewOptionRoyalty({ royaltyId: '', royaltyDesc: '' });
                                                }
                                                return !prevVal;
                                            });
                                        } }))] }))] })), _jsx(Box, __assign({ className: styles.royaltyContainer }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', width: '100%' } }, { children: [isOptionRoyaltyModalOpen && (_jsxs(Box, __assign({ className: styles.rowContainer }, { children: [_jsx(TextField, { label: "Code", size: "small", value: (_o = newOptionRoyalty === null || newOptionRoyalty === void 0 ? void 0 : newOptionRoyalty.royaltyId) !== null && _o !== void 0 ? _o : '', sx: {
                                                '&.MuiInputLabel-root': {
                                                    backgroundColor: 'red',
                                                },
                                            }, InputProps: {
                                                endAdornment: (_jsx(ErrorAdornment, { errors: [
                                                        {
                                                            condition: isNewOptionRoyaltyError,
                                                            message: 'Royalty Code cannot be empty and must contain only alpha-numeric characters and hyphens with no spaces',
                                                        },
                                                        {
                                                            condition: duplicateRoyaltyIdError,
                                                            message: "Royalty with Royalty Code '".concat((_p = newOptionRoyalty === null || newOptionRoyalty === void 0 ? void 0 : newOptionRoyalty.royaltyId) !== null && _p !== void 0 ? _p : '', "' already exists"),
                                                        },
                                                    ] })),
                                                sx: {
                                                    input: {
                                                        width: '100px',
                                                        padding: '5px',
                                                    },
                                                },
                                            }, onChange: function (e) {
                                                validateHeaderRoyaltyInput(e.target.value);
                                            }, error: isNewOptionRoyaltyError || duplicateRoyaltyIdError }), _jsx(TextField, { sx: {
                                                marginRight: '34px',
                                            }, label: "Description", fullWidth: true, size: "small", value: (_q = newOptionRoyalty === null || newOptionRoyalty === void 0 ? void 0 : newOptionRoyalty.royaltyDesc) !== null && _q !== void 0 ? _q : '', inputProps: {
                                                style: {
                                                    height: '33px !important',
                                                    padding: '5px',
                                                },
                                            }, onChange: function (e) {
                                                var _a;
                                                return setNewOptionRoyalty({
                                                    royaltyId: (_a = newOptionRoyalty === null || newOptionRoyalty === void 0 ? void 0 : newOptionRoyalty.royaltyId) !== null && _a !== void 0 ? _a : '',
                                                    royaltyDesc: e.target.value.trim(),
                                                });
                                            } })] }))), _jsx(Box, __assign({ sx: { marginBottom: '20px' } }, { children: optionRoyalties.map(function (royalty) { return (_jsx(ExistingRoyaltyRow, { isEditable: isEditable, royalty: royalty, royaltyArray: optionRoyalties, sendRoyaltyToParent: handleRoyaltyEdit, toggleAllowPublish: handleTogglePublish, sendRoyaltyIdToParentToDelete: deleteRoyalty, idThatIsBeingDeleted: idThatIsBeingDeleted }, royalty.royaltyId)); }) }))] })) }))] }))) : (_jsx(Box, { children: userHasEditPrivileges && isEditable && (_jsx(StyledButton, { label: "Add Royalties", widthFromProps: 150, handleClick: function () {
                        setDisableConfirmButton(true);
                        setIsOptionRoyaltyModalOpen(true);
                    } })) })), data.type !== EngineeringFeature.type.IMPLIED && (_jsxs(Box, __assign({ className: styles.derivedFeatureContainer }, { children: [_jsxs(Box, __assign({ className: styles.titleAndButtonRow }, { children: [(addNewDerivedFeature || (derivedFeatures && derivedFeatures.length > 0)) && (_jsx(Typography, __assign({ className: styles.titleContainer }, { children: "Derived Features" }))), userHasEditPrivileges && isEditable && (_jsx(Box, __assign({ sx: { marginLeft: 'auto' } }, { children: _jsx(StyledButton, { handleClick: !addNewDerivedFeature
                                        ? injectNewDerivedFeature
                                        : function () {
                                            setAddNewDerivedFeature(function (prevVal) { return !prevVal; });
                                            setOpenModalByForce(false);
                                        }, disabledFromProps: !isEditable, label: !addNewDerivedFeature ? 'Add New Derived Feature' : 'Close', widthFromProps: !addNewDerivedFeature ? 190 : 100 }) })))] })), (addNewDerivedFeature || openModalByForce) && (_jsx(Box, { children: _jsx(FeatureModal, { isEditable: isEditable, showMessageForDuplicateIds: false, addFeatureModal: true, derivedFeatureToInject: newDerivedFeatureData, featureData: data, optionCode: optionCode, productId: productId, productVersion: productVersion, sendDerivedFeatureToParent: receiveNewData, isModalLoaded: handleIsModalLoaded, programmaticallyInjectNewDerivedFeature: programmaticallyInjectNewDerivedFeature, ref: childRef, addToSequence: addOneToSequence }) })), derivedFeatures === null || derivedFeatures === void 0 ? void 0 : derivedFeatures.map(function (derivedFeature) { return (_jsx(Box, __assign({ sx: { marginBottom: '10px' } }, { children: _jsx(FeatureModal, { isEditable: isEditable, showMessageForDuplicateIds: false, addFeatureModal: true, derivedFeatureToInject: derivedFeature === null || derivedFeature === void 0 ? void 0 : derivedFeature[1], derivedFeatureIdToDelete: derivedFeatureIdToDelete, featureData: data, optionCode: optionCode, productId: productId, productVersion: productVersion, existingDerivedFeature: true, sendDerivedFeatureToParent: receiveNewData, handleCopyButtonClick: handleCopyButtonClick, handleDeleteButtonClick: handleDeleteButtonClick }) }), "".concat(derivedFeature === null || derivedFeature === void 0 ? void 0 : derivedFeature[0]))); })] }))), _jsx("hr", { className: styles.horizontalRule })] }));
}

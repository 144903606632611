export function getUTCDateData(date, time) {
    var timeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    var timeVal = time || '00:00:00';
    if (!time || time === null || time === '') {
        timeVal = '00:00:00';
    }
    else if (!timeRegex.test(time)) {
        timeVal = "".concat(timeVal, ":00");
    }
    else {
        timeVal = time;
    }
    var dt = new Date("".concat(date, "T").concat(timeVal, ".000Z"));
    var tzo = new Date().getTimezoneOffset() * 60000;
    var convertedDate = new Date(dt.getTime() + tzo * 2); // bad way of doing this - fix in future
    var d = convertedDate.getDate().toString().length > 1 ? convertedDate.getDate() : "0".concat(convertedDate.getDate());
    var m = (convertedDate.getMonth() + 1).toString().padStart(2, '0');
    var y = convertedDate.getUTCFullYear();
    var convertedDatePieces = convertedDate.toString().split(' ');
    return { time: convertedDatePieces[4], date: "".concat(y, "-").concat(m, "-").concat(d) };
}

export var AmplifyConfig = {
    aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
    oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: ['openid', 'profile', 'email'],
        redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
        responseType: 'code',
    },
    Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    },
};

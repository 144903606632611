var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import { StyledButton } from 'components/Shared/StyledComponents/StyledButton';
import { StyledProductExpireHeaderRow } from 'components/Shared/StyledComponents/StyledProductExpireHeaderRow';
import { StyledSearchWithDateAndTimeInput } from 'components/Shared/StyledComponents/StyledSearchWithDateAndTimeInput/StyledSearchWithDateAndTimeInput';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { expire, getProductVersions, info } from 'services/api/product-service';
import { convertDateToLocalTime } from 'services/helperFunctions/convertDateToLocalTime';
import { parseDate } from 'services/helperFunctions/parseDate';
import { toUTCTimestamp } from 'services/helperFunctions/toUTCTimestamp';
import { useLanguageCode } from 'services/helperFunctions/useLanguageCode';
import styles from './ProductExpireDetailPage.module.scss';
export function ProductExpireDetailPage() {
    var _this = this;
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var languageCode = useLanguageCode();
    var searchParams = useSearchParams()[0];
    var _j = useState(), expireData = _j[0], setExpireData = _j[1];
    var _k = useState(), startDateData = _k[0], setStartDateData = _k[1];
    var _l = useState(), endDateData = _l[0], setEndDateData = _l[1];
    var _m = useState(), latestProductVersion = _m[0], setLatestProductVersion = _m[1];
    var _o = useState(false), disableExpireSubmitButton = _o[0], setDisableExpireSubmitButton = _o[1];
    var confirm = useConfirm();
    var _p = useState(false), isLoading = _p[0], setIsLoading = _p[1];
    var navigate = useNavigate();
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
    var productIdFromSearchParams = searchParams.get('productId');
    useEffect(function () {
        if (!expireData) {
            var getProductInfo = function () { return __awaiter(_this, void 0, void 0, function () {
                var productVersionsData, productVersions, latestVersion, productInfo, startDateConvertedToLocalTime, endDateTimestampOnLoad, parsedStartDateData, parsedEndDateData;
                var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                return __generator(this, function (_k) {
                    switch (_k.label) {
                        case 0:
                            setIsLoading(true);
                            return [4 /*yield*/, getProductVersions({
                                    productId: productIdFromSearchParams !== null && productIdFromSearchParams !== void 0 ? productIdFromSearchParams : '',
                                    processingDate: '3099-12-31T23:59:59.000Z', // fetches all version before this date
                                })];
                        case 1:
                            productVersionsData = _k.sent();
                            productVersions = (_a = productVersionsData.body.details) === null || _a === void 0 ? void 0 : _a.versions;
                            latestVersion = productVersions === null || productVersions === void 0 ? void 0 : productVersions.sort(function (a, b) { return new Date(b.startDate).getTime() - new Date(a.startDate).getTime(); })[0];
                            setLatestProductVersion(latestVersion === null || latestVersion === void 0 ? void 0 : latestVersion.productVersion);
                            return [4 /*yield*/, info({
                                    productId: productIdFromSearchParams !== null && productIdFromSearchParams !== void 0 ? productIdFromSearchParams : '',
                                    productVersion: latestVersion === null || latestVersion === void 0 ? void 0 : latestVersion.productVersion,
                                })];
                        case 2:
                            productInfo = _k.sent();
                            startDateConvertedToLocalTime = convertDateToLocalTime(new Date((_b = latestVersion === null || latestVersion === void 0 ? void 0 : latestVersion.startDate) !== null && _b !== void 0 ? _b : ''));
                            endDateTimestampOnLoad = ((_d = (_c = productInfo.body.details) === null || _c === void 0 ? void 0 : _c.header) === null || _d === void 0 ? void 0 : _d.endDate) &&
                                ((_f = (_e = productInfo.body.details) === null || _e === void 0 ? void 0 : _e.header) === null || _f === void 0 ? void 0 : _f.endDate) !== '3099-12-31T23:59:59.000Z'
                                ? convertDateToLocalTime(new Date((_j = (_h = (_g = productInfo.body.details) === null || _g === void 0 ? void 0 : _g.header) === null || _h === void 0 ? void 0 : _h.endDate) !== null && _j !== void 0 ? _j : ''))
                                : '3099-12-31T23:59:59.000Z';
                            parsedStartDateData = parseDate(startDateConvertedToLocalTime);
                            parsedEndDateData = parseDate(endDateTimestampOnLoad);
                            setExpireData(productInfo.body);
                            setStartDateData(parsedStartDateData);
                            setEndDateData(parsedEndDateData ? parsedEndDateData : undefined);
                            setIsLoading(false);
                            return [2 /*return*/];
                    }
                });
            }); };
            getProductInfo();
        }
    }, [expireData, productIdFromSearchParams]);
    useEffect(function () {
        var _a, _b, _c;
        if (expireData) {
            var parsedEndDateFromApi = parseDate((_c = (_b = (_a = expireData === null || expireData === void 0 ? void 0 : expireData.details) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.endDate) !== null && _c !== void 0 ? _c : '3099-12-31T23:59:59.000Z');
            if ((parsedEndDateFromApi.parsedDateString ===
                (endDateData === null || endDateData === void 0 ? void 0 : endDateData.parsedDateString) &&
                parsedEndDateFromApi.parsedTimeString ===
                    (endDateData === null || endDateData === void 0 ? void 0 : endDateData.parsedTimeString)) ||
                (parsedEndDateFromApi.parsedDateString ===
                    (endDateData === null || endDateData === void 0 ? void 0 : endDateData.parsedDateString) &&
                    toUTCTimestamp(endDateData === null || endDateData === void 0 ? void 0 : endDateData.parsedTimeStamp)
                        .parsedTimeString) === parsedEndDateFromApi.parsedTimeString) {
                setDisableExpireSubmitButton(true);
            }
            else {
                setDisableExpireSubmitButton(false);
            }
        }
    }, [endDateData, expireData]);
    var compareTimeStamps = function (current, end) {
        if (current.milliseconds > end.milliseconds) {
            setSnackbarAlert({
                message: 'Unable to set expiration date before start date - reverting to current date',
                type: 'error',
            });
        }
        setEndDateData([current, end].sort(function (a, b) { return b.milliseconds - a.milliseconds; })[0]);
        return [current, end].sort(function (a, b) { return b.milliseconds - a.milliseconds; })[0];
    };
    var setProductExpirationDate = function () { return __awaiter(_this, void 0, void 0, function () {
        var now, dateObjectToConvert, newDateFromPassedTimestamp, milliseconds, millisecondsWithTzo, dateConvertedForBackend, backendTimestamp, response;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    setIsLoading(true);
                    now = parseDate(new Date().toString());
                    dateObjectToConvert = compareTimeStamps(now, endDateData);
                    newDateFromPassedTimestamp = new Date(dateObjectToConvert.parsedTimeStamp);
                    milliseconds = newDateFromPassedTimestamp.getTime();
                    millisecondsWithTzo = milliseconds + timezoneOffset;
                    dateConvertedForBackend = new Date(millisecondsWithTzo).toUTCString();
                    backendTimestamp = parseDate(dateConvertedForBackend);
                    return [4 /*yield*/, expire({
                            expirationDate: backendTimestamp.parsedTimeStamp,
                            productVersion: latestProductVersion,
                        })];
                case 1:
                    response = _e.sent();
                    if (response.status === 200) {
                        setSnackbarAlert({
                            message: "Expiration Date for product ".concat((_d = (_c = (_b = (_a = expireData.details) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c["".concat(languageCode)]) === null || _d === void 0 ? void 0 : _d.desc, " has been set"),
                            type: 'success',
                        });
                    }
                    else {
                        setSnackbarAlert({
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            message: response.body.message,
                            type: 'error',
                        });
                    }
                    setIsLoading(false);
                    setDisableExpireSubmitButton(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var resetProductExpire = function () {
        var _a, _b, _c, _d;
        confirm({
            title: "Are you sure you want to reset the expiration date for ".concat((_d = (_c = (_b = (_a = expireData.details) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c["".concat(languageCode)]) === null || _d === void 0 ? void 0 : _d.desc, "?"),
        })
            .then(function () { return __awaiter(_this, void 0, void 0, function () {
            var response;
            var _a, _b, _c, _d, _e, _f, _g, _h;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        setIsLoading(true);
                        setEndDateData(parseDate('3099-12-31T23:59:59.000Z'));
                        return [4 /*yield*/, expire({
                                expirationDate: '3099-12-31T23:59:59.000Z',
                                productVersion: latestProductVersion,
                            })];
                    case 1:
                        response = _j.sent();
                        if (response.status === 200) {
                            setSnackbarAlert({
                                message: "Successfully reset the expiration date for ".concat((_d = (_c = (_b = (_a = expireData.details) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c["".concat(languageCode)]) === null || _d === void 0 ? void 0 : _d.desc),
                                type: 'success',
                            });
                        }
                        else {
                            setSnackbarAlert({
                                message: "There was a problem resetting the expiration date for ".concat((_h = (_g = (_f = (_e = expireData.details) === null || _e === void 0 ? void 0 : _e.header) === null || _f === void 0 ? void 0 : _f.name) === null || _g === void 0 ? void 0 : _g["".concat(languageCode)]) === null || _h === void 0 ? void 0 : _h.desc),
                                type: 'error',
                            });
                        }
                        return [2 /*return*/];
                }
            });
        }); })
            .finally(function () {
            setIsLoading(false);
        });
    };
    var handleEndDateChange = function (newDateString) {
        setEndDateData(function (prevVal) {
            var useablePartOfTimestamp = "T".concat(prevVal.parsedTimeStamp.split('T')[1]);
            var newTimeStamp = "".concat(newDateString).concat(useablePartOfTimestamp);
            return parseDate(newTimeStamp);
        });
    };
    var handleEndTimeChange = function (newTimeString) {
        setEndDateData(function (prevVal) {
            var useablePartOfTimestamp = prevVal === null || prevVal === void 0 ? void 0 : prevVal.parsedTimeStamp.split('T')[0];
            var newTimeStamp = "".concat(useablePartOfTimestamp, "T").concat(newTimeString, ".000Z");
            return parseDate(newTimeStamp);
        });
    };
    if (isLoading) {
        return (_jsx(Box, __assign({ className: styles.loadingSpinnerContainer }, { children: _jsx(LoadingSpinner, { indicatorSize: 10 }) })));
    }
    return (_jsx(Box, { children: expireData && (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ className: styles.titleContainer }, { children: [_jsx(Typography, __assign({ variant: "h1", className: styles.pageTitle }, { children: "Product Expire" })), _jsx(Box, __assign({ className: styles.buttonContainer }, { children: _jsx(StyledButton, { label: "Back to Results", handleClick: function () {
                                    return navigate("/product-expire/search/".concat(sessionStorage.getItem('searchTerm')));
                                }, widthFromProps: 130 }) }))] })), _jsxs(Box, __assign({ className: styles.expireHeaderInfoContainer }, { children: [_jsx(StyledProductExpireHeaderRow, { label: "Product Prefix:", value: (_c = (_b = (_a = expireData.details) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.prefix) !== null && _c !== void 0 ? _c : '' }), _jsx(StyledProductExpireHeaderRow, { label: "Product Name:", value: (_h = (_g = (_f = (_e = (_d = expireData.details) === null || _d === void 0 ? void 0 : _d.header) === null || _e === void 0 ? void 0 : _e.name) === null || _f === void 0 ? void 0 : _f["".concat(languageCode)]) === null || _g === void 0 ? void 0 : _g.desc) !== null && _h !== void 0 ? _h : '' }), _jsx(StyledProductExpireHeaderRow, { label: "Product Version:", value: latestProductVersion !== null && latestProductVersion !== void 0 ? latestProductVersion : '' })] })), _jsx(Box, { children: _jsx(StyledSearchWithDateAndTimeInput, { isProductExpire: true, parsedStartDate: startDateData, parsedEndDate: endDateData, fireProductExpireApi: setProductExpirationDate, handleEndDateChangeInProductExpire: function (e) { return handleEndDateChange(e); }, handleEndTimeChangeInProductExpire: function (e) { return handleEndTimeChange(e); }, resetProductExpire: resetProductExpire, disableExpireSubmitButton: disableExpireSubmitButton }) })] })) }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { useEffect, useState } from 'react';
import { useCheckAuthScope } from 'services/helperFunctions/useCheckAuthScope';
import { noSpaces, alphanumericHyphen, alphanumericHyphenUnderscore } from 'services/helperFunctions/handleRegex';
import { StyledButton } from 'components/Shared/StyledComponents/StyledButton';
import { ExistingRoyaltyRow } from 'components/Shared/ExistingRoyaltyRow/ExistingRoyaltyRow';
import { useConfirm } from 'material-ui-confirm';
import { useLanguageCode } from 'services/helperFunctions/useLanguageCode';
import { handleEngRoyaltiesSave } from 'services/helperFunctions/handleInputFieldBlur';
import { ErrorAdornment } from 'components/Shared/ErrorAdornment/ErrorAdornment';
import { UI_WRITE_SCOPES } from 'utils/constants';
import styles from './ProductMaintenanceInputSection.module.scss';
export function ProductMaintenanceInputSection(_a) {
    var _this = this;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
    var isEditable = _a.isEditable, data = _a.data, modelCodeError = _a.modelCodeError, isProductRoyaltySectionOpen = _a.isProductRoyaltySectionOpen, forceCloseHeaderRoyaltySection = _a.forceCloseHeaderRoyaltySection, handleTogglePublish = _a.handleTogglePublish, productId = _a.productId, displayAddRoyaltiesButton = _a.displayAddRoyaltiesButton, handleBlurInParent = _a.handleBlurInParent;
    var languageCode = useLanguageCode();
    var userHasEditPrivileges = useCheckAuthScope(UI_WRITE_SCOPES);
    var confirm = useConfirm();
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var productLine = (_c = (_b = data.details) === null || _b === void 0 ? void 0 : _b.header) === null || _c === void 0 ? void 0 : _c.line;
    var productFunction = (_e = (_d = data.details) === null || _d === void 0 ? void 0 : _d.header) === null || _e === void 0 ? void 0 : _e.function;
    var _10 = useState(false), royaltyConfirmProcessing = _10[0], setRoyaltyConfirmProcessing = _10[1];
    var _11 = useState(false), disableConfirmButton = _11[0], setDisableConfirmButton = _11[1];
    // product line input values
    var _12 = useState((_g = (_f = productLine === null || productLine === void 0 ? void 0 : productLine.code) === null || _f === void 0 ? void 0 : _f.trim()) !== null && _g !== void 0 ? _g : ''), productLineCode = _12[0], setProductLineCode = _12[1];
    var _13 = useState(false), productLineCodeError = _13[0], setProductLineCodeError = _13[1];
    var _14 = useState((_j = (_h = productLine === null || productLine === void 0 ? void 0 : productLine.modelCode) === null || _h === void 0 ? void 0 : _h.trim()) !== null && _j !== void 0 ? _j : ''), productLineModelCode = _14[0], setProductLineModelCode = _14[1];
    var _15 = useState(false), productLineModelCodeError = _15[0], setProductLineModelCodeError = _15[1];
    var _16 = useState((_o = (_m = (_l = (_k = productLine === null || productLine === void 0 ? void 0 : productLine.description) === null || _k === void 0 ? void 0 : _k["".concat(languageCode)]) === null || _l === void 0 ? void 0 : _l.desc) === null || _m === void 0 ? void 0 : _m.trim()) !== null && _o !== void 0 ? _o : ''), productLineDesc = _16[0], setProductLineDesc = _16[1];
    // product function input values
    var _17 = useState((_q = (_p = productFunction === null || productFunction === void 0 ? void 0 : productFunction.code) === null || _p === void 0 ? void 0 : _p.trim()) !== null && _q !== void 0 ? _q : ''), productFunctionCode = _17[0], setProductFunctionCode = _17[1];
    var _18 = useState(false), productFunctionCodeError = _18[0], setProductFunctionCodeError = _18[1];
    var _19 = useState((_s = (_r = productFunction === null || productFunction === void 0 ? void 0 : productFunction.modelCode) === null || _r === void 0 ? void 0 : _r.trim()) !== null && _s !== void 0 ? _s : ''), productFunctionModelCode = _19[0], setProductFunctionModelCode = _19[1];
    var _20 = useState(false), productFunctionModelCodeError = _20[0], setProductFunctionModelCodeError = _20[1];
    var _21 = useState((_w = (_v = (_u = (_t = productFunction === null || productFunction === void 0 ? void 0 : productFunction.description) === null || _t === void 0 ? void 0 : _t["".concat(languageCode)]) === null || _u === void 0 ? void 0 : _u.desc) === null || _v === void 0 ? void 0 : _v.trim()) !== null && _w !== void 0 ? _w : ''), productFunctionDesc = _21[0], setProductFunctionDesc = _21[1];
    // discount input values
    var _22 = useState((_z = (_y = (_x = data.details) === null || _x === void 0 ? void 0 : _x.header) === null || _y === void 0 ? void 0 : _y.discountCode) !== null && _z !== void 0 ? _z : ''), discountCode = _22[0], setDiscountCode = _22[1];
    var _23 = useState(false), discountCodeError = _23[0], setDiscountCodeError = _23[1];
    var _24 = useState((_2 = (_1 = (_0 = data.details) === null || _0 === void 0 ? void 0 : _0.header) === null || _1 === void 0 ? void 0 : _1.discountDescription) !== null && _2 !== void 0 ? _2 : ((_3 = data.details) === null || _3 === void 0 ? void 0 : _3.header).discountDesc), discountDesc = _24[0], setDiscountDesc = _24[1];
    var _25 = useState(false), inputFieldChange = _25[0], setInputFieldChange = _25[1];
    // royalty fields and values
    var _26 = useState(false), isRoyaltyModalOpen = _26[0], setIsRoyaltyModalOpen = _26[1];
    var _27 = useState(), newRoyaltyValue = _27[0], setNewRoyaltyValue = _27[1];
    var _28 = useState((_6 = (_5 = (_4 = data.details) === null || _4 === void 0 ? void 0 : _4.header) === null || _5 === void 0 ? void 0 : _5.royalties) === null || _6 === void 0 ? void 0 : _6.sort(function (a, b) {
        var _a;
        return (_a = a.royaltyId.localeCompare(b.royaltyId, undefined, {
            numeric: true,
            sensitivity: 'base',
        })) !== null && _a !== void 0 ? _a : [];
    })), productHeaderRoyaltyArray = _28[0], setProductHeaderRoyaltyArray = _28[1];
    var _29 = useState(false), isNewRoyaltyError = _29[0], setIsNewRoyaltyError = _29[1];
    var _30 = useState(false), duplicateRoyaltyIdError = _30[0], setDuplicateRoyaltyIdError = _30[1];
    var _31 = useState(''), idThatIsBeingDeleted = _31[0], setIdThatIsBeingDeleted = _31[1];
    useEffect(function () {
        if (isProductRoyaltySectionOpen && productHeaderRoyaltyArray.length === 0) {
            setIsRoyaltyModalOpen(true);
        }
    }, [isProductRoyaltySectionOpen, isRoyaltyModalOpen, productHeaderRoyaltyArray]);
    var handleBlur = function (param1, param2) {
        if (!userHasEditPrivileges || !inputFieldChange)
            return;
        var newValue = '';
        switch (param2) {
            case 'code':
                if (param1 === 'line') {
                    newValue = productLineCode;
                }
                else {
                    newValue = productFunctionCode;
                }
                break;
            case 'description':
                if (param1 === 'line') {
                    newValue = productLineDesc.trim();
                }
                else {
                    newValue = productFunctionDesc.trim();
                }
                break;
            case 'modelCode':
                if (param1 === 'line') {
                    if (productLineModelCode &&
                        (!alphanumericHyphenUnderscore(productLineModelCode) || productLineModelCodeError)) {
                        return;
                    }
                    newValue = productLineModelCode;
                }
                else {
                    if (!alphanumericHyphenUnderscore(productFunctionModelCode) || productFunctionModelCodeError) {
                        return;
                    }
                    newValue = productFunctionModelCode;
                }
                break;
            default:
                if (param1 === 'discountCode') {
                    if (!noSpaces(discountCode)) {
                        return;
                    }
                    newValue = discountCode;
                }
                else if (param1 === 'discountDescription') {
                    newValue = discountDesc.trim();
                }
                break;
        }
        handleBlurInParent(param1, param2, newValue);
    };
    var handleInputFieldChange = function (fnc, event, inputValidationFnc, section) {
        if (isEditable && userHasEditPrivileges) {
            setInputFieldChange(true);
            fnc(event.target.value);
        }
        var codeError = false;
        if (inputValidationFnc && section) {
            switch (section) {
                case 'lineCode':
                    setProductLineCodeError(!inputValidationFnc(event.target.value));
                    break;
                case 'lineModelCode':
                    codeError = !inputValidationFnc(event.target.value);
                    setProductLineModelCodeError(codeError);
                    if (codeError) {
                        modelCodeError(productLineModelCodeError);
                    }
                    break;
                case 'functionCode':
                    setProductFunctionCodeError(!inputValidationFnc(event.target.value));
                    break;
                case 'functionModelCode':
                    codeError = !inputValidationFnc(event.target.value);
                    setProductFunctionModelCodeError(codeError);
                    if (codeError) {
                        modelCodeError(productFunctionModelCodeError);
                    }
                    break;
                case 'discount':
                    setDiscountCodeError(!inputValidationFnc(event.target.value));
                    break;
                default:
                    break;
            }
        }
    };
    var getResponseForSaveRoyalties = function (dataForSave) { return __awaiter(_this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleEngRoyaltiesSave(dataForSave.newArrayOfRoyalties, productId, data.details)];
                case 1:
                    response = _a.sent();
                    if (dataForSave.newRoyalty) {
                        if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                            setProductHeaderRoyaltyArray(__spreadArray(__spreadArray([], productHeaderRoyaltyArray, true), [dataForSave.newRoyalty], false));
                            setSnackbarAlert({
                                message: "Successfully added new royalty with id: ".concat(dataForSave.newRoyalty.royaltyId),
                                type: 'success',
                            });
                            setNewRoyaltyValue({ royaltyId: '', royaltyDesc: '' });
                        }
                        else {
                            setSnackbarAlert({
                                message: (response === null || response === void 0 ? void 0 : response.body).message || '',
                                type: 'error',
                            });
                        }
                        setRoyaltyConfirmProcessing(false);
                    }
                    if (dataForSave.royaltyToDelete) {
                        if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                            setProductHeaderRoyaltyArray(dataForSave.newArrayOfRoyalties);
                            setIdThatIsBeingDeleted('');
                            if (dataForSave.newArrayOfRoyalties.length === 0 &&
                                !(newRoyaltyValue === null || newRoyaltyValue === void 0 ? void 0 : newRoyaltyValue.royaltyId) &&
                                !(newRoyaltyValue === null || newRoyaltyValue === void 0 ? void 0 : newRoyaltyValue.royaltyDesc)) {
                                displayAddRoyaltiesButton();
                            }
                        }
                    }
                    setIdThatIsBeingDeleted('');
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSaveNewRoyalty = function (newRoyalty) {
        setRoyaltyConfirmProcessing(true);
        var positionOfExistingRoyalty = __spreadArray([], productHeaderRoyaltyArray, true).findIndex(function (royalty) { return royalty.royaltyId === newRoyalty.royaltyId; });
        if (positionOfExistingRoyalty !== -1) {
            setRoyaltyConfirmProcessing(false);
            setSnackbarAlert({
                message: "Royalty with id '".concat(newRoyalty.royaltyId, "' already exists.") || '',
                type: 'error',
            });
            setDisableConfirmButton(true);
            setDuplicateRoyaltyIdError(true);
            return;
        }
        var newArrayOfRoyalties = __spreadArray([], productHeaderRoyaltyArray, true);
        newArrayOfRoyalties.push(newRoyalty);
        getResponseForSaveRoyalties({ newArrayOfRoyalties: newArrayOfRoyalties, newRoyalty: newRoyalty });
    };
    var getBlurHandlerResponse = function (array, newRoyalty) { return __awaiter(_this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleEngRoyaltiesSave(array, productId, data.details)];
                case 1:
                    response = _a.sent();
                    if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                        setSnackbarAlert({
                            message: "Successfully saved edits to royalty with id '".concat(newRoyalty.royaltyId, "'.") || '',
                            type: 'success',
                        });
                        setProductHeaderRoyaltyArray(array);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleEditRoyaltyFromChild = function (newRoyalty, originalRoyalty) { return __awaiter(_this, void 0, void 0, function () {
        var originalIndex, updateRoyalty, foundIndex;
        return __generator(this, function (_a) {
            originalIndex = productHeaderRoyaltyArray.findIndex(function (r) { return r.royaltyId === originalRoyalty.royaltyId; });
            if (newRoyalty.royaltyId !== originalRoyalty.royaltyId) {
                foundIndex = productHeaderRoyaltyArray.findIndex(function (r) { return r.royaltyId === newRoyalty.royaltyId; });
                if (foundIndex !== -1) {
                    if (newRoyalty.royaltyDesc === originalRoyalty.royaltyDesc) {
                        setSnackbarAlert({
                            message: "Royalty with royalty code '".concat(newRoyalty.royaltyId, "' already exists."),
                            type: 'error',
                        });
                        return [2 /*return*/];
                    }
                    // update description of original Option Royalty when Royalty with Code already exists
                    productHeaderRoyaltyArray[originalIndex] = {
                        royaltyId: originalRoyalty.royaltyId,
                        royaltyDesc: newRoyalty.royaltyDesc,
                    };
                    updateRoyalty = {
                        royaltyId: originalRoyalty.royaltyId,
                        royaltyDesc: newRoyalty.royaltyDesc,
                    };
                }
                else {
                    // update original Option Royalty with new Code
                    productHeaderRoyaltyArray[originalIndex] = newRoyalty;
                    updateRoyalty = newRoyalty;
                }
            }
            else {
                // update description of original Option Royalty when Code wasn't changed
                productHeaderRoyaltyArray[originalIndex] = newRoyalty;
                updateRoyalty = newRoyalty;
            }
            getBlurHandlerResponse(productHeaderRoyaltyArray, updateRoyalty);
            return [2 /*return*/];
        });
    }); };
    var deleteRoyalty = function (id) {
        setIdThatIsBeingDeleted(id);
        confirm({
            title: "Delete royalty with ID '".concat(id, "'?"),
            cancellationText: 'No',
            confirmationText: 'Yes',
        })
            .then(function () { return __awaiter(_this, void 0, void 0, function () {
            var arrayCopy, royaltyToDelete, indexOfItemToRemove;
            return __generator(this, function (_a) {
                arrayCopy = [];
                if (productHeaderRoyaltyArray.length > 1) {
                    indexOfItemToRemove = productHeaderRoyaltyArray.findIndex(function (royalty) { return royalty.royaltyId === id; });
                    royaltyToDelete = productHeaderRoyaltyArray[indexOfItemToRemove];
                    arrayCopy = __spreadArray([], productHeaderRoyaltyArray, true);
                    arrayCopy.splice(indexOfItemToRemove, 1);
                }
                else {
                    royaltyToDelete = __assign({}, productHeaderRoyaltyArray[0]);
                }
                getResponseForSaveRoyalties({ newArrayOfRoyalties: arrayCopy, royaltyToDelete: royaltyToDelete });
                return [2 /*return*/];
            });
        }); })
            .catch(function () {
            setIdThatIsBeingDeleted('');
        });
    };
    var validateHeaderRoyaltyInput = function (input) {
        var _a;
        setNewRoyaltyValue({
            royaltyId: input,
            royaltyDesc: (_a = newRoyaltyValue === null || newRoyaltyValue === void 0 ? void 0 : newRoyaltyValue.royaltyDesc) !== null && _a !== void 0 ? _a : '',
        });
        if (input.trim() === '' || !alphanumericHyphen(input)) {
            setIsNewRoyaltyError(true);
            setDisableConfirmButton(true);
            return;
        }
        setIsNewRoyaltyError(false);
        if (productHeaderRoyaltyArray.find(function (r) { return r.royaltyId === input; })) {
            setDuplicateRoyaltyIdError(true);
            setDisableConfirmButton(true);
            return;
        }
        setDuplicateRoyaltyIdError(false);
        setDisableConfirmButton(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ className: styles.productMaintenanceContainer }, { children: [_jsxs(Box, __assign({ sx: {
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '0 !important',
                            width: productHeaderRoyaltyArray.length > 0 || isProductRoyaltySectionOpen ? '50%' : '31%',
                        }, className: styles.productMaintenanceInputSection }, { children: [_jsx(Typography, __assign({ className: styles.header }, { children: "Product Line" })), _jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsx(Typography, __assign({ className: styles.label }, { children: "Code:" })), _jsx(TextField, { name: "productLineCode", value: productLineCode, size: "small", type: "text", disabled: !userHasEditPrivileges || !isEditable, onChange: function (e) {
                                            handleInputFieldChange(setProductLineCode, e, noSpaces, 'lineCode');
                                        }, onBlur: function () {
                                            var _a, _b;
                                            return !productLineCodeError &&
                                                productLineCode.trim() !== ((_b = (_a = productLine === null || productLine === void 0 ? void 0 : productLine.code) === null || _a === void 0 ? void 0 : _a.trim()) !== null && _b !== void 0 ? _b : '') &&
                                                handleBlur('line', 'code');
                                        }, InputProps: {
                                            endAdornment: (_jsx(ErrorAdornment, { errors: [
                                                    {
                                                        condition: productLineCodeError,
                                                        message: 'Invalid Product Line Code. No spaces allowed',
                                                    },
                                                ] })),
                                            sx: {
                                                input: { padding: '5px', width: '312px' },
                                            },
                                        }, error: productLineCodeError })] })), _jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsx(Typography, __assign({ className: styles.label }, { children: "Description:" })), _jsx(TextField, { name: "productLineDescription", value: productLineDesc, size: "small", type: "text", disabled: !userHasEditPrivileges || !isEditable, onChange: function (e) { return handleInputFieldChange(setProductLineDesc, e); }, onBlur: function () {
                                            var _a, _b, _c;
                                            return productLineDesc.trim() !==
                                                ((_c = (_b = (_a = productLine === null || productLine === void 0 ? void 0 : productLine.description) === null || _a === void 0 ? void 0 : _a["".concat(languageCode)]) === null || _b === void 0 ? void 0 : _b.desc.trim()) !== null && _c !== void 0 ? _c : '') &&
                                                handleBlur('line', 'description');
                                        }, inputProps: { style: { padding: '5px', width: '350px' } } })] })), _jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsx(Typography, __assign({ className: styles.label }, { children: "Model Code:" })), _jsx(TextField, { name: "productLineModelCode", value: productLineModelCode !== null && productLineModelCode !== void 0 ? productLineModelCode : '', size: "small", type: "text", disabled: !userHasEditPrivileges || !isEditable, onChange: function (e) {
                                            return handleInputFieldChange(setProductLineModelCode, e, alphanumericHyphenUnderscore, 'lineModelCode');
                                        }, onBlur: function () {
                                            var _a, _b;
                                            return !productLineModelCodeError &&
                                                productLineModelCode.trim() !== ((_b = (_a = productLine === null || productLine === void 0 ? void 0 : productLine.modelCode) === null || _a === void 0 ? void 0 : _a.trim()) !== null && _b !== void 0 ? _b : '') &&
                                                handleBlur('line', 'modelCode');
                                        }, InputProps: {
                                            endAdornment: (_jsx(ErrorAdornment, { errors: [
                                                    {
                                                        condition: productLineModelCodeError,
                                                        message: 'Invalid Product Line Model Code. Allowed: A-Z, 0-9, Hyphen, Underscore',
                                                    },
                                                ] })),
                                            sx: {
                                                input: { padding: '5px', width: '312px' },
                                            },
                                        }, error: productLineModelCodeError })] }))] })), productHeaderRoyaltyArray.length === 0 && _jsx("hr", { className: styles.verticalLine }), _jsxs(Box, __assign({ sx: {
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '0 !important',
                            width: productHeaderRoyaltyArray.length > 0 || isProductRoyaltySectionOpen ? '50%' : '31%',
                        }, className: styles.productMaintenanceInputSection }, { children: [_jsx(Typography, __assign({ className: styles.header }, { children: "Product Function" })), _jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsx(Typography, __assign({ className: styles.label }, { children: "Code:" })), _jsx(TextField, { name: "productFunctionCode", value: productFunctionCode, size: "small", type: "text", disabled: !userHasEditPrivileges || !isEditable, onChange: function (e) {
                                            return handleInputFieldChange(setProductFunctionCode, e, noSpaces, 'functionCode');
                                        }, onBlur: function () {
                                            return !productFunctionCodeError &&
                                                productFunctionCode.trim() !== (productFunction === null || productFunction === void 0 ? void 0 : productFunction.code.trim()) &&
                                                handleBlur('function', 'code');
                                        }, InputProps: {
                                            endAdornment: (_jsx(ErrorAdornment, { errors: [
                                                    {
                                                        condition: productFunctionCodeError,
                                                        message: 'Invalid Product Function Code. No spaces allowed',
                                                    },
                                                ] })),
                                            sx: {
                                                input: { padding: '5px', width: '312px' },
                                            },
                                        }, error: productFunctionCodeError })] })), _jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsx(Typography, __assign({ className: styles.label }, { children: "Description:" })), _jsx(TextField, { name: "productFunctionDesc", value: productFunctionDesc, size: "small", type: "text", disabled: !userHasEditPrivileges || !isEditable, onChange: function (e) { return handleInputFieldChange(setProductFunctionDesc, e); }, onBlur: function () {
                                            var _a, _b, _c, _d;
                                            return productFunctionDesc.trim() !==
                                                ((_d = (_c = (_b = (_a = productFunction === null || productFunction === void 0 ? void 0 : productFunction.description) === null || _a === void 0 ? void 0 : _a["".concat(languageCode)]) === null || _b === void 0 ? void 0 : _b.desc) === null || _c === void 0 ? void 0 : _c.trim()) !== null && _d !== void 0 ? _d : '') &&
                                                handleBlur('function', 'description');
                                        }, inputProps: { style: { padding: '5px', width: '350px' } } })] })), _jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsx(Typography, __assign({ className: styles.label }, { children: "Model Code:" })), _jsx(TextField, { name: "productFunctionModelCode", value: productFunctionModelCode !== null && productFunctionModelCode !== void 0 ? productFunctionModelCode : null, size: "small", type: "text", disabled: !userHasEditPrivileges || !isEditable, onChange: function (e) {
                                            return handleInputFieldChange(setProductFunctionModelCode, e, alphanumericHyphenUnderscore, 'functionModelCode');
                                        }, onBlur: function () {
                                            var _a, _b;
                                            return !productFunctionModelCodeError &&
                                                productFunctionModelCode.trim() !== ((_b = (_a = productFunction === null || productFunction === void 0 ? void 0 : productFunction.modelCode) === null || _a === void 0 ? void 0 : _a.trim()) !== null && _b !== void 0 ? _b : '') &&
                                                handleBlur('function', 'modelCode');
                                        }, InputProps: {
                                            endAdornment: (_jsx(ErrorAdornment, { errors: [
                                                    {
                                                        condition: productFunctionModelCodeError,
                                                        message: 'Invalid Product Function Model Code. Allowed: A-Z, 0-9, Hyphen, Underscore',
                                                    },
                                                ] })),
                                            sx: {
                                                input: { padding: '5px', width: '312px' },
                                            },
                                        }, error: productFunctionModelCodeError })] }))] })), productHeaderRoyaltyArray.length === 0 && !isProductRoyaltySectionOpen && (_jsxs(_Fragment, { children: [_jsx("hr", { className: styles.verticalLine }), _jsxs(Box, __assign({ sx: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '0 !important',
                                    width: '31%',
                                }, className: styles.productMaintenanceInputSection }, { children: [_jsx(Typography, __assign({ className: styles.header }, { children: "Discount" })), _jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsx(Typography, __assign({ className: styles.label }, { children: "Code:" })), _jsx(TextField, { name: "discountCode", value: discountCode, size: "small", type: "text", disabled: !userHasEditPrivileges || !isEditable, onChange: function (e) { return handleInputFieldChange(setDiscountCode, e, noSpaces, 'discount'); }, onBlur: function () {
                                                    var _a, _b;
                                                    return discountCode !== ((_b = (_a = data.details) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.discountCode) &&
                                                        handleBlur('discountCode');
                                                }, InputProps: {
                                                    endAdornment: (_jsx(ErrorAdornment, { errors: [
                                                            {
                                                                condition: discountCodeError,
                                                                message: 'Invalid Discount Code. No spaces allowed',
                                                            },
                                                        ] })),
                                                    sx: {
                                                        input: { padding: '5px', width: '312px' },
                                                    },
                                                }, error: discountCodeError })] })), _jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsx(Typography, __assign({ className: styles.label }, { children: "Description:" })), _jsx(TextField, { name: "discountDesc", value: discountDesc, size: "small", type: "text", disabled: !userHasEditPrivileges || !isEditable, onChange: function (e) { return handleInputFieldChange(setDiscountDesc, e); }, onBlur: function () {
                                                    var _a, _b;
                                                    return discountDesc.trim() !== ((_b = (_a = data.details) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.discountDesc) &&
                                                        handleBlur('discountDescription');
                                                }, inputProps: { style: { padding: '5px', width: '350px' } } })] }))] }))] }))] })), (productHeaderRoyaltyArray.length > 0 ||
                isProductRoyaltySectionOpen ||
                (productHeaderRoyaltyArray.length > 0 &&
                    ((newRoyaltyValue === null || newRoyaltyValue === void 0 ? void 0 : newRoyaltyValue.royaltyId) || (newRoyaltyValue === null || newRoyaltyValue === void 0 ? void 0 : newRoyaltyValue.royaltyDesc)))) && (_jsxs(Box, __assign({ className: styles.productMaintenanceContainer }, { children: [_jsxs(Box, __assign({ sx: {
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '0 !important',
                            width: '50%',
                        }, className: styles.productMaintenanceInputSection }, { children: [_jsx(Typography, __assign({ className: styles.header }, { children: "Discount" })), _jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsx(Typography, __assign({ className: styles.label }, { children: "Code:" })), _jsx(TextField, { name: "discountCode", value: discountCode, size: "small", type: "text", disabled: !userHasEditPrivileges || !isEditable, onChange: function (e) { return handleInputFieldChange(setDiscountCode, e, noSpaces, 'discount'); }, onBlur: function () { var _a, _b; return discountCode !== ((_b = (_a = data.details) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.discountCode) && handleBlur('discountCode'); }, InputProps: {
                                            endAdornment: (_jsx(ErrorAdornment, { errors: [
                                                    {
                                                        condition: discountCodeError,
                                                        message: 'Invalid Discount Code. No spaces allowed',
                                                    },
                                                ] })),
                                            sx: {
                                                input: { padding: '5px', width: '312px' },
                                            },
                                        }, error: discountCodeError })] })), _jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsx(Typography, __assign({ className: styles.label }, { children: "Description:" })), _jsx(TextField, { name: "discountDesc", value: discountDesc, size: "small", type: "text", variant: "outlined", disabled: !userHasEditPrivileges || !isEditable, onChange: function (e) { return handleInputFieldChange(setDiscountDesc, e); }, onBlur: function () {
                                            var _a, _b, _c, _d;
                                            return discountDesc.trim() !== ((_d = (_c = (_b = (_a = data.details) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.discountDesc) === null || _c === void 0 ? void 0 : _c.trim()) !== null && _d !== void 0 ? _d : '') &&
                                                handleBlur('discountDescription', 'header');
                                        }, inputProps: { style: { padding: '5px', width: '350px' } } })] }))] })), _jsxs(Box, __assign({ className: styles.royaltyInputSection }, { children: [_jsxs(Box, __assign({ className: styles.headerContainer }, { children: [_jsx(Typography, __assign({ className: styles.header }, { children: "Royalties" })), _jsxs(Box, __assign({ className: styles.buttonContainer }, { children: [isRoyaltyModalOpen && (_jsx(StyledButton, { label: "Confirm", heightFromProps: 30, handleClick: function (e) {
                                                    var _a, _b;
                                                    e.preventDefault();
                                                    handleSaveNewRoyalty({
                                                        royaltyId: (_a = newRoyaltyValue === null || newRoyaltyValue === void 0 ? void 0 : newRoyaltyValue.royaltyId) !== null && _a !== void 0 ? _a : '',
                                                        royaltyDesc: (_b = newRoyaltyValue === null || newRoyaltyValue === void 0 ? void 0 : newRoyaltyValue.royaltyDesc) !== null && _b !== void 0 ? _b : '',
                                                    });
                                                }, loading: royaltyConfirmProcessing, loadingSpinnerSize: 16, disabledFromProps: !(newRoyaltyValue === null || newRoyaltyValue === void 0 ? void 0 : newRoyaltyValue.royaltyId) ||
                                                    (newRoyaltyValue === null || newRoyaltyValue === void 0 ? void 0 : newRoyaltyValue.royaltyId) === '' ||
                                                    disableConfirmButton })), _jsx("span", __assign({ style: { marginLeft: '10px' } }, { children: userHasEditPrivileges && isEditable && (_jsx(StyledButton, { label: isRoyaltyModalOpen ? 'Close' : 'Add', heightFromProps: 30, handleClick: function (e) {
                                                        e.preventDefault();
                                                        setIsRoyaltyModalOpen(function (prevVal) {
                                                            if (prevVal === true) {
                                                                setNewRoyaltyValue({ royaltyId: '', royaltyDesc: '' });
                                                            }
                                                            if (productHeaderRoyaltyArray.length === 0) {
                                                                forceCloseHeaderRoyaltySection();
                                                            }
                                                            return !prevVal;
                                                        });
                                                    } })) }))] }))] })), _jsxs(Box, __assign({ className: styles.royaltiesList }, { children: [isRoyaltyModalOpen && (_jsxs(Box, __assign({ className: styles.royaltyInputRow }, { children: [_jsx(TextField, { name: "newRoyaltyId", size: "small", type: "text", value: (_7 = newRoyaltyValue === null || newRoyaltyValue === void 0 ? void 0 : newRoyaltyValue.royaltyId) !== null && _7 !== void 0 ? _7 : '', label: "Code", InputProps: {
                                                    endAdornment: (_jsx(ErrorAdornment, { errors: [
                                                            {
                                                                condition: isNewRoyaltyError,
                                                                message: 'Royalty Code cannot be empty and must contain only alpha-numeric characters and hyphens with no spaces',
                                                            },
                                                            {
                                                                condition: duplicateRoyaltyIdError,
                                                                message: "Royalty with Royalty Code '".concat((_8 = newRoyaltyValue === null || newRoyaltyValue === void 0 ? void 0 : newRoyaltyValue.royaltyId) !== null && _8 !== void 0 ? _8 : '', "' already exists"),
                                                            },
                                                        ] })),
                                                    sx: {
                                                        input: {
                                                            width: '100px',
                                                            padding: '5px',
                                                        },
                                                    },
                                                }, disabled: !userHasEditPrivileges || !isEditable, onChange: function (e) { return validateHeaderRoyaltyInput(e.target.value); }, error: isNewRoyaltyError || duplicateRoyaltyIdError }), _jsx(TextField, { sx: {
                                                    marginRight: '34px',
                                                }, fullWidth: true, name: "newRoyaltyDesc", size: "small", type: "text", value: (_9 = newRoyaltyValue === null || newRoyaltyValue === void 0 ? void 0 : newRoyaltyValue.royaltyDesc) !== null && _9 !== void 0 ? _9 : '', label: "Description", inputProps: {
                                                    style: {
                                                        height: '33px !important',
                                                        backgroundColor: 'rgb(229, 255, 234)',
                                                        padding: '5px',
                                                    },
                                                }, disabled: !userHasEditPrivileges || !isEditable, onChange: function (e) {
                                                    var _a;
                                                    return setNewRoyaltyValue({
                                                        royaltyId: (_a = newRoyaltyValue === null || newRoyaltyValue === void 0 ? void 0 : newRoyaltyValue.royaltyId) !== null && _a !== void 0 ? _a : '',
                                                        royaltyDesc: e.target.value.trim(),
                                                    });
                                                } })] }))), productHeaderRoyaltyArray.map(function (royalty) { return (_jsx(ExistingRoyaltyRow, { isEditable: isEditable, royalty: royalty, royaltyArray: productHeaderRoyaltyArray, sendRoyaltyToParent: handleEditRoyaltyFromChild, toggleAllowPublish: handleTogglePublish, sendRoyaltyIdToParentToDelete: deleteRoyalty, idThatIsBeingDeleted: idThatIsBeingDeleted }, royalty.royaltyId)); })] }))] }))] })))] }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import uploadImageIconThin from 'assets/uploadImageIconThin.svg';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { fileUploadHelper } from 'utils/file-upload-helper';
import { FileSelectInput } from '../FileSelectInput/FileSelectInput';
import styles from './FileUploadDropZone.module.scss';
export function FileUploadDropZone(_a) {
    var containerSizeProps = _a.containerSizeProps, fileSelectProps = _a.fileSelectProps, handleFileSelect = _a.handleFileSelect;
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var _b = useState('transparent'), bgColor = _b[0], setBgColor = _b[1];
    var selectFile = function (dataTransferFiles) {
        var fileSelect = fileUploadHelper.fileSelectHandler(dataTransferFiles, fileSelectProps.allowedFileTypes, fileSelectProps.maxFileSizeMb);
        if (fileSelect.message) {
            setSnackbarAlert({ message: fileSelect.message, type: 'error' });
        }
        if (fileSelect.file) {
            handleFileSelect(fileSelect.file);
        }
    };
    return (_jsxs(Box, __assign({ className: styles.dropZoneContainer, width: containerSizeProps.width, height: containerSizeProps.height, onDrop: function (event) {
            event.preventDefault();
            setBgColor('transparent');
            selectFile(event.dataTransfer.files);
        }, onDragOver: function (event) {
            event.preventDefault();
            setBgColor('rgba(0, 0, 0, 0.1)');
        }, onDragLeave: function () { return setBgColor('transparent'); }, sx: { backgroundColor: bgColor } }, { children: [_jsx("img", { src: uploadImageIconThin, alt: "Upload Drawing Icon" }), _jsxs(Box, __assign({ display: "flex", sx: { alignItems: 'center' } }, { children: [_jsx(Typography, __assign({ sx: { fontSize: '20px' } }, { children: "Drop you drawing file here, or\u00A0" })), _jsxs(Button, __assign({ component: "label", role: undefined, disableRipple: true, tabIndex: -1, sx: {
                            fontFamily: "UntitledSansMedium, sans-serif, system-ui",
                            textDecoration: 'underline',
                            padding: 0,
                            fontSize: '20px',
                            '&:hover': {
                                fontFamily: "UntitledSansMedium, sans-serif, system-ui",
                                textDecoration: 'underline',
                                backgroundColor: 'transparent',
                            },
                        } }, { children: ["browse", _jsx(FileSelectInput, { allowedFileTypes: fileSelectProps.allowedFileTypes, fileSelectHandler: selectFile })] }))] }))] })));
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useState, useEffect } from 'react';
import { getPreSignedFileUrls } from 'services/api/search-catalogs';
import { getProductsByProductVersions } from 'services/api/search-product-service';
import { catalogHelper } from 'utils/catalog-helper';
export function useGetPrevAdHocDefinition(requestId) {
    var _a = useState(), definition = _a[0], setDefinition = _a[1];
    var _b = useState([]), products = _b[0], setProducts = _b[1];
    var _c = useState([]), hierarchies = _c[0], setHierarchies = _c[1];
    var _d = useState(false), definitionLoading = _d[0], setDefinitionLoading = _d[1];
    var _e = useState(false), productsLoading = _e[0], setProductsLoading = _e[1];
    useEffect(function () {
        if (!requestId)
            return;
        (function getPrevAdHocDefinition() {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function () {
                var rsp, signedUrl, productsUrl, definitionJson;
                var _this = this;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            setDefinitionLoading(true);
                            setProductsLoading(true);
                            return [4 /*yield*/, getPreSignedFileUrls(["".concat(requestId, "/definition.json"), "".concat(requestId, "/products.json")])];
                        case 1:
                            rsp = _c.sent();
                            signedUrl = (_a = rsp.body.urls) === null || _a === void 0 ? void 0 : _a.find(function (u) { return u.includes('definition.json'); });
                            productsUrl = (_b = rsp.body.urls) === null || _b === void 0 ? void 0 : _b.find(function (u) { return u.includes('products.json'); });
                            if (!signedUrl) return [3 /*break*/, 3];
                            return [4 /*yield*/, fetch(signedUrl).then(function (prevDefinition) { return __awaiter(_this, void 0, void 0, function () {
                                    var json;
                                    var _a, _b, _c;
                                    return __generator(this, function (_d) {
                                        switch (_d.label) {
                                            case 0:
                                                if (!prevDefinition.ok) return [3 /*break*/, 2];
                                                return [4 /*yield*/, prevDefinition.json()];
                                            case 1:
                                                json = _d.sent();
                                                if (!json)
                                                    return [2 /*return*/];
                                                definitionJson = json;
                                                setDefinition(json);
                                                setHierarchies((_c = (_b = (_a = json === null || json === void 0 ? void 0 : json.hierarchy) === null || _a === void 0 ? void 0 : _a.filter(function (h) { return !(h === null || h === void 0 ? void 0 : h.productId); })) === null || _b === void 0 ? void 0 : _b.map(function (h) { return catalogHelper.convertHierarchyToSearchHierarchyType(h); })) !== null && _c !== void 0 ? _c : []);
                                                _d.label = 2;
                                            case 2:
                                                setDefinitionLoading(false);
                                                return [2 /*return*/];
                                        }
                                    });
                                }); })];
                        case 2:
                            _c.sent();
                            _c.label = 3;
                        case 3:
                            if (!productsUrl) return [3 /*break*/, 5];
                            return [4 /*yield*/, fetch(productsUrl).then(function (productVersions) { return __awaiter(_this, void 0, void 0, function () {
                                    var json, selectedProductHierarchies_1, nonHierarchyProductVersions, prods;
                                    var _a, _b, _c;
                                    return __generator(this, function (_d) {
                                        switch (_d.label) {
                                            case 0:
                                                if (!productVersions.ok) return [3 /*break*/, 3];
                                                return [4 /*yield*/, productVersions.json()];
                                            case 1:
                                                json = _d.sent();
                                                if (!json)
                                                    return [2 /*return*/];
                                                selectedProductHierarchies_1 = (_b = (_a = definitionJson === null || definitionJson === void 0 ? void 0 : definitionJson.hierarchy) === null || _a === void 0 ? void 0 : _a.filter(function (h) { return h === null || h === void 0 ? void 0 : h.productId; })) !== null && _b !== void 0 ? _b : [];
                                                nonHierarchyProductVersions = (_c = json === null || json === void 0 ? void 0 : json.filter(function (version) {
                                                    return selectedProductHierarchies_1.find(function (h) { return version.includes("_".concat(h.productId, "_")); });
                                                })) !== null && _c !== void 0 ? _c : [];
                                                if (!(nonHierarchyProductVersions.length > 0)) return [3 /*break*/, 3];
                                                return [4 /*yield*/, getProductsByProductVersions(nonHierarchyProductVersions, nonHierarchyProductVersions.length)];
                                            case 2:
                                                prods = _d.sent();
                                                setProducts(prods.body);
                                                _d.label = 3;
                                            case 3:
                                                setProductsLoading(false);
                                                return [2 /*return*/];
                                        }
                                    });
                                }); })];
                        case 4:
                            _c.sent();
                            _c.label = 5;
                        case 5: return [2 /*return*/];
                    }
                });
            });
        })();
    }, [requestId]);
    return { definitionLoading: definitionLoading, productsLoading: productsLoading, definition: definition, products: products, hierarchies: hierarchies };
}

import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { useNavigate } from 'react-router-dom';
export function ErrorPage(_a) {
    var errorMessage = _a.errorMessage, fallbackPath = _a.fallbackPath;
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var navigate = useNavigate();
    setSnackbarAlert({ message: errorMessage, type: 'error' });
    navigate(fallbackPath);
    return _jsx(_Fragment, { children: " " });
}

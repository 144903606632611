var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { catalogHelper } from 'utils/catalog-helper';
export function CatalogRunAdditionalFields() {
    var _a = useState(catalogHelper.getTodayLocalDateString()), effDate = _a[0], setEffDate = _a[1];
    var _b = useState(''), descAppendText = _b[0], setDescAppendText = _b[1];
    var _c = useState(''), priceDateOverride = _c[0], setPriceDateOverride = _c[1];
    var _d = useState(false), includeNonPromoted = _d[0], setIncludeNonPromoted = _d[1];
    var _e = useState('03:00'), effTime = _e[0], setEffTime = _e[1];
    return (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', rowGap: '8px' } }, { children: [_jsx(Typography, __assign({ variant: "h2" }, { children: "Additional Processing Requirements" })), _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'row', columnGap: '24px' } }, { children: [_jsxs(Box, __assign({ sx: {
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        } }, { children: [_jsx(Typography, __assign({ sx: { width: '200px' } }, { children: "Effective Date:" })), _jsx(TextField, { id: "effectiveDate", size: "small", InputProps: {
                                    type: 'date',
                                    style: {
                                        height: '40px',
                                        width: '198px',
                                    },
                                }, value: effDate, onChange: function (event) { return setEffDate(event.target.value); } })] })), _jsx(Box, { children: _jsx(TextField, { id: "effectiveTime", value: effTime, onChange: function (event) { return setEffTime(event.target.value); }, InputProps: {
                                type: 'time',
                                style: {
                                    height: '40px',
                                    width: '125px',
                                },
                            } }) }), _jsxs(Box, __assign({ sx: {
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        } }, { children: [_jsx(Typography, __assign({ sx: { width: '200px' } }, { children: "Price Date Override:" })), _jsx(TextField, { size: "small", id: "priceDateOverride", InputProps: {
                                    type: 'date',
                                    style: {
                                        height: '40px',
                                        width: '198px',
                                    },
                                }, value: priceDateOverride, onChange: function (event) { return setPriceDateOverride(event.target.value); } })] }))] })), _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'row', columnGap: '24px' } }, { children: [_jsxs(Box, __assign({ sx: {
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        } }, { children: [_jsx(Typography, __assign({ sx: { width: '200px' } }, { children: "Description Append Text:" })), _jsx(TextField, { size: "small", id: "descAppendText", value: descAppendText, onChange: function (event) { return setDescAppendText(event.target.value); } })] })), _jsxs(Box, __assign({ sx: {
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        } }, { children: [_jsx(Typography, __assign({ sx: { width: '200px' } }, { children: "Include Non-Promoted:" })), _jsx(FormControl, { children: _jsx(FormGroup, __assign({ onChange: function () { return setIncludeNonPromoted(!includeNonPromoted); } }, { children: _jsx(FormControlLabel, { label: "", control: _jsx(Checkbox, { id: "includeNonPrmtbl", checked: includeNonPromoted }) }) })) })] }))] }))] })));
}

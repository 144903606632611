var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import { PubTabPanel } from './PubTabPanel/PubTabPanel';
export function PubTabs(_a) {
    var tabsDefinition = _a.tabsDefinition, activeIndex = _a.activeIndex, setActiveIndex = _a.setActiveIndex;
    var location = useLocation();
    var tabs = useMemo(function () {
        var tabDef = __assign({}, tabsDefinition);
        return tabDef.tabs;
    }, [tabsDefinition]);
    var handleTabChange = function (event, newValue) {
        event.preventDefault();
        setActiveIndex(newValue);
    };
    return (_jsxs(Box, { children: [_jsx(Tabs, __assign({ sx: { borderBottom: '1px solid #8C837B', fontFamily: "UntitledSansMedium, sans-serif, system-ui" }, value: activeIndex, onChange: handleTabChange, "aria-label": "cpa product tabs" }, { children: tabs.map(function (tabDef) { return (_jsx(Tab, { sx: {
                        fontSize: '20px',
                    }, style: {
                        pointerEvents: 'auto',
                    }, label: _jsx(Tooltip, __assign({ slotProps: {
                            tooltip: {
                                sx: {
                                    fontSize: '14px',
                                },
                            },
                        }, arrow: true, placement: "bottom", title: tabDef.tab.disabled ? tabDef.tab.disabledReason : undefined }, { children: _jsx("span", { children: tabDef.tab.labelText }) })), disabled: tabDef.tab.disabled }, tabDef.tab.labelText)); }) })), tabs.map(function (tabDef, i) { return (_jsx(PubTabPanel, __assign({ index: i, value: activeIndex }, { children: tabDef.tabPanel.component }), tabDef.tab.labelText)); })] }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import { StyledButton } from 'components/Shared/StyledComponents/StyledButton';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { useState } from 'react';
import { productLookup, productReload } from 'services/api/product-service';
import { numbersOnly } from 'services/helperFunctions/handleRegex';
import styles from './CSPOnDemand.module.scss';
export function CSPOnDemand() {
    var _this = this;
    var _a = useState(''), searchValue = _a[0], setSearchValue = _a[1];
    var _b = useState(), productLookupResponse = _b[0], setProductLookupResponse = _b[1];
    var _c = useState(false), isSearchingForProduct = _c[0], setIsSearchingForProduct = _c[1];
    var _d = useState(false), isReloadingProduct = _d[0], setIsReloadingProduct = _d[1];
    var _e = useState([]), productLookupMap = _e[0], setProductLookupMap = _e[1];
    var _f = useState(false), errorForLookupValue = _f[0], setErrorForLookupValue = _f[1];
    var _g = useState([]), arrayOfReloadedProducts = _g[0], setArrayOfReloadedProducts = _g[1];
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var _h = useState(true), isSearchButtonDisabled = _h[0], setIsSearchButtonDisabled = _h[1];
    var _j = useState(), idThatWasSearched = _j[0], setIdThatWasSearched = _j[1];
    var handleInputFieldChange = function (value) {
        if (!numbersOnly(value)) {
            setSnackbarAlert({
                message: 'Value cannot contain letters, special characters, or spaces. Please use numbers only.',
                type: 'error',
            });
            return;
        }
        setIsSearchButtonDisabled(false);
        setErrorForLookupValue(false);
        setSearchValue(value);
    };
    var createProductLookupMap = function (body) {
        var lookupMap = [
            { label: 'Prefix:', value: body === null || body === void 0 ? void 0 : body.prefix },
            { label: 'Name:', value: body === null || body === void 0 ? void 0 : body.name },
        ];
        setProductLookupResponse(body);
        setIdThatWasSearched(body.productId);
        setProductLookupMap(lookupMap);
    };
    var fireProductLookup = function () { return __awaiter(_this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSearchingForProduct(true);
                    return [4 /*yield*/, productLookup({ productId: +searchValue })];
                case 1:
                    response = _a.sent();
                    if (response.status !== 200) {
                        setSnackbarAlert({
                            message: (response === null || response === void 0 ? void 0 : response.body).message || '',
                            type: 'error',
                        });
                        setIsSearchingForProduct(false);
                        setErrorForLookupValue(true);
                        setProductLookupMap([]);
                        return [2 /*return*/];
                    }
                    setIsSearchingForProduct(false);
                    createProductLookupMap(response.body);
                    return [2 /*return*/];
            }
        });
    }); };
    var fireProductReload = function () { return __awaiter(_this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsReloadingProduct(true);
                    return [4 /*yield*/, productReload({ productId: +searchValue })];
                case 1:
                    response = _a.sent();
                    if (response.status !== 200) {
                        setSnackbarAlert({
                            message: (response === null || response === void 0 ? void 0 : response.body).message || '',
                            type: 'error',
                        });
                        setIsReloadingProduct(false);
                        return [2 /*return*/];
                    }
                    setSnackbarAlert({
                        message: "Product with id of ".concat(idThatWasSearched, " successfully sent for reload."),
                        type: 'success',
                    });
                    setArrayOfReloadedProducts(__spreadArray(__spreadArray([], arrayOfReloadedProducts, true), [productLookupResponse], false));
                    setIsReloadingProduct(false);
                    setProductLookupMap([]);
                    setSearchValue('');
                    setIsSearchButtonDisabled(true);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ className: styles.searchAndReloadColumn }, { children: [_jsx(Box, __assign({ className: styles.titleContainer }, { children: _jsx(Typography, __assign({ className: styles.title }, { children: "CSP On Demand" })) })), _jsxs(Box, __assign({ className: styles.productSearchRow }, { children: [_jsx(TextField, { size: "small", value: searchValue !== null && searchValue !== void 0 ? searchValue : '', sx: { width: '100%' }, label: "Search by CSP Product ID", onChange: function (e) { return handleInputFieldChange(e.target.value); }, error: errorForLookupValue, onKeyDown: function (e) {
                                    if (e.key === 'Enter' && searchValue && searchValue.length > 0) {
                                        fireProductLookup();
                                    }
                                } }), _jsx(Box, __assign({ className: styles.searchButtonContainer }, { children: _jsx(StyledButton, { label: "Search", handleClick: function () { return fireProductLookup(); }, heightFromProps: 40, disabledFromProps: isSearchButtonDisabled }) }))] })), (isSearchingForProduct || isReloadingProduct) && _jsx(LoadingSpinner, { indicatorSize: 10 }), productLookupMap.length > 0 && !isSearchingForProduct && (_jsxs(Box, __assign({ className: styles.productLookupResponseContainer }, { children: [productLookupMap === null || productLookupMap === void 0 ? void 0 : productLookupMap.map(function (rowItem) { return (_jsxs(Box, __assign({ className: styles.productLookupInfoRow }, { children: [_jsx(Typography, __assign({ className: styles.label }, { children: rowItem.label })), _jsx(Typography, __assign({ className: rowItem.label === 'Name:' ? styles.productNameValue : styles.value }, { children: rowItem.value }))] }), rowItem.label)); }), _jsx(Box, __assign({ className: styles.reloadButtonContainer }, { children: _jsx(StyledButton, { label: "Reload", heightFromProps: 40, handleClick: function () { return fireProductReload(); } }) }))] })))] })), arrayOfReloadedProducts.length > 0 && (_jsxs(Box, __assign({ className: styles.historyPanel }, { children: [_jsx(Typography, __assign({ className: styles.panelHeader }, { children: "Products sent for Reload" })), _jsx("ol", { children: arrayOfReloadedProducts.map(function (reloadedProduct) { return (_jsx("li", __assign({ className: styles.listItem }, { children: _jsxs(Box, __assign({ className: styles.itemContainer }, { children: [_jsx(Typography, __assign({ className: styles.itemId }, { children: reloadedProduct.productId })), _jsx(Typography, __assign({ className: styles.itemPrefix }, { children: reloadedProduct.prefix })), _jsx(Typography, __assign({ className: styles.itemName }, { children: reloadedProduct.name }))] }), reloadedProduct.productId) }), reloadedProduct.productId)); }) })] })))] }));
}

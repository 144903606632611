var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { RangeOptionTextField } from 'components/ProductValidation/RangeOptionTextField/RangeOptionTextField';
import { PubInputField } from 'components/Shared/PubInputField/PubInputField';
import { InputFieldType } from 'models/Input/InputModels';
import { getNonStandardOptions } from 'services/api/search-nso';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FabricOptionsInput } from 'components/ProductBuildNew/Features/Fabrics/FabricOptionsInput';
import { NonStdOptionsInput } from 'components/ProductBuildNew/Features/NonStandard/NonStdOptionsInput';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
export function NextFeatureRow(_a) {
    var _this = this;
    var nextFeature = _a.nextFeature, hideOptionalColumns = _a.hideOptionalColumns, nextFeatureRef = _a.nextFeatureRef, isGSA = _a.isGSA, userHasRoyaltyPrivileges = _a.userHasRoyaltyPrivileges, latestValidateResponse = _a.latestValidateResponse, effectiveDate = _a.effectiveDate, handleOptionSelectInParent = _a.handleOptionSelectInParent, sendNsoMetadataToParent = _a.sendNsoMetadataToParent, showOptionalColumns = _a.showOptionalColumns;
    var _b = useState(), isLoading = _b[0], setIsLoading = _b[1];
    var requiresRangeInput = useMemo(function () { var _a, _b; return ((_b = (_a = nextFeature === null || nextFeature === void 0 ? void 0 : nextFeature.options) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.range) != null; }, [nextFeature]);
    var requiresFabricInput = useMemo(function () { return (nextFeature === null || nextFeature === void 0 ? void 0 : nextFeature.featureDescription) === 'Fabric'; }, [nextFeature]);
    var requiresNonStdInput = useMemo(function () { return nextFeature === null || nextFeature === void 0 ? void 0 : nextFeature.nonStandardIndicator; }, [nextFeature]);
    var isNso = useMemo(function () { return nextFeature.nonStandardIndicator; }, [nextFeature]);
    var _c = useState(), nsOptions = _c[0], setNSOOptions = _c[1];
    var _d = useState(), nsoMetadata = _d[0], setNsoMetadata = _d[1];
    var getNsoOptions = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var request, response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!isNso) return [3 /*break*/, 2];
                    setIsLoading(true);
                    request = {
                        productVersion: latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.productVersion,
                        productNbr: latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.productNbrOut,
                        groupCode: (_a = latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.nextFeature) === null || _a === void 0 ? void 0 : _a.nonStandardGroupCode,
                        featureId: nextFeature.featureId,
                        effectiveDate: effectiveDate,
                        pageSize: 500,
                        businessUnitCode: latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.businessUnitCode,
                    };
                    return [4 /*yield*/, getNonStandardOptions(request)];
                case 1:
                    response = _b.sent();
                    setNsoMetadata({
                        productVersion: request.productVersion,
                        productNbr: request.productNbr,
                        groupCode: request.groupCode,
                        featureId: nextFeature.featureId,
                        effectiveDate: effectiveDate,
                        startAt: response.body.startAt,
                        businessUnitCode: request.businessUnitCode,
                    });
                    nextFeature.options = __spreadArray([], (response.body.data || []), true);
                    setNSOOptions(response.body.data || []);
                    setIsLoading(false);
                    _b.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [isNso, latestValidateResponse, nextFeature, effectiveDate]);
    useEffect(function () {
        if (isNso) {
            getNsoOptions();
        }
    }, [isNso, getNsoOptions]);
    useEffect(function () {
        sendNsoMetadataToParent === null || sendNsoMetadataToParent === void 0 ? void 0 : sendNsoMetadataToParent(nsoMetadata);
    }, [sendNsoMetadataToParent, nsoMetadata]);
    var filteredOptions = useMemo(function () {
        var _a;
        if (isGSA) {
            return (_a = nextFeature === null || nextFeature === void 0 ? void 0 : nextFeature.options) === null || _a === void 0 ? void 0 : _a.filter(function (option) { return option === null || option === void 0 ? void 0 : option.gsaInd; });
        }
        return nextFeature === null || nextFeature === void 0 ? void 0 : nextFeature.options;
    }, [isGSA, nextFeature]);
    var optionsArray = useMemo(function () {
        var opts = filteredOptions === null || filteredOptions === void 0 ? void 0 : filteredOptions.map(function (option) {
            return option.optionRestrictionPresent
                ? "".concat(option.optionCode, "  - (RESTRICTED) ").concat(option.optionDescription)
                : "".concat(option.optionCode, " - ").concat(option.optionDescription);
        });
        return opts;
    }, [filteredOptions]);
    var handleRangeOptionSubmit = function (e, rangeVal) {
        var _a, _b, _c, _d;
        var newProductNumber = (_c = (_b = (_a = nextFeature === null || nextFeature === void 0 ? void 0 : nextFeature.options) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.productNbr) === null || _c === void 0 ? void 0 : _c.replace('{}', rangeVal);
        var newOption = (_d = nextFeature === null || nextFeature === void 0 ? void 0 : nextFeature.options) === null || _d === void 0 ? void 0 : _d[0];
        newOption.productNbr = newProductNumber;
        handleOptionSelectInParent({ target: { value: newOption } }, nextFeature === null || nextFeature === void 0 ? void 0 : nextFeature.featureId, true, rangeVal);
    };
    var handleOptionSelect = function (e) {
        var descWithoutRest = e;
        if (e.includes('RESTRICTED')) {
            descWithoutRest = e.replace('(RESTRICTED)', '').trim();
        }
        var finalString = descWithoutRest.split(' - ')[0].trim();
        var i = (isNso ? nsOptions : nextFeature.options).findIndex(function (o) { return o.optionCode === finalString; });
        var option = (isNso ? nsOptions : nextFeature.options)[i];
        setNSOOptions(undefined);
        handleOptionSelectInParent({ target: { value: option } }, nextFeature.featureId);
    };
    var determineInput = function () {
        var _a;
        if (requiresRangeInput) {
            return (_jsx(RangeOptionTextField, { option: (_a = nextFeature === null || nextFeature === void 0 ? void 0 : nextFeature.options) === null || _a === void 0 ? void 0 : _a[0], handleRangeOptionSubmit: handleRangeOptionSubmit, v2: true }));
        }
        if (requiresFabricInput || nextFeature.fabricIndicator) {
            return (_jsx(FabricOptionsInput, { changeCodeHandler: function (row) {
                    if (row) {
                        handleOptionSelect(row);
                    }
                }, featureDescription: nextFeature.featureDescription, featureStep: nextFeature.step, options: nextFeature.options, isEditMode: true, showOptionalColumns: showOptionalColumns, fromInfo: true }));
        }
        if (requiresNonStdInput || isNso) {
            return (_jsx(NonStdOptionsInput, { changeCodeHandler: function (row) {
                    if (row) {
                        handleOptionSelect(row);
                    }
                }, featureDescription: nextFeature.featureDescription, featureStep: nextFeature.step, options: nsOptions, nsoMetadata: nsoMetadata, isEditMode: true, updateRowOptions: function (e) {
                    setNSOOptions(e);
                }, showOptionalColumns: showOptionalColumns }));
        }
        return (_jsx(PubInputField, { fieldType: InputFieldType.ENUM, fieldValueUpdateHandler: function (e) { return handleOptionSelect(e); }, fieldOptions: optionsArray }));
    };
    return !isLoading ? (_jsxs(TableRow, __assign({ ref: nextFeatureRef }, { children: [_jsx(TableCell, __assign({ sx: { width: '5%' } }, { children: nextFeature.step })), _jsx(TableCell, __assign({ sx: { width: '5%' } }, { children: nextFeature.type })), _jsx(TableCell, __assign({ sx: { width: '10%' } }, { children: nextFeature.featureDescription })), !hideOptionalColumns && _jsx(TableCell, { sx: { width: '5%' } }), _jsx(TableCell, { sx: { width: '5%' } }), _jsx(TableCell, __assign({ sx: { width: '20%' } }, { children: determineInput() })), !hideOptionalColumns && _jsx(TableCell, { sx: { width: '5%' } }), _jsx(TableCell, { sx: { width: '2.5%' } }), userHasRoyaltyPrivileges && _jsx(TableCell, { sx: { width: '2.5%' } }), _jsx(TableCell, { sx: { width: '10%' } }), _jsx(TableCell, { sx: { width: '5%' } })] }))) : (_jsxs(TableRow, { children: [_jsx(TableCell, { sx: { width: '5%' } }), _jsx(TableCell, { sx: { width: '5%' } }), _jsx(TableCell, { sx: { width: '10%' } }), !hideOptionalColumns && _jsx(TableCell, { sx: { width: '5%' } }), _jsx(TableCell, { sx: { width: '5%' } }), _jsx(TableCell, __assign({ sx: { width: '20%' } }, { children: _jsx(LoadingSpinner, { indicatorSize: 2, overridePosition: true }) })), !hideOptionalColumns && _jsx(TableCell, { sx: { width: '5%' } }), _jsx(TableCell, { sx: { width: '2.5%' } }), userHasRoyaltyPrivileges && _jsx(TableCell, { sx: { width: '2.5%' } }), _jsx(TableCell, { sx: { width: '10%' } }), _jsx(TableCell, { sx: { width: '5%' } })] }));
}

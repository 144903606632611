var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { PubInputField } from 'components/Shared/PubInputField/PubInputField';
import { StyledButtonV2 } from 'components/Shared/StyledButtonV2/StyledButtonV2';
import { determineCustomProductPrefixes } from 'utils/cpa-utils';
import { InputFieldType } from 'models/Input/InputModels';
import { useProductValidate } from 'hooks/useProductValidate';
import { useAuth } from 'services/auth/AuthProvider';
import { CPA_DATE_FOR_VALIDATE } from 'utils/constants';
import { getUTCDateData } from 'components/ProductBuild/helper';
import { searchParamsHelper } from 'components/ProductBuildNew/searchParamsHelper';
import { CpaSearchForm } from '../CpaSearchForm/CpaSearchForm';
import styles from '../CpaSearch.module.scss';
export function CpaSearchAddForm(_a) {
    var onSubmit = _a.onSubmit, addInProgress = _a.addInProgress, productNumber = _a.productNumber;
    var _b = useState(productNumber), stndProdRef = _b[0], setStndProdRef = _b[1];
    var _c = useState([]), optionData = _c[0], setOptionData = _c[1];
    var _d = useState(undefined), customProductType = _d[0], setCustomProductType = _d[1];
    var _e = useState(searchParamsHelper()), searchData = _e[0], setSearchData = _e[1];
    var _f = useState(null), dateError = _f[0], setDateError = _f[1];
    var _g = useState(false), stndProdRefInError = _g[0], setStndProdRefInError = _g[1];
    var _h = useState(false), productPrefixInError = _h[0], setProductPrefixInError = _h[1];
    var _j = useState(null), productValidate = _j[0], setProductValidate = _j[1];
    var user = useAuth().user;
    var _k = useState("".concat(searchData.date, "T").concat(searchData.time, ".000Z")), validationDateTime = _k[0], setValidationDateTime = _k[1];
    var productValidationResult = useProductValidate(stndProdRef, 'Standard Product Reference', validationDateTime);
    useEffect(function () {
        if (process.env.REACT_APP_ENV_NAME !== undefined) {
            setOptionData(determineCustomProductPrefixes(user, process.env.REACT_APP_ENV_NAME));
        }
    }, [user]);
    useEffect(function () {
        var dateTime = "".concat(searchData.date, "T").concat(searchData.time, ".000Z");
        setValidationDateTime(dateTime);
    }, [searchData.date, searchData.time]);
    useEffect(function () {
        setProductValidate(productValidationResult);
    }, [productValidationResult, validationDateTime]);
    var handleSearchDataValueChange = function (field, val) {
        var data = __assign({}, searchData);
        data[field] = val;
        if (field === 'date') {
            var selectedDate = new Date(val);
            var cutoffDate = new Date(CPA_DATE_FOR_VALIDATE);
            if (selectedDate < cutoffDate) {
                setDateError('Date cannot be before 11/01/2023');
            }
            data.date = getUTCDateData(val, data.displayTime).date;
            data.displayDate = val;
        }
        else if (field === 'time') {
            var _a = getUTCDateData(data.displayDate, val), date = _a.date, time = _a.time;
            data.time = time;
            data.date = date;
            data.displayTime = val;
        }
        setSearchData(data);
    };
    var ERROR_MESSAGES = {
        STANDARD_PRODUCT_REQUIRED: 'Standard Product Reference Must be Entered',
        STANDARD_PRODUCT_NO_SPACES_ALLOWED: 'Standard Product Reference Can not contain spaces',
        STANDARD_PRODUCT_INVALID_UNKNOWN: 'Standard Product Reference Invalid, UnKnown Error from Validation Api',
        CUSTOM_PRODUCT_TYPE_REQUIRED: 'Custom Product Type Must be Selected',
    };
    var handleAdd = function () {
        if (customProductType && !dateError) {
            var validationData = {
                body: productValidate === null || productValidate === void 0 ? void 0 : productValidate.body,
            };
            onSubmit(stndProdRef, customProductType, validationData);
        }
    };
    var standProdRefInError = useCallback(function (val) {
        if (stndProdRefInError !== val) {
            setStndProdRefInError(val);
        }
    }, [stndProdRefInError]);
    return (_jsxs(CpaSearchForm, { children: [_jsx(PubInputField, { fieldType: InputFieldType.STRING, validations: {
                    required: ERROR_MESSAGES.STANDARD_PRODUCT_REQUIRED,
                }, customErrorMsg: productValidate === null || productValidate === void 0 ? void 0 : productValidate.errorMessage, inProgress: productValidate === null || productValidate === void 0 ? void 0 : productValidate.loading, labels: {
                    left: 'Product to Copy',
                    right: 'Required',
                }, fieldValue: stndProdRef, helperText: "Enter the full product string to be used as the starting point for your new custom product and adjust date accordingly.", dirtyCheck: true, inError: function (val) { return standProdRefInError(val); }, fieldValueUpdateHandler: function (val) { return setStndProdRef(val.trim()); } }), _jsxs(Box, __assign({ className: styles.dateAndTimeInputRow }, { children: [_jsx(Box, __assign({ className: styles.spaceBetweenInputs }, { children: _jsx(PubInputField, { fieldType: InputFieldType.DATE, labels: {
                                left: 'Date',
                                right: 'Required',
                            }, formInputName: "date", fieldValue: (searchData === null || searchData === void 0 ? void 0 : searchData.displayDate) || '', fieldValueUpdateHandler: function (e) { return handleSearchDataValueChange('date', e); }, borderFocus: !!(searchData === null || searchData === void 0 ? void 0 : searchData.date) }) })), _jsx(Box, __assign({ className: styles.dateTimeFieldWidth }, { children: _jsx(PubInputField, { fieldType: InputFieldType.TIME, formInputName: "time", labels: {
                                left: 'Time',
                                right: 'Required',
                            }, fieldValue: (searchData === null || searchData === void 0 ? void 0 : searchData.displayTime) || '', fieldValueUpdateHandler: function (e) { return handleSearchDataValueChange('time', e); }, fieldContainerWidthOverride: "100%", borderFocus: !!(searchData === null || searchData === void 0 ? void 0 : searchData.time) }) }))] })), dateError && (_jsx("div", __assign({ className: styles.errorMessage, style: { color: 'red' } }, { children: dateError }))), _jsx(PubInputField, { fieldType: InputFieldType.ENUM, fieldOptions: optionData, fieldValue: customProductType, labels: {
                    left: 'Custom Product Prefix',
                    right: 'Required',
                }, inError: function (val) {
                    if (productPrefixInError !== val) {
                        setProductPrefixInError(val);
                    }
                }, validations: {
                    required: ERROR_MESSAGES.CUSTOM_PRODUCT_TYPE_REQUIRED,
                }, fieldPlaceholderText: "Select Custom Prefix", dirtyCheck: true, fieldValueUpdateHandler: function (val) { return setCustomProductType(val); } }), _jsx("div", __assign({ className: styles.centeredButton }, { children: _jsx(StyledButtonV2, { labelText: "Add Custom Product", disabled: productPrefixInError || stndProdRefInError || addInProgress || !!dateError, buttonAction: handleAdd }) }))] }));
}

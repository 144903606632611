var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCpaProduct } from 'components/Cpa/CpaProduct/CpaProductContextProvider';
import { InputFieldType } from 'models/Input/InputModels';
import { PubTable } from 'components/Shared/PubTable/PubTable';
import Box from '@mui/material/Box';
import { CPA_NOTE_TYPES } from 'utils/cpa-utils';
import { useAuth } from 'services/auth/AuthProvider';
import { useMemo } from 'react';
import { dateFormatHelper } from 'utils/date-format-helper';
export function NotesTable(_a) {
    var _b, _c;
    var _d = _a.showAddRow, showAddRow = _d === void 0 ? false : _d, showAddRowAction = _a.showAddRowAction;
    var user = useAuth();
    var cpaProductContext = useCpaProduct();
    var tableData = useMemo(function () {
        var _a, _b, _c;
        var data = {};
        var productNotes = __spreadArray([], ((_b = (_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct) === null || _a === void 0 ? void 0 : _a.productNotes) !== null && _b !== void 0 ? _b : []), true);
        (_c = productNotes
            .sort(function (a, b) {
            var _a, _b;
            var aTime = new Date((_a = a.maintDtm) !== null && _a !== void 0 ? _a : 0).getTime();
            var bTime = new Date((_b = b.maintDtm) !== null && _b !== void 0 ? _b : 0).getTime();
            return aTime > bTime ? -1 : 1;
        })) === null || _c === void 0 ? void 0 : _c.forEach(function (note) {
            data[crypto.randomUUID()] = note;
        });
        return data;
    }, [(_b = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct) === null || _b === void 0 ? void 0 : _b.productNotes]);
    var tableDataDefinition = {
        firstColumnIndent: true,
        noDataMessage: 'No Notes',
        addRowWhenNoData: false,
        columns: [
            {
                dataAttribute: 'maintDtm',
                fieldType: InputFieldType.READONLY,
                heading: 'Timestamp',
                formatter: function (value) { return dateFormatHelper.formatIsoString(value, true, true); },
                width: '172px',
            },
            {
                dataAttribute: 'noteType',
                fieldType: InputFieldType.ENUM,
                heading: 'Note Type',
                allowedFieldValues: Object.keys(CPA_NOTE_TYPES),
                defaultValue: undefined,
                validations: {
                    required: 'Note Type required.',
                },
                width: '144px',
            },
            {
                dataAttribute: 'title',
                fieldType: InputFieldType.STRING,
                heading: 'Title',
                defaultValue: '',
                validations: {
                    required: 'Title input required.',
                },
                width: '144px',
            },
            {
                dataAttribute: 'note',
                fieldType: InputFieldType.STRING,
                heading: 'Note',
                defaultValue: '',
                validations: {
                    required: 'Note input required.',
                },
            },
            {
                dataAttribute: 'userId',
                fieldType: InputFieldType.READONLY,
                heading: 'User',
                defaultValue: (_c = user.user) === null || _c === void 0 ? void 0 : _c.email,
                width: '200px',
            },
        ],
        showAddRow: showAddRow,
        showAddRowHandler: showAddRowAction,
        disableEdit: function (key) {
            var _a, _b;
            var rowNotInOriginal = JSON.stringify((_b = (_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProductOriginal) === null || _a === void 0 ? void 0 : _a.productNotes) !== null && _b !== void 0 ? _b : []).includes(JSON.stringify(tableData[key]));
            return rowNotInOriginal;
        },
        editHandler: function (key, row) {
            var _a;
            var updatedNote = {
                maintDtm: row.maintDtm,
                noteType: row.noteType,
                title: row.title,
                note: row.note,
                userId: (_a = user.user) === null || _a === void 0 ? void 0 : _a.email,
            };
            tableData[key] = updatedNote;
            dispatchUpdateProductNotes();
        },
        addHandler: function (row) {
            var _a;
            var newNote = {
                maintDtm: new Date().toISOString(),
                noteType: row.noteType,
                title: row.title,
                note: row.note,
                userId: (_a = user.user) === null || _a === void 0 ? void 0 : _a.email,
            };
            tableData[crypto.randomUUID()] = newNote;
            dispatchUpdateProductNotes();
        },
        deleteProps: {
            deleteHandler: function (key) {
                delete tableData[key];
                dispatchUpdateProductNotes();
            },
            modalProps: {
                buttonProps: {
                    confirmText: 'Delete Note',
                    cancelText: 'Keep Note',
                },
                titleText: 'Delete Notes',
                bodyTextArray: ['Are you sure you would like to delete this note? This action is permanent.'],
            },
        },
    };
    function dispatchUpdateProductNotes() {
        if (cpaProductContext && cpaProductContext.data.currentProduct) {
            cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.dispatch({
                type: 'UPDATE_PRODUCT_NOTES',
                payload: Object.values(tableData !== null && tableData !== void 0 ? tableData : {}),
            });
        }
    }
    return (_jsx(Box, { children: _jsx(PubTable, { def: tableDataDefinition, tableData: tableData }) }));
}

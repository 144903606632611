var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { InputFieldsContainer } from 'components/Shared/InputFieldsContainer/InputFieldsContainer';
import { useCpaProduct } from 'components/Cpa/CpaProduct/CpaProductContextProvider';
import { ActionType, InputFieldType } from 'models/Input/InputModels';
import { ProductSpecialRestrictionCode } from '@millerknoll/pub-api-schema';
import { inputFieldsContainerHelper } from 'components/Shared/InputFieldsContainer/InputFieldsContainerHelper';
import { EXTRA_DATA_RESTRICTION_FIELD_NAMES } from 'utils/cpa-utils';
export function CpaExtraDataRestrictions() {
    var _a;
    var cpaProductContext = useCpaProduct();
    /**
     * Declare state to hold field values of all columns , key as column heading
     */
    var _b = useState(buildFieldValues(undefined)), fieldValues = _b[0], setFieldValues = _b[1];
    /**
     * Holds Column Definition and Initial Values of the Column
     */
    var colDef = {
        rowGap: '24px',
        title: { displayText: 'Restrictions:' },
        preventReadOnly: true,
        showActions: true,
        fields: (_a = {},
            _a[EXTRA_DATA_RESTRICTION_FIELD_NAMES.CUSTOMER] = {
                fieldName: 'Customer:',
                displaySeq: 0,
                input: {
                    type: InputFieldType.RADIO,
                    allowedFieldValues: ['Yes', 'No'],
                },
                hasLookup: false,
            },
            _a[EXTRA_DATA_RESTRICTION_FIELD_NAMES.DISCONTINUED] = {
                fieldName: 'Discontinued:',
                displaySeq: 1,
                input: {
                    type: InputFieldType.RADIO,
                    allowedFieldValues: ['Yes', 'No'],
                },
                hasLookup: false,
            },
            _a[EXTRA_DATA_RESTRICTION_FIELD_NAMES.PRERELEASE] = {
                fieldName: 'Pre Release:',
                displaySeq: 2,
                input: {
                    type: InputFieldType.RADIO,
                    allowedFieldValues: ['Yes', 'No'],
                },
                hasLookup: false,
            },
            _a[EXTRA_DATA_RESTRICTION_FIELD_NAMES.QUALITY] = {
                fieldName: 'Quality:',
                displaySeq: 3,
                input: {
                    type: InputFieldType.RADIO,
                    allowedFieldValues: ['Yes', 'No'],
                },
                hasLookup: false,
            },
            _a[EXTRA_DATA_RESTRICTION_FIELD_NAMES.UNIT_TEST] = {
                fieldName: 'Unit Test:',
                displaySeq: 4,
                input: {
                    type: InputFieldType.RADIO,
                    allowedFieldValues: ['Yes', 'No'],
                },
                hasLookup: false,
            },
            _a),
    };
    useEffect(function () {
        var _a;
        if (!((_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _a === void 0 ? void 0 : _a.currentProduct)) {
            return;
        }
        var initialValues = buildFieldValues(cpaProductContext.data.currentProduct);
        setFieldValues(initialValues);
    }, [cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct]);
    var handleAction = function (actionType) {
        var _a;
        if (!((_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _a === void 0 ? void 0 : _a.currentProduct)) {
            return;
        }
        if (actionType === ActionType.CONFIRM) {
            postConfirmationUpdate();
        }
        else if (actionType === ActionType.REVERT) {
            setFieldValues(buildFieldValues(cpaProductContext.data.currentProduct));
        }
    };
    var postConfirmationUpdate = function () {
        updateContextRestrictions();
        cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.dispatch({
            type: 'UPDATE_CURRENT_PRODUCT',
            payload: __assign({}, cpaProductContext.data.currentProduct),
        });
    };
    function updateContextRestrictions() {
        if (!cpaProductContext || !cpaProductContext.data.currentProduct) {
            return;
        }
        Object.keys(fieldValues).forEach(function (propertyName) {
            if (cpaProductContext.data.currentProduct) {
                var currVal = fieldValues[propertyName].value;
                if (!cpaProductContext.data.currentProduct.restrictions) {
                    cpaProductContext.data.currentProduct.restrictions = {};
                }
                if (propertyName === EXTRA_DATA_RESTRICTION_FIELD_NAMES.CUSTOMER) {
                    cpaProductContext.data.currentProduct.restrictions[ProductSpecialRestrictionCode.CUSTOMER] = {
                        isActive: currVal === 'Yes',
                    };
                }
                if (propertyName === EXTRA_DATA_RESTRICTION_FIELD_NAMES.DISCONTINUED) {
                    cpaProductContext.data.currentProduct.restrictions[ProductSpecialRestrictionCode.DISCONTIN] = {
                        isActive: currVal === 'Yes',
                    };
                }
                if (propertyName === EXTRA_DATA_RESTRICTION_FIELD_NAMES.PRERELEASE) {
                    cpaProductContext.data.currentProduct.restrictions[ProductSpecialRestrictionCode.PRERELEASE] = {
                        isActive: currVal === 'Yes',
                    };
                }
                if (propertyName === EXTRA_DATA_RESTRICTION_FIELD_NAMES.QUALITY) {
                    cpaProductContext.data.currentProduct.restrictions[ProductSpecialRestrictionCode.QUALITY] = {
                        isActive: currVal === 'Yes',
                    };
                }
                if (propertyName === EXTRA_DATA_RESTRICTION_FIELD_NAMES.UNIT_TEST) {
                    cpaProductContext.data.currentProduct.restrictions[ProductSpecialRestrictionCode.UNIT_TEST] = {
                        isActive: currVal === 'Yes',
                    };
                }
            }
        });
    }
    return (_jsx(InputFieldsContainer, { colDef: colDef, handleAction: handleAction, fieldValues: fieldValues, 
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFieldValue: function (property, value) {
            setFieldValues(inputFieldsContainerHelper.getUpdatedFieldValues(fieldValues, property, value));
        } }));
}
var buildFieldValues = function (cpaProduct) {
    var _a;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    return (_a = {},
        _a[EXTRA_DATA_RESTRICTION_FIELD_NAMES.CUSTOMER] = {
            value: ((_c = (_b = cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.restrictions) === null || _b === void 0 ? void 0 : _b[ProductSpecialRestrictionCode.CUSTOMER]) === null || _c === void 0 ? void 0 : _c.isActive) ? 'Yes' : 'No',
        },
        _a[EXTRA_DATA_RESTRICTION_FIELD_NAMES.DISCONTINUED] = {
            value: ((_e = (_d = cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.restrictions) === null || _d === void 0 ? void 0 : _d[ProductSpecialRestrictionCode.DISCONTIN]) === null || _e === void 0 ? void 0 : _e.isActive) ? 'Yes' : 'No',
        },
        _a[EXTRA_DATA_RESTRICTION_FIELD_NAMES.PRERELEASE] = {
            value: ((_g = (_f = cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.restrictions) === null || _f === void 0 ? void 0 : _f[ProductSpecialRestrictionCode.PRERELEASE]) === null || _g === void 0 ? void 0 : _g.isActive) ? 'Yes' : 'No',
        },
        _a[EXTRA_DATA_RESTRICTION_FIELD_NAMES.QUALITY] = {
            value: ((_j = (_h = cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.restrictions) === null || _h === void 0 ? void 0 : _h[ProductSpecialRestrictionCode.QUALITY]) === null || _j === void 0 ? void 0 : _j.isActive) ? 'Yes' : 'No',
        },
        _a[EXTRA_DATA_RESTRICTION_FIELD_NAMES.UNIT_TEST] = {
            value: ((_l = (_k = cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.restrictions) === null || _k === void 0 ? void 0 : _k[ProductSpecialRestrictionCode.UNIT_TEST]) === null || _l === void 0 ? void 0 : _l.isActive) ? 'Yes' : 'No',
        },
        _a);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomEngStatus, CustomQuoteStatus, } from '@millerknoll/pub-api-schema';
import { CPA_DEFAULT_STEP_NO, GSA_CHANNEL, IMPLIED_FEATURE_TYPE, NA_CONTRACT_CHANNEL, NON_FEATURE_DESCRIPTIONS, } from './constants';
import { FSC_TYPE } from './cpa-utils';
function convertParsedResultsToCpaProduct(data) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var restrictions = {};
    (_a = data.restrictions) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
        if (el.reasonCode)
            restrictions[el.reasonCode] = {
                isActive: true,
                comment: el.comment,
            };
    });
    var channels = [];
    if (data.gsaInd && data.gsaInd === 'Y') {
        channels.push({
            prcBkPrmtbl: true,
            sifPrmtbl: true,
            busUnitCd: nullToUndefined(data.businessUnitCode),
            cetPrmtbl: true,
            m2mInd: false,
            code: GSA_CHANNEL,
        });
    }
    channels.push({
        prcBkPrmtbl: true,
        sifPrmtbl: true,
        cetPrmtbl: true,
        busUnitCd: nullToUndefined(data.businessUnitCode),
        m2mInd: false,
        code: NA_CONTRACT_CHANNEL,
    });
    var product = {
        startDate: new Date().toISOString(),
        brandCode: nullToUndefined(data.brandCode),
        businessUnitCode: nullToUndefined(data.businessUnitCode),
        complexity: nullToUndefined(data.complexity),
        configuredIndicator: nullToUndefined(data.configuredIndicator),
        deviationDesc: nullToUndefined(data.deviationDesc),
        discountCode: (_c = (_b = nullToUndefined(data.discountCode)) === null || _b === void 0 ? void 0 : _b.trim()) !== null && _c !== void 0 ? _c : '',
        engStatus: CustomEngStatus.TO_BE_ENGINEERED,
        kitParent: nullToUndefined(data.kitParent),
        minLeadtime: nullToUndefined(data.minLeadtime),
        channels: channels,
        orderableInd: nullToUndefined(data.orderableInd),
        productClass: nullToUndefined(data.productClass),
        productFunctionCode: (_e = (_d = nullToUndefined(data.productFunctionCode)) === null || _d === void 0 ? void 0 : _d.trim()) !== null && _e !== void 0 ? _e : '',
        productFunctionDesc: data.productFunctionDesc,
        productFunctionmodelCode: data.productFunctionmodelCode,
        productLineCode: (_g = (_f = nullToUndefined(data.productLineCode)) === null || _f === void 0 ? void 0 : _f.trim()) !== null && _g !== void 0 ? _g : '',
        productLineDesc: data.productLineDesc,
        productLinemodelCode: data.productLinemodelCode,
        productName: (_j = (_h = nullToUndefined(data.productName)) === null || _h === void 0 ? void 0 : _h.trim()) !== null && _j !== void 0 ? _j : '',
        productNotes: [],
        prop65: nullToUndefined(data.prop65),
        standardProductReference: (_l = (_k = nullToUndefined(data.productNbrOut)) === null || _k === void 0 ? void 0 : _k.trim()) !== null && _l !== void 0 ? _l : '',
        weightClassCode: (_o = (_m = nullToUndefined(data.weightClassCode)) === null || _m === void 0 ? void 0 : _m.trim()) !== null && _o !== void 0 ? _o : '',
        cetConnectorCode: data.cetConnectorCode,
        cetVisibleIndicator: data.cetVisibleIndicator,
        discountDesc: data.discountDesc,
        fscChainOfCustody: data.fscChainOfCustody,
        fscType: (_p = data.fscType) !== null && _p !== void 0 ? _p : FSC_TYPE.FSC_MIX_NO,
        genericCode: data.genericCode,
        priceMatrixId: data.priceMatrixId,
        productType: data.productType,
        restrictions: restrictions,
        quoteStatus: CustomQuoteStatus.ACTIVE,
        proprietaryInd: false,
        engSite: undefined,
        salesCategory: data.salesCategory,
        featureOptions: (_q = data.features) === null || _q === void 0 ? void 0 : _q.filter(function (el) {
            return el.type !== 'Derived' &&
                !NON_FEATURE_DESCRIPTIONS.find(function (desc) { var _a; return desc.toLowerCase() === ((_a = el.featureDescription) === null || _a === void 0 ? void 0 : _a.toLowerCase()); });
        }).map(function (f, i) {
            var _a;
            var featureOption = {
                devInd: !!(nullToUndefined(f.devInd) && ((_a = f.devInd) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()) === 'y'),
                featureDescription: nullToUndefined(f.featureDescription),
                featureSequence: String((i + 1) * 100),
                mfgFeatureModelCode: nullToUndefined(f.mfgFeatureModelCode),
                mfgOptionModelCode: nullToUndefined(f.mfgOptionModelCode),
                prcFeatureModelCode: nullToUndefined(f.prcFeatureModelCode),
                prcOptionModelCode: nullToUndefined(f.prcOptionModelCode),
                nonStandardIndicator: nullToUndefined(f.nonStandardIndicator),
                nonStandardGroupCode: f.nonStandardGroupCode,
                optionCode: nullToUndefined(f.optionCode),
                optionDescription: nullToUndefined(f.optionDescription),
                optionType: nullToUndefined(f.optionType),
                optionSequence: 1,
                type: IMPLIED_FEATURE_TYPE,
                cetHidden: f.cet_hidden,
                defaultOption: f.defaultOption,
                delimiter: f.delimiter,
                fabricFamilyCode: f.fabricFamilyCode,
                fabricFamilyName: f.fabricFamilyName,
                featureId: f.featureId,
                fscCompliantCode: f.fscCompliantCode,
                groupCode: f.groupCode,
                kitCode: f.kitCode,
                materialCode: f.materialCode,
                materialType: f.materialType,
                minLeadtime: f.minLeadtime,
                multipleSelection: f.multipleSelection,
                skuSelection: false,
                step: CPA_DEFAULT_STEP_NO,
            };
            return featureOption;
        }),
    };
    return product;
}
function nullToUndefined(value) {
    return value !== null && value !== void 0 ? value : undefined;
}
var cpaProductMapperHelper = { convertParsedResultsToCpaProduct: convertParsedResultsToCpaProduct };
export { cpaProductMapperHelper };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@mui/material/Button';
export function StyledButtonV2(_a) {
    var children = _a.children, labelText = _a.labelText, _b = _a.backgroundColorVariation, backgroundColorVariation = _b === void 0 ? 'primary' : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.height, height = _d === void 0 ? 'fit-content' : _d, _e = _a.width, width = _e === void 0 ? 'fit-content' : _e, _f = _a.componentOverride, componentOverride = _f === void 0 ? 'button' : _f, _g = _a.roleOverride, roleOverride = _g === void 0 ? 'button' : _g, colorOverride = _a.colorOverride, hoverColorOverride = _a.hoverColorOverride, disabledColorOverride = _a.disabledColorOverride, adornment = _a.adornment, buttonAction = _a.buttonAction, type = _a.type, borderString = _a.borderString, colorVariant2 = _a.colorVariant2, fontFamilyString = _a.fontFamilyString;
    var getColorVariation = function (variation) {
        switch (variation) {
            case 'secondary':
                return !colorVariant2
                    ? { background: 'white', font: 'black' }
                    : { background: 'rgb(241,240,239)', font: 'black' };
            default:
                return !colorVariant2 ? { background: 'black', font: 'white' } : { background: 'white', font: 'black' };
        }
    };
    return (_jsxs(Button, __assign({ sx: {
            backgroundColor: colorOverride
                ? colorOverride.background
                : getColorVariation(backgroundColorVariation).background,
            color: colorOverride ? colorOverride.font : getColorVariation(backgroundColorVariation).font,
            height: height !== null && height !== void 0 ? height : 'initial',
            width: width !== null && width !== void 0 ? width : 'initial',
            minWidth: "max-content",
            paddingLeft: '16px',
            paddingRight: '16px',
            border: borderString !== null && borderString !== void 0 ? borderString : '1px solid black',
            borderRadius: '2px',
            '&:disabled': {
                color: disabledColorOverride ? disabledColorOverride.font : 'gray',
                backgroundColor: disabledColorOverride
                    ? disabledColorOverride.background
                    : 'rgba(229, 229, 229, 1)',
                border: '1px solid lightgray',
            },
            '&:hover': {
                color: hoverColorOverride ? hoverColorOverride.font : 'black',
                backgroundColor: hoverColorOverride ? hoverColorOverride.background : '#F1F0EF',
            },
            fontFamily: fontFamilyString !== null && fontFamilyString !== void 0 ? fontFamilyString : "UntitledSansMedium, sans-serif, system-ui",
            columnGap: '12px',
        }, component: componentOverride !== null && componentOverride !== void 0 ? componentOverride : 'button', role: roleOverride, disabled: disabled, onClick: buttonAction, type: type ? 'submit' : '' }, { children: [adornment && adornment.position === 'left' && adornment.icon, labelText, adornment && adornment.position === 'right' && adornment.icon, children && children] })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { StyledButtonV2 } from 'components/Shared/StyledButtonV2/StyledButtonV2';
import { CpaBodyContainer } from '../CpaBodyContainer/CpaBodyContainer';
import { NotesTable } from './NotesTable/NotesTable';
export function CpaNotes() {
    var _a = useState(false), showAddRow = _a[0], setShowAddRow = _a[1];
    var closeAddRow = function () {
        setShowAddRow(function (curValue) { return !curValue; });
    };
    return (_jsx(CpaBodyContainer, __assign({ title: "Notes", buttons: {
            rightButtons: [
                _jsx(StyledButtonV2, { labelText: "Add Note", backgroundColorVariation: "primary", width: "100px", buttonAction: function () { return setShowAddRow(true); } }),
                _jsx(StyledButtonV2, { labelText: "Select Notes", backgroundColorVariation: "secondary", width: "100px", disabled: true, buttonAction: function () { return null; } }),
            ],
        } }, { children: _jsx(NotesTable, { showAddRow: showAddRow, showAddRowAction: closeAddRow }) })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useState } from 'react';
import { StyledButtonV2 } from 'components/Shared/StyledButtonV2/StyledButtonV2';
import { useNavigate } from 'react-router-dom';
import styles from './ProductSearchNewHeader.module.scss';
export function ProductSearchNewHeader(_a) {
    var page = _a.page;
    var _b = useState(page), activeButton = _b[0], setActiveButton = _b[1];
    var navigate = useNavigate();
    var handleSearchTypeClick = function (param) {
        setActiveButton(param);
        switch (param) {
            case 'basic':
                return navigate('/search-v2/simple/product');
            case 'advanced':
                return navigate('/search-v2/advanced');
            case 'hierarchy':
                return navigate('/search-v2/hierarchy');
            default:
                return navigate('/search-v2/simple/product');
        }
    };
    var buttonLabelParamArray = [
        { label: 'Basic Search', param: 'basic' },
        { label: 'Advanced Search', param: 'advanced' },
        { label: 'Search by Hierarchy', param: 'hierarchy' },
    ];
    return (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ className: styles.container }, { children: [_jsx("h1", __assign({ className: styles.title }, { children: "Search Products" })), _jsx(Box, __assign({ className: styles.buttonContainer }, { children: buttonLabelParamArray.map(function (b) { return (_jsx(StyledButtonV2, { labelText: b.label, backgroundColorVariation: activeButton === b.param ? 'secondary' : 'primary', buttonAction: function () { return handleSearchTypeClick(b.param); }, colorVariant2: true, borderString: "none", height: "47px", fontFamilyString: "UntitledSans, sans-serif, system-ui" }, b.param)); }) }))] })), _jsx("hr", { className: styles.hr })] }));
}

import { jsx as _jsx } from "react/jsx-runtime";
import { InputFieldType } from 'models/Input/InputModels';
import { useEffect, useState } from 'react';
import { useLookupCodes } from 'hooks/useLookupCodes';
import { LookupFieldProperty } from 'services/api/search-lookups';
import { LookupModal } from './LookupModal';
export function LookupInputs(_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, fieldProperty = _a.fieldProperty, fieldValue = _a.fieldValue, changeCodeHandler = _a.changeCodeHandler;
    var _b = useLookupCodes(fieldProperty, fieldValue), loading = _b.loading, output = _b.output;
    var _c = useState({}), tableData = _c[0], setTableData = _c[1];
    var titleText;
    if (fieldProperty === LookupFieldProperty.discountCode) {
        titleText = 'Discount (Code)';
    }
    else if (fieldProperty === LookupFieldProperty.productLineCode) {
        titleText = 'Product Line (Code)';
    }
    else if (fieldProperty === LookupFieldProperty.productFunctionCode) {
        titleText = 'Product Func. Code';
    }
    else if (fieldProperty === LookupFieldProperty.weightClassCode) {
        titleText = 'Weight Class Code';
    }
    else if (fieldProperty === LookupFieldProperty.salesBrandCode) {
        titleText = 'Sales Brand Code';
    }
    else if (fieldProperty === LookupFieldProperty.salesCategoryCode) {
        titleText = 'Sales Category Code';
    }
    else if (fieldProperty === LookupFieldProperty.salesProductLineCode) {
        titleText = 'Sales Product Line Code';
    }
    else if (fieldProperty === LookupFieldProperty.salesSubCategoryCode) {
        titleText = 'Sales Sub-Category Code';
    }
    else if (fieldProperty === LookupFieldProperty.salesTypeCode) {
        titleText = 'Sales Type Code';
    }
    var tableDefinition = {
        dataLoading: loading,
        noDataMessage: 'No Codes Data',
        columns: getColumns(fieldProperty),
        rowClickHandler: function (row) {
            changeCodeHandler(row);
        },
    };
    function getColumns(fieldPropertyType) {
        var columns = [
            {
                dataAttribute: 'code',
                fieldType: InputFieldType.READONLY,
                heading: 'Code',
                width: '120px',
            },
        ];
        if (fieldPropertyType !== LookupFieldProperty.weightClassCode)
            columns.push({
                dataAttribute: 'description',
                fieldType: InputFieldType.READONLY,
                heading: 'Description',
                width: '120px',
            });
        return columns;
    }
    useEffect(function () {
        var data = {};
        output === null || output === void 0 ? void 0 : output.forEach(function (code) {
            var _a, _b, _c;
            if (code.code && code.code.trim() !== '') {
                data[code.code] =
                    fieldProperty === LookupFieldProperty.weightClassCode
                        ? {
                            code: (_a = code.code.toUpperCase()) !== null && _a !== void 0 ? _a : '',
                        }
                        : {
                            code: (_b = code.code.toUpperCase()) !== null && _b !== void 0 ? _b : '',
                            description: (_c = code.description) !== null && _c !== void 0 ? _c : '',
                        };
            }
        });
        setTableData(data);
    }, [fieldProperty, output]);
    return (_jsx(LookupModal, { isOpen: isOpen, setIsOpen: setIsOpen, title: titleText, tableDefinition: tableDefinition, tableData: tableData }));
}

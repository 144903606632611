var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
var ValidationHeaderLabelWithStyling = styled(Typography)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-weight: 900;\n    text-decoration: underline;\n    margin-right: 10px;\n    width: 260px;\n    text-align: right;\n"], ["\n    font-weight: 900;\n    text-decoration: underline;\n    margin-right: 10px;\n    width: 260px;\n    text-align: right;\n"])));
export function StyledValidationHeaderLabel(_a) {
    var label = _a.label;
    return _jsx(ValidationHeaderLabelWithStyling, { children: label });
}
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { useCheckAuthScope } from 'services/helperFunctions/useCheckAuthScope';
import { classes } from 'utils/styleHelper';
import styles from './SecuredLink.module.scss';
export function SecuredLink(_a) {
    var scopes = _a.scopes, labelText = _a.labelText, urlPath = _a.urlPath, disabled = _a.disabled;
    var isAuthed = useCheckAuthScope(scopes);
    return (_jsx(Link, __assign({ style: { display: isAuthed ? 'flex' : 'none' }, className: classes(styles.securedLink, disabled && styles.disabledLink), to: urlPath }, { children: labelText })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback, useEffect } from 'react';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { tryCopy } from 'utils/clipboardHelper';
import styles from './JsonViewer.module.scss';
export function JsonViewer(_a) {
    var stringifiedJson = _a.stringifiedJson, isOpen = _a.isOpen, closeModal = _a.closeModal, maxWidth = _a.maxWidth;
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var handleCopy = function (code) {
        if (code === -1) {
            setSnackbarAlert({
                message: "Looks like your browser does not support automatic copying. Please copy the JSON.",
                type: 'error',
            });
        }
    };
    var keyPress = useCallback(function (e) {
        if (e.key === 'Escape') {
            closeModal();
        }
    }, [closeModal]);
    useEffect(function () {
        document.addEventListener('keyup', keyPress);
        return function () { return document.removeEventListener('keyup', keyPress); };
    }, [keyPress]);
    return (_jsx(Dialog, __assign({ open: isOpen, onClose: closeModal, className: styles.dialog, PaperProps: {
            sx: {
                width: '100%',
                maxWidth: maxWidth !== null && maxWidth !== void 0 ? maxWidth : '75%',
                '::-webkit-scrollbar-track': { background: 'transparent' },
                scrollbarColor: '#acacac transparent',
            },
        } }, { children: _jsxs(Box, __assign({ className: styles.jsonContainer }, { children: [_jsx("pre", { children: _jsx("code", { children: stringifiedJson }) }), _jsxs(Box, __assign({ className: styles.iconContainer }, { children: [_jsx(IconButton, __assign({ className: styles.copyIcon, onClick: function () { return handleCopy(tryCopy(stringifiedJson !== null && stringifiedJson !== void 0 ? stringifiedJson : '')); } }, { children: _jsx(ContentCopyIcon, {}) })), _jsx(IconButton, __assign({ className: styles.closeIcon, onClick: function () {
                                closeModal();
                            } }, { children: _jsx(CloseIcon, {}) }))] }))] })) })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo, useState } from 'react';
var SearchProductContext = createContext({ setConditionGroups: function () { return null; }, conditionGroups: {} });
export function SearchProductProvider(_a) {
    var children = _a.children;
    var _b = useState({}), conditionGroups = _b[0], setConditionGroups = _b[1];
    var handleSetConditionGroups = function (conditionGroup, groupKey, operation) {
        var modifiedConditionGroups = __assign({}, conditionGroups);
        if (operation === 'add') {
            modifiedConditionGroups[groupKey] = conditionGroup;
        }
        else {
            delete modifiedConditionGroups[groupKey];
        }
        setConditionGroups(modifiedConditionGroups);
    };
    var contextValue = useMemo(function () { return ({
        setConditionGroups: handleSetConditionGroups,
        conditionGroups: conditionGroups,
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conditionGroups]);
    return _jsx(SearchProductContext.Provider, __assign({ value: contextValue }, { children: children }));
}
export var useSearchProduct = function () { return useContext(SearchProductContext); };

import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
var VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
export function FileSelectInput(_a) {
    var _b;
    var allowedFileTypes = _a.allowedFileTypes, fileSelectHandler = _a.fileSelectHandler;
    return (_jsx(VisuallyHiddenInput, { type: "file", accept: (_b = allowedFileTypes === null || allowedFileTypes === void 0 ? void 0 : allowedFileTypes.join(',')) !== null && _b !== void 0 ? _b : undefined, onChange: function (e) { return fileSelectHandler(e.target.files); } }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StyledTableContainer } from 'components/Shared/StyledTableContainer/StyledTableContainer';
import { catalogHelper } from 'utils/catalog-helper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import styles from './HierarchiesTable.module.scss';
export function HierarchiesTable(_a) {
    var _b = _a.selectedHierarchies, selectedHierarchies = _b === void 0 ? [] : _b, removeIndex = _a.removeIndex;
    var _c = useState([]), hierarchyKeys = _c[0], setHierarchyKeys = _c[1];
    useEffect(function () {
        setHierarchyKeys(selectedHierarchies.map(function (h) { return catalogHelper.searchHierarchyToKey(h); }));
    }, [selectedHierarchies]);
    return (_jsxs(StyledTableContainer, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ className: styles.colDescription }, { children: "Selected Hierarchies" })), _jsx(TableCell, __assign({ className: styles.colIcon }, { children: " " }))] }) }), _jsx(TableBody, { children: hierarchyKeys.length === 0 ? (_jsx(TableRow, { children: _jsx(TableCell, __assign({ className: styles.noResultsCell, colSpan: 2 }, { children: "No selected hierarchies" })) })) : (hierarchyKeys.map(function (key, ind) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx(Box, __assign({ className: styles.levelsContainer }, { children: key
                                    .replaceAll(catalogHelper.hierarchyKeyLevelDelimiter, '')
                                    .split('_')
                                    .map(function (level, i, arr) { return (_jsxs(Box, __assign({ className: styles.levelsContainer }, { children: [i === arr.length - 1 ? (_jsx(Typography, __assign({ variant: "h4" }, { children: level }))) : (_jsx(Typography, __assign({ variant: "h5" }, { children: level }))), i !== arr.length - 1 && _jsx(Typography, __assign({ variant: "h3" }, { children: ' > ' }))] }), "".concat(level, "_").concat(i))); }) })) }), _jsx(TableCell, { children: _jsx(IconButton, __assign({ onClick: function () { return removeIndex(ind); }, sx: { padding: 0 } }, { children: _jsx(DeleteOutlineIcon, {}) })) })] }, key)); })) })] }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
export function HierarchyDropdown(_a) {
    var levelData = _a.levelData, loading = _a.loading, levelNum = _a.levelNum, dropdownKey = _a.dropdownKey, handleLevelSelect = _a.handleLevelSelect, unsetLevel = _a.unsetLevel, newHierarchy = _a.newHierarchy;
    var _b = useState(''), selected = _b[0], setSelected = _b[1];
    var handleChange = function (event) {
        setSelected(event.target.value);
        handleLevelSelect(event.target.value, levelNum, dropdownKey);
    };
    var convertIntoTitleCase = function (str) {
        return str
            .toLowerCase()
            .split(' ')
            .map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); })
            .join(' ');
    };
    return (_jsx(Box, __assign({ width: "100%" }, { children: loading ? (_jsx(LoadingSpinner, { indicatorSize: 10 })) : (_jsxs(Box, __assign({ sx: {
                display: 'flex',
                alignItems: 'center',
                // eslint-disable-next-line no-nested-ternary
                columnGap: newHierarchy ? (levelNum > 1 ? '23px' : '24.5px') : '12px',
            } }, { children: [_jsx(Tooltip, __assign({ title: "Unset Level", arrow: true }, { children: _jsx("span", { children: _jsx(IconButton, __assign({ sx: { padding: '0' }, disabled: selected === '', onClick: function () { return unsetLevel(levelNum); } }, { children: _jsx(RemoveCircleOutlineIcon, {}) })) }) })), _jsx(Typography, __assign({ sx: { whiteSpace: 'nowrap', fontFamily: "UntitledSansMedium, sans-serif, system-ui" } }, { children: "Level ".concat(levelNum, ":") })), _jsxs(FormControl, __assign({ fullWidth: true, size: "small" }, { children: [(!newHierarchy || selected === '') && (_jsx(InputLabel, __assign({ sx: { backgroundColor: 'white', padding: '0 4px' } }, { children: "Select Hierarchy" }))), _jsx(Select, __assign({ value: selected, onChange: handleChange }, { children: levelData.keys
                                .sort(function (a, b) { return (a > b ? 1 : -1); })
                                .map(function (val) { return (_jsx(MenuItem, __assign({ value: val }, { children: newHierarchy ? convertIntoTitleCase(val) : val }), val)); }) }))] }))] }))) })));
}

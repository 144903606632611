var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { useSearchProduct } from 'context/searchProduct/SearchProductProvider';
import { InputFieldRow } from '../InputFieldRow/InputFieldRow';
import styles from './CriteriaGroup.module.scss';
import { DEFAULT_DATE_ROWS } from '../../../utils/search-product-field-mapping';
export function CriteriaGroup(_a) {
    var groupKey = _a.groupKey;
    var _b = useSearchProduct(), conditionGroups = _b.conditionGroups, setConditionGroups = _b.setConditionGroups;
    var _c = useState([]), inputFieldRowKeys = _c[0], setInputFieldRowKeys = _c[1];
    var handleAddNewInputFieldRow = function () {
        var _a;
        var newInputFieldRowKey = crypto.randomUUID();
        var parentConditionGroup = conditionGroups === null || conditionGroups === void 0 ? void 0 : conditionGroups[groupKey];
        var emptySearchProductCondition = {};
        setConditionGroups(__assign(__assign({}, parentConditionGroup), (_a = {}, _a[newInputFieldRowKey] = emptySearchProductCondition, _a)), groupKey, 'add');
        setInputFieldRowKeys(__spreadArray(__spreadArray([], inputFieldRowKeys, true), [newInputFieldRowKey], false));
    };
    var handleRemoveInputFieldRow = function (key) {
        var _a;
        if (inputFieldRowKeys.length === 1 &&
            inputFieldRowKeys[0] !== DEFAULT_DATE_ROWS.startDate.key &&
            inputFieldRowKeys[0] !== DEFAULT_DATE_ROWS.endDate.key)
            return;
        var modifiedConditionGroup = conditionGroups === null || conditionGroups === void 0 ? void 0 : conditionGroups[groupKey];
        if (modifiedConditionGroup[key])
            delete modifiedConditionGroup[key];
        var arr = __spreadArray([], inputFieldRowKeys, true);
        arr.splice(arr.findIndex(function (rowKey) { return rowKey === key; }), 1);
        if (arr.length === 0) {
            var newInputFieldRowKey = crypto.randomUUID();
            var emptySearchProductCondition = {};
            arr.push(newInputFieldRowKey);
            setInputFieldRowKeys(arr);
            setConditionGroups(__assign(__assign({}, modifiedConditionGroup), (_a = {}, _a[newInputFieldRowKey] = emptySearchProductCondition, _a)), groupKey, 'add');
            return;
        }
        setInputFieldRowKeys(arr);
        setConditionGroups(__assign({}, modifiedConditionGroup), groupKey, 'add');
    };
    useEffect(function () {
        setInputFieldRowKeys(__spreadArray(__spreadArray([], inputFieldRowKeys, true), [DEFAULT_DATE_ROWS.startDate.key, DEFAULT_DATE_ROWS.endDate.key], false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(Box, __assign({ className: styles.criteriaGroupContainer }, { children: [_jsx(Box, __assign({ className: styles.rowsContainer }, { children: inputFieldRowKeys.map(function (rowKey, i) { return (_jsxs(Box, __assign({ className: styles.indRowContainer }, { children: [i !== 0 && _jsx("hr", {}), _jsx(Box, __assign({ className: styles.inputFieldContainer }, { children: _jsx(InputFieldRow, { groupKey: groupKey, fieldRowKey: rowKey, showRemoveIcon: inputFieldRowKeys.length > 1 ||
                                    inputFieldRowKeys[0] === DEFAULT_DATE_ROWS.startDate.key ||
                                    inputFieldRowKeys[0] === DEFAULT_DATE_ROWS.endDate.key, handleRemoveInputFieldRow: function () { return handleRemoveInputFieldRow(rowKey); } }, rowKey) }), rowKey)] }), rowKey)); }) })), _jsxs(Box, __assign({ className: styles.addFieldContainer }, { children: [_jsx(Typography, __assign({ className: styles.addText }, { children: "Add Field" })), _jsx(IconButton, __assign({ onClick: function () { return handleAddNewInputFieldRow(); }, className: styles.addFieldButton }, { children: _jsx(AddIcon, {}) }))] }))] })));
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var CatalogEventPublishRequest;
(function (CatalogEventPublishRequest) {
    var eventType;
    (function (eventType) {
        eventType["CATALOG_DEFINITION"] = "CATALOG_DEFINITION";
        eventType["CATALOG_EXTRACT"] = "CATALOG_EXTRACT";
    })(eventType = CatalogEventPublishRequest.eventType || (CatalogEventPublishRequest.eventType = {}));
    var eventSubType;
    (function (eventSubType) {
        eventSubType["CREATED"] = "CREATED";
        eventSubType["UPDATED"] = "UPDATED";
        eventSubType["DELETED"] = "DELETED";
        eventSubType["REQUESTED"] = "REQUESTED";
        eventSubType["COMPLETED"] = "COMPLETED";
        eventSubType["FAILED"] = "FAILED";
    })(eventSubType = CatalogEventPublishRequest.eventSubType || (CatalogEventPublishRequest.eventSubType = {}));
})(CatalogEventPublishRequest || (CatalogEventPublishRequest = {}));

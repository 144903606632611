var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import styles from './PageContainer.module.scss';
export function PageContainer(_a) {
    var children = _a.children, _b = _a.rowGap, rowGap = _b === void 0 ? 0 : _b, margin = _a.margin;
    return (_jsx(Box, __assign({ className: styles.container, sx: { rowGap: "".concat(rowGap, "px"), margin: margin !== null && margin !== void 0 ? margin : '' } }, { children: children })));
}

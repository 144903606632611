var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { StyledRowBox } from 'components/Shared/StyledComponents/StyledRowBox';
import { StyledValidationHeaderLabel } from 'components/Shared/StyledComponents/StyledValidationHeaderLabel';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { pubDaoUtils } from 'services/helperFunctions/descHelper';
import { Link, useLocation } from 'react-router-dom';
import { JsonViewer } from 'components/Shared/JsonViewer/JsonViewer';
import DataObjectIcon from '@mui/icons-material/DataObject';
import Tooltip from '@mui/material/Tooltip';
import styles from './ProductValidationHeader.module.scss';
export function ProductValidationHeader(_a) {
    var _b, _c, _d, _e, _f;
    var validationData = _a.validationData, productNumber = _a.productNumber, productBuild = _a.productBuild;
    var _g = useState(), levelsData = _g[0], setLevelsData = _g[1];
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var nameValueRef = useRef(null);
    var _h = useState(false), isJsonViewerOpen = _h[0], setJsonViewerOpen = _h[1];
    var pathname = useLocation().pathname;
    var handleModalClose = function () {
        setJsonViewerOpen(false);
    };
    useEffect(function () {
        var _a, _b, _c;
        var levels = [];
        if ((_a = validationData === null || validationData === void 0 ? void 0 : validationData.body) === null || _a === void 0 ? void 0 : _a.hierarchy) {
            (_c = (_b = validationData === null || validationData === void 0 ? void 0 : validationData.body) === null || _b === void 0 ? void 0 : _b.hierarchy) === null || _c === void 0 ? void 0 : _c.forEach(function (hierarchyValue) {
                var _a;
                var levelsDescriptions = Object.values((_a = hierarchyValue === null || hierarchyValue === void 0 ? void 0 : hierarchyValue.levels) !== null && _a !== void 0 ? _a : {});
                if (levelsDescriptions.length > 0) {
                    levels.push(levelsDescriptions);
                }
            });
            setLevelsData(levels);
        }
    }, [validationData === null || validationData === void 0 ? void 0 : validationData.body]);
    var handleProductNumberCopyClick = function () {
        try {
            // if clipboard is unsupported, it will be handled in catch clause
            // eslint-disable-next-line compat/compat
            navigator.clipboard.writeText("".concat(productNumber));
            setSnackbarAlert({
                message: 'Product Number copied',
                type: 'success',
            });
        }
        catch (_a) {
            setSnackbarAlert({
                message: 'Looks like your browser does not support automatic copying. Please copy the product number manually.',
                type: 'error',
            });
        }
    };
    return (_jsxs(Box, __assign({ className: styles.container }, { children: [_jsx(Box, __assign({ className: styles.dataIcon }, { children: _jsxs(IconButton, __assign({ onMouseUp: function () { return setJsonViewerOpen(true); } }, { children: [_jsx(Typography, { children: "View Full Response" }), _jsx(DataObjectIcon, {})] })) })), _jsx(JsonViewer, { isOpen: isJsonViewerOpen, closeModal: function () { return handleModalClose(); }, stringifiedJson: JSON.stringify(validationData.body, null, 4) }), _jsxs(StyledRowBox, { children: [_jsxs(Box, __assign({ className: styles.largeSizedRow }, { children: [_jsx(StyledValidationHeaderLabel, { label: "Product Prefix:" }), _jsx(Typography, { children: validationData.body.productPrefix })] })), _jsxs(Box, __assign({ className: styles.infoContainer }, { children: [_jsx(Typography, __assign({ className: styles.labelContainer }, { children: "Name:" })), _jsx(Tooltip, __assign({ title: validationData.body.productName, placement: "top", arrow: true, slotProps: {
                                    tooltip: {
                                        sx: { fontSize: '14px', maxWidth: '750px' },
                                    },
                                } }, { children: _jsx(Typography, __assign({ className: styles.productNameValue, ref: nameValueRef }, { children: validationData.body.productName })) }))] }))] }), _jsxs(StyledRowBox, { children: [_jsxs(Box, __assign({ className: styles.largeSizedRow }, { children: [_jsx(StyledValidationHeaderLabel, { label: "Discount Description (Code):" }), _jsx(Typography, { children: "".concat((_b = validationData.body.discountDesc) !== null && _b !== void 0 ? _b : '', " ").concat(validationData.body.discountCode
                                    ? "(".concat(validationData.body.discountCode, ")")
                                    : '') })] })), _jsxs(Box, __assign({ className: styles.infoContainer, onClick: function () { return null; } }, { children: [_jsx(Typography, __assign({ className: styles.labelContainer }, { children: "Product Version:" })), _jsx(Box, __assign({ className: styles.productVersion }, { children: _jsx(Link, __assign({ target: "_blank", rel: "noopener noreferrer", to: "/product-info?productVersion=".concat(validationData.body.productVersion) }, { children: validationData.body.productVersion })) }))] }))] }), _jsx(StyledRowBox, { children: _jsxs(Box, __assign({ className: styles.largeSizedRow }, { children: [_jsx(StyledValidationHeaderLabel, { label: "Product Line Description (Code):" }), _jsx(Typography, { children: "".concat((_c = validationData.body.productLineDesc) !== null && _c !== void 0 ? _c : '', " ").concat(validationData.body.productLineCode &&
                                ((_d = validationData.body.productLineCode) === null || _d === void 0 ? void 0 : _d.trim()) !== ''
                                ? "(".concat(validationData.body.productLineCode, ")")
                                : '') })] })) }), _jsxs(StyledRowBox, { children: [_jsxs(Box, __assign({ className: styles.largeSizedRow }, { children: [_jsx(StyledValidationHeaderLabel, { label: "Product Function Description (Code):" }), _jsx(Typography, { children: "".concat((_e = validationData.body.productFunctionDesc) !== null && _e !== void 0 ? _e : '', " ").concat(validationData.body.productFunctionCode &&
                                    ((_f = validationData.body.productFunctionCode) === null || _f === void 0 ? void 0 : _f.trim()) !== ''
                                    ? "(".concat(validationData.body.productFunctionCode, ")")
                                    : '') })] })), _jsxs(Box, __assign({ className: styles.infoContainer }, { children: [_jsx(Typography, __assign({ className: styles.labelContainer }, { children: "GSA:" })), _jsx(Typography, __assign({ className: styles.productNameValue }, { children: validationData.body.gsaInd }))] }))] }), _jsxs(StyledRowBox, { children: [_jsxs(Box, __assign({ className: styles.largeSizedRow }, { children: [_jsx(StyledValidationHeaderLabel, { label: "Min. Lead Time:" }), _jsx(Typography, { children: validationData.body.minLeadtime })] })), !productBuild && (_jsxs(Box, __assign({ className: styles.infoContainer }, { children: [_jsx(Typography, __assign({ className: styles.labelContainer }, { children: "Base Product Number:" })), _jsx(Typography, __assign({ className: styles.productNameValue }, { children: validationData.body.baseProductNbr }))] })))] }), _jsxs(StyledRowBox, { children: [_jsxs(Box, __assign({ className: styles.largeSizedRow }, { children: [_jsx(StyledValidationHeaderLabel, { label: "Product Class:" }), _jsx(Typography, { children: validationData.body.productClass })] })), validationData.body.returnMessage &&
                        (pathname === '/product-validate' ||
                            (pathname === '/product-build' &&
                                validationData.body.returnCode === '-1')) && (_jsxs(Box, __assign({ className: styles.infoContainer }, { children: [_jsx(Typography, __assign({ className: styles.labelContainer }, { children: "Return Message:" })), _jsx(Typography, __assign({ className: styles.returnMessage }, { children: validationData.body.returnMessage }))] })))] }), _jsx("hr", { className: styles.horizontalRule }), _jsxs(Box, __assign({ className: styles.levelsAndProductNumberContainer }, { children: [_jsxs(Box, __assign({ className: styles.levelsContainer }, { children: [_jsx(StyledValidationHeaderLabel, { label: "Levels:" }), _jsx("div", __assign({ className: "levelsContainer" }, { children: levelsData === null || levelsData === void 0 ? void 0 : levelsData.map(function (level, levelIndex) { return (_jsx(Box, __assign({ sx: { width: 'fit-content', display: 'flex' } }, { children: level === null || level === void 0 ? void 0 : level.map(function (levelValue, levelValueIndex) {
                                        var _a, _b, _c;
                                        return (_jsx(Typography, { children: pubDaoUtils.getDefaultDesc(levelValue) !== null && (_jsxs("span", __assign({ style: { wordBreak: 'break-word' } }, { children: [(_a = pubDaoUtils.getDefaultDesc(levelValue)) === null || _a === void 0 ? void 0 : _a.desc, levelValueIndex < level.length - 1 && (_jsx("span", __assign({ style: { margin: '0 10px' } }, { children: '>' })))] }), "".concat((_b = pubDaoUtils.getDefaultDesc(levelValue)) === null || _b === void 0 ? void 0 : _b.desc).concat(levelValueIndex))) }, "".concat((_c = pubDaoUtils.getDefaultDesc(levelValue)) === null || _c === void 0 ? void 0 : _c.desc).concat(levelValueIndex)));
                                    }) }), "".concat(level).concat(levelIndex))); }) }))] })), productBuild && (_jsx(Box, __assign({ className: styles.productNumberContainer }, { children: _jsxs(Typography, __assign({ className: styles.text }, { children: ["Product Number: ", productNumber, _jsx("span", { children: _jsx(IconButton, __assign({ onClick: function () { return handleProductNumberCopyClick(); } }, { children: _jsx(ContentCopyIcon, {}) })) })] })) })))] }))] })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { StyledButton } from 'components/Shared/StyledComponents/StyledButton';
import { useSearchProduct } from 'context/searchProduct/SearchProductProvider';
import { searchProductHelper } from 'utils/searchProductHelper';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { SearchSortVal, } from '@millerknoll/pub-api-schema';
import { searchProducts } from 'services/api/search-product-service';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import { SEARCH_PRODUCT_PAGE_SIZE, SEARCH_PRODUCT_REQUEST_FIELDS, SEARCH_PRODUCT_SORT_VALUES } from 'utils/constants';
import { SortDropdown } from 'components/Shared/SortDropdown/SortDropdown';
import { CriteriaGroup } from './CriteriaGroup/CriteriaGroup';
import styles from './SearchProduct.module.scss';
import { RequestDisplay } from './RequestDisplay/RequestDisplay';
import { ResultsTable } from './ResultsTable/ResultsTable';
import { DEFAULT_DATE_ROWS } from '../../utils/search-product-field-mapping';
export function SearchProduct() {
    var _this = this;
    var _a;
    var _b = useSearchProduct(), conditionGroups = _b.conditionGroups, setConditionGroups = _b.setConditionGroups;
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var _c = useState([]), criteriaGroupKeys = _c[0], setCriteriaGroupKeys = _c[1];
    var _d = useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var _e = useState(''), builtRequest = _e[0], setBuiltRequest = _e[1];
    var _f = useState(false), showRequest = _f[0], setShowRequest = _f[1];
    var _g = useState(), productResults = _g[0], setProductResults = _g[1];
    var _h = useState("".concat(SEARCH_PRODUCT_SORT_VALUES[0].key, "_").concat(SEARCH_PRODUCT_SORT_VALUES[0].direction)), selectedSort = _h[0], setSelectedSort = _h[1];
    var handleAddNewCriteriaGroup = function () {
        var _a, _b, _c;
        var newGroupKey = crypto.randomUUID();
        setCriteriaGroupKeys(__spreadArray(__spreadArray([], criteriaGroupKeys, true), [newGroupKey], false));
        setConditionGroups((_a = {},
            _a["".concat(DEFAULT_DATE_ROWS.startDate.key)] = (_b = {
                    selectedField: DEFAULT_DATE_ROWS.startDate.key
                },
                _b["".concat(DEFAULT_DATE_ROWS.startDate.key)] = {
                    operator: DEFAULT_DATE_ROWS.startDate.operator,
                    val: DEFAULT_DATE_ROWS.startDate.value,
                },
                _b),
            _a["".concat(DEFAULT_DATE_ROWS.endDate.key)] = (_c = {
                    selectedField: DEFAULT_DATE_ROWS.endDate.key
                },
                _c["".concat(DEFAULT_DATE_ROWS.endDate.key)] = {
                    operator: DEFAULT_DATE_ROWS.endDate.operator,
                    val: DEFAULT_DATE_ROWS.endDate.value,
                },
                _c),
            _a), newGroupKey, 'add');
    };
    var handleRemoveCriteriaGroup = function (key) {
        if (criteriaGroupKeys.length === 1)
            return;
        var arr = __spreadArray([], criteriaGroupKeys, true);
        arr.splice(arr.findIndex(function (cgKey) { return cgKey === key; }), 1);
        setCriteriaGroupKeys(arr);
        setConditionGroups({}, key, 'remove');
    };
    var handleBuildRequest = useCallback(function (field, direction) { return __awaiter(_this, void 0, void 0, function () {
        var requestConditions, request, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    requestConditions = searchProductHelper.buildSearchProductRequestConditions(conditionGroups);
                    request = {
                        pageNumber: 1,
                        pageSize: SEARCH_PRODUCT_PAGE_SIZE,
                        fields: SEARCH_PRODUCT_REQUEST_FIELDS,
                        conditions: requestConditions,
                        sort: field === 'relevance'
                            ? undefined
                            : {
                                field: field,
                                direction: (direction.toUpperCase() in SearchSortVal.direction
                                    ? direction
                                    : 'asc'),
                            },
                    };
                    setBuiltRequest(JSON.stringify(request, null, 2));
                    return [4 /*yield*/, searchProducts(request)];
                case 1:
                    response = _a.sent();
                    if (response.status !== 200) {
                        setSnackbarAlert({
                            message: 'Error processing search.',
                            type: 'error',
                        });
                        return [2 /*return*/];
                    }
                    setProductResults(response.data);
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [conditionGroups, setSnackbarAlert]);
    var handleSortSelect = function (field, direction) {
        var newSelectedSort = "".concat(field, "_").concat(direction);
        if (newSelectedSort !== selectedSort) {
            setSelectedSort(newSelectedSort);
            setIsLoading(true);
            handleBuildRequest(field, direction);
        }
    };
    var getResultsLabelCount = function (resultsCount) {
        if (!resultsCount)
            return 0;
        if (resultsCount < SEARCH_PRODUCT_PAGE_SIZE)
            return resultsCount;
        return SEARCH_PRODUCT_PAGE_SIZE;
    };
    var handleSubmitClick = function () {
        var _a = selectedSort.split('_'), field = _a[0], direction = _a[1];
        setIsLoading(true);
        handleBuildRequest(field, direction);
    };
    var keyPress = useCallback(function (e) {
        if (e.key === 'Enter') {
            var _a = selectedSort.split('_'), field = _a[0], direction = _a[1];
            setIsLoading(true);
            handleBuildRequest(field, direction);
        }
    }, [handleBuildRequest, selectedSort]);
    useEffect(function () {
        document.addEventListener('keyup', keyPress);
        return function () { return document.removeEventListener('keyup', keyPress); };
    }, [keyPress]);
    useEffect(function () {
        handleAddNewCriteriaGroup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(Box, __assign({ className: styles.pageContainer }, { children: _jsxs(Box, __assign({ width: "80%" }, { children: [_jsx(Typography, __assign({ variant: "h1", className: styles.pageTitle }, { children: "Advanced Product Search" })), _jsxs(Box, { children: [criteriaGroupKeys.map(function (criteriaGroupKey, i) { return (_jsxs(Box, __assign({ sx: { position: 'relative' } }, { children: [i > 0 && (_jsxs(Box, __assign({ className: styles.groupDivider }, { children: [_jsx("hr", {}), _jsx(Typography, { children: " OR " }), _jsx("hr", {})] }))), criteriaGroupKeys.length > 1 && (_jsx(IconButton, __assign({ sx: {
                                        top: i === 0 ? '-20px' : '36px',
                                        background: "conic-gradient(white 50%, ".concat(i % 2 === 0 ? 'white' : 'rgba(229, 229, 229, 1)', " 0 75%, white 0 25%)"),
                                    }, className: styles.closeIcon, onClick: function () { return handleRemoveCriteriaGroup(criteriaGroupKey); } }, { children: _jsx(CloseIcon, {}) }))), _jsx(Box, __assign({ className: i % 2 === 0 ? styles.criteriaGroupOdd : styles.criteriaGroupEven }, { children: _jsx(CriteriaGroup, { groupKey: criteriaGroupKey }, criteriaGroupKey) }))] }), criteriaGroupKey)); }), _jsxs(Box, __assign({ className: styles.addGroupSubmitContainer }, { children: [_jsxs(Box, __assign({ className: styles.addGroupContainer }, { children: [_jsx(Typography, __assign({ className: styles.addText }, { children: "Add Criteria Group" })), _jsx(IconButton, __assign({ onClick: function () { return handleAddNewCriteriaGroup(); }, className: styles.addGroupButton }, { children: _jsx(AddIcon, {}) }))] })), _jsx(Box, __assign({ className: styles.submitContainer }, { children: _jsx(StyledButton, { id: "submitRequest", label: "Submit", widthFromProps: 160, handleClick: function () { return handleSubmitClick(); }, buttonType: "submit" }) }))] }))] }), _jsx(Box, __assign({ className: styles.resultsContainer }, { children: isLoading ? (_jsx(Box, __assign({ className: styles.loadingContainer }, { children: _jsx(LoadingSpinner, { indicatorSize: 10 }) }))) : (_jsxs(Box, { children: [builtRequest !== '' && (productResults === null || productResults === void 0 ? void 0 : productResults.data) && (_jsxs(_Fragment, { children: [_jsx("hr", {}), _jsxs(Box, __assign({ className: styles.showRequestButtonContainer }, { children: [_jsxs(Box, __assign({ display: "flex", flexDirection: "row", justifyContent: "space-between" }, { children: [_jsxs(Box, __assign({ mb: showRequest ? '4px' : '24px', sx: {
                                                            marginBottom: showRequest ? '4px' : '24px',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            columnGap: '24px',
                                                        }, className: styles.resultsCountContainer }, { children: [_jsx(StyledButton, { handleClick: function () { return setShowRequest(!showRequest); }, label: "Show/Hide Request", widthFromProps: 168 }), _jsx(Typography, { children: productResults.resultsCount
                                                                    ? "Showing Top ".concat(getResultsLabelCount(productResults.resultsCount), " of ").concat((_a = productResults === null || productResults === void 0 ? void 0 : productResults.resultsCount) !== null && _a !== void 0 ? _a : 0).concat((productResults === null || productResults === void 0 ? void 0 : productResults.resultsCount) === 10000 ? '+' : '')
                                                                    : '' })] })), _jsx(SortDropdown, { sortValues: SEARCH_PRODUCT_SORT_VALUES, selectedSort: selectedSort, handleSelect: handleSortSelect })] })), showRequest && _jsx(RequestDisplay, { text: builtRequest })] }))] })), productResults &&
                                ((productResults === null || productResults === void 0 ? void 0 : productResults.data) && productResults.data.length > 0 ? (_jsx(ResultsTable, { data: productResults.data, fieldNames: SEARCH_PRODUCT_REQUEST_FIELDS })) : (_jsx(Typography, { children: "No products were found with the criteria" })))] })) }))] })) })));
}

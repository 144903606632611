var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { StyledButton } from 'components/Shared/StyledComponents/StyledButton';
import { useLanguageCode } from 'services/helperFunctions/useLanguageCode';
import styles from './FeatureRules.module.scss';
export function FeatureRules(_a) {
    var arrayOfDependencyRules = _a.arrayOfDependencyRules, ruleLimit = _a.ruleLimit, productFeaturesMap = _a.productFeaturesMap, handleRemoveRulesFromChild = _a.handleRemoveRulesFromChild, handleAddRulesFromChild = _a.handleAddRulesFromChild;
    var depRulesArray = Object.entries(arrayOfDependencyRules).sort(function (a, b) { return +a[0].split('e')[1] - +b[0].split('e')[1]; });
    var languageCode = useLanguageCode();
    var numberOfFeaturesNotDisplayed = depRulesArray.length - ruleLimit;
    var handleRemoveCircleIcon = function () {
        handleRemoveRulesFromChild();
    };
    var handleAddRulesButtonClick = function () {
        handleAddRulesFromChild();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ className: styles.rulesContainer }, { children: depRulesArray === null || depRulesArray === void 0 ? void 0 : depRulesArray.slice(0, ruleLimit).map(function (rule, i) {
                    var _a, _b;
                    return (_jsxs(Box, { children: [_jsxs(Box, __assign({ className: styles.singleRule }, { children: [_jsx(Typography, __assign({ className: styles.ruleLabel }, { children: _jsx("span", __assign({ className: styles.capitalizeFirstLetter }, { children: "".concat(rule[0].split(/(\d+)/)[0], " ").concat(rule[0].split(/(\d+)/)[1], ":") })) }), "".concat(rule[0])), (_b = (_a = Object.entries(rule[1].dependencies)) === null || _a === void 0 ? void 0 : _a.sort(function (a, b) {
                                        if ((productFeaturesMap === null || productFeaturesMap === void 0 ? void 0 : productFeaturesMap[a[0]].step) && (productFeaturesMap === null || productFeaturesMap === void 0 ? void 0 : productFeaturesMap[b[0]].step)) {
                                            return ((productFeaturesMap === null || productFeaturesMap === void 0 ? void 0 : productFeaturesMap[a[0]].step) -
                                                (productFeaturesMap === null || productFeaturesMap === void 0 ? void 0 : productFeaturesMap[b[0]].step));
                                        }
                                        return rule[1].dependencies;
                                    })) === null || _b === void 0 ? void 0 : _b.map(function (
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    dependency) {
                                        var _a, _b, _c, _d;
                                        return (_jsx(Typography, { children: "Step ".concat((_a = productFeaturesMap === null || productFeaturesMap === void 0 ? void 0 : productFeaturesMap[dependency[0]]) === null || _a === void 0 ? void 0 : _a.step, ": ").concat((_d = (_c = (_b = productFeaturesMap === null || productFeaturesMap === void 0 ? void 0 : productFeaturesMap[dependency[0]]) === null || _b === void 0 ? void 0 : _b.desc) === null || _c === void 0 ? void 0 : _c["".concat(languageCode)]) === null || _d === void 0 ? void 0 : _d.desc, " (").concat(dependency[0], ") - ").concat(dependency[1]) }, "".concat(dependency[0]).concat(dependency[1])));
                                    })] }), "".concat(rule[0])), i < ruleLimit - 1 && i !== depRulesArray.length - 1 && _jsx("hr", {})] }, "".concat(rule[0])));
                }) })), _jsx(Box, __assign({ className: styles.buttonRow }, { children: _jsxs(Box, __assign({ className: styles.buttonBox }, { children: [numberOfFeaturesNotDisplayed && ruleLimit > 5 && (_jsx("span", __assign({ className: styles.buttonSpan }, { children: _jsx(StyledButton, { label: "Show 5 Rules", handleClick: handleRemoveCircleIcon, widthFromProps: 120 }) }))), numberOfFeaturesNotDisplayed > 0 && (_jsx(StyledButton, { handleClick: handleAddRulesButtonClick, label: "Show ".concat(numberOfFeaturesNotDisplayed > 25 ? 25 : numberOfFeaturesNotDisplayed, " More ").concat(numberOfFeaturesNotDisplayed && numberOfFeaturesNotDisplayed > 1 ? 'Rules' : 'Rule'), widthFromProps: 160 }))] })) }))] }));
}

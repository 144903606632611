var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createTheme, hexToRgb } from '@mui/material/styles';
import colors from './materialTheme.module.scss';
export var untitledSansFontFamily = ['UntitledSans', 'sans-serif', 'system-ui'].join(',');
export var untitledSansBoldFontFamily = ['UntitledSansBold', 'UntitledSans', 'sans-serif', 'system-ui'].join(',');
export var untitledSansMediumFontFamily = ['UntitledSansMedium', 'UntitledSans', 'sans-serif', 'system-ui'].join(',');
var typography = {
    p: {
        fontFamily: untitledSansFontFamily,
        fontSize: '14px',
    },
    a: {
        fontFamily: untitledSansFontFamily,
        fontSize: '14px',
    },
    span: {
        fontFamily: untitledSansFontFamily,
        fontSize: '14px',
    },
    h1: {
        fontFamily: untitledSansBoldFontFamily,
        fontSize: '24px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.44',
        letterSpacing: 'normal',
        color: colors.colorFilterPill,
    },
    h2: {
        fontFamily: untitledSansFontFamily,
        fontSize: '18px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.17',
        letterSpacing: 'normal',
        color: colors.colorFilterPill,
    },
    h3: {
        fontFamily: untitledSansFontFamily,
        fontSize: '18px',
        fontWeight: 50,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.49',
        letterSpacing: 'normal',
        color: colors.colorFilterPill,
    },
    h4: {
        fontFamily: untitledSansFontFamily,
        fontSize: '16px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: colors.colorFilterPill,
    },
    h5: {
        fontFamily: untitledSansFontFamily,
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: colors.colorFilterPill,
    },
    caption: {
        fontFamily: untitledSansFontFamily,
        fontSize: '10px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.1',
        letterSpacing: 'normal',
        color: colors.colorFilterPill,
    },
};
export var materialTheme = createTheme({
    typography: __assign({ fontFamily: untitledSansFontFamily }, typography),
    palette: {
        primary: { main: hexToRgb(colors.colorFilterPill) },
        secondary: { main: hexToRgb(colors.colorDark) },
        success: { main: hexToRgb(colors.colorBluegreen) },
        warning: { main: hexToRgb(colors.colorWarningLite) },
        error: { main: hexToRgb(colors.colorWarningStrong) },
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: 'unset',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: __assign(__assign({}, typography), { body: {
                    margin: '0',
                    fontFamily: untitledSansFontFamily,
                    fontSize: '14px',
                }, label: {
                    fontFamily: untitledSansFontFamily,
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    textAlign: 'left',
                    color: colors.colorFilterPill,
                } }),
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    marginTop: 0,
                },
            },
        },
        MuiIcon: {
            styleOverrides: {
                colorSecondary: colors.colorDark,
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    fontFamily: untitledSansFontFamily,
                    fontSize: '12px',
                    color: colors.colorDarker,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontFamily: untitledSansFontFamily,
                    fontSize: '13px',
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    fontFamily: untitledSansFontFamily,
                    fontSize: '13px',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                message: {
                    textAlign: 'center',
                    width: '100%',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: untitledSansFontFamily,
                    fontSize: '13px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fontFamily: untitledSansFontFamily,
                    fontSize: '13px',
                },
            },
        },
    },
});

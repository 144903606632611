import { jsx as _jsx } from "react/jsx-runtime";
import CircularProgress from '@mui/material/CircularProgress';
export function LoadingSpinner(_a) {
    var indicatorSize = _a.indicatorSize, overridePosition = _a.overridePosition;
    return (_jsx(CircularProgress, { size: "".concat(indicatorSize, "em"), sx: {
            position: overridePosition ? 'block' : 'absolute',
            top: "calc(50% - ".concat(indicatorSize / 2, "em)"),
            left: "calc(50% - ".concat(indicatorSize / 2, "em)"),
            transform: 'translate(-50%, -50%)',
            color: 'black',
            zIndex: 999999,
        } }));
}

import { jsx as _jsx } from "react/jsx-runtime";
import { useCpaProduct } from 'components/Cpa/CpaProduct/CpaProductContextProvider';
import { CpaProductMode } from 'components/Cpa/CpaProduct/CpaProductReducer';
import { InputFieldsContainer } from 'components/Shared/InputFieldsContainer/InputFieldsContainer';
import { useGetListPrice } from 'hooks/useGetListPrice';
import { useGetListPriceWithValidate } from 'hooks/useGetListPriceWithValidate';
import { InputFieldType } from 'models/Input/InputModels';
import { useEffect, useState } from 'react';
export function CpaProductHeaderPricing(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var saveInProgress = _a.saveInProgress;
    var cpaProductContext = useCpaProduct();
    var _o = useState(Object.keys((_b = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.pricingTableData) !== null && _b !== void 0 ? _b : {}).length > 0), hasPricingData = _o[0], setHasPricingData = _o[1];
    var _p = useState(JSON.stringify(cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.pricingTableData) !==
        JSON.stringify(cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.pricingTableDataOriginal)), unsavedPricing = _p[0], setUnsavedPricing = _p[1];
    var standardProductPrice = useGetListPrice((_c = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct) === null || _c === void 0 ? void 0 : _c.standardProductReference, cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.cpn, false);
    var calculatedProductPrice = useGetListPriceWithValidate(cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.cpn, cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.cpn, true, (cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.mode) === CpaProductMode.ADD || unsavedPricing || !hasPricingData, saveInProgress, true, 10);
    useEffect(function () {
        if (!standardProductPrice.loading) {
            cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.dispatch({
                type: 'UPDATE_STANDARD_PRODUCT_PRICING',
                payload: standardProductPrice.body,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(_d = standardProductPrice.body) === null || _d === void 0 ? void 0 : _d.totalListPrice, standardProductPrice.loading, standardProductPrice.status]);
    useEffect(function () {
        var _a;
        setHasPricingData(Object.keys((_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.pricingTableData) !== null && _a !== void 0 ? _a : {}).length > 0);
    }, [cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.pricingTableData]);
    useEffect(function () {
        if (!saveInProgress) {
            setUnsavedPricing(JSON.stringify(cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.pricingTableData) !==
                JSON.stringify(cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.pricingTableDataOriginal));
        }
    }, [cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.pricingTableData, cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.pricingTableDataOriginal, saveInProgress]);
    var priceHeaderCols = {
        rowGap: '4px',
        title: { displayText: 'Current Pricing', marginBottom: '16px' },
        showActions: false,
        fields: {
            stdProdPrice: {
                fieldName: 'Standard Product Price:',
                displaySeq: 0,
                input: {
                    validations: {
                        validate: function () {
                            var _a, _b;
                            return ((_a = standardProductPrice.body) === null || _a === void 0 ? void 0 : _a.returnCode) !== 0
                                ? (_b = standardProductPrice.body) === null || _b === void 0 ? void 0 : _b.returnMessage
                                : undefined;
                        },
                    },
                    type: InputFieldType.READONLY,
                },
                hasLookup: false,
            },
            calcProdPrice: {
                fieldName: 'Calculated Product Price:',
                displaySeq: 1,
                input: {
                    validations: {
                        validate: function () {
                            var _a, _b;
                            return ((_a = calculatedProductPrice.body) === null || _a === void 0 ? void 0 : _a.returnCode) !== 0 && hasPricingData
                                ? (_b = calculatedProductPrice.body) === null || _b === void 0 ? void 0 : _b.returnMessage
                                : undefined;
                        },
                    },
                    placeholderText: 'Add Price to see Calculated Product Price',
                    type: InputFieldType.READONLY,
                    informationalText: unsavedPricing ? '(Pending pricing changes)' : undefined,
                },
                hasLookup: false,
            },
        },
    };
    return (_jsx(InputFieldsContainer, { colDef: priceHeaderCols, fieldValues: {
            stdProdPrice: {
                value: ((_e = standardProductPrice.body) === null || _e === void 0 ? void 0 : _e.totalListPrice)
                    ? "$".concat(Number((_f = standardProductPrice.body) === null || _f === void 0 ? void 0 : _f.totalListPrice).toFixed(2))
                    : undefined,
                customErrorMsg: ((_g = standardProductPrice.body) === null || _g === void 0 ? void 0 : _g.returnCode) !== 0
                    ? (_h = standardProductPrice.body) === null || _h === void 0 ? void 0 : _h.returnMessage
                    : undefined,
                loading: standardProductPrice.loading,
            },
            calcProdPrice: {
                value: ((_j = calculatedProductPrice.body) === null || _j === void 0 ? void 0 : _j.totalListPrice) && (hasPricingData || unsavedPricing)
                    ? "$".concat(Number((_k = calculatedProductPrice.body) === null || _k === void 0 ? void 0 : _k.totalListPrice).toFixed(2))
                    : undefined,
                customErrorMsg: ((_l = calculatedProductPrice.body) === null || _l === void 0 ? void 0 : _l.returnCode) !== 0 && hasPricingData
                    ? (_m = calculatedProductPrice.body) === null || _m === void 0 ? void 0 : _m.returnMessage
                    : undefined,
                loading: calculatedProductPrice.loading,
            },
        }, labelFieldNameWidth: "160px", lineHeight: "40px" }));
}

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
var ValidationTableHeaderCellWithStyling = styled.td(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: solid 1px black;\n    font-size: 18px;\n    font-weight: 900;\n    height: 50px;\n    padding-left: 15px;\n"], ["\n    border: solid 1px black;\n    font-size: 18px;\n    font-weight: 900;\n    height: 50px;\n    padding-left: 15px;\n"])));
export function StyledValidationTableHeaderCell(_a) {
    var widthFromProps = _a.widthFromProps, label = _a.label;
    return (_jsx(ValidationTableHeaderCellWithStyling, __assign({ style: { width: "".concat(widthFromProps, "%") } }, { children: label })));
}
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Form, useLoaderData, useLocation, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { router } from 'components/App/App';
import { ProductMaintenanceInputSection } from 'components/EngData/EngDataProduct/ProductMaintenanceInputSection/ProductMaintenanceInputSection';
import { mapProductHeaderToEngineeringHeaderRequest } from 'services/helperFunctions/handleInputFieldBlur';
import { publishDraft, discardDraft, saveDraft } from 'services/api/engineering-service';
import { noSpaces, alphanumericHyphen } from 'services/helperFunctions/handleRegex';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { SaveDraftRequest, } from '@millerknoll/pub-api-schema';
import { StyledButton } from 'components/Shared/StyledComponents/StyledButton';
import { useCheckAuthScope } from 'services/helperFunctions/useCheckAuthScope';
import { useLanguageCode } from 'services/helperFunctions/useLanguageCode';
import { ErrorAdornment } from 'components/Shared/ErrorAdornment/ErrorAdornment';
import { UI_WRITE_SCOPES } from 'utils/constants';
import { FeaturesAndOptionsTable } from '../FeaturesAndOptionsTable/FeaturesAndOptionsTable';
import styles from './EngDataProductHeaderForm.module.scss';
export function EngDataProductHeaderForm() {
    var _this = this;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
    var location = useLocation();
    var languageCode = useLanguageCode();
    var nameValueRef = useRef(null);
    var userHasEditPrivileges = useCheckAuthScope(UI_WRITE_SCOPES);
    var data = useLoaderData();
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var urlParams = useParams();
    var _4 = useState(false), showModal = _4[0], setShowModal = _4[1];
    var _5 = useState(false), priceMatrixError = _5[0], setPriceMatrixError = _5[1];
    var _6 = useState(false), weightClassError = _6[0], setWeightClassError = _6[1];
    var _7 = useState(false), modelCodeError = _7[0], setModelCodeError = _7[1];
    var _8 = useState(false), showFullProductName = _8[0], setShowFullProductName = _8[1];
    var _9 = useState((_a = location.state) === null || _a === void 0 ? void 0 : _a.searchText), searchTerm = _9[0], setSearchTerm = _9[1];
    var status = useState((_c = (_b = data.details) === null || _b === void 0 ? void 0 : _b.metadata) === null || _c === void 0 ? void 0 : _c.status)[0];
    var _10 = useState(status !== SaveDraftRequest.status.PUBLISH_IN_PROGRESS), isEditable = _10[0], setIsEditable = _10[1];
    var _11 = useState(false), inputFieldChange = _11[0], setInputFieldChange = _11[1];
    var _12 = useState(false), discardInProgress = _12[0], setDiscardInProgress = _12[1];
    var _13 = useState(false), publishInProgress = _13[0], setPublishInProgress = _13[1];
    var _14 = useState(false), isProductRoyaltySectionOpen = _14[0], setIsProductRoyaltySectionOpen = _14[1];
    var _15 = useState(), publishDisabled = _15[0], setPublishDisabled = _15[1];
    var _16 = useState(((_g = (_f = (_e = (_d = data.details) === null || _d === void 0 ? void 0 : _d.header) === null || _e === void 0 ? void 0 : _e.royalties) === null || _f === void 0 ? void 0 : _f.length) !== null && _g !== void 0 ? _g : 0) > 0), areThereRoyalties = _16[0], setAreThereRoyalties = _16[1];
    var _17 = useState({
        headerData: (_h = data.details) === null || _h === void 0 ? void 0 : _h.header,
        isChanged: false,
        message: '',
    }), headerData = _17[0], setHeaderData = _17[1];
    var controllerRef = useState(useRef())[0];
    useEffect(function () {
        var _a, _b;
        var productHeaderRoyalties = (_b = (_a = data.details) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.royalties;
        setAreThereRoyalties(productHeaderRoyalties.length > 0);
    }, [(_j = data.details) === null || _j === void 0 ? void 0 : _j.header]);
    var saveHeaderData = function () { return __awaiter(_this, void 0, void 0, function () {
        var controller, response;
        var _a, _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    if (controllerRef.current) {
                        controllerRef.current.abort();
                    }
                    controller = new AbortController();
                    controllerRef.current = controller;
                    return [4 /*yield*/, saveDraft({
                            productId: (_a = urlParams.productId) !== null && _a !== void 0 ? _a : '',
                            productVersion: (_d = (_c = (_b = data.details) === null || _b === void 0 ? void 0 : _b.metadata) === null || _c === void 0 ? void 0 : _c.productVersion) !== null && _d !== void 0 ? _d : (_f = (_e = data.details) === null || _e === void 0 ? void 0 : _e.header) === null || _f === void 0 ? void 0 : _f.productVersion,
                            status: SaveDraftRequest.status.DRAFT,
                            header: mapProductHeaderToEngineeringHeaderRequest(headerData.headerData),
                        }, controllerRef === null || controllerRef === void 0 ? void 0 : controllerRef.current.signal)];
                case 1:
                    response = _h.sent();
                    if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                        setSnackbarAlert({
                            message: (_g = (response === null || response === void 0 ? void 0 : response.body).message) !== null && _g !== void 0 ? _g : '',
                            type: 'error',
                        });
                    }
                    else {
                        setSnackbarAlert({
                            message: headerData.message,
                            type: 'success',
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (headerData.isChanged) {
            saveHeaderData();
            setInputFieldChange(function (prevVal) { return !prevVal; });
            setHeaderData(__assign(__assign({}, headerData), { isChanged: false }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerData]);
    var confirm = useConfirm();
    var publishDraftData = function () { return __awaiter(_this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            if (!data)
                return [2 /*return*/];
            confirm({
                title: "Publish changes for ".concat(urlParams.productId, ", Continue? "),
                cancellationText: 'No',
                confirmationText: 'Yes',
            })
                .then(function () { return __awaiter(_this, void 0, void 0, function () {
                var response;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            setPublishInProgress(function (prevVal) { return !prevVal; });
                            return [4 /*yield*/, publishDraft({ productId: (_a = urlParams.productId) !== null && _a !== void 0 ? _a : '' })];
                        case 1:
                            response = _b.sent();
                            setPublishInProgress(function (prevVal) { return !prevVal; });
                            if (response.status !== 200) {
                                setSnackbarAlert({
                                    message: response.body.message || '',
                                    type: 'error',
                                });
                            }
                            else {
                                setIsEditable(false);
                                setSnackbarAlert({
                                    message: "".concat(urlParams.productId, " Published"),
                                    type: 'success',
                                });
                            }
                            return [2 /*return*/];
                    }
                });
            }); })
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                .catch(function () { });
            return [2 /*return*/];
        });
    }); };
    var discardDraftData = function () { return __awaiter(_this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            if (!data)
                return [2 /*return*/];
            confirm({
                title: "Are you sure you want to discard all the changes you made for the product ".concat(urlParams.productId, "? "),
                cancellationText: 'No',
                confirmationText: 'Yes',
            })
                .then(function () { return __awaiter(_this, void 0, void 0, function () {
                var response;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            setDiscardInProgress(function (prevVal) { return !prevVal; });
                            return [4 /*yield*/, discardDraft({ productId: (_a = urlParams.productId) !== null && _a !== void 0 ? _a : '' })];
                        case 1:
                            response = _b.sent();
                            setDiscardInProgress(function (prevVal) { return !prevVal; });
                            if (response.status !== 200) {
                                setSnackbarAlert({
                                    message: response.body.message || '',
                                    type: 'error',
                                });
                            }
                            else {
                                backToSearchResultsPage();
                                setSnackbarAlert({
                                    message: "Changes to ".concat(urlParams.productId, " Discarded"),
                                    type: 'success',
                                });
                            }
                            return [2 /*return*/];
                    }
                });
            }); })
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                .catch(function () { });
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        function checkNameLength(widthOfNameContainer) {
            if (widthOfNameContainer > 500) {
                setShowModal(true);
            }
        }
        if (nameValueRef.current) {
            checkNameLength(nameValueRef.current.scrollWidth);
        }
    }, []);
    useEffect(function () {
        var _a, _b;
        if (searchTerm) {
            sessionStorage.setItem('searchTerm', searchTerm);
        }
        else {
            var prefix = (_b = (_a = data.details) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.prefix;
            if (prefix) {
                sessionStorage.setItem('searchTerm', prefix);
            }
        }
    }, [(_l = (_k = data.details) === null || _k === void 0 ? void 0 : _k.header) === null || _l === void 0 ? void 0 : _l.prefix, searchTerm]);
    useEffect(function () {
        function checkForSearchTerm() {
            if (searchTerm) {
                setSearchTerm(searchTerm);
            }
            else {
                setSearchTerm(sessionStorage.getItem('searchTerm'));
            }
        }
        checkForSearchTerm();
    }, [location.state, searchTerm]);
    var _18 = useState((_p = (_o = (_m = data.details) === null || _m === void 0 ? void 0 : _m.header) === null || _o === void 0 ? void 0 : _o.priceMatrixId) !== null && _p !== void 0 ? _p : ''), priceMatrixValue = _18[0], setPriceMatrixValue = _18[1];
    var _19 = useState((_s = (_r = (_q = data.details) === null || _q === void 0 ? void 0 : _q.header) === null || _r === void 0 ? void 0 : _r.weightClassCode) !== null && _s !== void 0 ? _s : ''), weightClassCode = _19[0], setWeightClassCode = _19[1];
    var formatStartDate = function (passedDate) {
        var date = passedDate.getUTCDate().toString();
        var month = (passedDate.getUTCMonth() + 1).toString();
        if (month.toString().length === 1) {
            month = "0".concat(month);
        }
        if (date.toString().length === 1) {
            date = "0".concat(date);
        }
        var year = passedDate.getUTCFullYear();
        return "".concat(month, "/").concat(date, "/").concat(year);
    };
    var handlePriceMatrixWeightClassChange = function (newVal, item) {
        switch (item) {
            case 'weightClassCode':
                var weightError = !alphanumericHyphen(newVal, 2);
                setInputFieldChange(weightClassCode !== newVal);
                setWeightClassCode(newVal);
                setWeightClassError(weightError);
                if (weightError) {
                    setSnackbarAlert({
                        message: 'Invalid Weight Class Input. Allowed: A-Z, 0-9, Hyphen' || '',
                        type: 'error',
                    });
                }
                break;
            case 'priceMatrixValue':
                var matrixError = !noSpaces(newVal);
                setInputFieldChange(priceMatrixValue !== newVal);
                setPriceMatrixValue(newVal);
                setPriceMatrixError(matrixError);
                if (matrixError) {
                    setSnackbarAlert({
                        message: 'Invalid Price Matrix Input. No spaces allowed' || '',
                        type: 'error',
                    });
                }
                break;
            default:
                break;
        }
    };
    var handleBlur = function (param1, param2, newValue) { return __awaiter(_this, void 0, void 0, function () {
        var hasError, message, newHeaderData;
        var _a, _b;
        var _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        return __generator(this, function (_r) {
            if (!userHasEditPrivileges || !headerData.headerData || (!inputFieldChange && !param2 && !newValue))
                return [2 /*return*/];
            hasError = false;
            message = '';
            newHeaderData = __assign({}, headerData.headerData);
            switch (param2) {
                case 'code':
                    if (param1 === 'line') {
                        newHeaderData.line = __assign(__assign({}, newHeaderData.line), { code: newValue });
                        message = 'Product Line Code saved';
                    }
                    else {
                        newHeaderData.function = __assign(__assign({}, newHeaderData.function), { code: newValue });
                        message = 'Product Function Code saved';
                    }
                    break;
                case 'description':
                    if (param1 === 'line') {
                        newHeaderData.line = __assign(__assign({}, newHeaderData.line), { code: (_d = (_c = newHeaderData.line) === null || _c === void 0 ? void 0 : _c.code) !== null && _d !== void 0 ? _d : '', description: __assign(__assign({}, newHeaderData.line.description), (_a = {}, _a[languageCode] = { desc: newValue }, _a)) });
                        message = 'Product Line Description saved';
                    }
                    else {
                        newHeaderData.function = __assign(__assign({}, newHeaderData.function), { code: (_f = (_e = newHeaderData.function) === null || _e === void 0 ? void 0 : _e.code) !== null && _f !== void 0 ? _f : '', description: __assign(__assign({}, (_g = newHeaderData.function) === null || _g === void 0 ? void 0 : _g.description), (_b = {}, _b[languageCode] = { desc: newValue }, _b)) });
                        message = 'Product Function Description saved';
                    }
                    break;
                case 'modelCode':
                    if (param1 === 'line') {
                        newHeaderData.line = __assign(__assign({}, newHeaderData.line), { code: (_j = (_h = newHeaderData.line) === null || _h === void 0 ? void 0 : _h.code) !== null && _j !== void 0 ? _j : '', modelCode: newValue });
                        message = 'Product Line Model Code saved';
                    }
                    else {
                        newHeaderData.function = __assign(__assign({}, newHeaderData.function), { code: (_l = (_k = newHeaderData.function) === null || _k === void 0 ? void 0 : _k.code) !== null && _l !== void 0 ? _l : '', modelCode: newValue });
                        message = 'Product Function Model Code saved';
                    }
                    break;
                default:
                    if (param1 === 'discountCode') {
                        newHeaderData.discountCode = newValue;
                        message = 'Discount Code saved';
                        break;
                    }
                    else if (param1 === 'discountDescription') {
                        newHeaderData.discountDesc = newValue;
                        message = 'Discount Description saved';
                        break;
                    }
                    else if (param1 === 'priceMatrixId') {
                        if (priceMatrixValue === ((_o = (_m = data.details) === null || _m === void 0 ? void 0 : _m.header) === null || _o === void 0 ? void 0 : _o.priceMatrixId) ||
                            priceMatrixError ||
                            !noSpaces(priceMatrixValue)) {
                            return [2 /*return*/];
                        }
                        newHeaderData.priceMatrixId = priceMatrixValue.trim();
                        hasError = priceMatrixError;
                        message = "Price Matrix ID saved";
                        break;
                    }
                    else if (param1 === 'weightClassCode') {
                        if (weightClassCode === ((_q = (_p = data.details) === null || _p === void 0 ? void 0 : _p.header) === null || _q === void 0 ? void 0 : _q.weightClassCode) ||
                            weightClassError ||
                            !alphanumericHyphen(weightClassCode, 2)) {
                            return [2 /*return*/];
                        }
                        newHeaderData.weightClassCode = weightClassCode.trim();
                        hasError = weightClassError;
                        message = "Weight Class Code saved";
                        break;
                    }
            }
            if (hasError) {
                return [2 /*return*/];
            }
            setHeaderData({ headerData: newHeaderData, isChanged: true, message: message });
            return [2 /*return*/];
        });
    }); };
    var handleModelCodeError = function (val) {
        setModelCodeError(val);
    };
    var handleBackToResultsButtonClick = function () {
        backToSearchResultsPage();
    };
    var closeHeaderRoyaltySectionFromChild = function () {
        setIsProductRoyaltySectionOpen(false);
    };
    var togglePublish = function (val) {
        setPublishDisabled(!val);
    };
    var handleDisplayAddRoyaltiesButton = function () {
        setIsProductRoyaltySectionOpen(false);
        setAreThereRoyalties(false);
    };
    var backToSearchResultsPage = function () {
        router.navigate("engdatamaint/search/".concat(searchTerm));
    };
    var productName = (_w = (_v = (_u = (_t = data.details) === null || _t === void 0 ? void 0 : _t.header) === null || _u === void 0 ? void 0 : _u.name) === null || _v === void 0 ? void 0 : _v["".concat(languageCode)]) === null || _w === void 0 ? void 0 : _w.desc;
    var formattedStartDate = formatStartDate(new Date((_y = (_x = data.details) === null || _x === void 0 ? void 0 : _x.header) === null || _y === void 0 ? void 0 : _y.startDate));
    var productVersion = (_0 = ((_z = data.details) === null || _z === void 0 ? void 0 : _z.metadata).productVersion) !== null && _0 !== void 0 ? _0 : ((_1 = data.details) === null || _1 === void 0 ? void 0 : _1.header).productVersion;
    return (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ className: styles.productHeaderContainer }, { children: [_jsxs(Box, __assign({ className: styles.productHeaderRow }, { children: [!isEditable && (_jsx("p", __assign({ className: styles.statusText }, { children: "Publish in Progress - Editing is disabled at this time." }))), _jsx(Box, __assign({ sx: { marginLeft: 'auto' } }, { children: _jsx(StyledButton, { label: "Back to Results", handleClick: function () { return handleBackToResultsButtonClick(); }, widthFromProps: 130 }) }))] })), _jsxs(Form, __assign({ method: "post" }, { children: [_jsx(Box, __assign({ className: styles.metadataContainer }, { children: _jsxs(Box, __assign({ className: styles.metadataRow }, { children: [_jsxs(Box, __assign({ className: styles.productPrefixAndStartDateColumn }, { children: [_jsxs(Box, __assign({ className: styles.infoContainer }, { children: [_jsx(Typography, __assign({ className: styles.label }, { children: "Product Prefix:" })), _jsx(Typography, { children: (_3 = (_2 = data.details) === null || _2 === void 0 ? void 0 : _2.header) === null || _3 === void 0 ? void 0 : _3.prefix })] })), _jsxs(Box, __assign({ className: styles.infoContainer }, { children: [_jsx(Typography, __assign({ className: styles.label }, { children: "Start Date:" })), _jsx(Typography, { children: formattedStartDate })] }))] })), _jsxs(Box, __assign({ className: styles.nameAndVersionColumn }, { children: [_jsxs(Box, __assign({ onMouseEnter: function () {
                                                        setShowFullProductName(function (prevVal) { return !prevVal; });
                                                    }, onMouseLeave: function () {
                                                        setShowFullProductName(function (prevVal) { return !prevVal; });
                                                    }, className: styles.infoContainer }, { children: [showFullProductName && showModal && (_jsx("p", __assign({ className: styles.productNameModal }, { children: productName }))), _jsx(Typography, __assign({ className: styles.labelContainer }, { children: "Name:" })), _jsx(Typography, __assign({ className: styles.productNameValue, ref: nameValueRef }, { children: productName }))] })), _jsxs(Box, __assign({ className: styles.infoContainer }, { children: [_jsx(Typography, __assign({ className: styles.labelContainer }, { children: "Version:" })), _jsx(Typography, { children: productVersion })] }))] })), _jsxs(Box, __assign({ className: styles.matrixAndWeightClassColumn }, { children: [_jsxs(Box, __assign({ className: styles.priceMatrixContainer }, { children: [_jsx(Typography, __assign({ className: styles.priceMatrixLabel }, { children: "Price Matrix:" })), _jsx(TextField, { name: "priceMatrixValue", value: priceMatrixValue !== null && priceMatrixValue !== void 0 ? priceMatrixValue : '', size: "small", type: "text", disabled: !userHasEditPrivileges || !isEditable, onChange: function (e) {
                                                                handlePriceMatrixWeightClassChange(e.target.value, 'priceMatrixValue');
                                                            }, onBlur: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                                                return [2 /*return*/, handleBlur('priceMatrixId')];
                                                            }); }); }, InputProps: {
                                                                endAdornment: (_jsx(ErrorAdornment, { errors: [
                                                                        {
                                                                            condition: priceMatrixError,
                                                                            message: 'Invalid Price Matrix Input. No spaces allowed',
                                                                        },
                                                                    ] })),
                                                                sx: {
                                                                    input: { padding: '5px', width: '200px' },
                                                                },
                                                            }, error: priceMatrixError })] })), _jsxs(Box, __assign({ className: styles.priceMatrixContainer }, { children: [_jsx(Typography, __assign({ className: styles.priceMatrixLabel }, { children: "Weight Class:" })), _jsx(TextField, { name: "weightClassCode", value: weightClassCode !== null && weightClassCode !== void 0 ? weightClassCode : '', size: "small", type: "text", disabled: !userHasEditPrivileges || !isEditable, onChange: function (e) {
                                                                var _a;
                                                                if (e.target.value.trim() !== ((_a = weightClassCode.trim()) !== null && _a !== void 0 ? _a : '')) {
                                                                    handlePriceMatrixWeightClassChange(e.target.value, 'weightClassCode');
                                                                }
                                                            }, onBlur: function () { return __awaiter(_this, void 0, void 0, function () {
                                                                var _a, _b, _c, _d;
                                                                return __generator(this, function (_e) {
                                                                    if (weightClassCode.trim() !==
                                                                        ((_d = (_c = (_b = (_a = data.details) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.weightClassCode) === null || _c === void 0 ? void 0 : _c.trim()) !== null && _d !== void 0 ? _d : '')) {
                                                                        handleBlur('weightClassCode');
                                                                    }
                                                                    return [2 /*return*/];
                                                                });
                                                            }); }, error: weightClassError || weightClassCode.length > 2, InputProps: {
                                                                endAdornment: (_jsx(ErrorAdornment, { errors: [
                                                                        {
                                                                            condition: weightClassError || weightClassCode.length > 2,
                                                                            message: 'Invalid Weight Class Input. Allowed: A-Z, 0-9, Hyphen',
                                                                        },
                                                                    ] })),
                                                                sx: {
                                                                    input: {
                                                                        padding: '5px',
                                                                        width: '200px',
                                                                    },
                                                                },
                                                            } })] }))] }))] })) })), _jsx(ProductMaintenanceInputSection, { isProductRoyaltySectionOpen: isProductRoyaltySectionOpen, isEditable: isEditable, data: __assign(__assign({}, data), { details: __assign(__assign({}, data.details), { header: headerData.headerData }) }), modelCodeError: handleModelCodeError, forceCloseHeaderRoyaltySection: closeHeaderRoyaltySectionFromChild, handleTogglePublish: togglePublish, 
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                productId: urlParams.productId, displayAddRoyaltiesButton: function () { return handleDisplayAddRoyaltiesButton(); }, handleBlurInParent: handleBlur }), _jsxs(Box, __assign({ className: styles.buttonContainer }, { children: [userHasEditPrivileges && (_jsx(StyledButton, { label: "Add Royalties", widthFromProps: 150, handleClick: function () { return setIsProductRoyaltySectionOpen(true); }, hideButton: areThereRoyalties || isProductRoyaltySectionOpen || !userHasEditPrivileges })), _jsxs(Box, __assign({ className: styles.publishDiscardButtonContainer, sx: { display: !userHasEditPrivileges ? 'none !important' : 'initial' } }, { children: [_jsx(StyledButton, { handleClick: function () {
                                                    publishDraftData();
                                                }, label: "Publish", loading: publishInProgress, disabledFromProps: !isEditable ||
                                                    priceMatrixError ||
                                                    modelCodeError ||
                                                    weightClassError ||
                                                    publishDisabled }), _jsx(StyledButton, { handleClick: function () {
                                                    discardDraftData();
                                                }, label: "Discard", disabledFromProps: !isEditable, loading: discardInProgress })] }))] }))] }))] })), _jsx("hr", { className: styles.horizontalRule }), _jsx(FeaturesAndOptionsTable, { isEditable: isEditable, togglePublish: togglePublish })] }));
}
export var productHeaderFormAction = function (_a) {
    var request = _a.request;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _c = (_b = Object).fromEntries;
                    return [4 /*yield*/, request.formData()];
                case 1:
                    data = _c.apply(_b, [_d.sent()]);
                    return [2 /*return*/, data];
            }
        });
    });
};

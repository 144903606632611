var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useLanguageCode } from 'services/helperFunctions/useLanguageCode';
import Typography from '@mui/material/Typography';
import styles from './CatalogHierarchies.module.scss';
export function CatalogHierarchies(_a) {
    var _b, _c;
    var infoResponse = _a.infoResponse;
    var languageCode = useLanguageCode();
    var catalogGroups = [];
    var hierarchyList = (_c = (_b = infoResponse.details) === null || _b === void 0 ? void 0 : _b.hierarchy) !== null && _c !== void 0 ? _c : [];
    hierarchyList.forEach(function (hierarchy) {
        if (hierarchy.levels.length === 0)
            return;
        var levels = [];
        hierarchy.levels.forEach(function (level) {
            var _a, _b;
            levels.push((_b = (_a = level === null || level === void 0 ? void 0 : level[languageCode]) === null || _a === void 0 ? void 0 : _a.desc) !== null && _b !== void 0 ? _b : '');
        });
        catalogGroups.push(levels);
    });
    return (_jsxs(Box, __assign({ style: { marginBottom: '20px' } }, { children: [_jsx(Typography, __assign({ className: styles.catalogTitle }, { children: "Catalog Hierarchies" })), catalogGroups.map(function (levelGroup, index) { return (_jsxs(Box, __assign({ className: styles.firstCatalog }, { children: [levelGroup.map(function (levelText, i) { return (_jsxs(Box, __assign({ display: "flex", flexDirection: "row" }, { children: [_jsx(Typography, __assign({ className: index === 0 ? styles.value : styles.otherValue }, { children: levelText })), i !== levelGroup.length - 1 && _jsx(Typography, __assign({ className: styles.arrow }, { children: '>' }))] }), Math.random())); }), index === 0 && (_jsx(Typography, __assign({ className: styles.value, sx: { paddingLeft: 0.5 } }, { children: "(P)" })))] }), Math.random())); })] })));
}

import { SearchProductPropertyVal } from '@millerknoll/pub-api-schema';
export var PRODUCT_VERSION_SEARCH_DEFAULTS = {
    operators: {
        TERM: [
            SearchProductPropertyVal.operator.EQ,
            SearchProductPropertyVal.operator.BEGINS,
            SearchProductPropertyVal.operator.EXISTS,
            SearchProductPropertyVal.operator.NOT_EXISTS,
        ],
        STRING: [
            SearchProductPropertyVal.operator.EQ,
            SearchProductPropertyVal.operator.BEGINS,
            SearchProductPropertyVal.operator.MATCH_ALL,
            SearchProductPropertyVal.operator.MATCH_ANY,
            SearchProductPropertyVal.operator.MATCH_PHRASE,
            SearchProductPropertyVal.operator.WILDCARD,
        ],
        DATE: [
            SearchProductPropertyVal.operator.EQ,
            SearchProductPropertyVal.operator.GT,
            SearchProductPropertyVal.operator.GTE,
            SearchProductPropertyVal.operator.LTE,
            SearchProductPropertyVal.operator.LT,
            SearchProductPropertyVal.operator.NE,
        ],
        NUMBER: [
            SearchProductPropertyVal.operator.EQ,
            SearchProductPropertyVal.operator.GT,
            SearchProductPropertyVal.operator.GTE,
            SearchProductPropertyVal.operator.LTE,
            SearchProductPropertyVal.operator.LT,
            SearchProductPropertyVal.operator.NE,
        ],
        BOOLEAN: [SearchProductPropertyVal.operator.EQ],
    },
};
export var PRODUCT_VERSION_SEARCH_FIELD_TYPE;
(function (PRODUCT_VERSION_SEARCH_FIELD_TYPE) {
    PRODUCT_VERSION_SEARCH_FIELD_TYPE["TERM"] = "TERM";
    PRODUCT_VERSION_SEARCH_FIELD_TYPE["STRING"] = "STRING";
    PRODUCT_VERSION_SEARCH_FIELD_TYPE["BOOLEAN"] = "BOOLEAN";
    PRODUCT_VERSION_SEARCH_FIELD_TYPE["NUMBER"] = "NUMBER";
    PRODUCT_VERSION_SEARCH_FIELD_TYPE["DATE"] = "DATE";
})(PRODUCT_VERSION_SEARCH_FIELD_TYPE || (PRODUCT_VERSION_SEARCH_FIELD_TYPE = {}));
export var OPERATOR_DEFAULT_SELECTION = {
    TERM: SearchProductPropertyVal.operator.BEGINS,
    STRING: SearchProductPropertyVal.operator.MATCH_ANY,
    BOOLEAN: SearchProductPropertyVal.operator.EQ,
    NUMBER: SearchProductPropertyVal.operator.GTE,
    DATE: SearchProductPropertyVal.operator.GTE,
};
export var DISPLAY_NAMES = {
    EXACT: 'Exact',
    BEGINS: 'Begins With',
    MATCH_ALL: 'Match All',
    MATCH_ANY: 'Match Any',
    MATCH_PHRASE: 'Match Phrase',
    EQ: 'Equals',
    GT: 'Greater Than',
    GTE: 'Greater/Equal To',
    LTE: 'Less/Equal To',
    LT: 'Less Than',
    NE: 'Not Equals',
    EXISTS: 'Exists',
    NOT_EXISTS: 'Not Exists',
    WILDCARD: 'Wildcard',
};
/**
 * Only STRING/TERM fields are allowed with multiple TERM Fields
 */
export var ModifyInputType;
(function (ModifyInputType) {
    ModifyInputType[ModifyInputType["PRODUCT_NBR"] = 0] = "PRODUCT_NBR";
})(ModifyInputType || (ModifyInputType = {}));
export var PRODUCT_VERSION_SEARCH_FIELD_MAP = {
    startDate: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.DATE,
        field: {
            term: 'startDate',
        },
        displayName: 'Start Date',
    },
    endDate: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.DATE,
        field: {
            term: 'endDate',
        },
        displayName: 'End Date',
    },
    productNbr: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'baseProducts.baseProductNbr',
        },
        displayName: 'Product Number',
        nestedPath: 'baseProducts',
        modifyInputType: ModifyInputType.PRODUCT_NBR,
    },
    salesBrandCode: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'baseProducts.brandCode',
        },
        displayName: 'Sales Category Brand Code',
        nestedPath: 'baseProducts',
    },
    salesBrandDesc: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'baseProducts.brandDesc',
        },
        displayName: 'Sales Category Brand Description',
        nestedPath: 'baseProducts',
    },
    categoryDesc: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'baseProducts.categoryDesc',
        },
        displayName: 'Sales Category Category Description',
        nestedPath: 'baseProducts',
    },
    categoryCode: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'baseProducts.categoryCode',
        },
        displayName: 'Sales Category Category Code',
        nestedPath: 'baseProducts',
    },
    subCategoryDesc: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'baseProducts.subCategoryDesc',
        },
        displayName: 'Sales Category SubCategory Description',
        nestedPath: 'baseProducts',
    },
    subCategoryCode: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'baseProducts.subCategoryCode',
        },
        displayName: 'Sales Category SubCategory Code',
        nestedPath: 'baseProducts',
    },
    productLineDesc: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'baseProducts.productLineDesc',
        },
        displayName: 'Sales Category Product Line Description',
        nestedPath: 'baseProducts',
    },
    productLineCode: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'baseProducts.productLineCode',
        },
        displayName: 'Sales Category ProductLine Code',
        nestedPath: 'baseProducts',
    },
    typeDesc: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'baseProducts.typeDesc',
        },
        displayName: 'Sales Category Type Description',
        nestedPath: 'baseProducts',
    },
    typeCode: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'baseProducts.typeCode',
        },
        displayName: 'Sales Category Type Code',
        nestedPath: 'baseProducts',
    },
    prefix: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'prefix',
        },
        displayName: 'Product Prefix',
    },
    brandCode: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'brandCode',
        },
        displayName: 'Brand Code',
    },
    productClass: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'productClass',
        },
        displayName: 'Product Class',
    },
    legacyProductId: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'legacyProductId',
        },
        displayName: 'Legacy Product Id',
    },
    productId: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'productId',
        },
        displayName: 'Product ID',
    },
    productName: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: ['productName.desc', 'productName.shortDesc'],
            search: ['productName.desc.searchable', 'productName.shortDesc.searchable'],
        },
        displayName: 'Product Name',
    },
    productType: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'productType',
        },
        displayName: 'Product Type',
    },
    productVersion: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'productVersion',
        },
        displayName: 'Product Version',
    },
    createdBy: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: 'createdBy',
            search: ['createdBy.searchable'],
        },
        displayName: 'Created By',
    },
    descriptions: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: [
                'productName.desc',
                'productName.shortDesc',
                'discountDesc',
                'deviationDesc',
                'channels.desc',
                'royalty.desc',
                'line.desc',
            ],
            search: [
                'productName.desc.searchable',
                'productName.shortDesc.searchable',
                'deviationDesc.searchable',
                'channels.desc.searchable',
                'discountDesc.searchable',
                'line.desc.searchable',
                'royalty.desc.searchable',
            ],
        },
        displayName: 'Descriptions',
    },
    featureDesc: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: ['features.description.desc', 'features.description.shortDesc'],
            search: ['features.description.desc.searchable', 'features.description.shortDesc.searchable'],
        },
        displayName: 'Feature Description',
        nestedPath: 'features',
    },
    featureOptions: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: [
                'features.id',
                'features.description.desc',
                'features.description.shortDesc',
                'features.options.id',
                'features.options.description.desc',
                'features.options.description.shortDesc',
            ],
            search: [
                'features.description.desc.searchable',
                'features.description.shortDesc.searchable',
                'features.options.id.searchable',
                'features.options.description.desc.searchable',
                'features.options.description.shortDesc.searchable',
            ],
        },
        displayName: 'Feature Options',
        nestedPath: 'features',
    },
    featureOptionId: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: 'features.options.id',
            search: 'features.options.id.searchable',
        },
        displayName: 'Option Code',
        nestedPath: 'features',
    },
    featureOptionDesc: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: ['features.options.description.desc', 'features.options.description.shortDesc'],
            search: [
                'features.options.description.desc.searchable',
                'features.options.description.shortDesc.searchable',
            ],
        },
        displayName: 'Option Description',
        nestedPath: 'features',
    },
    featureId: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'features.id',
        },
        displayName: 'Feature ID',
        nestedPath: 'features',
    },
    gsaInd: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN,
        field: {
            term: 'gsaInd',
        },
        displayName: 'Gsa Indicator',
    },
    priceMatrixId: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'priceMatrixId',
        },
        displayName: 'Price Matrix Id',
    },
    discountCode: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: 'discountCode',
            search: 'discountCode.searchable',
        },
        displayName: 'Discount Code',
    },
    productFunctionCode: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'productFunctionCode',
        },
        displayName: 'Product Function Code',
    },
    weightClassCode: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'weightClassCode',
        },
        displayName: 'Weight Class Code',
    },
    assetTypes: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'assetTypes',
        },
        displayName: 'Asset Type',
    },
    discountDesc: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: 'discountDesc',
            search: 'discountDesc.searchable',
        },
        displayName: 'Discount Description',
    },
    lineCode: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'line.code',
        },
        displayName: 'Line Code',
    },
    lineDesc: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: 'line.desc',
            search: 'line.desc.searchable',
        },
        displayName: 'Line Description',
    },
    minLeadtime: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.NUMBER,
        field: {
            term: 'minLeadtime',
        },
        displayName: 'Minimum Leadtime',
    },
    deviationDesc: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: 'deviationDesc',
            search: 'deviationDesc.searchable',
        },
        displayName: 'Deviation Description',
    },
    standardProdRef: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: 'standardProdRef',
            search: 'standardProdRef.searchable',
        },
        displayName: 'Standard Product Reference',
    },
    customRequestor: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: 'customRequestor',
            search: 'customRequestor.searchable',
        },
        displayName: 'Custom Requestor',
    },
    customEngSite: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'customEngSite',
        },
        displayName: 'Custom Engineering Site',
    },
    customEngStatus: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'customEngStatus',
        },
        displayName: 'Custom Engineering Status',
    },
    customProprietaryInd: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN,
        field: {
            term: 'customProprietaryInd',
        },
        displayName: 'Custom Proprietary Indicator',
    },
    customQuoteStatus: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'customQuoteStatus',
        },
        displayName: 'Custom Quote Status',
    },
    featureCreatedBy: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: 'features.createdby',
            search: 'features.createdby.searchable',
        },
        displayName: 'Feature Created By',
        nestedPath: 'features',
    },
    featureUpdatedBy: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: 'features.updatedBy',
            search: 'features.updatedBy.searchable',
        },
        displayName: 'Feature Updated By',
        nestedPath: 'features',
    },
    featureOptionMinLeadTime: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.NUMBER,
        field: {
            term: 'features.options.minLeadtime',
        },
        displayName: 'Option Minimum Leadtime',
        nestedPath: 'features',
    },
    hierarchyAnyLevel: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: [
                'hierarchy.levelOne.desc',
                'hierarchy.levelOne.shortDesc',
                'hierarchy.levelTwo.desc',
                'hierarchy.levelTwo.shortDesc',
                'hierarchy.levelThree.desc',
                'hierarchy.levelThree.shortDesc',
                'hierarchy.levelEight.desc',
                'hierarchy.levelEight.shortDesc',
                'hierarchy.levelFour.desc',
                'hierarchy.levelFour.shortDesc',
                'hierarchy.levelFive.desc',
                'hierarchy.levelFive.shortDesc',
                'hierarchy.levelSix.desc',
                'hierarchy.levelSix.shortDesc',
                'hierarchy.levelSeven.desc',
                'hierarchy.levelSeven.shortDesc',
                'hierarchy.levelNine.desc',
                'hierarchy.levelNine.shortDesc',
                'hierarchy.levelTen.desc',
                'hierarchy.levelTen.shortDesc',
            ],
            search: [
                'hierarchy.levelOne.desc.searchable',
                'hierarchy.levelOne.shortDesc.searchable',
                'hierarchy.levelTwo.desc.searchable',
                'hierarchy.levelTwo.shortDesc.searchable',
                'hierarchy.levelThree.desc.searchable',
                'hierarchy.levelThree.shortDesc.searchable',
                'hierarchy.levelFour.desc.searchable',
                'hierarchy.levelFour.shortDesc.searchable',
                'hierarchy.levelFive.desc.searchable',
                'hierarchy.levelFive.shortDesc.searchable',
                'hierarchy.levelSix.desc.searchable',
                'hierarchy.levelSix.shortDesc.searchable',
                'hierarchy.levelSeven.desc.searchable',
                'hierarchy.levelSeven.shortDesc.searchable',
                'hierarchy.levelEight.desc.searchable',
                'hierarchy.levelEight.shortDesc.searchable',
                'hierarchy.levelNine.desc.searchable',
                'hierarchy.levelNine.shortDesc.searchable',
                'hierarchy.levelTen.desc.searchable',
                'hierarchy.levelTen.shortDesc.searchable',
            ],
        },
        displayName: 'Hierarchy Any Level',
        nestedPath: 'hierarchy',
    },
    hierarchyLevelOne: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: ['hierarchy.levelOne.desc', 'hierarchy.levelOne.shortDesc'],
            search: ['hierarchy.levelOne.desc.searchable', 'hierarchy.levelOne.shortDesc.searchable'],
        },
        displayName: 'Hierarchy Level One',
        nestedPath: 'hierarchy',
    },
    hierarchyLevelTwo: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: ['hierarchy.levelTwo.desc', 'hierarchy.levelTwo.shortDesc'],
            search: ['hierarchy.levelTwo.desc.searchable', 'hierarchy.levelTwo.shortDesc.searchable'],
        },
        displayName: 'Hierarchy Level Two',
        nestedPath: 'hierarchy',
    },
    hierarchyLevelThree: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: ['hierarchy.levelThree.desc', 'hierarchy.levelThree.shortDesc'],
            search: ['hierarchy.levelThree.desc.searchable', 'hierarchy.levelThree.shortDesc.searchable'],
        },
        displayName: 'Hierarchy Level Three',
        nestedPath: 'hierarchy',
    },
    hierarchyLevelFour: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: ['hierarchy.levelFour.desc', 'hierarchy.levelFour.shortDesc'],
            search: ['hierarchy.levelFour.desc.searchable', 'hierarchy.levelFour.shortDesc.searchable'],
        },
        displayName: 'Hierarchy Level Four',
        nestedPath: 'hierarchy',
    },
    hierarchyLevelFive: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: ['hierarchy.levelFive.desc', 'hierarchy.levelFive.shortDesc'],
            search: ['hierarchy.levelFive.desc.searchable', 'hierarchy.levelFive.shortDesc.searchable'],
        },
        displayName: 'Hierarchy Level Five',
        nestedPath: 'hierarchy',
    },
    hierarchyLevelSix: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: ['hierarchy.levelSix.desc', 'hierarchy.levelSix.shortDesc'],
            search: ['hierarchy.levelSix.desc.searchable', 'hierarchy.levelSix.shortDesc.searchable'],
        },
        displayName: 'Hierarchy Level Six',
        nestedPath: 'hierarchy',
    },
    hierarchyLevelSeven: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: ['hierarchy.levelSeven.desc', 'hierarchy.levelSeven.shortDesc'],
            search: ['hierarchy.levelSeven.desc.searchable', 'hierarchy.levelSeven.shortDesc.searchable'],
        },
        displayName: 'Hierarchy Level Seven',
        nestedPath: 'hierarchy',
    },
    hierarchyLevelEight: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: ['hierarchy.levelEight.desc', 'hierarchy.levelEight.shortDesc'],
            search: ['hierarchy.levelEight.desc.searchable', 'hierarchy.levelEight.shortDesc.searchable'],
        },
        displayName: 'Hierarchy Level Eight',
        nestedPath: 'hierarchy',
    },
    hierarchyLevelNine: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: ['hierarchy.levelNine.desc', 'hierarchy.levelNine.shortDesc'],
            search: ['hierarchy.levelNine.desc.searchable', 'hierarchy.levelNine.shortDesc.searchable'],
        },
        displayName: 'Hierarchy Level Nine',
        nestedPath: 'hierarchy',
    },
    hierarchyLevelTen: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: ['hierarchy.levelTen.desc', 'hierarchy.levelTen.shortDesc'],
            search: ['hierarchy.levelTen.desc.searchable', 'hierarchy.levelTen.shortDesc.searchable'],
        },
        displayName: 'Hierarchy Level Ten',
        nestedPath: 'hierarchy',
    },
    hierarchyType: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'hierarchy.hierarchyType',
        },
        displayName: 'Hierarchy Type',
        nestedPath: 'hierarchy',
    },
    hierarchyIsPrimary: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN,
        field: {
            term: 'hierarchy.isPrimary',
        },
        displayName: 'Hierarchy isPrimary',
        nestedPath: 'hierarchy',
    },
    productRestrictions: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'restrictions.restrictionId',
        },
        displayName: 'Product Restriction Id',
    },
    optionRestriction: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'features.options.restriction.restrictionId',
        },
        displayName: 'Option Restriction Id',
        nestedPath: 'features',
    },
    b2bInd: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN,
        field: {
            term: 'b2bInd',
        },
        displayName: 'B2B Indicator',
    },
    channelCode: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'channels.code',
        },
        displayName: 'Channel Code',
    },
    channelDesc: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: 'channels.desc',
            search: 'channels.desc.searchable',
        },
        displayName: 'Channel Description',
    },
    channelM2MInd: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN,
        field: {
            term: 'channels.m2mInd',
        },
        displayName: 'M2M Promotable',
    },
    channelSifPromotable: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN,
        field: {
            term: 'channels.sifPromotable',
        },
        displayName: 'Channel Sif Promotable',
    },
    channelCetPromotable: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN,
        field: {
            term: 'channels.cetPromotable',
        },
        displayName: 'Channel CET Promotable',
    },
    channelPriceBookPromotable: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN,
        field: {
            term: 'channels.priceBookPromotable',
        },
        displayName: 'Channel Price Book Promotable',
    },
    kitParent: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN,
        field: {
            term: 'kitParent',
        },
        displayName: 'Kit Parent',
    },
    prop65: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN,
        field: {
            term: 'prop65',
        },
        displayName: 'Prop65',
    },
    royaltyDesc: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.STRING,
        field: {
            term: 'royalty.desc',
            search: 'royalty.desc.searchable',
        },
        displayName: 'Royalty Description',
    },
    royaltyId: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'royalty.id',
        },
        displayName: 'Royalty Id',
    },
    lineModelCode: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'line.modelCode',
        },
        displayName: 'Line Model Code',
    },
    genericCode: {
        type: PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM,
        field: {
            term: 'genericCode',
        },
        displayName: 'Generic Code',
    },
};
export var DEFAULT_DATE_ROWS = {
    startDate: {
        key: 'startDate',
        value: new Date().toISOString(),
        field: PRODUCT_VERSION_SEARCH_FIELD_MAP.startDate,
        operator: SearchProductPropertyVal.operator.LTE,
    },
    endDate: {
        key: 'endDate',
        value: new Date().toISOString(),
        field: PRODUCT_VERSION_SEARCH_FIELD_MAP.endDate,
        operator: SearchProductPropertyVal.operator.GTE,
    },
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';
import styles from './TableRowDecorator.module.scss';
export function TableRowDecorator(_a) {
    var show = _a.show, margin = _a.margin, value = _a.value;
    return (_jsx(TableCell, __assign({ className: styles.cell, sx: { padding: margin !== null && margin !== void 0 ? margin : '' } }, { children: show && _jsx(Chip, { className: styles.pill, size: "small", label: value }) })));
}

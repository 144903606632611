var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { InputFieldType } from 'models/Input/InputModels';
import { useState } from 'react';
import { InputFieldsContainer } from 'components/Shared/InputFieldsContainer/InputFieldsContainer';
import styles from './ProductCodes.module.scss';
var BUSINESS_UNIT_CODE = 'businessUnitCode';
var BRAND_CODE = 'brandCode';
var PRODUCT_CLASS = 'productClass';
var DISCOUNT_CODE = 'discountCode';
var PRODUCT_LINE_CODE = 'productLineCode';
var PRODUCT_FUNCTION_CODE = 'productFunctionCode';
var WEIGHT_CLASS_CODE = 'weightClassCode';
export function ProductCodes(_a) {
    var _b;
    var infoResponse = _a.infoResponse;
    var fieldValuesCode = useState(buildFieldValuesCodes(infoResponse))[0];
    var colDefCode = {
        rowGap: '2px',
        title: { displayText: 'Product Codes', marginBottom: '16px' },
        showActions: false,
        preventReadOnly: true,
        fields: (_b = {},
            _b[BUSINESS_UNIT_CODE] = {
                fieldName: 'Business Unit (Code)',
                displaySeq: 0,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[BRAND_CODE] = {
                fieldName: 'Brand (Code)',
                displaySeq: 1,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[PRODUCT_CLASS] = {
                fieldName: 'Product Class',
                displaySeq: 2,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[DISCOUNT_CODE] = {
                fieldName: 'Discount (Code)',
                displaySeq: 3,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[PRODUCT_LINE_CODE] = {
                fieldName: 'Product Line (Code)',
                displaySeq: 4,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[PRODUCT_FUNCTION_CODE] = {
                fieldName: 'Product Function (Code)',
                displaySeq: 5,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[WEIGHT_CLASS_CODE] = {
                fieldName: 'Weight Class (Code)',
                displaySeq: 6,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b),
    };
    return (_jsx(Box, __assign({ className: styles.headerRightHalfContainer }, { children: _jsx(InputFieldsContainer, { colDef: colDefCode, fieldValues: fieldValuesCode, labelFieldNameWidth: "190px", topBottomPadding: "2px" }) })));
}
var buildFieldValuesCodes = function (infoResponse) {
    var _a;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6;
    return (_a = {},
        _a[BUSINESS_UNIT_CODE] = {
            value: (_c = (_b = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _b === void 0 ? void 0 : _b.header) === null || _c === void 0 ? void 0 : _c.businessUnitCode,
        },
        _a[BRAND_CODE] = {
            value: (_e = (_d = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _d === void 0 ? void 0 : _d.header) === null || _e === void 0 ? void 0 : _e.brandCode,
        },
        _a[PRODUCT_CLASS] = {
            value: (_g = (_f = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _f === void 0 ? void 0 : _f.header) === null || _g === void 0 ? void 0 : _g.productClass,
        },
        _a[DISCOUNT_CODE] = {
            value: "".concat((_j = (_h = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _h === void 0 ? void 0 : _h.header) === null || _j === void 0 ? void 0 : _j.discountDesc, " (").concat((_l = (_k = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _k === void 0 ? void 0 : _k.header) === null || _l === void 0 ? void 0 : _l.discountCode, ")"),
        },
        _a[PRODUCT_LINE_CODE] = {
            value: "".concat((_s = (_r = (_q = (_p = (_o = (_m = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _m === void 0 ? void 0 : _m.header) === null || _o === void 0 ? void 0 : _o.line) === null || _p === void 0 ? void 0 : _p.description) === null || _q === void 0 ? void 0 : _q['en-US']) === null || _r === void 0 ? void 0 : _r.desc) !== null && _s !== void 0 ? _s : '', " (").concat((_v = (_u = (_t = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _t === void 0 ? void 0 : _t.header) === null || _u === void 0 ? void 0 : _u.line) === null || _v === void 0 ? void 0 : _v.code, ")"),
        },
        _a[PRODUCT_FUNCTION_CODE] = {
            value: "".concat((_1 = (_0 = (_z = (_y = (_x = (_w = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _w === void 0 ? void 0 : _w.header) === null || _x === void 0 ? void 0 : _x.function) === null || _y === void 0 ? void 0 : _y.description) === null || _z === void 0 ? void 0 : _z['en-US']) === null || _0 === void 0 ? void 0 : _0.desc) !== null && _1 !== void 0 ? _1 : '', " (").concat((_4 = (_3 = (_2 = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _2 === void 0 ? void 0 : _2.header) === null || _3 === void 0 ? void 0 : _3.function) === null || _4 === void 0 ? void 0 : _4.code, ")"),
        },
        _a[WEIGHT_CLASS_CODE] = {
            value: (_6 = (_5 = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _5 === void 0 ? void 0 : _5.header) === null || _6 === void 0 ? void 0 : _6.weightClassCode,
        },
        _a);
};

export function tryCopy(text) {
    try {
        // if clipboard is unsupported, it will be handled in catch clause
        // eslint-disable-next-line compat/compat
        navigator.clipboard.writeText(text);
        return 1;
    }
    catch (_a) {
        return -1;
    }
}

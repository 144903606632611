var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
var LimitReachedTextWithStyling = styled(Typography)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    text-align: center;\n    margin: 16px 0 0 0 !important;\n    color: red;\n    font-weight: 900 !important;\n"], ["\n    text-align: center;\n    margin: 16px 0 0 0 !important;\n    color: red;\n    font-weight: 900 !important;\n"])));
export function StyledProductLimitReachedText(_a) {
    var amountRemaining = _a.amountRemaining;
    return (_jsx(LimitReachedTextWithStyling, { children: "Results limited to the first 500 products - ".concat(amountRemaining, " products not included") }));
}
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TableCell from '@mui/material/TableCell';
import { Link } from 'react-router-dom';
import { SOURCE_PREFIX } from 'utils/constants';
import Box from '@mui/material/Box';
import styles from './PageLinksCell.module.scss';
export function PageLinksCell(_a) {
    var productVersion = _a.productVersion, prefix = _a.prefix, hideEngDataLink = _a.hideEngDataLink;
    var getProductId = function (version) {
        if (version.startsWith(SOURCE_PREFIX.KNOLL)) {
            return version.substring(0, version.indexOf('_', SOURCE_PREFIX.KNOLL.length));
        }
        if (version.startsWith(SOURCE_PREFIX.CSP)) {
            return version.substring(0, version.indexOf('_', SOURCE_PREFIX.CSP.length));
        }
        if (version.startsWith(SOURCE_PREFIX.CUSTOM)) {
            return version.substring(0, version.indexOf('_', SOURCE_PREFIX.CUSTOM.length));
        }
        return version;
    };
    var handleClick = function () {
        sessionStorage.setItem('searchTerm', prefix);
    };
    return (_jsxs(TableCell, __assign({ colSpan: 3, className: styles.pageLinkCell }, { children: [productVersion.startsWith(SOURCE_PREFIX.KNOLL) && !hideEngDataLink && (_jsx(Box, __assign({ className: styles.linkContainer }, { children: _jsx(Link, __assign({ target: "_blank", rel: "noopener noreferrer", to: "/engdatamaint/product/".concat(getProductId(productVersion)), className: styles.pageLink, onClick: function () { return handleClick(); }, onAuxClick: function () { return handleClick(); } }, { children: "Eng Data" })) }))), _jsx(Box, __assign({ className: styles.linkContainer }, { children: _jsx(Link, __assign({ target: "_blank", rel: "noopener noreferrer", to: "/product-build?productId=".concat(getProductId(productVersion), "&productNo=").concat(prefix), className: styles.pageLink, onClick: function () { return handleClick(); }, onAuxClick: function () { return handleClick(); } }, { children: "Build" })) })), _jsx(Box, __assign({ className: styles.linkContainer }, { children: _jsx(Link, __assign({ target: "_blank", rel: "noopener noreferrer", to: "/product-info?productVersion=".concat(productVersion), className: styles.pageLink }, { children: "Info" })) }))] })));
}

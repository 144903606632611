var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Box from '@mui/material/Box';
import { useCallback, useEffect, useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Pagination from '@mui/material/Pagination';
import { useSearchProduct } from 'context/searchProduct/SearchProductProvider';
import { searchProductHelper } from 'utils/searchProductHelper';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { SearchSortVal, } from '@millerknoll/pub-api-schema';
import { searchProducts } from 'services/api/search-product-service';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import { SEARCH_PRODUCT_PAGE_SIZE, SEARCH_PRODUCT_SORT_VALUES } from 'utils/constants';
import { SortDropdown } from 'components/Shared/SortDropdown/SortDropdown';
import { InputFieldType } from 'models/Input/InputModels';
import { PubTable } from 'components/Shared/PubTable/PubTable';
import { getDiscountString } from 'components/ProductSearchNew/ProductSearchNewListSearch/ProductSearchNewListSearch';
import { PubInputField } from 'components/Shared/PubInputField/PubInputField';
import { Form, useNavigation } from 'react-router-dom';
import { JsonViewer } from 'components/Shared/JsonViewer/JsonViewer';
import { DateTime } from 'luxon';
import { CriteriaGroup } from './CriteriaGroup/CriteriaGroup';
import { DEFAULT_DATE_ROWS } from '../../utils/search-product-field-mapping';
import styles from './SearchProductV2.module.scss';
export function SearchProductV2() {
    var _this = this;
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(false), isPaginating = _b[0], setIsPaginating = _b[1];
    var navigation = useNavigation();
    var _c = useState(false), isJsonViewerOpen = _c[0], setJsonViewerOpen = _c[1];
    var _d = useState(), rawRequest = _d[0], setRawRequest = _d[1];
    var routeItems = useMemo(function () {
        var now = DateTime.now();
        var d = "".concat(now.year, "-").concat(now.month.toString().length === 1 ? "0".concat(now.month) : now.month, "-").concat(now.day.toString().length === 1 ? "0".concat(now.day) : now.day);
        var t = "".concat(now.hour.toString().length === 1 ? "0".concat(now.hour) : now.hour, ":").concat(now.minute.toString().length === 1 ? "0".concat(now.minute) : now.minute, ":").concat(now.second.toString().length === 1 ? "0".concat(now.second) : now.second);
        return { date: d, time: t };
    }, []);
    var createTableDefinition = function (optionalColumns) {
        var defaultTable = {
            firstColumnIndent: true,
            noDataMessage: 'No Products found',
            columns: [
                {
                    dataAttribute: 'prefix',
                    fieldType: InputFieldType.STRING,
                    heading: 'Product Prefix',
                    width: optionalColumns ? '7%' : '12%',
                    allowCopy: true,
                    index: 0,
                    setOverflowWrap: 'break-word',
                },
                {
                    dataAttribute: 'name',
                    fieldType: InputFieldType.STRING,
                    heading: 'Product Name',
                    width: optionalColumns ? '25%' : '35%',
                    allowCopy: true,
                    index: 1,
                },
                {
                    dataAttribute: 'discountString',
                    fieldType: InputFieldType.STRING,
                    heading: 'Discount',
                    width: optionalColumns ? '20%' : '30%',
                    index: 2,
                },
                {
                    dataAttribute: 'startDate',
                    fieldType: InputFieldType.STRING,
                    heading: 'Start Date',
                    width: optionalColumns ? '13%' : '23%',
                    index: 5,
                },
            ],
            showAddRow: false,
            extraIconIsActionText: true,
            extraIcon: {
                icon: null,
                dateAndTimeStrings: {
                    date: routeItems.date,
                    time: routeItems.time,
                },
            },
        };
        if (optionalColumns) {
            var cols = __spreadArray(__spreadArray(__spreadArray([], defaultTable.columns.slice(0, 3), true), [
                {
                    dataAttribute: 'standardProductRef',
                    fieldType: InputFieldType.STRING,
                    heading: 'Standard Product Ref.',
                    width: '20%',
                    index: 3,
                    setOverflowWrap: 'anywhere',
                },
                {
                    dataAttribute: 'deviations',
                    fieldType: InputFieldType.STRING,
                    heading: 'Deviations',
                    width: '15%',
                    index: 4,
                }
            ], false), defaultTable.columns.slice(3), true);
            defaultTable.columns = cols;
        }
        else if (defaultTable.columns.length > 4) {
            var indexOfVersion = defaultTable.columns.findIndex(function (item) { return item.dataAttribute === 'standardProductRef'; });
            defaultTable.columns.splice(indexOfVersion, 2);
        }
        return defaultTable;
    };
    var _e = useState(createTableDefinition()), tableDefinition = _e[0], setTableDefinition = _e[1];
    var _f = useState(false), areCPAColumnsPresent = _f[0], setAreCPAColumnsPresent = _f[1];
    var _g = useSearchProduct(), conditionGroups = _g.conditionGroups, setConditionGroups = _g.setConditionGroups;
    var _h = useState(), paginationCount = _h[0], setPaginationCount = _h[1];
    var _j = useState(1), selectedPageNo = _j[0], setSelectedPageNo = _j[1];
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var _k = useState([]), criteriaGroupKeys = _k[0], setCriteriaGroupKeys = _k[1];
    var _l = useState(''), builtRequest = _l[0], setBuiltRequest = _l[1];
    var _m = useState(), productResults = _m[0], setProductResults = _m[1];
    var _o = useState("".concat(SEARCH_PRODUCT_SORT_VALUES[0].key, "_").concat(SEARCH_PRODUCT_SORT_VALUES[0].direction)), selectedSort = _o[0], setSelectedSort = _o[1];
    var _p = useState(false), areGroupsCollapsed = _p[0], setAreGroupsCollapsed = _p[1];
    var handleAddNewCriteriaGroup = function () {
        var _a, _b, _c;
        var newGroupKey = crypto.randomUUID();
        setCriteriaGroupKeys(__spreadArray(__spreadArray([], criteriaGroupKeys, true), [newGroupKey], false));
        setConditionGroups((_a = {},
            _a["".concat(DEFAULT_DATE_ROWS.startDate.key)] = (_b = {
                    selectedField: DEFAULT_DATE_ROWS.startDate.key
                },
                _b["".concat(DEFAULT_DATE_ROWS.startDate.key)] = {
                    operator: DEFAULT_DATE_ROWS.startDate.operator,
                    val: DEFAULT_DATE_ROWS.startDate.value,
                },
                _b),
            _a["".concat(DEFAULT_DATE_ROWS.endDate.key)] = (_c = {
                    selectedField: DEFAULT_DATE_ROWS.endDate.key
                },
                _c["".concat(DEFAULT_DATE_ROWS.endDate.key)] = {
                    operator: DEFAULT_DATE_ROWS.endDate.operator,
                    val: DEFAULT_DATE_ROWS.endDate.value,
                },
                _c),
            _a), newGroupKey, 'add');
    };
    var resultsText = useMemo(function () {
        return "Showing ".concat(selectedPageNo * 50 - 49, " - ").concat(selectedPageNo * 50 > (productResults === null || productResults === void 0 ? void 0 : productResults.resultsCount) ? paginationCount : selectedPageNo * 50, " of ").concat(productResults === null || productResults === void 0 ? void 0 : productResults.resultsCount, " results");
    }, [selectedPageNo, paginationCount, productResults]);
    var handleRemoveCriteriaGroup = function (key) {
        if (criteriaGroupKeys.length === 1)
            return;
        var arr = __spreadArray([], criteriaGroupKeys, true);
        arr.splice(arr.findIndex(function (cgKey) { return cgKey === key; }), 1);
        setCriteriaGroupKeys(arr);
        setConditionGroups({}, key, 'remove');
    };
    var formatResponse = function (data) {
        var _a;
        var formattedRsp = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.map(function (item) {
            var _a, _b;
            var splitDate = item.startDate.split('T')[0].split('-');
            var splitTime = item.startDate.split('T')[1].split(':');
            var splitVersion = item.productVersion.split('_');
            var builtId = "".concat(splitVersion[0], "_").concat(splitVersion[1]);
            return {
                prefix: item.prefix,
                name: (_a = item === null || item === void 0 ? void 0 : item.productName) === null || _a === void 0 ? void 0 : _a[0].desc,
                discountString: getDiscountString(item.discountDesc, item.discountCode),
                startDate: "".concat(splitDate[0], "/").concat(splitDate[1], "/").concat(splitDate[2], " @ ").concat(splitTime[0], ":").concat(splitTime[1].replace('Z', '')),
                standardProductRef: item === null || item === void 0 ? void 0 : item.standardProdRef,
                deviations: item === null || item === void 0 ? void 0 : item.deviationDesc,
                version: item.productVersion,
                id: (_b = item.productId) !== null && _b !== void 0 ? _b : builtId,
            };
        });
        var pageCount = Math.ceil((data === null || data === void 0 ? void 0 : data.resultsCount) / 50);
        setPaginationCount(pageCount);
        return {
            data: formattedRsp,
            pageNumber: data.pageNumber,
            pageSize: data === null || data === void 0 ? void 0 : data.pageSize,
            resultsCount: data === null || data === void 0 ? void 0 : data.resultsCount,
        };
    };
    var handleBuildRequest = useCallback(function (field, direction, pageNo) { return __awaiter(_this, void 0, void 0, function () {
        var requestConditions, request, response, rsp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    requestConditions = searchProductHelper.buildSearchProductRequestConditions(conditionGroups);
                    request = {
                        pageNumber: pageNo !== null && pageNo !== void 0 ? pageNo : 1,
                        pageSize: SEARCH_PRODUCT_PAGE_SIZE,
                        fields: [
                            'prefix',
                            'productVersion',
                            'productName',
                            'discountCode',
                            'discountDesc',
                            'startDate',
                            'productId',
                            'productNbr',
                            'standardProdRef',
                            'deviationDesc',
                        ],
                        conditions: requestConditions,
                        sort: field === 'relevance'
                            ? undefined
                            : {
                                field: field,
                                direction: (direction.toUpperCase() in SearchSortVal.direction
                                    ? direction
                                    : 'asc'),
                            },
                    };
                    setBuiltRequest(JSON.stringify(request, null, 2));
                    return [4 /*yield*/, searchProducts(request)];
                case 1:
                    response = _a.sent();
                    if (response.status !== 200) {
                        if (response.status >= 500) {
                            setSnackbarAlert({
                                message: 'Server Error - Please report the error to the PLS support board/help desk with details',
                                type: 'error',
                            });
                        }
                        else {
                            setSnackbarAlert({
                                message: 'Error processing search.',
                                type: 'error',
                            });
                        }
                        setIsLoading(false);
                        return [2 /*return*/];
                    }
                    setRawRequest(request);
                    rsp = formatResponse(response.data);
                    setProductResults(rsp);
                    setAreGroupsCollapsed(true);
                    setIsPaginating(false);
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [conditionGroups, setSnackbarAlert]);
    var handleSortSelect = function (field, direction) {
        var newSelectedSort = "".concat(field, "_").concat(direction);
        setSelectedPageNo(1);
        if (newSelectedSort !== selectedSort) {
            setSelectedSort(newSelectedSort);
            setIsLoading(true);
            handleBuildRequest(field, direction);
        }
    };
    var handleSubmitClick = function () {
        var _a = selectedSort.split('_'), field = _a[0], direction = _a[1];
        setIsLoading(true);
        handleBuildRequest(field, direction, 1);
    };
    var keyPress = useCallback(function (e) {
        if (e.key === 'Enter') {
            var _a = selectedSort.split('_'), field = _a[0], direction = _a[1];
            setIsLoading(true);
            handleBuildRequest(field, direction);
        }
    }, [handleBuildRequest, selectedSort]);
    useEffect(function () {
        document.addEventListener('keyup', keyPress);
        return function () { return document.removeEventListener('keyup', keyPress); };
    }, [keyPress]);
    useEffect(function () {
        handleAddNewCriteriaGroup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var toggleCriteriaGroups = function () {
        setAreGroupsCollapsed(function (prevVal) { return !prevVal; });
    };
    var handlePageChange = function (num) {
        var _a = selectedSort.split('_'), field = _a[0], direction = _a[1];
        setSelectedPageNo(num);
        setIsPaginating(true);
        handleBuildRequest(field, direction, num);
    };
    var handleToggleCpaColumns = function (e) {
        setAreCPAColumnsPresent(function (prevVal) {
            setTableDefinition(createTableDefinition(!prevVal));
            return !prevVal;
        });
    };
    var handleModalClose = function () {
        setJsonViewerOpen(false);
    };
    var advancedSearchComponent = function () {
        if (navigation.state === 'loading') {
            return _jsx(LoadingSpinner, { indicatorSize: 10 });
        }
        return (_jsx(Form, { children: _jsxs(Box, __assign({ className: styles.container }, { children: [_jsxs(Box, __assign({ className: styles.criteriaGroupButtonContainer }, { children: [rawRequest && (_jsx(Typography, __assign({ className: styles.viewJsonString, onClick: function () { return setJsonViewerOpen(true); } }, { children: "View JSON Request" }))), _jsxs(Box, __assign({ onClick: function () { return toggleCriteriaGroups(); }, sx: { display: 'flex' } }, { children: [_jsx(Typography, __assign({ className: styles.label }, { children: "".concat(areGroupsCollapsed ? 'Expand' : 'Collapse', " Criteria Groups") })), areGroupsCollapsed ? _jsx(AddIcon, {}) : _jsx(RemoveIcon, {})] }))] })), _jsx(Box, __assign({ sx: { display: areGroupsCollapsed ? 'none' : 'initial' } }, { children: criteriaGroupKeys.map(function (criteriaGroupKey, i) { return (_jsxs(Box, __assign({ className: styles.criteriaGroupContainer }, { children: [i > 0 && (_jsxs(Box, __assign({ className: styles.moreThanOneDecorator }, { children: [_jsx("hr", { className: styles.hr1 }), _jsx(Typography, { children: " OR " }), _jsx("hr", { className: styles.hr2 })] }))), criteriaGroupKeys.length > 1 && (_jsx(IconButton, __assign({ sx: {
                                        top: i === 0 ? '-20px' : '25px',
                                        background: "conic-gradient(white 50%, ".concat(i % 2 === 0 ? 'white' : 'white', " 0 75%, white 0 25%)"),
                                        position: 'absolute',
                                        left: '21px',
                                        zIndex: 99,
                                    }, onClick: function () { return handleRemoveCriteriaGroup(criteriaGroupKey); } }, { children: _jsx(CloseIcon, { className: styles.close }) }))), _jsx(Box, __assign({ className: styles.wrapper }, { children: _jsx(CriteriaGroup, { groupKey: criteriaGroupKey, isLast: i === criteriaGroupKeys.length - 1, rowKeys: Object.keys(conditionGroups === null || conditionGroups === void 0 ? void 0 : conditionGroups[criteriaGroupKey]), fireAddNewCriteriaGroup: function () {
                                            handleAddNewCriteriaGroup();
                                        }, fireSubmitClickInParent: function () { return handleSubmitClick(); } }, criteriaGroupKey) }))] }), criteriaGroupKey)); }) })), _jsx(Box, { children: isLoading ? (_jsx(LoadingSpinner, { indicatorSize: 10 })) : (_jsxs(Box, __assign({ className: styles.resultsContainer }, { children: [builtRequest !== '' && (productResults === null || productResults === void 0 ? void 0 : productResults.data) && (_jsxs(_Fragment, { children: [_jsx("hr", {}), _jsx(Box, { children: _jsxs(Box, __assign({ className: styles.toggleRow }, { children: [_jsxs(Box, __assign({ className: styles.resultsCountContainer }, { children: [_jsx(Typography, __assign({ className: styles.count }, { children: productResults.resultsCount > 0 && resultsText })), (productResults === null || productResults === void 0 ? void 0 : productResults.resultsCount) > 50 && (_jsx(Pagination, { count: paginationCount, onChange: function (_, num) { return handlePageChange(num); } }))] })), _jsx(Box, { children: _jsx(PubInputField, { fieldType: InputFieldType.SWITCH, fieldValueUpdateHandler: function (e) { return handleToggleCpaColumns(e); }, switchLabel: "Include CPA Columns", switchWidth: 250, defaultChecked: areCPAColumnsPresent }) }), _jsx(SortDropdown, { sortValues: SEARCH_PRODUCT_SORT_VALUES, selectedSort: selectedSort, handleSelect: handleSortSelect })] })) })] })), !isPaginating ? (_jsxs(_Fragment, { children: [' ', productResults &&
                                            ((productResults === null || productResults === void 0 ? void 0 : productResults.data) && productResults.data.length > 0 ? (_jsx(Box, { children: _jsx(PubTable, { def: tableDefinition, tableData: productResults.data, origin: "advanced" }) })) : (_jsx(Typography, __assign({ sx: { textAlign: 'center' } }, { children: "No products were found with the criteria" }))))] })) : (_jsx(LoadingSpinner, { indicatorSize: 10 }))] }))) })] })) }));
    };
    return (_jsxs(_Fragment, { children: [advancedSearchComponent(), isJsonViewerOpen && (_jsx(Box, __assign({ position: "absolute" }, { children: _jsx(JsonViewer, { isOpen: isJsonViewerOpen, closeModal: function () { return handleModalClose(); }, stringifiedJson: JSON.stringify(rawRequest, null, 4) }) })))] }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/EditOutlined';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { LabelWithInput } from 'components/Shared/LabelWithInput/LabelWithInput';
import { useEffect, useState } from 'react';
import { ActionType } from 'models/Input/InputModels';
import styles from './InputFieldsContainer.module.scss';
export function InputFieldsContainer(_a) {
    var _b, _c, _d;
    var fieldValues = _a.fieldValues, colDef = _a.colDef, handleAction = _a.handleAction, labelFieldNameWidth = _a.labelFieldNameWidth, lineHeight = _a.lineHeight, minHeight = _a.minHeight, isCompleteProduct = _a.isCompleteProduct, setFieldValue = _a.setFieldValue, fireActionInParent = _a.fireActionInParent, handleJSONViewerOpen = _a.handleJSONViewerOpen, topBottomPadding = _a.topBottomPadding;
    var _e = useState({}), fieldErrors = _e[0], setFieldErrors = _e[1];
    var _f = useState(false), inEdit = _f[0], setInEdit = _f[1];
    useEffect(function () {
        if (colDef.isEditMode)
            setInEdit(colDef.isEditMode);
    }, [colDef.isEditMode]);
    var actions = inEdit
        ? [
            {
                actionIcon: _jsx(DoneIcon, {}),
                actionType: ActionType.CONFIRM,
                disabled: Object.values(fieldErrors).find(function (el) { return el === true; }),
            },
            {
                actionIcon: _jsx(CloseIcon, {}),
                actionType: ActionType.REVERT,
                disabled: false,
            },
        ]
        : [
            {
                actionIcon: _jsx(EditIcon, {}),
                actionType: ActionType.EDIT,
                disabled: false,
            },
        ];
    var fireLinkAction = function (prop) {
        if (prop === 'kitParent') {
            fireActionInParent === null || fireActionInParent === void 0 ? void 0 : fireActionInParent(prop);
        }
    };
    return (_jsx(Box, __assign({ className: styles.container }, { children: _jsxs(Box, __assign({ className: styles.columnContainer, sx: { rowGap: (_b = colDef.rowGap) !== null && _b !== void 0 ? _b : 'initial' } }, { children: [(colDef.title || colDef.showActions) && (_jsxs(Box, __assign({ className: styles.titleContainer, sx: { marginBottom: (_d = (_c = colDef.title) === null || _c === void 0 ? void 0 : _c.marginBottom) !== null && _d !== void 0 ? _d : 'initial' } }, { children: [colDef.title && (_jsx(Typography, __assign({ className: styles.title }, { children: colDef.title.displayText }))), colDef.hasJSONLink && (_jsx(Box, __assign({ className: styles.dataIcon }, { children: _jsx(Typography, __assign({ sx: { textDecoration: 'underline', cursor: 'pointer', fontSize: '13px' }, onClick: function () {
                                    if (handleJSONViewerOpen)
                                        handleJSONViewerOpen();
                                } }, { children: "View JSON Response" })) }))), colDef.showActions && (_jsx(Box, { children: actions.map(function (action) { return (_jsx(IconButton, __assign({ onClick: function () {
                                    setInEdit(function (prev) { return !prev; });
                                    if (handleAction)
                                        handleAction(action.actionType);
                                }, disabled: action.disabled }, { children: action.actionIcon }), action.actionType)); }) }))] }))), Object.keys(colDef.fields)
                    .sort(function (k1, k2) { return (colDef.fields[k1].displaySeq > colDef.fields[k2].displaySeq ? 1 : -1); })
                    .map(function (property) {
                    var _a, _b, _c;
                    return (_jsx(LabelWithInput, { fieldProperty: property, fieldName: colDef.fields[property].fieldName, fieldType: colDef.fields[property].input.type, fieldOptions: colDef.fields[property].input.allowedFieldValues, loading: (_a = fieldValues[property]) === null || _a === void 0 ? void 0 : _a.loading, hasLookup: colDef.fields[property].hasLookup, validations: colDef.fields[property].input.validations, informationalText: colDef.fields[property].input.informationalText, customErrorMsg: (_b = fieldValues[property]) === null || _b === void 0 ? void 0 : _b.customErrorMsg, fieldValue: (_c = fieldValues[property]) === null || _c === void 0 ? void 0 : _c.value, fieldPlaceholderText: colDef.fields[property].input.placeholderText, fieldNameWidth: labelFieldNameWidth, lineHeight: lineHeight, minHeight: minHeight, topBottomPadding: topBottomPadding, inError: function (error) {
                            var _a;
                            if (fieldErrors[property] !== error)
                                setFieldErrors(__assign(__assign({}, fieldErrors), (_a = {}, _a[property] = error, _a)));
                        }, 
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        updateFieldValue: function (value) {
                            if (setFieldValue)
                                setFieldValue(property, value);
                        }, disabled: !inEdit && colDef.preventReadOnly, link: fieldValues[property].link, linkText: fieldValues[property].linkText, linkAction: function () { return fireLinkAction === null || fireLinkAction === void 0 ? void 0 : fireLinkAction(property); }, isCompleteProduct: isCompleteProduct }, property));
                })] })) })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import { alphanumericHyphen } from 'services/helperFunctions/handleRegex';
import { useCheckAuthScope } from 'services/helperFunctions/useCheckAuthScope';
import { UI_WRITE_SCOPES } from 'utils/constants';
import styles from './ExistingRoyaltyRow.module.scss';
import { ErrorAdornment } from '../ErrorAdornment/ErrorAdornment';
export function ExistingRoyaltyRow(_a) {
    var _b;
    var royalty = _a.royalty, royaltyArray = _a.royaltyArray, isEditable = _a.isEditable, sendRoyaltyToParent = _a.sendRoyaltyToParent, sendRoyaltyIdToParentToDelete = _a.sendRoyaltyIdToParentToDelete, toggleAllowPublish = _a.toggleAllowPublish, idThatIsBeingDeleted = _a.idThatIsBeingDeleted;
    var _c = useState(royalty.royaltyId), royaltyId = _c[0], setRoyaltyId = _c[1];
    var _d = useState((_b = royalty === null || royalty === void 0 ? void 0 : royalty.royaltyDesc) !== null && _b !== void 0 ? _b : ''), royaltyDesc = _d[0], setRoyaltyDesc = _d[1];
    var _e = useState(false), royaltyCodeError = _e[0], setRoyaltyCodeError = _e[1];
    var userHasEditPrivileges = useCheckAuthScope(UI_WRITE_SCOPES);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var _f = useState(royaltyArray
        .filter(function (item) { return item.royaltyId !== royalty.royaltyId; })
        .reduce(function (a, v) {
        var _a;
        return (__assign(__assign({}, a), (_a = {}, _a[v.royaltyId] = v.royaltyId, _a)));
    }, {})), royaltyCodes = _f[0], setRoyaltyCodes = _f[1];
    useEffect(function () {
        setRoyaltyCodes(royaltyArray
            .filter(function (item) { return item.royaltyId !== royalty.royaltyId; })
            .reduce(function (a, v) {
            var _a;
            return (__assign(__assign({}, a), (_a = {}, _a[v.royaltyId] = v.royaltyId, _a)));
        }, {}));
    }, [royaltyArray, royalty.royaltyId]);
    useEffect(function () {
        var id = royalty.royaltyId, desc = royalty.royaltyDesc;
        setRoyaltyId(id);
        setRoyaltyDesc(desc !== null && desc !== void 0 ? desc : '');
        setRoyaltyCodeError(false);
    }, [royalty]);
    var _g = useState(true), isValidCode = _g[0], setIsValidCode = _g[1];
    var handleRoyaltyCodeChange = function (val) {
        setRoyaltyId(val);
        if (royaltyCodes[val] || val.trim() === '') {
            setIsValidCode(false);
            setRoyaltyCodeError(true);
            return false;
        }
        if (alphanumericHyphen(val)) {
            setRoyaltyCodeError(false);
            toggleAllowPublish(true);
            setIsValidCode(true);
            return true;
        }
        setRoyaltyCodeError(true);
        toggleAllowPublish(false);
        setIsValidCode(false);
        return false;
    };
    var handleDeleteRoyaltyButtonClick = function () {
        sendRoyaltyIdToParentToDelete(royalty.royaltyId);
    };
    return (_jsxs(Box, __assign({ className: styles.existingRowContainer }, { children: [_jsx(TextField, { className: styles.textfield, size: "small", type: "text", value: royaltyId, label: "Code", InputProps: {
                    endAdornment: (_jsx(ErrorAdornment, { errors: [
                            {
                                condition: royaltyCodes[royaltyId] !== undefined,
                                message: "Royalty with Royalty Code '".concat(royaltyId !== null && royaltyId !== void 0 ? royaltyId : '', "' already exists"),
                            },
                            {
                                condition: royaltyCodeError,
                                message: 'Royalty Code cannot be empty and must contain only alpha-numeric characters and hyphens with no spaces',
                            },
                        ] })),
                    sx: {
                        input: {
                            width: '100px',
                            padding: '5px',
                        },
                    },
                }, onChange: function (e) { return handleRoyaltyCodeChange(e.target.value); }, onBlur: function () {
                    if (isValidCode && (royalty === null || royalty === void 0 ? void 0 : royalty.royaltyId) !== royaltyId) {
                        sendRoyaltyToParent({ royaltyId: royaltyId, royaltyDesc: royaltyDesc }, royalty);
                    }
                }, error: royaltyCodeError, disabled: !isEditable || !userHasEditPrivileges }), _jsx(TextField, { fullWidth: true, size: "small", type: "text", value: royaltyDesc, label: "Description", className: styles.textfield, inputProps: {
                    sx: {
                        width: '100%',
                        padding: '5px',
                    },
                }, onChange: function (e) {
                    setRoyaltyDesc(e.target.value);
                }, onBlur: function () {
                    if ((royalty === null || royalty === void 0 ? void 0 : royalty.royaltyDesc) !== royaltyDesc) {
                        sendRoyaltyToParent({ royaltyId: royaltyId, royaltyDesc: royaltyDesc }, royalty);
                    }
                }, disabled: !isEditable || !userHasEditPrivileges }), userHasEditPrivileges && isEditable && (_jsx(Box, { children: idThatIsBeingDeleted !== royalty.royaltyId ? (_jsx(IconButton, __assign({ onClick: function () { return handleDeleteRoyaltyButtonClick(); }, className: styles.icon }, { children: _jsx(DeleteIcon, {}) }))) : (_jsx(IconButton, __assign({ className: styles.icon }, { children: _jsx(CircularProgress, { size: "1.5rem" }) }))) }))] })));
}

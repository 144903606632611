var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
var ProductExpireHeaderRow = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n"], ["\n    display: flex;\n    flex-direction: row;\n"])));
export function StyledProductExpireHeaderRow(_a) {
    var label = _a.label, value = _a.value;
    return (_jsxs(ProductExpireHeaderRow, { children: [_jsx(Typography, __assign({ sx: {
                    textDecoration: 'underline',
                    fontWeight: 900,
                    marginRight: '10px',
                    width: '120px',
                    textAlign: 'right',
                } }, { children: label })), _jsx(Typography, { children: value })] }));
}
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { PubTable } from 'components/Shared/PubTable/PubTable';
import { StyledDialogV2 } from 'components/Shared/StyledDialogV2/StyledDialogV2';
import { useGetAudits } from 'hooks/useGetAudits';
import { InputFieldType } from 'models/Input/InputModels';
import { useEffect, useState } from 'react';
import { dateFormatHelper } from 'utils/date-format-helper';
export function PriceHistoryAuditModal() {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = useState({}), tableData = _b[0], setTableData = _b[1];
    var _c = useGetAudits(isOpen), loading = _c.loading, body = _c.body;
    var tableDefinition = {
        dataLoading: loading,
        noDataMessage: 'No Audit Data',
        columns: [
            {
                dataAttribute: 'insertTimestp',
                fieldType: InputFieldType.READONLY,
                heading: 'Insert Timestp',
                width: '120px',
            },
            {
                dataAttribute: 'maintFunc',
                fieldType: InputFieldType.READONLY,
                heading: 'Maint Func',
                width: '80px',
            },
            {
                dataAttribute: 'maintType',
                fieldType: InputFieldType.READONLY,
                heading: 'Maint Type',
                width: '80px',
            },
            {
                dataAttribute: 'auditDesc',
                fieldType: InputFieldType.READONLY,
                heading: 'Audit Desc',
                width: '80px',
            },
            {
                dataAttribute: 'auditComms',
                fieldType: InputFieldType.READONLY,
                heading: 'Audit Comms',
                width: '100px',
            },
            {
                dataAttribute: 'workflow',
                fieldType: InputFieldType.READONLY,
                heading: 'Workflow',
                width: '120px',
            },
            {
                dataAttribute: 'before',
                fieldType: InputFieldType.READONLY,
                heading: 'Before',
                width: '240px',
            },
            {
                dataAttribute: 'after',
                fieldType: InputFieldType.READONLY,
                heading: 'After',
                width: '240px',
            },
            {
                dataAttribute: 'user',
                fieldType: InputFieldType.READONLY,
                heading: 'User',
                width: '160px',
            },
        ],
    };
    useEffect(function () {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        var data = {};
        var sortedBody = body === null || body === void 0 ? void 0 : body.sort(function (a, b) { var _a, _b; return (((_a = a.insertTimestamp) !== null && _a !== void 0 ? _a : '') < ((_b = b.insertTimestamp) !== null && _b !== void 0 ? _b : '') ? 1 : -1); });
        sortedBody === null || sortedBody === void 0 ? void 0 : sortedBody.forEach(function (audit, i) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            data[i] = {
                insertTimestp: dateFormatHelper.formatIsoString((_a = audit.insertTimestamp) !== null && _a !== void 0 ? _a : '', true, true),
                maintFunc: (_b = audit.maintenanceFunction) !== null && _b !== void 0 ? _b : '',
                maintType: (_c = audit.maintenanceType) !== null && _c !== void 0 ? _c : '',
                auditDesc: (_d = audit.auditDesc) !== null && _d !== void 0 ? _d : '',
                auditComms: (_e = audit.auditComment) !== null && _e !== void 0 ? _e : '',
                workflow: (_f = audit.workflow) !== null && _f !== void 0 ? _f : '',
                before: convertJson((_g = audit.beforeValue) !== null && _g !== void 0 ? _g : '').join(', '),
                after: convertJson((_h = audit.afterValue) !== null && _h !== void 0 ? _h : '').join(', '),
                user: (_j = audit.insertId) !== null && _j !== void 0 ? _j : '',
            };
        });
        setTableData(data);
    }, [body]);
    return (_jsx(StyledDialogV2, __assign({ buttonProps: {
            labelText: 'View Audit',
            backgroundColorVariation: 'secondary',
            width: '100px',
        }, sizeProps: { maxWidth: '100%' }, rowGap: "16px", padding: "40px", title: "History Audit", isOpen: isOpen, setIsOpen: setIsOpen }, { children: _jsx(PubTable, { def: tableDefinition, tableData: tableData, alternateRowBackgroundColor: false }) })));
}
var convertJson = function (json) {
    var entries = [];
    try {
        var parsed_1 = JSON.parse(json);
        Object.keys(parsed_1).forEach(function (k) {
            entries.push("".concat(k, ": ").concat(parsed_1[k]));
        });
    }
    catch (error) {
        entries.push(json);
    }
    return entries;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useState } from 'react';
export function SortDropdown(_a) {
    var _b, _c;
    var sortValues = _a.sortValues, selectedSort = _a.selectedSort, handleSelect = _a.handleSelect;
    var _d = useState(selectedSort !== null && selectedSort !== void 0 ? selectedSort : "".concat((_b = sortValues === null || sortValues === void 0 ? void 0 : sortValues[0]) === null || _b === void 0 ? void 0 : _b.key, "_").concat((_c = sortValues === null || sortValues === void 0 ? void 0 : sortValues[0]) === null || _c === void 0 ? void 0 : _c.direction)), selectedOption = _d[0], setSelectedOption = _d[1];
    var handleChange = function (event) {
        setSelectedOption(event.target.value);
        var _a = event.target.value.split('_'), f = _a[0], d = _a[1];
        handleSelect(f, d);
    };
    return (_jsx(Box, { children: _jsx(FormControl, { children: _jsx(Select, __assign({ value: selectedOption, onChange: handleChange, sx: { width: '240px', height: '35px' } }, { children: sortValues === null || sortValues === void 0 ? void 0 : sortValues.map(function (field) { return (_jsx(MenuItem, __assign({ value: "".concat(field.key, "_").concat(field.direction) }, { children: "".concat(field.display, " ").concat(field.key === 'relevance' ? '' : field.directionDisplay) }), "".concat(field.key, "_").concat(field.direction))); }) })) }) }));
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from '@mui/material/utils';
import { validateWithCache } from 'services/api/product-service';
export function useProductValidate(productNbr, fieldName, processingDate, skipValidation) {
    var _this = this;
    if (skipValidation === void 0) { skipValidation = false; }
    var _a = useState(), body = _a[0], setBody = _a[1];
    var _b = useState(-1), status = _b[0], setStatus = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(), errorMessage = _d[0], setErrorMessage = _d[1];
    var controllerRef = useRef();
    var handleAbort = function () {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        var controller = new AbortController();
        controllerRef.current = controller;
    };
    var validateProduct = useMemo(function () {
        return debounce(function (text) { return __awaiter(_this, void 0, void 0, function () {
            var rsp, rspBody;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setLoading(true);
                        if (!text) return [3 /*break*/, 2];
                        return [4 /*yield*/, validateWithCache({
                                productNbr: text,
                                processingDate: processingDate,
                                signal: (_a = controllerRef.current) === null || _a === void 0 ? void 0 : _a.signal,
                            })];
                    case 1:
                        rsp = _b.sent();
                        if (rsp.status !== 200) {
                            setErrorMessage(rsp.body.message || 'UnKnown Error');
                        }
                        else {
                            rspBody = rsp.body;
                            if ((rspBody === null || rspBody === void 0 ? void 0 : rspBody.returnCode) !== '0') {
                                setErrorMessage(rspBody.returnMessage || 'UnKnown Error');
                            }
                            else {
                                setErrorMessage(undefined);
                            }
                        }
                        setStatus(rsp.status);
                        setBody(rsp.body);
                        return [3 /*break*/, 3];
                    case 2:
                        setErrorMessage('Product Number empty');
                        _b.label = 3;
                    case 3:
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); }, 350);
    }, [processingDate]);
    useEffect(function () {
        if (!skipValidation) {
            if (productNbr && productNbr.trim().length > 0) {
                handleAbort();
                validateProduct(productNbr);
            }
            else {
                setErrorMessage("".concat(fieldName, " can't be empty"));
            }
        }
        else {
            setErrorMessage(undefined);
        }
    }, [productNbr, fieldName, validateProduct, skipValidation]);
    return { loading: loading, status: status, body: body, errorMessage: errorMessage };
}

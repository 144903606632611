var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { InputFieldType } from 'models/Input/InputModels';
import { useState } from 'react';
import { InputFieldsContainer } from 'components/Shared/InputFieldsContainer/InputFieldsContainer';
import Typography from '@mui/material/Typography';
import styles from './CustomDetails.module.scss';
var STANDARD_PRODUCT_REFERENCE = 'standardProductReference';
var COMPLEXITY = 'complexity';
var PROPRIETARY_IND = 'proprietaryInd';
var FSC_TYPE = 'fscType';
var FSC_CHAIN_OF_CUSTODY = 'fscChainOfCustody';
var REQUESTOR = 'requestor';
var QUOTE_STATUS = 'quoteStatus';
var ENGINEERING_STATUS = 'engineeringStatus';
var ENGINEERING_SITE = 'engineeringSite';
var DEVIATION_DESCRIPTION = 'deviationDescription';
export function CustomDetails(_a) {
    var _b;
    var infoResponse = _a.infoResponse, version = _a.version, pid = _a.pid, kitParentArray = _a.kitParentArray;
    var fieldValuesCustom = useState(buildFieldValuesCustom(infoResponse))[0];
    var colDefCustom = {
        showActions: false,
        fontSize: '13px',
        preventReadOnly: true,
        fields: (_b = {},
            _b[STANDARD_PRODUCT_REFERENCE] = {
                fieldName: 'Standard Product Reference',
                displaySeq: 0,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[COMPLEXITY] = {
                fieldName: 'Manufacturing Complexity',
                displaySeq: 1,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[PROPRIETARY_IND] = {
                fieldName: 'Proprietary Ind',
                displaySeq: 2,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[FSC_TYPE] = {
                fieldName: 'FSC Types',
                displaySeq: 3,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[FSC_CHAIN_OF_CUSTODY] = {
                fieldName: 'FSC Chain of Custody',
                displaySeq: 4,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[REQUESTOR] = {
                fieldName: 'Requestor',
                displaySeq: 5,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[QUOTE_STATUS] = {
                fieldName: 'Quote Status',
                displaySeq: 6,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[ENGINEERING_STATUS] = {
                fieldName: 'Engineering Status',
                displaySeq: 7,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[ENGINEERING_SITE] = {
                fieldName: 'Engineering Site',
                displaySeq: 8,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[DEVIATION_DESCRIPTION] = {
                fieldName: 'Deviation Description',
                displaySeq: 9,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b),
    };
    return (_jsxs(Box, __assign({ className: styles.mainContainer }, { children: [_jsx(Typography, __assign({ className: styles.headerContainer }, { children: "Custom Details" })), _jsx(Box, __assign({ className: styles.dataContainer }, { children: _jsx(InputFieldsContainer, { colDef: colDefCustom, fieldValues: fieldValuesCustom, labelFieldNameWidth: "240px", lineHeight: "27px" }) }))] })));
}
var buildFieldValuesCustom = function (infoResponse) {
    var _a;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
    return (_a = {},
        _a[STANDARD_PRODUCT_REFERENCE] = {
            value: (_c = (_b = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _b === void 0 ? void 0 : _b.header) === null || _c === void 0 ? void 0 : _c.standardProductReference,
        },
        _a[COMPLEXITY] = {
            value: (_e = (_d = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _d === void 0 ? void 0 : _d.header) === null || _e === void 0 ? void 0 : _e.complexity,
        },
        _a[PROPRIETARY_IND] = {
            value: (_h = (_g = (_f = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _f === void 0 ? void 0 : _f.header) === null || _g === void 0 ? void 0 : _g.custom) === null || _h === void 0 ? void 0 : _h.customProprietaryInd,
        },
        _a[FSC_TYPE] = {
            value: (_k = (_j = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _j === void 0 ? void 0 : _j.header) === null || _k === void 0 ? void 0 : _k.fscType,
        },
        _a[FSC_CHAIN_OF_CUSTODY] = {
            value: (_m = (_l = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _l === void 0 ? void 0 : _l.header) === null || _m === void 0 ? void 0 : _m.fscChainOfCustody,
        },
        _a[REQUESTOR] = {
            value: (_q = (_p = (_o = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _o === void 0 ? void 0 : _o.header) === null || _p === void 0 ? void 0 : _p.custom) === null || _q === void 0 ? void 0 : _q.customRequestor,
        },
        _a[QUOTE_STATUS] = {
            value: (_t = (_s = (_r = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _r === void 0 ? void 0 : _r.header) === null || _s === void 0 ? void 0 : _s.custom) === null || _t === void 0 ? void 0 : _t.customQuoteStatus,
        },
        _a[ENGINEERING_STATUS] = {
            value: (_w = (_v = (_u = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _u === void 0 ? void 0 : _u.header) === null || _v === void 0 ? void 0 : _v.custom) === null || _w === void 0 ? void 0 : _w.customEngStatus,
        },
        _a[ENGINEERING_SITE] = {
            value: (_z = (_y = (_x = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _x === void 0 ? void 0 : _x.header) === null || _y === void 0 ? void 0 : _y.custom) === null || _z === void 0 ? void 0 : _z.customEngSite,
        },
        _a[DEVIATION_DESCRIPTION] = {
            value: (_1 = (_0 = infoResponse === null || infoResponse === void 0 ? void 0 : infoResponse.details) === null || _0 === void 0 ? void 0 : _0.header) === null || _1 === void 0 ? void 0 : _1.deviationDesc,
        },
        _a);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Table from '@mui/material/Table';
import Select from '@mui/material/Select';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import FormControl from '@mui/material/FormControl';
import { StyledValidationTableCell } from 'components/Shared/StyledComponents/StyledValidationTableCell/StyledValidationTableCell';
import { StyledValidationTableHeaderCell } from 'components/Shared/StyledComponents/StyledValidationTableHeaderCell';
import { useContext, useEffect, useRef, useState } from 'react';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import { ProductBuildContext } from 'components/ProductBuild/ProductBuildDetailPage/ProductBuildDetailPage';
import Box from '@mui/material/Box';
import { RangeOptionTextField } from '../RangeOptionTextField/RangeOptionTextField';
import styles from './ValidationFeatureGrid.module.scss';
export function ValidationFeatureGrid(_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var features = _a.features, nextFeature = _a.nextFeature, handleOptionRowClickInParent = _a.handleOptionRowClickInParent, productBuild = _a.productBuild, productValidate = _a.productValidate, selectableFeatureOptions = _a.selectableFeatureOptions, fireNewOptionInParent = _a.fireNewOptionInParent, isProductBuildInError = _a.isProductBuildInError, isAutoBuild = _a.isAutoBuild;
    var nextFeatureRef = useRef(null);
    var productBuildContext = useContext(ProductBuildContext);
    var _j = useState({
        showRangeTextField: false,
    }), rangeOptionData = _j[0], setRangeOptionData = _j[1];
    var clearRangeOptionData = function () {
        setRangeOptionData({
            showRangeTextField: false,
        });
    };
    var handleOptionRowClick = function (option, newFeatureId) {
        if (option === null || option === void 0 ? void 0 : option.range) {
            setRangeOptionData({
                showRangeTextField: true,
                option: option,
            });
        }
        else {
            clearRangeOptionData();
            handleOptionRowClickInParent === null || handleOptionRowClickInParent === void 0 ? void 0 : handleOptionRowClickInParent(option, newFeatureId);
        }
    };
    var handleRangeOptionSubmit = function (option, rangeVal) {
        var _a;
        setRangeOptionData({
            showRangeTextField: false,
        });
        handleOptionRowClickInParent === null || handleOptionRowClickInParent === void 0 ? void 0 : handleOptionRowClickInParent(__assign(__assign({}, option), { productNbr: (_a = option.productNbr) === null || _a === void 0 ? void 0 : _a.replace('{}', rangeVal) }), newFeatureId);
    };
    var _k = useState((_b = nextFeature === null || nextFeature === void 0 ? void 0 : nextFeature.featureId) !== null && _b !== void 0 ? _b : ''), newFeatureId = _k[0], setNewFeatureId = _k[1];
    useEffect(function () {
        var _a, _b, _c;
        if (!nextFeature) {
            clearRangeOptionData();
            return;
        }
        setNewFeatureId((_a = nextFeature === null || nextFeature === void 0 ? void 0 : nextFeature.featureId) !== null && _a !== void 0 ? _a : '');
        (_b = nextFeatureRef === null || nextFeatureRef === void 0 ? void 0 : nextFeatureRef.current) === null || _b === void 0 ? void 0 : _b.scrollIntoView({ behavior: 'smooth', block: 'center' });
        if (!nextFeature.options || nextFeature.options.length > 1) {
            clearRangeOptionData();
            return;
        }
        var nextOption = nextFeature.options[0];
        if ((_c = nextFeature.options[0]) === null || _c === void 0 ? void 0 : _c.range) {
            setRangeOptionData({
                showRangeTextField: true,
                option: nextOption,
            });
        }
        else {
            clearRangeOptionData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextFeature]);
    var fireNewOptionItemClick = function (option, feature) {
        fireNewOptionInParent === null || fireNewOptionInParent === void 0 ? void 0 : fireNewOptionInParent(option, feature);
    };
    return (_jsxs(Table, { children: [_jsx(TableHead, __assign({ className: styles.tableHead }, { children: _jsxs(TableRow, { children: [_jsx(StyledValidationTableHeaderCell, { widthFromProps: 6, label: "Step No." }), _jsx(StyledValidationTableHeaderCell, { widthFromProps: 6, label: "Type" }), _jsx(StyledValidationTableHeaderCell, { widthFromProps: 26, label: "Feature Description" }), _jsx(StyledValidationTableHeaderCell, { widthFromProps: 34, label: "Option Description (Code) - (SKU Selection)" })] }) })), _jsxs(TableBody, { children: [features === null || features === void 0 ? void 0 : features.map(function (feature, i) {
                        var _a, _b, _c, _d, _e;
                        return (_jsxs(TableRow, __assign({ sx: {
                                backgroundColor: i % 2 === 0 ? '' : 'white',
                                '&.MuiTableRow-root:hover': {
                                    backgroundColor: 'rgba(241, 255, 237, 1)',
                                },
                            } }, { children: [_jsx(StyledValidationTableCell, { label: (_a = feature.step) !== null && _a !== void 0 ? _a : '' }), _jsx(StyledValidationTableCell, { label: (_b = feature.type) !== null && _b !== void 0 ? _b : '' }), _jsx(StyledValidationTableCell, { label: (_c = feature.featureDescription) !== null && _c !== void 0 ? _c : '' }), !productBuild ? (_jsx(StyledValidationTableCell, { label: (_d = "".concat(feature.optionDescription, " (").concat(feature.optionCode, ") - (").concat(feature.skuSelection, ")")) !== null && _d !== void 0 ? _d : '' })) : (_jsx(StyledValidationTableCell, { label: (_e = "".concat(feature.optionDescription, " (").concat(feature.optionCode, ") - (").concat(feature.skuSelection, ")")) !== null && _e !== void 0 ? _e : '', productBuild: productBuild && feature.multipleSelection !== '1', handleNewOptionItemClick: function (option) {
                                        fireNewOptionItemClick(option, feature);
                                    }, feature: feature, options: selectableFeatureOptions === null || selectableFeatureOptions === void 0 ? void 0 : selectableFeatureOptions.filter(function (selectableOption) { return (selectableOption === null || selectableOption === void 0 ? void 0 : selectableOption.key) === (feature === null || feature === void 0 ? void 0 : feature.featureId); }) }))] }), "".concat(feature.featureId).concat(feature.optionCode)));
                    }), nextFeature && productBuild && !productBuildContext && !isProductBuildInError ? ( // for the new option selector row
                    _jsxs(TableRow, __assign({ className: styles.newFeatureRow, ref: nextFeatureRef }, { children: [_jsx(StyledValidationTableCell, { label: (_d = (_c = nextFeature === null || nextFeature === void 0 ? void 0 : nextFeature.step) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : '' }), _jsx(StyledValidationTableCell, { label: (_f = (_e = nextFeature === null || nextFeature === void 0 ? void 0 : nextFeature.type) === null || _e === void 0 ? void 0 : _e.toString()) !== null && _f !== void 0 ? _f : '' }), _jsx(StyledValidationTableCell, { label: (_h = (_g = nextFeature === null || nextFeature === void 0 ? void 0 : nextFeature.featureDescription) === null || _g === void 0 ? void 0 : _g.toString()) !== null && _h !== void 0 ? _h : '' }), _jsx(TableCell, __assign({ colSpan: 2, className: styles.newFeatureRowCell }, { children: rangeOptionData.showRangeTextField && rangeOptionData.option ? (_jsx(Box, __assign({ width: "100%", display: "flex" }, { children: _jsx(RangeOptionTextField, { option: rangeOptionData.option, handleRangeOptionSubmit: handleRangeOptionSubmit }) }))) : (_jsx(FormControl, __assign({ fullWidth: true, className: styles.dropdownContainer }, { children: _jsx(Select, __assign({ className: styles.selectComponent, value: "" }, { children: (nextFeature === null || nextFeature === void 0 ? void 0 : nextFeature.options) &&
                                            nextFeature.options.map(function (option, i, arr) {
                                                var _a;
                                                return (_jsxs(Box, { children: [(option === null || option === void 0 ? void 0 : option.range) && !((_a = arr[i - 1]) === null || _a === void 0 ? void 0 : _a.range) && (_jsx("hr", { className: styles.optionSeparator })), _jsx(MenuItem, __assign({ value: option.optionCode, onClick: function () { return handleOptionRowClick(option, newFeatureId); } }, { children: "".concat(option.optionDescription, " (").concat(option.optionCode, " - ").concat(option.skuSelection, ")") }))] }, "".concat(option === null || option === void 0 ? void 0 : option.optionCode, "_").concat(option === null || option === void 0 ? void 0 : option.productNbr)));
                                            }) })) }))) }))] }))) : (_jsx(TableRow, __assign({ sx: {
                            display: productValidate || !nextFeature ? 'none' : '',
                            position: 'absolute',
                            left: '48%',
                            bottom: '20%',
                        } }, { children: !isProductBuildInError && !isAutoBuild && (_jsx(TableCell, { children: _jsx(LoadingSpinner, { indicatorSize: 10 }) })) })))] })] }));
}

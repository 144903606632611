var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import styles from './ErrorAdornment.module.scss';
export function ErrorAdornment(_a) {
    var errors = _a.errors, display = _a.display, fontSize = _a.fontSize, placement = _a.placement, horizontalShiftInPx = _a.horizontalShiftInPx;
    var _b = useState((errors === null || errors === void 0 ? void 0 : errors.filter(function (e) { return e.condition; }).length) > 0), hasErrors = _b[0], setHasErrors = _b[1];
    useEffect(function () {
        setHasErrors((errors === null || errors === void 0 ? void 0 : errors.filter(function (e) { return e.condition; }).length) > 0);
    }, [errors]);
    return (_jsx(Tooltip, __assign({ className: styles.tooltip, sx: {
            svg: {
                display: display ? 'unset' : 'none',
            },
        }, slotProps: {
            tooltip: {
                sx: {
                    fontSize: fontSize !== null && fontSize !== void 0 ? fontSize : '14px',
                    display: hasErrors ? 'inherit' : 'none',
                },
            },
        }, arrow: true, title: _jsx("div", __assign({ style: { whiteSpace: 'pre-line' } }, { children: errors
                .filter(function (e) { return e.condition; })
                .map(function (e) { return e.message; })
                .join('\n\n') })), placement: placement !== null && placement !== void 0 ? placement : 'top' }, { children: _jsx(ErrorOutlineIcon, { sx: {
                color: hasErrors ? 'red' : 'transparent',
                ':hover': { cursor: 'default' },
                position: 'absolute',
                right: "".concat(14 - (horizontalShiftInPx !== null && horizontalShiftInPx !== void 0 ? horizontalShiftInPx : 0), "px"),
            } }) })));
}

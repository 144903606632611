var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { StyledButton } from './StyledButton';
var SearchFieldAndButtonBoxWithStyling = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n    margin: 0 20%;\n    height: 40px;\n"], ["\n    display: flex;\n    flex-direction: row;\n    margin: 0 20%;\n    height: 40px;\n"])));
export function StyledSearchFieldAndButton(_a) {
    var label = _a.label, inputFieldName = _a.inputFieldName, searchValue = _a.searchValue, pageTitle = _a.pageTitle, handleInputValueChange = _a.handleInputValueChange, handleSearchSubmitClick = _a.handleSearchSubmitClick, _b = _a.hideTitle, hideTitle = _b === void 0 ? false : _b;
    return (_jsxs(_Fragment, { children: [!hideTitle && (_jsx(Typography, __assign({ variant: "h1", sx: { textAlign: 'center', margin: '19.92px 0' } }, { children: pageTitle }))), _jsxs(SearchFieldAndButtonBoxWithStyling, { children: [_jsx(TextField, { sx: { width: '88%', height: '40px !important', marginRight: '15px' }, size: "small", label: label, name: inputFieldName, value: searchValue, onChange: function (e) { return handleInputValueChange(e); }, InputProps: {
                            endAdornment: _jsx(SearchIcon, { sx: { color: 'rgba(196, 196, 196, 1)' } }),
                        } }), _jsx(StyledButton, { label: "Submit", handleClick: handleSearchSubmitClick, disabledFromProps: searchValue.length === 0, widthIsPercentage: true, widthFromProps: 10, heightFromProps: 40 })] })] }));
}
var templateObject_1;

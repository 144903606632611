var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { Form, useNavigate } from 'react-router-dom';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import { PubInputField } from 'components/Shared/PubInputField/PubInputField';
import { InputFieldType } from 'models/Input/InputModels';
import { StyledButtonV2 } from 'components/Shared/StyledButtonV2/StyledButtonV2';
import { useState, useMemo, useRef, useEffect } from 'react';
import { getUTCDateData } from 'components/ProductBuild/helper';
import { searchParamsHelper } from 'components/ProductBuildNew/searchParamsHelper';
import { getParsedProduct } from 'services/api/parsed-product-search-service';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { dateFormatHelper } from 'utils/date-format-helper';
import styles from './ParsedProductSearchForm.module.scss';
export function ParsedProductSearchForm() {
    var _this = this;
    var navigate = useNavigate();
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var indicatorSize = 10;
    var searchInputRef = useRef(null);
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(0), totalNumberOfProducts = _b[0], setTotalNumberOfProducts = _b[1];
    var _c = useState(false), searchButtonClicked = _c[0], setSearchButtonClicked = _c[1];
    var _d = useState(searchParamsHelper(true)), searchData = _d[0], setSearchData = _d[1];
    useEffect(function () {
        searchInputRef.current.focus();
    }, [searchInputRef]);
    var handleSearchDataValueChange = function (field, val) {
        var data = __assign({}, searchData);
        data[field] = val;
        if (field === 'date') {
            data.date = getUTCDateData(val, data.displayTime).date;
            data.displayDate = val;
        }
        else if (field === 'time') {
            var _a = getUTCDateData(data.displayDate === ''
                ? dateFormatHelper.todaysDateFormattedLocale({ format: 'yyyy-MM-dd' })
                : data.displayDate, val), date = _a.date, time = _a.time;
            data.time = time;
            data.date = date;
            data.displayTime = val;
        }
        setSearchData(data);
    };
    var disableButton = useMemo(function () {
        var isDisplayDateEmpty = searchData.displayDate === '';
        var isDisplayTimeEmpty = searchData.displayTime === '';
        if (isDisplayDateEmpty && isDisplayTimeEmpty && !searchData.parsedProductId && !searchData.text) {
            return true;
        }
        if (!isDisplayDateEmpty && isDisplayTimeEmpty && !searchData.parsedProductId && !searchData.text) {
            return true;
        }
        if (!isDisplayDateEmpty && !isDisplayTimeEmpty && !searchData.parsedProductId && !searchData.text) {
            return true;
        }
        if (isDisplayDateEmpty && !isDisplayTimeEmpty && !searchData.parsedProductId && !searchData.text) {
            return true;
        }
        if (!isDisplayDateEmpty && isDisplayTimeEmpty && searchData.parsedProductId && searchData.text) {
            return true;
        }
        if (isDisplayDateEmpty && !isDisplayTimeEmpty && searchData.parsedProductId && searchData.text) {
            return true;
        }
        if (!isDisplayDateEmpty && isDisplayTimeEmpty && searchData.parsedProductId && !searchData.text) {
            return true;
        }
        if (!isDisplayDateEmpty && isDisplayTimeEmpty && !searchData.parsedProductId && searchData.text) {
            return true;
        }
        if (isDisplayDateEmpty && !isDisplayTimeEmpty && searchData.parsedProductId && !searchData.text) {
            return true;
        }
        if (isDisplayDateEmpty && !isDisplayTimeEmpty && !searchData.parsedProductId && searchData.text) {
            return true;
        }
        return false;
    }, [searchData]);
    var handleSearchButtonClick = function () { return __awaiter(_this, void 0, void 0, function () {
        var text, date, time, parsedProductId, parsedProductResult, parsedResult, dataToPass;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return __generator(this, function (_j) {
            switch (_j.label) {
                case 0:
                    setIsLoading(true);
                    setSearchButtonClicked(true);
                    text = searchData.text, date = searchData.date, time = searchData.time, parsedProductId = searchData.parsedProductId;
                    return [4 /*yield*/, getParsedProduct(parsedProductId !== null && parsedProductId !== void 0 ? parsedProductId : '', text, date, time, 1)];
                case 1:
                    parsedProductResult = _j.sent();
                    if (parsedProductResult.status >= 500) {
                        setSnackbarAlert({
                            message: 'Server Error - Please report the error to the PLS support board/help desk with details',
                            type: 'error',
                        });
                        setIsLoading(false);
                        return [2 /*return*/, null];
                    }
                    if (parsedProductResult.status === 200 && parsedProductResult.body) {
                        if ((parsedProductResult === null || parsedProductResult === void 0 ? void 0 : parsedProductResult.total) === 0) {
                            setTotalNumberOfProducts(parsedProductResult === null || parsedProductResult === void 0 ? void 0 : parsedProductResult.total);
                            setIsLoading(false);
                        }
                        else if ((parsedProductResult === null || parsedProductResult === void 0 ? void 0 : parsedProductResult.total) === 1) {
                            parsedResult = (_a = parsedProductResult === null || parsedProductResult === void 0 ? void 0 : parsedProductResult.body) === null || _a === void 0 ? void 0 : _a[0];
                            dataToPass = {
                                productName: (_b = parsedResult === null || parsedResult === void 0 ? void 0 : parsedResult.body) === null || _b === void 0 ? void 0 : _b.productName,
                                productNbrOut: (_c = parsedResult === null || parsedResult === void 0 ? void 0 : parsedResult.body) === null || _c === void 0 ? void 0 : _c.productNbrOut,
                                productId: (_d = parsedResult === null || parsedResult === void 0 ? void 0 : parsedResult.body) === null || _d === void 0 ? void 0 : _d.productId,
                                startDate: (_e = parsedResult === null || parsedResult === void 0 ? void 0 : parsedResult.body) === null || _e === void 0 ? void 0 : _e.parsedTimestamp,
                                parsedProductId: (_f = parsedResult === null || parsedResult === void 0 ? void 0 : parsedResult.body) === null || _f === void 0 ? void 0 : _f.parsedProductId,
                                parsedTimestamp: (_g = parsedResult === null || parsedResult === void 0 ? void 0 : parsedResult.body) === null || _g === void 0 ? void 0 : _g.parsedTimestamp,
                            };
                            setTotalNumberOfProducts(parsedProductResult === null || parsedProductResult === void 0 ? void 0 : parsedProductResult.total);
                            sessionStorage.removeItem('buildGateway');
                            return [2 /*return*/, navigate("/parsed-product/details?parsedProductId=".concat(dataToPass.parsedProductId, "&parsedTimestamp=").concat((_h = parsedResult === null || parsedResult === void 0 ? void 0 : parsedResult.body) === null || _h === void 0 ? void 0 : _h.parsedTimestamp), {
                                    state: { item: dataToPass },
                                })];
                        }
                        else {
                            setTotalNumberOfProducts(parsedProductResult === null || parsedProductResult === void 0 ? void 0 : parsedProductResult.total);
                            sessionStorage.setItem('rawMultiData', JSON.stringify(parsedProductResult));
                            navigate("/parsed-product/multi?".concat(createNavigationURL(text, date, time, parsedProductId !== null && parsedProductId !== void 0 ? parsedProductId : '')), {
                                state: { data: parsedProductResult },
                            });
                        }
                    }
                    return [2 /*return*/, null];
            }
        });
    }); };
    return (_jsx(Form, { children: _jsxs(Box, __assign({ className: styles.container }, { children: [_jsx("h1", __assign({ className: styles.title }, { children: "Parsed Product" })), _jsx("p", __assign({ className: styles.caption }, { children: "Parsed Product is associated to an order. Parsed Product ID is a product data identifier to your order. Enter your Parsed Product ID, date / time of order or Partial or Full Product number to see your order's product data." })), _jsx(Box, __assign({ className: styles.formContainer }, { children: _jsxs(Box, { children: [_jsx(PubInputField, { formInputRef: searchInputRef, fieldType: InputFieldType.NUMBER, labels: {
                                    left: 'Parsed Product ID',
                                }, formInputName: "parsedProductId", fieldValue: searchData === null || searchData === void 0 ? void 0 : searchData.parsedProductId, fieldValueUpdateHandler: function (e) { return handleSearchDataValueChange('parsedProductId', e); }, borderFocus: !!searchData.parsedProductId && searchData.parsedProductId !== '', dirtyCheck: true }), _jsxs(Box, __assign({ className: styles.dateAndTimeInputRow, sx: { marginTop: '24px' } }, { children: [_jsxs(Box, __assign({ className: styles.spaceBetweenInputs }, { children: [_jsx(PubInputField, { fieldType: InputFieldType.DATE, labels: {
                                                    left: 'Date',
                                                }, formInputName: "date", fieldValue: searchData === null || searchData === void 0 ? void 0 : searchData.displayDate, fieldValueUpdateHandler: function (e) { return handleSearchDataValueChange('date', e); }, fieldError: !!(searchData === null || searchData === void 0 ? void 0 : searchData.displayTime) && !(searchData === null || searchData === void 0 ? void 0 : searchData.displayDate), borderFocus: !!(searchData === null || searchData === void 0 ? void 0 : searchData.displayDate) }), (searchData === null || searchData === void 0 ? void 0 : searchData.displayTime) && !(searchData === null || searchData === void 0 ? void 0 : searchData.displayDate) && (_jsx(Typography, __assign({ className: styles.errorMessage }, { children: "Date is required " })))] })), _jsxs(Box, __assign({ className: styles.dateTimeFieldWidth }, { children: [_jsx(PubInputField, { fieldType: InputFieldType.TIME, formInputName: "time", labels: {
                                                    left: 'Time',
                                                }, fieldValue: searchData === null || searchData === void 0 ? void 0 : searchData.displayTime, fieldValueUpdateHandler: function (e) { return handleSearchDataValueChange('time', e); }, fieldContainerWidthOverride: "100%", fieldError: !!(searchData === null || searchData === void 0 ? void 0 : searchData.displayDate) && !(searchData === null || searchData === void 0 ? void 0 : searchData.displayTime), borderFocus: !!(searchData === null || searchData === void 0 ? void 0 : searchData.time) }), (searchData === null || searchData === void 0 ? void 0 : searchData.displayDate) && !(searchData === null || searchData === void 0 ? void 0 : searchData.displayTime) && (_jsx(Typography, __assign({ className: styles.errorMessage }, { children: "Time is required" })))] }))] })), _jsx(Box, __assign({ sx: { marginTop: '24px' } }, { children: _jsx(PubInputField, { fieldType: InputFieldType.STRING, labels: {
                                        left: 'Partial or Full Product Number',
                                    }, formInputName: "productNbr", fieldValue: searchData === null || searchData === void 0 ? void 0 : searchData.text, fieldValueUpdateHandler: function (e) { return handleSearchDataValueChange('text', e); }, borderFocus: !!searchData.text && searchData.text !== '', dirtyCheck: true, validations: { noSpaces: 'Please avoide space' } }) })), _jsx(Box, __assign({ sx: { textAlign: 'center', margin: '20px' } }, { children: _jsx(StyledButtonV2, { labelText: "Search", height: "47px", disabled: disableButton, buttonAction: function () { return handleSearchButtonClick(); }, width: "230px" }) }))] }) })), searchButtonClicked && (_jsx(Box, { children: !isLoading ? (_jsx(Box, { children: totalNumberOfProducts && totalNumberOfProducts > 0 ? (_jsxs("p", { children: [totalNumberOfProducts, " Products found"] })) : (_jsx("h4", { children: " No Parsed Results found based on input " })) })) : (_jsx(LoadingSpinner, { indicatorSize: indicatorSize })) }))] })) }));
}
export function createNavigationURL(text, date, time, parsedProductId) {
    var navigateURL = '';
    if (parsedProductId && parsedProductId !== '' && parsedProductId !== null)
        navigateURL = "".concat(navigateURL, "parsedProductId=").concat(parsedProductId !== null && parsedProductId !== void 0 ? parsedProductId : '');
    if (text && text !== '' && text !== null) {
        navigateURL = navigateURL.includes('parsedProductId')
            ? "".concat(navigateURL, "&productNbr=").concat(text !== null && text !== void 0 ? text : '')
            : "".concat(navigateURL, "productNbr=").concat(text !== null && text !== void 0 ? text : '');
    }
    if (date && date !== '' && date !== null)
        navigateURL = "".concat(navigateURL, "&date=").concat(date !== null && date !== void 0 ? date : '');
    if (time && time !== '' && time !== null)
        navigateURL = "".concat(navigateURL, "&time=").concat(time !== null && time !== void 0 ? time : '');
    return navigateURL;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useCpaProduct } from 'components/Cpa/CpaProduct/CpaProductContextProvider';
import { InputFieldsContainer } from 'components/Shared/InputFieldsContainer/InputFieldsContainer';
import { inputFieldsContainerHelper } from 'components/Shared/InputFieldsContainer/InputFieldsContainerHelper';
import { ActionType, InputFieldType } from 'models/Input/InputModels';
import { COMPLEXITY, EXTRA_DATA_FIELD_NAMES } from 'utils/cpa-utils';
import { useLookupSearch } from 'hooks/useLookupSearch';
import { LookupType } from 'services/api/search-service';
export function CpaExtraDataCustomFieldsWorkOrder() {
    var _a;
    var _b, _c;
    var cpaProductContext = useCpaProduct();
    /**
     * Declare state to hold field values of all columns , key as column heading
     */
    var _d = useState(buildFieldValues(undefined)), fieldValues = _d[0], setFieldValues = _d[1];
    var weightClassCodeValidate = useLookupSearch(LookupType.WeightClassCode, (_b = fieldValues[EXTRA_DATA_FIELD_NAMES.WEIGHT_CLASS_CODE]) === null || _b === void 0 ? void 0 : _b.value);
    var productFunctionCodeValidate = useLookupSearch(LookupType.ProductFunctionCode, (_c = fieldValues[EXTRA_DATA_FIELD_NAMES.PRODUCT_FUNCTION_CODE]) === null || _c === void 0 ? void 0 : _c.value);
    /**
     * Holds Column Definition and Initial Values of the Column
     */
    var colDef = {
        title: { displayText: 'Extra Data:' },
        rowGap: '24px',
        showActions: true,
        preventReadOnly: true,
        fields: (_a = {},
            _a[EXTRA_DATA_FIELD_NAMES.LEAD_TIME] = {
                fieldName: 'Lead Time:',
                displaySeq: 0,
                input: {
                    type: InputFieldType.ENUM,
                    allowedFieldValues: ['Assigned'],
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a[EXTRA_DATA_FIELD_NAMES.COMPLEXITY] = {
                fieldName: 'Manufacturing Complexity:',
                displaySeq: 1,
                input: {
                    type: InputFieldType.ENUM,
                    allowedFieldValues: Object.values(COMPLEXITY),
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a[EXTRA_DATA_FIELD_NAMES.WEIGHT_CLASS_CODE] = {
                fieldName: 'Weight Class Code:',
                displaySeq: 2,
                input: {
                    type: InputFieldType.STRING,
                },
                hasTextField: false,
                hasLookup: true,
            },
            _a[EXTRA_DATA_FIELD_NAMES.PRODUCT_FUNCTION_CODE] = {
                fieldName: 'Product Func. Code:',
                displaySeq: 3,
                input: {
                    type: InputFieldType.STRING,
                },
                hasTextField: false,
                hasLookup: true,
            },
            _a),
    };
    useEffect(function () {
        setFieldValues(function (cur) {
            cur[EXTRA_DATA_FIELD_NAMES.WEIGHT_CLASS_CODE].loading = weightClassCodeValidate.loading;
            cur[EXTRA_DATA_FIELD_NAMES.WEIGHT_CLASS_CODE].customErrorMsg = weightClassCodeValidate.errorMessage;
            return __assign({}, cur);
        });
    }, [weightClassCodeValidate.loading, weightClassCodeValidate.errorMessage]);
    useEffect(function () {
        setFieldValues(function (cur) {
            cur[EXTRA_DATA_FIELD_NAMES.PRODUCT_FUNCTION_CODE].loading = productFunctionCodeValidate.loading;
            cur[EXTRA_DATA_FIELD_NAMES.PRODUCT_FUNCTION_CODE].customErrorMsg = productFunctionCodeValidate.errorMessage;
            return __assign({}, cur);
        });
    }, [productFunctionCodeValidate.loading, productFunctionCodeValidate.errorMessage]);
    useEffect(function () {
        var _a;
        if (!((_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _a === void 0 ? void 0 : _a.currentProduct)) {
            return;
        }
        var initialValues = buildFieldValues(cpaProductContext.data.currentProduct);
        setFieldValues(initialValues);
    }, [cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct]);
    var handleAction = function (actionType) {
        var _a;
        if (!((_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _a === void 0 ? void 0 : _a.currentProduct)) {
            return;
        }
        if (actionType === ActionType.CONFIRM) {
            postConfirmationUpdate();
        }
        else if (actionType === ActionType.REVERT) {
            setFieldValues(buildFieldValues(cpaProductContext.data.currentProduct));
        }
    };
    var postConfirmationUpdate = function () {
        updateCurrentProductCustomFields(colDef);
        cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.dispatch({
            type: 'UPDATE_CURRENT_PRODUCT',
            payload: __assign({}, cpaProductContext.data.currentProduct),
        });
    };
    function updateCurrentProductCustomFields(def) {
        if (!cpaProductContext || !cpaProductContext.data.currentProduct) {
            return;
        }
        Object.keys(fieldValues).forEach(function (propertyName) {
            if (cpaProductContext.data.currentProduct) {
                var type = def.fields[propertyName].input.type;
                var currVal = fieldValues[propertyName].value;
                if (type === InputFieldType.STRING || propertyName === EXTRA_DATA_FIELD_NAMES.COMPLEXITY) {
                    cpaProductContext.data.currentProduct[propertyName] = currVal;
                }
                if (propertyName === EXTRA_DATA_FIELD_NAMES.WEIGHT_CLASS_CODE) {
                    cpaProductContext.data.currentProduct[propertyName] = currVal;
                }
                if (propertyName === EXTRA_DATA_FIELD_NAMES.PRODUCT_FUNCTION_CODE) {
                    var _a = currVal.split(' '), code = _a[0], descriptionParts = _a.slice(1);
                    var description = descriptionParts.join(' ');
                    cpaProductContext.data.currentProduct[propertyName] = code;
                    cpaProductContext.data.currentProduct.productFunctionDesc = description;
                }
            }
        });
    }
    return (_jsx(InputFieldsContainer, { colDef: colDef, handleAction: handleAction, fieldValues: fieldValues, setFieldValue: function (property, value) {
            setFieldValues(inputFieldsContainerHelper.getUpdatedFieldValues(fieldValues, property, value));
        } }));
}
var buildFieldValues = function (currentProduct) {
    var _a;
    return (_a = {},
        _a[EXTRA_DATA_FIELD_NAMES.LEAD_TIME] = {
            value: (currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.minLeadtime) ? 'Assigned' : undefined,
        },
        _a[EXTRA_DATA_FIELD_NAMES.COMPLEXITY] = {
            value: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.complexity,
        },
        _a[EXTRA_DATA_FIELD_NAMES.WEIGHT_CLASS_CODE] = {
            value: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.weightClassCode,
        },
        _a[EXTRA_DATA_FIELD_NAMES.PRODUCT_FUNCTION_CODE] = {
            value: currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.productFunctionCode,
        },
        _a);
};

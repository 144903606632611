var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { PriceListDatesRequest } from '@millerknoll/pub-api-schema';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { useEffect, useState } from 'react';
import { priceService } from 'services/api/price-service';
import { cacheHelper } from 'utils/cache-helper';
/**
 * @returns loading state commercial, loading state GSA, date array string -Commercial, date array string -GSA
 */
export function usePriceListDatesWithFilter(isNonContract) {
    var orderReceiptDateValue = '2008-08-04';
    var vendorValue = 'HMI';
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var _a = useState(true), loadingCommercial = _a[0], setLoadingCommercial = _a[1];
    var _b = useState(true), loadingGSA = _b[0], setLoadingGSA = _b[1];
    var _c = useState(), dateListCommercial = _c[0], setDateListCommercial = _c[1];
    var _d = useState(), dateListGSA = _d[0], setDateListGSA = _d[1];
    var currDateISO = new Date().toISOString();
    useEffect(function () {
        (function getStandardProductPrice() {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            return __awaiter(this, void 0, void 0, function () {
                var key, keyGSA, cachedDateList, cachedDateListGSA, dates, datesGSA, priceDateListRspForCommercial, datesValues, priceDateListRspForGSA, datesGSA1;
                return __generator(this, function (_j) {
                    switch (_j.label) {
                        case 0:
                            if (!isNonContract) return [3 /*break*/, 5];
                            setLoadingCommercial(true);
                            setLoadingGSA(true);
                            key = "price_list_dates";
                            keyGSA = "price_list_dates_GSA";
                            cachedDateList = cacheHelper.getCache(key);
                            cachedDateListGSA = cacheHelper.getCache(keyGSA);
                            dates = cachedDateList !== null && cachedDateList !== void 0 ? cachedDateList : [];
                            datesGSA = cachedDateListGSA !== null && cachedDateListGSA !== void 0 ? cachedDateListGSA : [];
                            if (!!cachedDateList) return [3 /*break*/, 2];
                            return [4 /*yield*/, priceService.getPriceDateListWithFilter({
                                    orderReceiptDate: orderReceiptDateValue,
                                    gsaFilter: PriceListDatesRequest.gsaFilter.COMMERCIAL_ONLY,
                                    vendor: vendorValue,
                                    newFilter: PriceListDatesRequest.newFilter.NEW_ONLY,
                                })];
                        case 1:
                            priceDateListRspForCommercial = _j.sent();
                            datesValues = ((_b = (_a = priceDateListRspForCommercial === null || priceDateListRspForCommercial === void 0 ? void 0 : priceDateListRspForCommercial.body) === null || _a === void 0 ? void 0 : _a.priceListDates) !== null && _b !== void 0 ? _b : [])
                                .filter(function (priceDate) { return priceDate.priceListDate !== undefined; })
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                .map(function (priceDate) { return priceDate.priceListDate.split('T')[0]; });
                            dates = __spreadArray([currDateISO.split('T')[0]], datesValues, true).sort().reverse();
                            cacheHelper.setCache(key, dates);
                            if (priceDateListRspForCommercial.status !== 200) {
                                setSnackbarAlert({
                                    message: (_d = (_c = priceDateListRspForCommercial.body) === null || _c === void 0 ? void 0 : _c.returnMessage) !== null && _d !== void 0 ? _d : "Error retrieving price list dates",
                                    type: 'error',
                                });
                            }
                            _j.label = 2;
                        case 2:
                            setDateListCommercial(Array.from(new Set(dates)));
                            setLoadingCommercial(false);
                            if (!!cachedDateListGSA) return [3 /*break*/, 4];
                            return [4 /*yield*/, priceService.getPriceDateListWithFilter({
                                    orderReceiptDate: orderReceiptDateValue,
                                    gsaFilter: PriceListDatesRequest.gsaFilter.GSA_ONLY,
                                    vendor: vendorValue,
                                    newFilter: PriceListDatesRequest.newFilter.NEW_ONLY,
                                })];
                        case 3:
                            priceDateListRspForGSA = _j.sent();
                            datesGSA1 = ((_f = (_e = priceDateListRspForGSA === null || priceDateListRspForGSA === void 0 ? void 0 : priceDateListRspForGSA.body) === null || _e === void 0 ? void 0 : _e.priceListDates) !== null && _f !== void 0 ? _f : [])
                                .filter(function (priceDate) { return priceDate.priceListDate !== undefined; })
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                .map(function (priceDate) { return priceDate.priceListDate.split('T')[0]; });
                            datesGSA = __spreadArray([currDateISO.split('T')[0]], datesGSA1, true).sort().reverse();
                            cacheHelper.setCache(keyGSA, datesGSA);
                            if (priceDateListRspForGSA.status !== 200) {
                                setSnackbarAlert({
                                    message: (_h = (_g = priceDateListRspForGSA.body) === null || _g === void 0 ? void 0 : _g.returnMessage) !== null && _h !== void 0 ? _h : "Error retrieving price list dates with GSA filter",
                                    type: 'error',
                                });
                            }
                            _j.label = 4;
                        case 4:
                            setDateListGSA(Array.from(new Set(datesGSA)));
                            setLoadingGSA(false);
                            _j.label = 5;
                        case 5: return [2 /*return*/];
                    }
                });
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { loadingCommercial: loadingCommercial, loadingGSA: loadingGSA, dateListCommercial: dateListCommercial, dateListGSA: dateListGSA };
}

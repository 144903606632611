var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useMemo, useRef } from 'react';
import { debounce } from '@mui/material/utils';
import { PubInputField } from 'components/Shared/PubInputField/PubInputField';
import { StyledButtonV2 } from 'components/Shared/StyledButtonV2/StyledButtonV2';
import { cpaService } from 'services/api/cpa-service';
import { InputFieldType } from 'models/Input/InputModels';
import { CpaSearchForm } from '../CpaSearchForm/CpaSearchForm';
import styles from '../CpaSearch.module.scss';
export function CpaSearchEditForm(_a) {
    var onSubmit = _a.onSubmit, editInProgress = _a.editInProgress;
    var _b = useState(''), customProductNbr = _b[0], setCustomProductNbr = _b[1];
    var _c = useState(false), apiCallInProgress = _c[0], setApiCallInProgress = _c[1];
    var _d = useState(false), formInError = _d[0], setFormInError = _d[1];
    var controllerRef = useRef();
    var CUSTOM_PRODUCT_NUMBER_REQUIRED = 'Custom Product Number Must Be Entered';
    var handleAbort = function () {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        var controller = new AbortController();
        controllerRef.current = controller;
    };
    var _e = useState(CUSTOM_PRODUCT_NUMBER_REQUIRED), customError = _e[0], setCustomError = _e[1];
    var handlecustomProductNbrDebounced = useMemo(function () {
        return debounce(function (text) {
            var _a;
            handleAbort();
            if (text && text.trim().length > 0) {
                setApiCallInProgress(true);
                cpaService
                    .getProduct(text, true, (_a = controllerRef.current) === null || _a === void 0 ? void 0 : _a.signal)
                    .then(function (rsp) {
                    if (rsp.status !== 200) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        setCustomError(rsp.body.message);
                    }
                    else {
                        setCustomError(undefined);
                    }
                    setApiCallInProgress(false);
                })
                    .catch(function () {
                    setApiCallInProgress(false);
                });
            }
            else {
                setCustomError(CUSTOM_PRODUCT_NUMBER_REQUIRED);
            }
        }, 350);
    }, []);
    var handleEdit = function () {
        onSubmit(customProductNbr);
    };
    return (_jsxs(CpaSearchForm, { children: [_jsx(PubInputField, { fieldType: InputFieldType.STRING, validations: {
                    required: CUSTOM_PRODUCT_NUMBER_REQUIRED,
                }, customErrorMsg: customError, inProgress: apiCallInProgress, helperText: "Enter an existing Custom Product Number you would like to view or edit.", fieldValue: customProductNbr, labels: {
                    left: 'Custom Product Number',
                    right: 'Required',
                }, inError: function (val) {
                    if (formInError !== val) {
                        setFormInError(val);
                    }
                }, dirtyCheck: true, uppercaseInput: true, fieldValueUpdateHandler: function (val) {
                    var trimmedVal = val.trim();
                    setCustomProductNbr(trimmedVal);
                    handlecustomProductNbrDebounced(trimmedVal);
                } }), _jsx("div", __assign({ className: styles.centeredButton }, { children: _jsx(StyledButtonV2, { labelText: "Edit Custom Product", disabled: formInError || editInProgress || !!customError, buttonAction: handleEdit }) }))] }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useSearchProduct } from 'context/searchProduct/SearchProductProvider';
import { SearchProductCondition } from '@millerknoll/pub-api-schema';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { DEFAULT_DATE_ROWS, OPERATOR_DEFAULT_SELECTION, PRODUCT_VERSION_SEARCH_FIELD_MAP, PRODUCT_VERSION_SEARCH_FIELD_TYPE, } from '../../../utils/search-product-field-mapping';
import { InputField } from '../InputField/InputField';
import styles from './InputFieldRow.module.scss';
export function InputFieldRow(_a) {
    var _b, _c, _d, _e;
    var groupKey = _a.groupKey, fieldRowKey = _a.fieldRowKey, boldFilledRows = _a.boldFilledRows;
    var _f = useSearchProduct(), conditionGroups = _f.conditionGroups, setConditionGroups = _f.setConditionGroups;
    var _g = useState(), currField = _g[0], setCurrField = _g[1];
    var _h = useState(SearchProductCondition.logicalOperator.OR), logicalOperator = _h[0], setLogicalOperator = _h[1];
    var _j = useState([]), inputFieldKeys = _j[0], setInputFieldKeys = _j[1];
    var _k = useState(false), isHover = _k[0], setIsHover = _k[1];
    var fieldNames = useState(Object.entries(PRODUCT_VERSION_SEARCH_FIELD_MAP).map(function (fieldName) { return fieldName[0]; }))[0];
    var _l = useState(false), disableLogicals = _l[0], setDisableLogicals = _l[1];
    var handleFieldSelect = function (event) {
        var _a, _b;
        var _c, _d, _e, _f, _g, _h;
        var fieldKey = event.target.value;
        var selectedFieldData = PRODUCT_VERSION_SEARCH_FIELD_MAP === null || PRODUCT_VERSION_SEARCH_FIELD_MAP === void 0 ? void 0 : PRODUCT_VERSION_SEARCH_FIELD_MAP["".concat(fieldKey)];
        setCurrField({
            fieldKey: fieldKey,
            fieldData: selectedFieldData,
            isFieldSelected: true,
        });
        setDisableLogicals((selectedFieldData === null || selectedFieldData === void 0 ? void 0 : selectedFieldData.type) === PRODUCT_VERSION_SEARCH_FIELD_TYPE.TERM);
        var parentConditionGroup = conditionGroups === null || conditionGroups === void 0 ? void 0 : conditionGroups[groupKey];
        var isBooleanFieldType = (selectedFieldData === null || selectedFieldData === void 0 ? void 0 : selectedFieldData.type) === PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN;
        if (isBooleanFieldType) {
            setInputFieldKeys([inputFieldKeys[0]]);
            var prevFieldWasBoolean = ((_c = parentConditionGroup[fieldRowKey]) === null || _c === void 0 ? void 0 : _c.selectedField) &&
                ((_d = PRODUCT_VERSION_SEARCH_FIELD_MAP["".concat(parentConditionGroup[fieldRowKey].selectedField)]) === null || _d === void 0 ? void 0 : _d.type) === PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN;
            parentConditionGroup[fieldRowKey] = (_a = {
                    logicalOperator: undefined
                },
                _a[inputFieldKeys[0]] = {
                    val: prevFieldWasBoolean ? (_f = (_e = parentConditionGroup[fieldRowKey]) === null || _e === void 0 ? void 0 : _e[inputFieldKeys[0]]) === null || _f === void 0 ? void 0 : _f.val : undefined,
                    operator: undefined,
                },
                _a);
        }
        else {
            inputFieldKeys.forEach(function (inputFieldKey) {
                var _a;
                var _b, _c;
                parentConditionGroup[fieldRowKey] = __assign(__assign({}, parentConditionGroup[fieldRowKey]), (_a = { logicalOperator: inputFieldKeys.length > 1
                            ? (_c = (_b = parentConditionGroup[fieldRowKey]) === null || _b === void 0 ? void 0 : _b.logicalOperator) !== null && _c !== void 0 ? _c : SearchProductCondition.logicalOperator.OR
                            : undefined }, _a[inputFieldKey] = __assign(__assign({}, parentConditionGroup[fieldRowKey][inputFieldKey]), { operator: (selectedFieldData === null || selectedFieldData === void 0 ? void 0 : selectedFieldData.type)
                        ? OPERATOR_DEFAULT_SELECTION[selectedFieldData.type]
                        : undefined }), _a));
            });
        }
        setConditionGroups(__assign(__assign({}, parentConditionGroup), (_b = {}, _b[fieldRowKey] = __assign(__assign({}, parentConditionGroup[fieldRowKey]), { selectedField: fieldKey, logicalOperator: !isBooleanFieldType && inputFieldKeys.length > 1
                ? (_h = (_g = parentConditionGroup[fieldRowKey]) === null || _g === void 0 ? void 0 : _g.logicalOperator) !== null && _h !== void 0 ? _h : SearchProductCondition.logicalOperator.OR
                : undefined }), _b)), groupKey, 'add');
    };
    var handleAddNewInputField = function () {
        var _a, _b;
        var _c, _d, _e, _f;
        var newInputFieldKey = crypto.randomUUID();
        var updateInputFieldKeys = __spreadArray(__spreadArray([], inputFieldKeys, true), [newInputFieldKey], false);
        setInputFieldKeys(updateInputFieldKeys);
        var parentConditionGroup = conditionGroups === null || conditionGroups === void 0 ? void 0 : conditionGroups[groupKey];
        setConditionGroups(__assign(__assign({}, parentConditionGroup), (_a = {}, _a[fieldRowKey] = __assign(__assign({}, parentConditionGroup[fieldRowKey]), (_b = { logicalOperator: updateInputFieldKeys.length > 1
                    ? (_d = (_c = parentConditionGroup[fieldRowKey]) === null || _c === void 0 ? void 0 : _c.logicalOperator) !== null && _d !== void 0 ? _d : SearchProductCondition.logicalOperator.OR
                    : undefined }, _b["".concat(newInputFieldKey)] = {
            val: '',
            operator: ((_e = currField === null || currField === void 0 ? void 0 : currField.fieldData) === null || _e === void 0 ? void 0 : _e.type)
                ? OPERATOR_DEFAULT_SELECTION[(_f = currField === null || currField === void 0 ? void 0 : currField.fieldData) === null || _f === void 0 ? void 0 : _f.type]
                : undefined,
        }, _b)), _a)), groupKey, 'add');
    };
    var handleRemoveInputField = function (key) {
        var _a;
        if (inputFieldKeys.length === 1)
            return;
        var parentConditionGroup = conditionGroups === null || conditionGroups === void 0 ? void 0 : conditionGroups[groupKey];
        var modifiedRow = parentConditionGroup[fieldRowKey];
        if (modifiedRow && (modifiedRow === null || modifiedRow === void 0 ? void 0 : modifiedRow[key]))
            delete modifiedRow[key];
        var arr = __spreadArray([], inputFieldKeys, true);
        arr.splice(arr.findIndex(function (fieldKey) { return fieldKey === key; }), 1);
        setInputFieldKeys(arr);
        if (arr.length <= 1) {
            setLogicalOperator(SearchProductCondition.logicalOperator.OR);
        }
        setConditionGroups(__assign(__assign({}, parentConditionGroup), (_a = {}, _a[fieldRowKey] = __assign(__assign({}, modifiedRow), { logicalOperator: arr.length > 1 ? logicalOperator : undefined }), _a)), groupKey, 'add');
    };
    var handleLogicalOperatorSelect = function (operator) {
        var _a;
        setLogicalOperator(operator);
        var parentConditionGroup = conditionGroups === null || conditionGroups === void 0 ? void 0 : conditionGroups[groupKey];
        var modifiedRow = parentConditionGroup[fieldRowKey];
        setConditionGroups(__assign(__assign({}, parentConditionGroup), (_a = {}, _a[fieldRowKey] = __assign(__assign({}, modifiedRow), { logicalOperator: operator }), _a)), groupKey, 'add');
    };
    useEffect(function () {
        switch (fieldRowKey) {
            case DEFAULT_DATE_ROWS.startDate.key:
                setCurrField({
                    isFieldSelected: true,
                    fieldKey: fieldRowKey,
                    fieldData: DEFAULT_DATE_ROWS.startDate.field,
                });
                setInputFieldKeys(__spreadArray(__spreadArray([], inputFieldKeys, true), [fieldRowKey], false));
                break;
            case DEFAULT_DATE_ROWS.endDate.key:
                setCurrField({
                    isFieldSelected: true,
                    fieldKey: fieldRowKey,
                    fieldData: DEFAULT_DATE_ROWS.endDate.field,
                });
                setInputFieldKeys(__spreadArray(__spreadArray([], inputFieldKeys, true), [fieldRowKey], false));
                break;
            default:
                handleAddNewInputField();
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(Box, __assign({ sx: {
            paddingTop: ((_b = currField === null || currField === void 0 ? void 0 : currField.fieldData) === null || _b === void 0 ? void 0 : _b.type) === PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN ||
                inputFieldKeys.length <= 1
                ? '8px'
                : 0,
        }, className: styles.rowContainer, onMouseEnter: function () { return setIsHover(true); }, onMouseLeave: function () { return setIsHover(false); }, onFocus: function () { return setIsHover(true); }, onBlur: function () { return setIsHover(false); } }, { children: [((_c = currField === null || currField === void 0 ? void 0 : currField.fieldData) === null || _c === void 0 ? void 0 : _c.type) !== PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN && inputFieldKeys.length > 1 && (_jsxs(Box, __assign({ className: styles.rowOperators }, { children: [_jsx(Typography, { children: "Logic Between Field Values:" }), _jsx(FormControl, __assign({ disabled: disableLogicals }, { children: _jsxs(RadioGroup, __assign({ className: styles.radioGroup, value: logicalOperator }, { children: [_jsx(FormControlLabel, { value: SearchProductCondition.logicalOperator.AND, control: _jsx(Radio, { size: "small", onChange: function () {
                                            return handleLogicalOperatorSelect(SearchProductCondition.logicalOperator.AND);
                                        } }), label: "And", slotProps: {
                                        typography: {
                                            fontSize: '14px',
                                        },
                                    } }, "".concat(groupKey, "_and")), _jsx(FormControlLabel, { value: SearchProductCondition.logicalOperator.OR, control: _jsx(Radio, { size: "small", onChange: function () {
                                            return handleLogicalOperatorSelect(SearchProductCondition.logicalOperator.OR);
                                        } }), label: "Or", slotProps: {
                                        typography: {
                                            fontSize: '14px',
                                        },
                                    } }, "".concat(groupKey, "_or"))] })) }))] }))), _jsxs(Box, __assign({ className: styles.indRow }, { children: [_jsx(Box, __assign({ className: styles.fieldSelect }, { children: _jsxs(FormControl, __assign({ fullWidth: true, size: "small" }, { children: [_jsx(InputLabel, __assign({ className: styles.selectLabel }, { children: "Select Field" })), _jsx(Select, __assign({ value: (_d = currField === null || currField === void 0 ? void 0 : currField.fieldKey) !== null && _d !== void 0 ? _d : '', onChange: function (e) { return handleFieldSelect(e); }, className: (currField === null || currField === void 0 ? void 0 : currField.fieldKey) && boldFilledRows ? 'Mui-focused' : '' }, { children: fieldNames.map(function (field) {
                                        var _a;
                                        return (_jsx(MenuItem, __assign({ value: field }, { children: (_a = PRODUCT_VERSION_SEARCH_FIELD_MAP === null || PRODUCT_VERSION_SEARCH_FIELD_MAP === void 0 ? void 0 : PRODUCT_VERSION_SEARCH_FIELD_MAP["".concat(field)]) === null || _a === void 0 ? void 0 : _a.displayName }), field));
                                    }) }))] })) })), _jsxs(Box, __assign({ className: styles.fieldsContainer }, { children: [_jsx(Grid, __assign({ container: true, 
                                // eslint-disable-next-line no-nested-ternary
                                columns: { xs: inputFieldKeys.length === 1 ? 4 : inputFieldKeys.length > 2 ? 12 : 8 }, spacing: { xs: 2 }, rowSpacing: { xs: 0.5 }, sx: {
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    width: '100%',
                                    flexWrap: 'wrap',
                                } }, { children: inputFieldKeys.map(function (key) {
                                    var _a, _b;
                                    return (_jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(InputField, { groupKey: groupKey, fieldData: {
                                                selectedProperty: currField === null || currField === void 0 ? void 0 : currField.fieldKey,
                                                rowKey: fieldRowKey,
                                                fieldKey: key,
                                                fieldType: (_a = currField === null || currField === void 0 ? void 0 : currField.fieldData) === null || _a === void 0 ? void 0 : _a.type,
                                            }, showRemoveIcon: inputFieldKeys.length > 1, isFieldSelected: (_b = currField === null || currField === void 0 ? void 0 : currField.isFieldSelected) !== null && _b !== void 0 ? _b : false, isInitialDateDefault: (fieldRowKey === DEFAULT_DATE_ROWS.startDate.key ||
                                                fieldRowKey === DEFAULT_DATE_ROWS.endDate.key) &&
                                                inputFieldKeys.length === 1, handleRemoveInputField: function () { return handleRemoveInputField(key); }, boldFilledRows: boldFilledRows }, key) }), key));
                                }) })), ((_e = currField === null || currField === void 0 ? void 0 : currField.fieldData) === null || _e === void 0 ? void 0 : _e.type) !== PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN && (_jsx(IconButton, __assign({ disabled: !(currField === null || currField === void 0 ? void 0 : currField.isFieldSelected), className: styles.addFieldIcon, onClick: function () { return handleAddNewInputField(); } }, { children: _jsx(AddIcon, {}) })))] }))] }))] })));
}

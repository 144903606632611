var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import spinnerText from 'assets/spinnerText.svg';
import { useAuth } from 'services/auth/AuthProvider';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Home.module.scss';
export function Home() {
    var auth = useAuth();
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var location = useLocation();
    useEffect(function () {
        var _a, _b, _c;
        if ((_a = location.state) === null || _a === void 0 ? void 0 : _a.accessDenied) {
            var message = ((_b = location.state) === null || _b === void 0 ? void 0 : _b.message) ? (_c = location.state) === null || _c === void 0 ? void 0 : _c.message : 'please verify user access credentials';
            setSnackbarAlert({
                message: "Access denied:  ".concat(message),
                type: 'error',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx("div", __assign({ className: styles.Home }, { children: _jsxs("header", __assign({ className: styles['Home-header'] }, { children: [_jsx("img", { src: spinnerText, className: styles['Home-logo'], alt: "logo" }), _jsx("p", { children: "Welcome to Publication Layer" })] })) })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { StyledDialogV2 } from 'components/Shared/StyledDialogV2/StyledDialogV2';
import styles from './ManufacturingSiteModal.module.scss';
var NO_DATA_ERROR_MSG = 'There is no available manufacturing site information for this product';
export function ManufacturingSiteModal(_a) {
    var _b;
    var isOpen = _a.isOpen, data = _a.data, setIsOpen = _a.setIsOpen, errorMessage = _a.errorMessage;
    var titleText = 'Determine Sourcing';
    var errorMsgVal = mapErrorMsg(data, errorMessage);
    var dataObj = null;
    if (data) {
        dataObj = {
            firstSection: [
                {
                    title: 'Product Number',
                    value: data.productNumber,
                },
                {
                    title: 'Product Line (Code)',
                    value: data.productLineCode,
                },
                {
                    title: 'Effective Date',
                    value: data.effectiveDate,
                },
                {
                    title: 'Description',
                    value: data.productDescription,
                    width: 400,
                },
            ],
        };
        if (bodyFieldsExists(data)) {
            dataObj.secondSection = [
                {
                    title: 'Mainframe Division Code',
                    value: data.mainframeDivisionCode,
                },
                {
                    title: 'BAAN Company (Number)',
                    value: data.baanCompany,
                },
                {
                    title: 'Supplier (Number)',
                    value: data.supplier,
                    subtitle: '(Only available for purchase complete items that are in BAAN)',
                },
                {
                    title: 'Buyer Code (P/C Distribution)',
                    value: data.buyerCode,
                    subtitle: '(Only relevant for Mainframe Division Code=$)',
                },
                {
                    title: 'Weight',
                    value: data.weight,
                },
                {
                    title: 'Packaged Cube',
                    value: data.packagedCube,
                },
            ];
        }
    }
    return (_jsx(StyledDialogV2, __assign({ isOpen: isOpen, setIsOpen: setIsOpen, sizeProps: { width: '90%' }, rowGap: "16px", padding: "28px", title: titleText }, { children: _jsxs(Box, { children: [_jsx(Box, __assign({ className: styles.firstContainer }, { children: (dataObj === null || dataObj === void 0 ? void 0 : dataObj.firstSection) &&
                        ((_b = dataObj === null || dataObj === void 0 ? void 0 : dataObj.firstSection) === null || _b === void 0 ? void 0 : _b.map(function (item) { return (_jsxs(Box, __assign({ sx: { display: 'flex', marginBottom: '10px' } }, { children: [_jsx(Typography, __assign({ className: styles.title }, { children: item.title })), _jsx(Typography, __assign({ sx: { width: item.width ? "".concat(item.width, "px") : 'auto' } }, { children: item.value }))] }), item.title)); })) })), _jsx(Typography, __assign({ sx: { fontSize: '24px', marginBottom: '20px' } }, { children: "Manufacturing Site Information" })), data && (_jsx(Box, __assign({ className: styles.secondContainer }, { children: (dataObj === null || dataObj === void 0 ? void 0 : dataObj.secondSection) &&
                        dataObj.secondSection
                            .filter(function (val, index) { return includeBuyerCode(val.title, data); })
                            .map(function (item) { return (_jsxs(Box, __assign({ className: styles.columnCont }, { children: [_jsxs(Box, __assign({ className: styles.rowCont }, { children: [_jsx(Typography, __assign({ className: styles.title }, { children: item.title })), _jsx(Typography, { children: item.value })] }), item.title), item.subtitle && (_jsx(Typography, __assign({ className: styles.subtitle }, { children: item.subtitle })))] }), item.title)); }) }))), errorMsgVal && _jsx(Typography, __assign({ className: styles.error }, { children: errorMsgVal }))] }) })));
}
function bodyFieldsExists(data) {
    var baanCompany = data.baanCompany, buyerCode = data.buyerCode, mainframeDivisionCode = data.mainframeDivisionCode, packagedCube = data.packagedCube, supplier = data.supplier, weight = data.weight;
    return baanCompany || buyerCode || mainframeDivisionCode || packagedCube || supplier || weight;
}
function mapErrorMsg(data, msg) {
    if (msg === 'Item not found in any company.' || !bodyFieldsExists(data))
        return NO_DATA_ERROR_MSG;
    if (msg === null || msg === undefined)
        return null;
    return msg;
}
function includeBuyerCode(value, data) {
    if (data.buyerCode !== null || data.buyerCode !== undefined)
        return true;
    if ((data.supplier === null || data.supplier === undefined) && value.startsWith('Buyer Code'))
        return false;
    return true;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { tryCopy } from 'utils/clipboardHelper';
import styles from './RequestDisplay.module.scss';
export function RequestDisplay(_a) {
    var text = _a.text;
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var handleCopy = function (code) {
        if (code === -1) {
            setSnackbarAlert({
                message: "Looks like your browser does not support automatic copying. Please copy the JSON.",
                type: 'error',
            });
        }
    };
    return (_jsxs(Box, __assign({ className: styles.displayContainer }, { children: [_jsx("pre", { children: _jsx("code", { children: text }) }), _jsx(IconButton, __assign({ className: styles.copyIcon, onClick: function () { return handleCopy(tryCopy(text)); } }, { children: _jsx(ContentCopyIcon, {}) }))] })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { InputFieldType } from 'models/Input/InputModels';
import { DEFAULT_WORKFLOW } from 'utils/cpa-utils';
import { StyledDialogV2 } from '../StyledDialogV2/StyledDialogV2';
import { StyledButtonV2 } from '../StyledButtonV2/StyledButtonV2';
import { PubInputField } from '../PubInputField/PubInputField';
export function WorkflowModal(_a) {
    var buttonProps = _a.buttonProps, title = _a.title, messages = _a.messages, isOpen = _a.isOpen, setIsOpen = _a.setIsOpen;
    var _b = useState(''), workflow = _b[0], setWorkflow = _b[1];
    var _c = useState(!title), formInError = _c[0], setFormInError = _c[1];
    return (_jsxs(StyledDialogV2, __assign({ isOpen: isOpen, setIsOpen: setIsOpen, sizeProps: { width: '40%' }, rowGap: "16px", padding: "28px", title: "Add Workflow" }, { children: [title && messages && (_jsx(Box, __assign({ marginY: "32px" }, { children: messages.map(function (message, i) { return (
                // eslint-disable-next-line react/no-array-index-key
                _jsxs(Typography, { children: [message, message.trim() === '' && _jsx("br", {})] }, "".concat(message, "_").concat(i))); }) }))), !title && (_jsx(Box, __assign({ marginY: "32px" }, { children: _jsx(PubInputField, { fieldType: InputFieldType.STRING, validations: {
                        required: 'Please enter value.',
                    }, inError: function (val) {
                        if (formInError !== val) {
                            setFormInError(val);
                        }
                    }, fieldValue: workflow, labels: {
                        left: 'Workflow Text',
                        right: 'Required',
                    }, dirtyCheck: true, fieldValueUpdateHandler: function (val) {
                        setWorkflow(val);
                    } }) }))), _jsx(Box, __assign({ width: "100%", display: "flex", columnGap: "16px", justifyContent: "flex-end" }, { children: buttonProps.map(function (buttonProp) { return (_jsx(StyledButtonV2, { labelText: buttonProp.labelText, backgroundColorVariation: buttonProp.backgroundColorVariation, buttonAction: function () {
                        if (buttonProp.buttonAction) {
                            if (title) {
                                setWorkflow(DEFAULT_WORKFLOW);
                                buttonProp.buttonAction(DEFAULT_WORKFLOW);
                            }
                            else {
                                buttonProp.buttonAction(workflow);
                            }
                        }
                    }, colorOverride: buttonProp.colorOverride, disabled: buttonProp.labelText === 'Confirm' && formInError, disabledColorOverride: buttonProp.disabledColorOverride, height: buttonProp.height, hoverColorOverride: buttonProp.hoverColorOverride, width: buttonProp.width }, buttonProp.labelText)); }) }))] })));
}

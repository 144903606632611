export function parseDate(passedDate) {
    var checkLengthOfTimeParameter = function (param) {
        if (param.length === 1) {
            // eslint-disable-next-line no-param-reassign
            param = "0".concat(param);
            return param;
        }
        return param; // no 0 added to front of value
    };
    if (passedDate) {
        var newDate = new Date(passedDate);
        var parsedYear = newDate.getUTCFullYear();
        var parsedMonth = checkLengthOfTimeParameter((newDate.getUTCMonth() + 1).toString());
        var parsedDay = checkLengthOfTimeParameter(newDate.getUTCDate().toString());
        var parsedHours = checkLengthOfTimeParameter(newDate.getUTCHours().toString());
        var parsedMinutes = checkLengthOfTimeParameter(newDate.getUTCMinutes().toString());
        var parsedSeconds = checkLengthOfTimeParameter(newDate.getUTCSeconds().toString());
        var parsedDateString = "".concat(parsedYear, "-").concat(parsedMonth, "-").concat(parsedDay);
        var parsedTimeString = "".concat(parsedHours, ":").concat(parsedMinutes, ":").concat(parsedSeconds);
        var parsedTimeStamp = "".concat(parsedYear, "-").concat(parsedMonth, "-").concat(parsedDay, "T").concat(parsedHours, ":").concat(parsedMinutes, ":").concat(parsedSeconds, ".000Z");
        var milliseconds = new Date(parsedTimeStamp).getTime();
        return { parsedDateString: parsedDateString, parsedTimeString: parsedTimeString, parsedTimeStamp: parsedTimeStamp, milliseconds: milliseconds };
    }
    return '';
}

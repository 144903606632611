var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useEffect, useMemo, useState } from 'react';
import { RangeOptionTextField } from 'components/ProductValidation/RangeOptionTextField/RangeOptionTextField';
import { PubInputField } from 'components/Shared/PubInputField/PubInputField';
import { OptionRoyaltyRestrictionModal } from 'components/Shared/OptionRoyaltyRestrictionModal/OptionRoyaltyRestrictionModal';
import { InputFieldType } from 'models/Input/InputModels';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Error';
import StarsIcon from '@mui/icons-material/Stars';
import Box from '@mui/material/Box';
import { FabricOptionsInput } from 'components/ProductBuildNew/Features/Fabrics/FabricOptionsInput';
import { infoSelect } from 'services/api/product-service';
import { NonStdOptionsInput } from 'components/ProductBuildNew/Features/NonStandard/NonStdOptionsInput';
import { useSearchParams } from 'react-router-dom';
import styles from './ProductBuildPageV2TableRow.module.scss';
export function ProductBuildPageV2TableRow(_a) {
    var _this = this;
    var feature = _a.feature, featureArray = _a.featureArray, index = _a.index, optionalColumn = _a.optionalColumn, isGSA = _a.isGSA, latestValidateResponse = _a.latestValidateResponse, timestamp = _a.timestamp, prefix = _a.prefix, buildMode = _a.buildMode, userHasRoyaltyPrivileges = _a.userHasRoyaltyPrivileges, nsoMetadata = _a.nsoMetadata, handleOptionEditInParent = _a.handleOptionEditInParent, showOptionalColumns = _a.showOptionalColumns;
    var _b = useState(feature), rowFeature = _b[0], setRowFeature = _b[1];
    var _c = useState(feature === null || feature === void 0 ? void 0 : feature.options), rowOptions = _c[0], setRowOptions = _c[1];
    var _d = useState(true), isLoading = _d[0], setIsLoading = _d[1];
    var _e = useState(false), isRange = _e[0], setIsRange = _e[1];
    var _f = useState(false), isModalOpen = _f[0], setIsModalOpen = _f[1];
    var _g = useState(false), isShowRestriction = _g[0], setIsShowRestriction = _g[1];
    var _h = useState(false), fabricsAreLoading = _h[0], setFabricsAreLoading = _h[1];
    var isNso = useMemo(function () { return feature.isNonStandard; }, [feature]);
    var searchParams = useSearchParams()[0];
    var params = useMemo(function () {
        var _a, _b, _c, _d;
        var sp = {
            text: (_a = searchParams.get('productNbr')) !== null && _a !== void 0 ? _a : '',
            date: (_b = searchParams.get('date')) !== null && _b !== void 0 ? _b : '',
            time: (_c = searchParams.get('time')) !== null && _c !== void 0 ? _c : '',
            id: (_d = searchParams.get('pid')) !== null && _d !== void 0 ? _d : '',
        };
        return sp;
    }, [searchParams]);
    function handleOpenModal(value) {
        setIsModalOpen(true);
        setIsShowRestriction(value);
    }
    var filteredOptions = useMemo(function () {
        if (isGSA) {
            return rowOptions === null || rowOptions === void 0 ? void 0 : rowOptions.filter(function (option) { return option === null || option === void 0 ? void 0 : option.gsaInd; });
        }
        return rowOptions;
    }, [isGSA, rowOptions]);
    var optionsArray = useMemo(function () {
        if (rowFeature.type !== 'Derived' && rowFeature.type !== 'Implied') {
            return filteredOptions === null || filteredOptions === void 0 ? void 0 : filteredOptions.map(function (option) {
                return option.optionRestrictionPresent
                    ? "".concat(option.optionCode, " - (RESTRICTED) ").concat(option.optionDescription)
                    : "".concat(option.optionCode, " - ").concat(option.optionDescription);
            });
        }
        return null;
    }, [rowFeature, filteredOptions]);
    var _j = useState("".concat(rowFeature.selectedOptionCode, " - ").concat(rowFeature.option)), selectedOption = _j[0], setSelectedOption = _j[1];
    useEffect(function () {
        var _a, _b;
        if (rowFeature.range) {
            setIsRange(true);
            var productNbr_1 = prefix;
            featureArray.forEach(function (f) {
                if (f.type !== 'Implied' && f.type !== 'Derived') {
                    if (!f.range) {
                        productNbr_1 = "".concat(productNbr_1).concat(f.selectedOptionCode);
                    }
                    else {
                        productNbr_1 = "".concat(productNbr_1).concat(f.selectedOptionCode);
                    }
                }
            });
            var option = {
                productNbr: productNbr_1,
                optionCode: "".concat((_a = rowFeature === null || rowFeature === void 0 ? void 0 : rowFeature.range) === null || _a === void 0 ? void 0 : _a.start, " - ").concat((_b = rowFeature === null || rowFeature === void 0 ? void 0 : rowFeature.range) === null || _b === void 0 ? void 0 : _b.end),
                optionDescription: rowFeature.featureName,
                gsaInd: rowFeature.gsaInd === 'Y',
                mfgFeatureModelCode: rowFeature.mfgFeatureModelCode,
                mfgOptionModelCode: rowFeature.mfgOptionModelCode,
                optionRestrictionPresent: rowFeature.restrictionsPresent,
                range: rowFeature.range,
                skuSelection: rowFeature.skuSelection,
            };
            rowFeature.options = [];
            rowFeature.options.push(option);
            setIsLoading(false);
        }
        else {
            setIsLoading(false);
        }
    }, [rowFeature, setIsLoading, prefix, featureArray, latestValidateResponse]);
    var handleItemClick = function (e) {
        if (rowOptions) {
            rowFeature.options = rowOptions;
        }
        var descWithoutRest = e;
        setSelectedOption(e);
        if (e.includes('RESTRICTED')) {
            descWithoutRest = e.replace('(RESTRICTED)', '').trim();
        }
        var finalString = descWithoutRest.split(' - ')[0].trim();
        var i = rowOptions.findIndex(function (o) { return o.optionCode === finalString; });
        var option = rowFeature.options[i];
        setRowFeature(function (prevVal) {
            prevVal.option = option.optionDescription;
            prevVal.selectedOptionCode = option.optionCode;
            prevVal.restrictionsPresent = option.optionRestrictionPresent;
            return prevVal;
        });
        handleOptionEditInParent === null || handleOptionEditInParent === void 0 ? void 0 : handleOptionEditInParent(option, rowFeature);
    };
    var handleRangeOptionEdit = function (e, rangeVal) {
        var _a, _b;
        var travelLength = rangeVal.split('.')[0].length;
        var indexOfDecimal = (_a = e === null || e === void 0 ? void 0 : e.productNbr) === null || _a === void 0 ? void 0 : _a.lastIndexOf('.');
        var slicedProductNbr = (_b = e === null || e === void 0 ? void 0 : e.productNbr) === null || _b === void 0 ? void 0 : _b.slice(0, indexOfDecimal - travelLength);
        e.productNbr = "".concat(slicedProductNbr).concat(rangeVal);
        rowFeature.option = "Depth Entry (".concat(rangeVal, ")");
        var newFeatSelects = [];
        featureArray.forEach(function (f) {
            var _a, _b;
            if (f.type !== 'Implied' && f.type !== 'Derived') {
                if (!f.range) {
                    newFeatSelects.push({ featureId: f.featureId, optionCode: f.selectedOptionCode });
                }
                else {
                    newFeatSelects.push({
                        featureId: f.featureId,
                        optionCode: "".concat((_a = f === null || f === void 0 ? void 0 : f.range) === null || _a === void 0 ? void 0 : _a.start, " - ").concat((_b = f === null || f === void 0 ? void 0 : f.range) === null || _b === void 0 ? void 0 : _b.end),
                    });
                }
            }
        });
        handleOptionEditInParent === null || handleOptionEditInParent === void 0 ? void 0 : handleOptionEditInParent(e, rowFeature, rangeVal, newFeatSelects);
    };
    var editableTableField = useMemo(function () {
        var _a, _b;
        if (feature.isRange || rowFeature.range) {
            return (_jsx(RangeOptionTextField, { option: (_a = rowFeature.options) === null || _a === void 0 ? void 0 : _a[0], rangeValFromParent: rowFeature.range
                    ? featureArray[featureArray.length - 1].selectedOptionCode
                    : (_b = rowFeature.rangeVal) !== null && _b !== void 0 ? _b : '', handleRangeOptionSubmit: handleRangeOptionEdit, v2: true, fieldHasValue: !!rowFeature.selectedOptionCode }));
        }
        if (feature.isNonStandard || isNso) {
            return (_jsx(NonStdOptionsInput, { changeCodeHandler: function (row) { return handleItemClick(row); }, featureDescription: feature.featureName, featureStep: feature.step, options: rowOptions, selectedValue: selectedOption, isEditMode: true, nsoMetadata: nsoMetadata, showOptionalColumns: showOptionalColumns }));
        }
        if (feature.isFabric) {
            return (_jsx(FabricOptionsInput, { changeCodeHandler: function (row) { return handleItemClick(row); }, featureDescription: feature.featureName, featureStep: feature.step, options: rowOptions, selectedValue: selectedOption, showOptionalColumns: showOptionalColumns, fromInfo: true }));
        }
        return (_jsx(PubInputField, { fieldType: InputFieldType.ENUM, fieldValueUpdateHandler: function (e) { return handleItemClick(e); }, fieldOptions: optionsArray !== null && optionsArray !== void 0 ? optionsArray : [], fieldValue: selectedOption !== null && selectedOption !== void 0 ? selectedOption : '', fieldHasOption: true }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowFeature, rowOptions, selectedOption, feature, featureArray, optionsArray, handleRangeOptionEdit]);
    var handleOpen = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var infoRsp, feats, buildProductNbr, cutoffIndex, options, map, arr;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (e === null || e === void 0 ? void 0 : e.isFabric) {
                        setFabricsAreLoading(true);
                    }
                    return [4 /*yield*/, infoSelect({ featureId: e === null || e === void 0 ? void 0 : e.featureId, productId: params.id }, 'options')];
                case 1:
                    infoRsp = _d.sent();
                    feats = latestValidateResponse.features;
                    buildProductNbr = latestValidateResponse.productPrefix;
                    cutoffIndex = (_a = latestValidateResponse.features) === null || _a === void 0 ? void 0 : _a.findIndex(function (f) { return f.featureId === (e === null || e === void 0 ? void 0 : e.featureId); });
                    feats === null || feats === void 0 ? void 0 : feats.slice(0, cutoffIndex).forEach(function (f) {
                        if (f.type !== 'Derived' && f.type !== 'Implied' && f.skuSelection) {
                            buildProductNbr = "".concat(buildProductNbr).concat(f.optionCode);
                        }
                    });
                    options = (_c = (_b = infoRsp.body.details) === null || _b === void 0 ? void 0 : _b.feature) === null || _c === void 0 ? void 0 : _c.options;
                    map = Object.entries(options);
                    arr = [];
                    map.forEach(function (item) {
                        var _a, _b, _c, _d;
                        if (!item[0].includes('option_')) {
                            var thing = {
                                productNbr: "".concat(buildProductNbr).concat(item[1].skuSelection ? item[0] : ''),
                                optionCode: item[0],
                                optionDescription: (_c = (_b = (_a = item === null || item === void 0 ? void 0 : item[1]) === null || _a === void 0 ? void 0 : _a.description) === null || _b === void 0 ? void 0 : _b['en-US']) === null || _c === void 0 ? void 0 : _c.desc,
                                skuSelection: item[1].skuSelection,
                                optionRestrictionPresent: Object.values(item[1].restriction).length > 0,
                                pbSubHeading: (_d = item[1].pbSubHeading) !== null && _d !== void 0 ? _d : '',
                                minLeadTime: item[1].minLeadtime,
                                mfgOptionModelCode: item[1].mfgOptionModelCode,
                            };
                            arr.push(thing);
                        }
                    });
                    setRowOptions(arr);
                    rowFeature.options = rowOptions;
                    setFabricsAreLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var fullValidatedEditableTableRows = function () {
        var _a, _b;
        if (feature.isNonStandard) {
            return (_jsx(NonStdOptionsInput, { changeCodeHandler: function (row) { return handleItemClick(row); }, featureDescription: feature.featureName, featureStep: feature.step, options: rowOptions, selectedValue: selectedOption, modelOpenHandler: function () {
                    if (!rowOptions)
                        handleOpen(feature);
                }, updateRowOptions: function (e) {
                    setRowOptions(e);
                }, isEditMode: true, showOptionalColumns: showOptionalColumns }));
        }
        if (feature.isFabric) {
            return (_jsx(FabricOptionsInput, { changeCodeHandler: function (row) { return handleItemClick(row); }, featureDescription: feature.featureName, featureStep: feature.step, options: rowOptions, selectedValue: selectedOption, modelOpenHandler: function () {
                    if (!rowOptions)
                        handleOpen(feature);
                }, showOptionalColumns: showOptionalColumns, fromInfo: true }));
        }
        if (isRange) {
            return (_jsx(RangeOptionTextField, { option: (_a = rowFeature === null || rowFeature === void 0 ? void 0 : rowFeature.options) === null || _a === void 0 ? void 0 : _a[0], rangeValFromParent: (_b = rowFeature.rangeVal) !== null && _b !== void 0 ? _b : '', handleRangeOptionSubmit: handleRangeOptionEdit, v2: true, fieldHasValue: !!rowFeature.rangeVal }));
        }
        return (_jsx(PubInputField, { fieldType: InputFieldType.ENUM, fieldValueUpdateHandler: function (e) { return handleItemClick(e); }, fieldOptions: optionsArray !== null && optionsArray !== void 0 ? optionsArray : [], fieldValue: selectedOption !== null && selectedOption !== void 0 ? selectedOption : '', handleOpen: function () { return handleOpen(rowFeature); }, fieldHasOption: !!selectedOption }));
    };
    var fullValRowsWAutoSelect = function () {
        if (rowFeature.type !== 'Implied' && rowFeature.type !== 'Derived') {
            return fullValidatedEditableTableRows();
        }
        return _jsx(Typography, { children: "".concat(rowFeature.selectedOptionCode, " - ").concat(rowFeature.option) });
    };
    var fullValAutoSelectFromScratch = function () {
        if (rowFeature.type !== 'Implied' &&
            rowFeature.type !== 'Derived' &&
            !rowFeature.autoFire &&
            rowFeature.options) {
            if (feature.isNonStandard) {
                return (_jsx(NonStdOptionsInput, { changeCodeHandler: function (row) { return handleItemClick(row); }, featureDescription: feature.featureName, featureStep: feature.step, options: rowOptions, selectedValue: selectedOption, isEditMode: true, updateRowOptions: function (e) {
                        setRowOptions(e);
                    }, showOptionalColumns: showOptionalColumns }));
            }
            if (feature.isFabric) {
                return (_jsx(FabricOptionsInput, { changeCodeHandler: function (row) { return handleItemClick(row); }, featureDescription: feature.featureName, featureStep: feature.step, options: rowOptions, selectedValue: selectedOption, showOptionalColumns: showOptionalColumns, fromInfo: true }));
            }
            return editableTableField;
        }
        return fullValRowsWAutoSelect();
    };
    var row = function () {
        if (buildMode) {
            return (_jsx(TableCell, __assign({ sx: { width: optionalColumn === 'Show None' ? '40%' : '30%' } }, { children: fullValAutoSelectFromScratch() })));
        }
        return (_jsx(TableCell, __assign({ sx: { width: optionalColumn === 'Show None' ? '40%' : '30%' } }, { children: _jsx(Typography, { children: "".concat(rowFeature.selectedOptionCode, " - ").concat(rowFeature.option) }) })));
    };
    return (_jsxs(TableRow, __assign({ sx: { backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.1)' : '' } }, { children: [_jsx(TableCell, __assign({ sx: { width: '5%' } }, { children: rowFeature.step })), _jsx(TableCell, __assign({ sx: { width: '5%' } }, { children: rowFeature.type })), _jsx(TableCell, __assign({ sx: { width: '15%' } }, { children: rowFeature.featureName })), optionalColumn !== 'Show None' && (_jsx(TableCell, __assign({ sx: { width: '5%' } }, { children: optionalColumn === 'Engineering Model Codes'
                    ? rowFeature.mfgFeatureModelCode
                    : rowFeature.prcFeatureModelCode }))), _jsx(TableCell, __assign({ sx: { width: '5%' } }, { children: rowFeature.subheading })), !isLoading && row(), optionalColumn !== 'Show None' && (_jsx(TableCell, __assign({ sx: { width: '7%' } }, { children: optionalColumn === 'Engineering Model Codes'
                    ? rowFeature.mfgOptionModelCode
                    : rowFeature.prcOptionModelCode }))), _jsx(TableCell, __assign({ sx: { width: '2.5%' } }, { children: rowFeature.restrictionsPresent ? (_jsxs(Button, __assign({ className: styles.btnRestric, onClick: function () { return handleOpenModal(true); } }, { children: [_jsx(ErrorIcon, { sx: { width: 20, height: 20 } }), _jsx(Box, __assign({ sx: { marginLeft: '5px' } }, { children: "View" }))] }))) : null })), userHasRoyaltyPrivileges && (_jsxs(TableCell, __assign({ sx: { width: '2.5%' } }, { children: [rowFeature.royaltiesPresent ? (_jsxs(Button, __assign({ className: styles.btnRoyalty, onClick: function () { return handleOpenModal(false); } }, { children: [_jsx(StarsIcon, { sx: { width: 20, height: 20 } }), _jsx(Box, __assign({ sx: { marginLeft: '5px' } }, { children: "View" }))] }))) : null, isModalOpen && (_jsx(Box, __assign({ position: "absolute" }, { children: _jsx(OptionRoyaltyRestrictionModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, isShowRestriction: isShowRestriction, rowFeature: rowFeature }) })))] }))), _jsx(TableCell, __assign({ sx: { width: '10%' } }, { children: rowFeature.minLeadTime })), _jsx(TableCell, __assign({ sx: { width: '5%' } }, { children: rowFeature.gsaInd }))] })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputFieldType } from 'models/Input/InputModels';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { InputFieldsContainer } from '../InputFieldsContainer/InputFieldsContainer';
import styles from './OptionRoyaltyRestrictionDetails.module.scss';
import { PubTable } from '../PubTable/PubTable';
var STEP = 'discountCode';
var FEATURE = 'inputProductNbr';
var OPTIONTYPE = 'minLeadtime';
export function OptionRoyaltyRestrictionDetails(_a) {
    var _b;
    var _c, _d;
    var isShowRestriction = _a.isShowRestriction, rowFeature = _a.rowFeature;
    var colDef = {
        fields: (_b = {},
            _b[STEP] = {
                fieldName: 'Step',
                displaySeq: 1,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[OPTIONTYPE] = {
                fieldName: 'Option Type',
                displaySeq: 2,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[FEATURE] = {
                fieldName: 'Feature',
                displaySeq: 3,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b),
    };
    var buildFieldValues = function (dataBody) {
        var _a;
        return (_a = {},
            _a[STEP] = {
                value: dataBody === null || dataBody === void 0 ? void 0 : dataBody.step,
            },
            _a[OPTIONTYPE] = {
                value: dataBody === null || dataBody === void 0 ? void 0 : dataBody.type,
            },
            _a[FEATURE] = {
                value: dataBody === null || dataBody === void 0 ? void 0 : dataBody.featureName,
            },
            _a);
    };
    var fieldValues = useState(buildFieldValues(rowFeature))[0];
    var royaltyDataDefination = {
        firstColumnIndent: true,
        columns: [
            {
                dataAttribute: 'royaltyId',
                fieldType: InputFieldType.NUMBER,
                heading: 'Royalty ID',
                width: '100px',
                showHelpTooltip: true,
            },
            {
                dataAttribute: 'royaltyDesc',
                fieldType: InputFieldType.STRING,
                heading: 'Description',
                width: '500px',
            },
            {
                dataAttribute: 'startDate',
                fieldType: InputFieldType.STRING,
                heading: 'Start Date',
                width: '100px',
            },
            {
                dataAttribute: 'endDate',
                fieldType: InputFieldType.STRING,
                heading: 'End Date',
                width: '100px',
            },
        ],
    };
    var restrictionDataDefination = {
        firstColumnIndent: true,
        columns: [
            {
                dataAttribute: 'reasonCode',
                fieldType: InputFieldType.NUMBER,
                heading: 'Reason',
                width: '100px',
                showHelpTooltip: true,
            },
            {
                dataAttribute: 'bulletinNbr',
                fieldType: InputFieldType.STRING,
                heading: 'Bulletin',
                width: '100px',
            },
            {
                dataAttribute: 'comment',
                fieldType: InputFieldType.STRING,
                heading: 'Comment',
                width: '500px',
            },
            {
                dataAttribute: 'startDate',
                fieldType: InputFieldType.STRING,
                heading: 'Start Date',
                width: '100px',
            },
            {
                dataAttribute: 'endDate',
                fieldType: InputFieldType.STRING,
                heading: 'End Date',
                width: '100px',
            },
        ],
    };
    return (_jsxs(Box, __assign({ sx: { marginBottom: '50px' } }, { children: [_jsx(Box, __assign({ className: styles.headerContainer }, { children: _jsx(Box, __assign({ className: styles.headerLeft, sx: { width: '48%' } }, { children: _jsx(InputFieldsContainer, { colDef: colDef, fieldValues: fieldValues, minHeight: "10px" }) })) })), !isShowRestriction && (_jsx(PubTable, { def: royaltyDataDefination, tableData: (_c = rowFeature.optionRoyalties) !== null && _c !== void 0 ? _c : [], alternateRowBackgroundColor: true })), isShowRestriction && (_jsx(PubTable, { def: restrictionDataDefination, tableData: (_d = rowFeature.optionRestrictions) !== null && _d !== void 0 ? _d : [], alternateRowBackgroundColor: true }))] })));
}

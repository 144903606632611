var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { PRODUCT_VERSION_SEARCH_FIELD_MAP } from 'utils/search-product-field-mapping';
import { SearchProductPropertyVal } from '@millerknoll/pub-api-schema';
var buildSearchProductRequestConditions = function (conditions) {
    var conditionGroups = [];
    Object.keys(conditions).forEach(function (groupKey) {
        var conditionGroup = {};
        var groupFields = conditions[groupKey];
        var groupFieldsKeys = __spreadArray([], Object.keys(groupFields), true);
        groupFieldsKeys.forEach(function (fieldKey) {
            var _a;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            var fieldData = groupFields[fieldKey];
            if (!(PRODUCT_VERSION_SEARCH_FIELD_MAP === null || PRODUCT_VERSION_SEARCH_FIELD_MAP === void 0 ? void 0 : PRODUCT_VERSION_SEARCH_FIELD_MAP[fieldData.selectedField])) {
                return;
            }
            var rowData = conditionGroup === null || conditionGroup === void 0 ? void 0 : conditionGroup[fieldData.selectedField];
            var fieldCondition = {
                logicalOperator: rowData ? rowData.logicalOperator : fieldData.logicalOperator,
                values: rowData && (rowData === null || rowData === void 0 ? void 0 : rowData.values) ? __spreadArray([], rowData.values, true) : [],
            };
            var fieldValueKeys = Object.keys(fieldData).filter(function (f) { return f !== 'logicalOperator' && f !== 'selectedField'; });
            fieldValueKeys.forEach(function (valueKey) {
                var _a;
                var valueData = fieldData[valueKey];
                if ((!valueData.val || valueData.val.trim() === '') &&
                    valueData.operator !== SearchProductPropertyVal.operator.EXISTS &&
                    valueData.operator !== SearchProductPropertyVal.operator.NOT_EXISTS)
                    return;
                var propertyVal = {
                    val: (fieldData.selectedField === 'productNbr'
                        ? valueData.val.toUpperCase()
                        : (_a = valueData.val) !== null && _a !== void 0 ? _a : '').trim(),
                    operator: valueData.operator,
                };
                var prevFieldConditionValues = fieldCondition.values;
                if (prevFieldConditionValues) {
                    prevFieldConditionValues.push(propertyVal);
                }
                else {
                    prevFieldConditionValues = [propertyVal];
                }
                fieldCondition = __assign(__assign({}, fieldCondition), { values: prevFieldConditionValues });
            });
            if (((_a = fieldCondition === null || fieldCondition === void 0 ? void 0 : fieldCondition.values) === null || _a === void 0 ? void 0 : _a.length) === 0)
                return;
            conditionGroup[fieldData.selectedField] = fieldCondition;
        });
        if (Object.keys(conditionGroup).length > 0) {
            conditionGroups.push(conditionGroup);
        }
    });
    return conditionGroups;
};
var searchProductHelper = { buildSearchProductRequestConditions: buildSearchProductRequestConditions };
export { searchProductHelper };

export var validateRangeInput = function (range, rangeInput) {
    if (!range || !rangeInput || rangeInput === '')
        return true;
    if (Number.isNaN(+rangeInput))
        return false;
    var start = range.start, end = range.end, increment = range.increment, rangeScale = range.rangeScale;
    var textAsNumber = +rangeInput;
    var scaledVal = textAsNumber * Math.pow(10, rangeScale);
    var scaledIncrement = increment * Math.pow(10, rangeScale);
    if (textAsNumber < start || textAsNumber > end || scaledVal % scaledIncrement !== 0)
        return false;
    return true;
};
export var replaceProductNbrPlaceholder = function (originalProductNbr, placeholder, replaceText) {
    return originalProductNbr.replace(placeholder, replaceText);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ActionModal } from 'components/Shared/ActionModal/ActionModal';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
export function PubDeleteActionCell(_a) {
    var _b, _c, _d;
    var deleteProps = _a.deleteProps, deleteAction = _a.deleteAction;
    var _e = useState(false), isOpen = _e[0], setIsOpen = _e[1];
    return (_jsxs(TableCell, __assign({ sx: { paddingX: '4px', backgroundColor: 'transparent' } }, { children: [_jsx(IconButton, __assign({ onClick: function () {
                    if (deleteProps.modalProps === undefined) {
                        deleteAction();
                    }
                    else {
                        setIsOpen(true);
                    }
                } }, { children: _jsx(DeleteIcon, {}) })), deleteProps.modalProps && deleteProps.deleteHandler && isOpen && (_jsx(ActionModal, { isOpen: isOpen, setIsOpen: setIsOpen, title: deleteProps.modalProps.titleText, buttonProps: [
                    {
                        labelText: (_b = deleteProps.modalProps.buttonProps.confirmText) !== null && _b !== void 0 ? _b : 'Confirm',
                        backgroundColorVariation: 'primary',
                        width: '120px',
                        buttonAction: function () {
                            setIsOpen(false);
                            deleteAction();
                        },
                    },
                    {
                        labelText: (_c = deleteProps.modalProps.buttonProps.cancelText) !== null && _c !== void 0 ? _c : 'Cancel',
                        backgroundColorVariation: 'secondary',
                        width: '120px',
                        buttonAction: function () { return setIsOpen(false); },
                    },
                ], messages: (_d = deleteProps.modalProps.bodyTextArray) !== null && _d !== void 0 ? _d : [] }))] })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { validate } from 'services/api/product-service';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import { REQUEST_TYPE_QUOTE } from 'utils/constants';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { ValidationFeatureGrid } from './ValidationFeatureGrid/ValidationFeatureGrid';
import { ProductValidationSearch } from './ProductValidationSearch/ProductValidationSearch';
import styles from './ProductValidationPage.module.scss';
import { ProductValidationHeader } from './ProductValidationHeader/ProductValidationHeader';
export function ProductValidationPage() {
    var _this = this;
    var _a;
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(false), displayProductData = _c[0], setDisplayProductData = _c[1];
    var _d = useState(), validationData = _d[0], setValidationData = _d[1];
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var fetchProductNumberData = function (_a) {
        var searchTerm = _a.searchTerm, timestamp = _a.timestamp;
        return __awaiter(_this, void 0, void 0, function () {
            var data;
            var _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        setDisplayProductData(false);
                        setIsLoading(true);
                        return [4 /*yield*/, validate({
                                productNbr: searchTerm,
                                requestType: REQUEST_TYPE_QUOTE,
                                processingDate: timestamp,
                            })];
                    case 1:
                        data = _e.sent();
                        if (data) {
                            setIsLoading(false);
                            setValidationData(data);
                            if (((_b = data.body) === null || _b === void 0 ? void 0 : _b.returnCode) === '-1') {
                                setSnackbarAlert({
                                    message: (_d = (_c = data.body) === null || _c === void 0 ? void 0 : _c.returnMessage) !== null && _d !== void 0 ? _d : 'Validate Error',
                                    type: 'error',
                                });
                            }
                        }
                        setIsLoading(false);
                        setDisplayProductData(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "h1", className: styles.pageTitle }, { children: "Product Validation" })), _jsx(ProductValidationSearch, { handleSearchButtonClick: fetchProductNumberData }), isLoading && (_jsx(Box, __assign({ className: styles.loadingSpinnerContainer }, { children: _jsx(LoadingSpinner, { indicatorSize: 10 }) }))), displayProductData && (_jsx(Box, __assign({ className: styles.dataContainer }, { children: validationData && validationData.status === 200 ? (_jsxs(_Fragment, { children: [_jsx(ProductValidationHeader, { validationData: validationData }), _jsx(Box, __assign({ className: styles.validationFeatureGridContainer }, { children: _jsx(ValidationFeatureGrid, { features: validationData.body.features, productValidate: true }) }))] })) : (_jsx(Typography, __assign({ variant: "h1" }, { children: (_a = validationData === null || validationData === void 0 ? void 0 : validationData.body) === null || _a === void 0 ? void 0 : _a.message }))) })))] }));
}

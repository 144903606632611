var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useCpaProduct } from 'components/Cpa/CpaProduct/CpaProductContextProvider';
import { InputFieldsContainer } from 'components/Shared/InputFieldsContainer/InputFieldsContainer';
import { inputFieldsContainerHelper } from 'components/Shared/InputFieldsContainer/InputFieldsContainerHelper';
import { ActionType, InputFieldType } from 'models/Input/InputModels';
import { EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES } from 'utils/cpa-utils';
export function CpaExtraDataSalesCategoryWorkOrder() {
    var _a;
    var cpaProductContext = useCpaProduct();
    /**
     * Declare state to hold field values of all columns , key as column heading
     */
    var _b = useState(buildFieldValues(undefined)), fieldValues = _b[0], setFieldValues = _b[1];
    var colDef = {
        title: { displayText: 'Sales Category:' },
        rowGap: '24px',
        showActions: false,
        preventReadOnly: true,
        fields: (_a = {},
            _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_BRAND_CODE] = {
                fieldName: 'Sales Brand Code:',
                displaySeq: 0,
                input: {
                    type: InputFieldType.STRING,
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_CATEGORY_CODE] = {
                fieldName: 'Sales Category Code:',
                displaySeq: 1,
                input: {
                    type: InputFieldType.STRING,
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_PRODUCT_LINE_CODE] = {
                fieldName: 'Sales Product Line Code:',
                displaySeq: 2,
                input: {
                    type: InputFieldType.STRING,
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_SUB_CATEGORY_CODE] = {
                fieldName: 'Sales Sub-Category Code:',
                displaySeq: 3,
                input: {
                    type: InputFieldType.STRING,
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_TYPE_CODE] = {
                fieldName: 'Sales Type Code:',
                displaySeq: 4,
                input: {
                    type: InputFieldType.STRING,
                },
                hasTextField: false,
                hasLookup: false,
            },
            _a),
    };
    useEffect(function () {
        var _a;
        if (!((_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _a === void 0 ? void 0 : _a.currentProduct)) {
            return;
        }
        var initialValues = buildFieldValues(cpaProductContext.data.currentProduct);
        setFieldValues(initialValues);
    }, [cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct]);
    var handleAction = function (actionType) {
        var _a;
        if (!((_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _a === void 0 ? void 0 : _a.currentProduct)) {
            return;
        }
        if (actionType === ActionType.CONFIRM) {
            postConfirmationUpdate();
        }
        else if (actionType === ActionType.REVERT) {
            setFieldValues(buildFieldValues(cpaProductContext.data.currentProduct));
        }
    };
    var postConfirmationUpdate = function () {
        cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.dispatch({
            type: 'UPDATE_CURRENT_PRODUCT',
            payload: __assign({}, cpaProductContext.data.currentProduct),
        });
    };
    return (_jsx(InputFieldsContainer, { colDef: colDef, handleAction: handleAction, fieldValues: fieldValues, setFieldValue: function (property, value) {
            setFieldValues(inputFieldsContainerHelper.getUpdatedFieldValues(fieldValues, property, value));
        } }));
}
var buildFieldValues = function (currentProduct) {
    var _a;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    return (_a = {},
        _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_BRAND_CODE] = {
            value: getCodeAndDescription((_b = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.salesCategory) === null || _b === void 0 ? void 0 : _b.brandCode, (_c = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.salesCategory) === null || _c === void 0 ? void 0 : _c.brandDesc),
        },
        _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_PRODUCT_LINE_CODE] = {
            value: getCodeAndDescription((_d = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.salesCategory) === null || _d === void 0 ? void 0 : _d.productLineCode, (_e = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.salesCategory) === null || _e === void 0 ? void 0 : _e.productLineDesc),
        },
        _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_CATEGORY_CODE] = {
            value: getCodeAndDescription((_f = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.salesCategory) === null || _f === void 0 ? void 0 : _f.categoryCode, (_g = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.salesCategory) === null || _g === void 0 ? void 0 : _g.categoryDesc),
        },
        _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_SUB_CATEGORY_CODE] = {
            value: getCodeAndDescription((_h = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.salesCategory) === null || _h === void 0 ? void 0 : _h.subCategoryCode, (_j = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.salesCategory) === null || _j === void 0 ? void 0 : _j.subCategoryDesc),
        },
        _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_TYPE_CODE] = {
            value: getCodeAndDescription((_k = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.salesCategory) === null || _k === void 0 ? void 0 : _k.typeCode, (_l = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.salesCategory) === null || _l === void 0 ? void 0 : _l.typeDesc),
        },
        _a);
};
var getCodeAndDescription = function (code, desc) {
    if (!code)
        return '';
    var truncatedDesc = '';
    if (desc) {
        truncatedDesc = desc.length > 20 ? "".concat(desc.substring(0, 20), "...") : desc;
    }
    return "".concat(code, "(").concat(truncatedDesc, ")");
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-underscore-dangle */
import { useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { StyledButton } from 'components/Shared/StyledComponents/StyledButton';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { debounce } from '@mui/material/utils';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import { useQueryProducts } from 'hooks/useQueryProducts';
import { useGetPrevAdHocDefinition } from 'hooks/useGetPrevAdHocDefinition';
import CircularProgress from '@mui/material/CircularProgress';
import { catalogHelper } from 'utils/catalog-helper';
import { CatalogType } from 'models/catalogs/CatalogType';
import { publishCatalogEventRequest } from 'services/api/catalog-service';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { alphaNumericOnly, dateFormat } from 'services/helperFunctions/handleRegex';
import { ErrorAdornment } from 'components/Shared/ErrorAdornment/ErrorAdornment';
import { catTypeKey } from 'utils/constants';
import { PageContainer } from 'components/Shared/PageContainer/PageContainer';
import Tooltip from '@mui/material/Tooltip';
import { ProductsTable } from './ProductsTable/ProductsTable';
import { HierarchyModal } from './HierarchyModal/HierarchyModal';
import styles from './CatalogRequestAdHoc.module.scss';
import { HierarchiesTable } from './HierarchiesTable/HierarchiesTable';
export function CatalogRequestAdHoc() {
    var _this = this;
    var _a, _b;
    var navigate = useNavigate();
    var location = useLocation();
    var _c = useGetPrevAdHocDefinition((_a = location.state) === null || _a === void 0 ? void 0 : _a.prevDefinitionRequestId), prevDefinition = _c.definition, prevDefProducts = _c.products, prevDefHierarchies = _c.hierarchies, productsLoading = _c.productsLoading, definitionLoading = _c.definitionLoading;
    var _d = useState(false), resetEnabled = _d[0], setResetEnabled = _d[1];
    var _e = useState(''), catDesc = _e[0], setCatDesc = _e[1];
    var _f = useState(''), filePrefix = _f[0], setFilePrefix = _f[1];
    var _g = useState(catalogHelper.getTodayLocalDateString()), effectiveDate = _g[0], setEffectiveDate = _g[1];
    var _h = useState(''), priceDateOverride = _h[0], setPriceDateOverride = _h[1];
    var _j = useState(false), includeNonPrmtbl = _j[0], setIncludeNonPromoted = _j[1];
    var _k = useState(CatalogType.PRICEBOOK), selectedCatType = _k[0], setSelectedCatType = _k[1];
    var _l = useState(99), selectedLeadTime = _l[0], setSelectedLeadTime = _l[1];
    var _m = useState('NA CONTRACT'), selectedChannel = _m[0], setSelectedChannel = _m[1];
    var _o = useState(['USD']), selectedCurrencies = _o[0], setSelectedCurrencies = _o[1];
    var _p = useState([]), selectedHierarchies = _p[0], setSelectedHierarchies = _p[1];
    var _q = useState('03:00'), effectiveTime = _q[0], setEffectiveTime = _q[1];
    var _r = useState({
        catDesc: false,
        filePrefix: false,
        effectiveDate: false,
        selectedProducts: false,
        selectedHierarchies: false,
    }), inputErrors = _r[0], setInputErrors = _r[1];
    var _s = useState([]), selectedProducts = _s[0], setSelectedProducts = _s[1];
    var _t = useState([]), nonErrorSelectedProducts = _t[0], setNonErrorSelectedProducts = _t[1];
    var _u = useState(''), productQuery = _u[0], setProductQuery = _u[1];
    /**
     * queryDisplayText needed to not clear the product search textfield's text on product selection
     */
    var _v = useState(''), queryDisplayText = _v[0], setQueryDisplayText = _v[1];
    var _w = useState(false), searchOpen = _w[0], setSearchOpen = _w[1];
    var _x = useState(false), hierarchyModalOpen = _x[0], setHierarchyModalOpen = _x[1];
    var controllerRef = useRef();
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var _y = useQueryProducts(selectedLeadTime, effectiveDate, selectedChannel, selectedCatType, includeNonPrmtbl, (_b = controllerRef.current) === null || _b === void 0 ? void 0 : _b.signal, productQuery, inputErrors.effectiveDate), loading = _y.loading, products = _y.body;
    var selectedProductKeys = function () {
        var s = {};
        selectedProducts.forEach(function (p) {
            s["".concat(p.key._id)] = p.key._id;
        });
        return s;
    };
    var selectedHierarchyKeys = function () {
        var s = {};
        selectedHierarchies.forEach(function (h) {
            var k = catalogHelper.searchHierarchyToKey(h);
            s[k] = k;
        });
        return s;
    };
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (prevDefinition) {
            setCatDesc(prevDefinition.catalogDesc);
            setFilePrefix(prevDefinition.fileNamePrefix);
            setEffectiveDate(prevDefinition.effectiveDate.substring(0, 10));
            setEffectiveTime(new Date(prevDefinition.effectiveDate).toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
            }));
            setPriceDateOverride((_b = (_a = prevDefinition === null || prevDefinition === void 0 ? void 0 : prevDefinition.priceListDate) === null || _a === void 0 ? void 0 : _a.substring(0, 10)) !== null && _b !== void 0 ? _b : '');
            setIncludeNonPromoted((_c = prevDefinition === null || prevDefinition === void 0 ? void 0 : prevDefinition.includeNonPrmtbl) !== null && _c !== void 0 ? _c : false);
            setSelectedCatType(prevDefinition.catalogType);
            setSelectedLeadTime((_d = prevDefinition === null || prevDefinition === void 0 ? void 0 : prevDefinition.leadTime) !== null && _d !== void 0 ? _d : 99);
            setSelectedChannel((_h = (_g = (_f = (_e = prevDefinition === null || prevDefinition === void 0 ? void 0 : prevDefinition.channels) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.channelCode) === null || _g === void 0 ? void 0 : _g.toUpperCase()) !== null && _h !== void 0 ? _h : 'NA CONTRACT');
            setSelectedCurrencies((_k = (_j = prevDefinition === null || prevDefinition === void 0 ? void 0 : prevDefinition.currency) === null || _j === void 0 ? void 0 : _j.filter(function (c) { return (c === null || c === void 0 ? void 0 : c.currencyCode) !== undefined; }).sort(function (a, b) {
                return ((a === null || a === void 0 ? void 0 : a.currencySequence) ? +a.currencySequence : 99) >
                    ((b === null || b === void 0 ? void 0 : b.currencySequence) ? +b.currencySequence : 99)
                    ? 1
                    : -1;
            }).map(function (c) { return c.currencyCode; })) !== null && _k !== void 0 ? _k : ['USD']);
        }
    }, [prevDefinition]);
    useEffect(function () {
        setSelectedProducts(prevDefProducts !== null && prevDefProducts !== void 0 ? prevDefProducts : []);
    }, [prevDefProducts]);
    useEffect(function () {
        setSelectedHierarchies(prevDefHierarchies !== null && prevDefHierarchies !== void 0 ? prevDefHierarchies : []);
    }, [prevDefHierarchies]);
    var LEAD_TIMES = [10, 20, 99];
    var CHANNELS = [
        {
            channelCode: 'B2B',
        },
        {
            channelCode: 'GSA',
        },
        {
            channelCode: 'NA CONTRACT',
        },
    ];
    var CURRENCIES = ['USD', 'CAD'];
    var handleAbort = function () {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        var controller = new AbortController();
        controllerRef.current = controller;
    };
    var handleSearchInputDebounced = useMemo(function () {
        return debounce(function (text) {
            handleAbort();
            setProductQuery(text);
        }, 350);
    }, []);
    var validateFields = function () {
        var inputError = !catDesc ||
            catDesc.length === 0 ||
            !filePrefix ||
            filePrefix === '' ||
            !alphaNumericOnly(filePrefix) ||
            !dateFormat(effectiveDate) ||
            (selectedHierarchies.length === 0 &&
                (selectedProducts.length === 0 || nonErrorSelectedProducts.length === 0));
        var errors = {
            catDesc: !catDesc || catDesc === '',
            filePrefix: !filePrefix || filePrefix === '' || !alphaNumericOnly(filePrefix),
            effectiveDate: !dateFormat(effectiveDate),
            selectedProducts: selectedProducts.length === 0,
            selectedHierarchies: selectedHierarchies.length === 0,
        };
        setInputErrors(errors);
        return {
            hasError: inputError,
            errors: errors,
        };
    };
    useEffect(function () {
        var catType = catTypeKey[selectedCatType];
        var hasErrors = function (product) {
            var _a, _b, _c, _d;
            return !((_a = product.body) === null || _a === void 0 ? void 0 : _a.minLeadtime) ||
                product.body.minLeadtime > selectedLeadTime ||
                !((_b = product.body) === null || _b === void 0 ? void 0 : _b.channels) ||
                !product.body.channels.find(function (ch) { return ch.code.toLowerCase() === selectedChannel.toLowerCase(); }) ||
                catalogHelper.setDateToActualValue(product.body.startDate).getTime() >
                    catalogHelper.setDateToActualValue(effectiveDate).getTime() ||
                (((_c = product.body) === null || _c === void 0 ? void 0 : _c.endDate) !== undefined &&
                    catalogHelper.setDateToActualValue(product.body.endDate).getTime() <
                        catalogHelper.setDateToActualValue(effectiveDate).getTime()) ||
                (!includeNonPrmtbl &&
                    !((_d = product.body.channels) === null || _d === void 0 ? void 0 : _d.find(function (ch) { return ch === null || ch === void 0 ? void 0 : ch[catType]; })));
        };
        setNonErrorSelectedProducts(selectedProducts.filter(function (p) { return !hasErrors(p); }));
    }, [effectiveDate, includeNonPrmtbl, selectedCatType, selectedChannel, selectedLeadTime, selectedProducts]);
    var handleSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
        var errorCheck, errorString, hierarchies, event;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    errorCheck = validateFields();
                    if (errorCheck.hasError) {
                        errorString = "Error(s): ".concat(errorCheck.errors.catDesc ? 'Catalog Description, ' : '').concat(errorCheck.errors.effectiveDate ? 'Effective Date, ' : '').concat(errorCheck.errors.filePrefix ? 'File Prefix, ' : '').concat(errorCheck.errors.selectedHierarchies && errorCheck.errors.selectedProducts
                            ? 'No valid products or hierarchies selected, '
                            : '');
                        setSnackbarAlert({
                            message: errorString.substring(0, errorString.length - 2),
                            type: 'error',
                        });
                        return [2 /*return*/];
                    }
                    hierarchies = [];
                    nonErrorSelectedProducts.forEach(function (prod) {
                        var _a, _b, _c;
                        var id = catalogHelper.getProductNumberFromVersion((_b = (_a = prod.body) === null || _a === void 0 ? void 0 : _a.productId) !== null && _b !== void 0 ? _b : prod.key._id);
                        var productHierarchies = (_c = prod.body) === null || _c === void 0 ? void 0 : _c.hierarchy;
                        if (!productHierarchies || productHierarchies.length === 0) {
                            return;
                        }
                        var productHierarchy = catalogHelper.getProductHierarchy(productHierarchies);
                        hierarchies.push(catalogHelper.convertToHierarchyType(productHierarchy, id));
                    });
                    selectedHierarchies.forEach(function (h) {
                        hierarchies.push(catalogHelper.convertToHierarchyType(h));
                    });
                    return [4 /*yield*/, catalogHelper.buildCatalogEvent({
                            hierarchies: hierarchies,
                            effectiveDate: effectiveDate,
                            effectiveTime: effectiveTime,
                            catalogDesc: catDesc,
                            catalogType: selectedCatType,
                            channel: selectedChannel,
                            fileNamePrefix: filePrefix,
                            includeNonPrmtbl: includeNonPrmtbl,
                            leadTime: selectedLeadTime,
                            priceListDate: priceDateOverride && priceDateOverride !== ''
                                ? catalogHelper.setDateToActualValueWithTime(priceDateOverride, effectiveTime).toISOString()
                                : undefined,
                            currency: selectedCurrencies.map(function (c, i) { return ({ currencyCode: c, currencySequence: "".concat(i + 1) }); }),
                        })];
                case 1:
                    event = _a.sent();
                    publishCatalogEventRequest([event]);
                    navigate('/catalogs/requests');
                    return [2 /*return*/];
            }
        });
    }); };
    var handleProductSelect = function (product, deselect) {
        if (deselect) {
            var index = selectedProducts.findIndex(function (p) { return p.body.productVersion === product.body.productVersion; });
            if (index >= 0) {
                handleRemoveProductByIndex(index);
                return;
            }
        }
        setSelectedProducts(function (prev) { return __spreadArray(__spreadArray([], prev, true), [product], false); });
    };
    var handleHierarchySelect = function (hierarchy) {
        var updated = __spreadArray([], selectedHierarchies, true);
        var equalIndex = false;
        var key = catalogHelper.searchHierarchyToKey(hierarchy);
        var indices = [];
        selectedHierarchies.forEach(function (h, i) {
            var selectedKey = catalogHelper.searchHierarchyToKey(h);
            if (!equalIndex) {
                equalIndex = key === selectedKey;
            }
            if (selectedKey.includes(key) || key.includes(selectedKey) || equalIndex) {
                indices.push(i);
            }
        });
        if (indices.length > 0) {
            updated = removeHierarchyIndices(indices);
        }
        if (!equalIndex) {
            updated.push(hierarchy);
        }
        setSelectedHierarchies(updated);
    };
    var handleRemoveProductByIndex = function (index) {
        var updatedSelected = __spreadArray([], selectedProducts, true);
        updatedSelected.splice(index, 1);
        setSelectedProducts(updatedSelected);
    };
    var removeHierarchyByIndex = function (index) {
        var updatedSelected = __spreadArray([], selectedHierarchies, true);
        updatedSelected.splice(index, 1);
        setSelectedHierarchies(updatedSelected);
    };
    var removeHierarchyIndices = function (indices) {
        var updatedSelected = __spreadArray([], selectedHierarchies, true);
        indices.reverse().forEach(function (index) {
            updatedSelected.splice(index, 1);
        });
        return updatedSelected;
    };
    var handleReset = function () {
        setCatDesc('');
        setFilePrefix('');
        setEffectiveDate(catalogHelper.getTodayLocalDateString());
        setEffectiveTime('03:00');
        setPriceDateOverride('');
        setIncludeNonPromoted(false);
        setSelectedCatType(CatalogType.PRICEBOOK);
        setSelectedLeadTime(99);
        setSelectedChannel('NA CONTRACT');
        setSelectedCurrencies(['USD']);
        setSelectedHierarchies([]);
        setSelectedProducts([]);
        setNonErrorSelectedProducts([]);
        setQueryDisplayText('');
        setInputErrors({
            catDesc: false,
            filePrefix: false,
            effectiveDate: false,
            selectedProducts: false,
            selectedHierarchies: false,
        });
        setSnackbarAlert({
            message: 'All fields and selected products reset.',
            type: 'info',
        });
    };
    useEffect(function () {
        setResetEnabled(catDesc !== '' ||
            filePrefix !== '' ||
            effectiveDate !== catalogHelper.getTodayLocalDateString() ||
            priceDateOverride !== '' ||
            includeNonPrmtbl ||
            selectedCatType !== CatalogType.PRICEBOOK ||
            selectedLeadTime !== 99 ||
            selectedChannel !== 'NA CONTRACT' ||
            selectedCurrencies.join('') !== 'USD' ||
            selectedProducts.length > 0 ||
            selectedHierarchies.length > 0);
    }, [
        catDesc,
        effectiveDate,
        filePrefix,
        includeNonPrmtbl,
        priceDateOverride,
        selectedCatType,
        selectedChannel,
        selectedCurrencies,
        selectedHierarchies.length,
        selectedLeadTime,
        selectedProducts.length,
    ]);
    var icon = _jsx(CheckBoxOutlineBlankIcon, { fontSize: "small" });
    var checkedIcon = _jsx(CheckBoxIcon, { fontSize: "small" });
    var required = _jsx("span", __assign({ style: { color: 'red' } }, { children: "*" }));
    return (_jsxs(PageContainer, __assign({ rowGap: 16 }, { children: [_jsx(Typography, __assign({ variant: "h1" }, { children: "Ad Hoc Catalog Request" })), _jsxs(Box, __assign({ className: styles.titleButtonContainer }, { children: [_jsxs(Box, __assign({ className: styles.buttonContainer }, { children: [_jsx(Typography, __assign({ variant: "h2" }, { children: "Additional Processing Requirements" })), _jsx(StyledButton, { label: "Reset Fields", widthFromProps: 104, handleClick: function () { return handleReset(); }, hideButton: !resetEnabled })] })), _jsxs(Box, __assign({ className: styles.buttonContainer }, { children: [_jsx(StyledButton, { label: "Submit", handleClick: function () { return handleSubmit(); } }), _jsx(Link, __assign({ className: styles.backLink, to: "/catalogs" }, { children: "Back" }))] }))] })), _jsx(Box, __assign({ className: styles.inputRowContainer }, { children: _jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsxs(Typography, __assign({ className: definitionLoading ? styles.disabledLabel : styles.label }, { children: ["Catalog Description", required] })), _jsx(TextField, { required: true, size: "small", className: styles.textField, value: catDesc, disabled: definitionLoading, error: inputErrors.catDesc, onChange: function (e) {
                                setInputErrors(__assign(__assign({}, inputErrors), { catDesc: !e.target.value || e.target.value === '' }));
                                setCatDesc(e.target.value);
                            }, InputProps: {
                                endAdornment: inputErrors.catDesc && (_jsx(ErrorAdornment, { errors: [
                                        {
                                            condition: inputErrors.catDesc,
                                            message: 'Invalid Catalog Description. Must be at least one character.',
                                        },
                                    ] })),
                            } })] })) })), _jsxs(Box, __assign({ className: styles.inputRowContainer }, { children: [_jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsxs(Typography, __assign({ className: definitionLoading ? styles.disabledLabel : styles.label }, { children: ["Effective Date", required] })), _jsx(TextField, { required: true, className: styles.textField, size: "small", type: "date", value: effectiveDate, disabled: definitionLoading, error: inputErrors.effectiveDate, onChange: function (e) {
                                    handleAbort();
                                    setInputErrors(__assign(__assign({}, inputErrors), { effectiveDate: !dateFormat(e.target.value) }));
                                    setEffectiveDate(e.target.value);
                                }, InputProps: {
                                    endAdornment: inputErrors.effectiveDate && (_jsx(ErrorAdornment, { errors: [{ condition: inputErrors.effectiveDate, message: 'Invalid Date' }], display: inputErrors.effectiveDate })),
                                } })] })), _jsx(Box, { children: _jsx(TextField, { id: "effectiveTime", value: effectiveTime, onChange: function (event) { return setEffectiveTime(event.target.value); }, disabled: definitionLoading, error: inputErrors.effectiveDate, InputProps: {
                                type: 'time',
                                style: {
                                    height: '40px',
                                    width: '125px',
                                },
                            } }) }), _jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsxs(Typography, __assign({ className: definitionLoading ? styles.disabledLabel : styles.label }, { children: ["File Prefix", required] })), _jsx(TextField, { required: true, className: styles.textField, size: "small", value: filePrefix, disabled: definitionLoading, error: inputErrors.filePrefix, onChange: function (e) {
                                    setInputErrors(__assign(__assign({}, inputErrors), { filePrefix: !e.target.value || e.target.value === '' || !alphaNumericOnly(e.target.value) }));
                                    setFilePrefix(e.target.value);
                                }, InputProps: {
                                    endAdornment: inputErrors.filePrefix && (_jsx(ErrorAdornment, { errors: [
                                            {
                                                condition: inputErrors.filePrefix,
                                                message: 'Invalid File Prefix. Must contain only alpha-numeric values with no spaces.',
                                            },
                                        ] })),
                                } })] })), _jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsx(Typography, __assign({ className: definitionLoading ? styles.disabledLabel : styles.label }, { children: "Price Date Override" })), _jsx(TextField, { className: styles.textField, size: "small", type: "date", value: priceDateOverride, disabled: definitionLoading, onChange: function (e) { return setPriceDateOverride(e.target.value); } })] })), _jsxs(Box, __assign({ className: styles.inputContainer }, { children: [_jsx(Typography, __assign({ className: definitionLoading ? styles.disabledLabel : styles.label }, { children: "Include Non-Promoted" })), _jsx(Checkbox, { className: styles.checkbox, checked: includeNonPrmtbl, disabled: definitionLoading, onClick: function () {
                                    handleAbort();
                                    setIncludeNonPromoted(!includeNonPrmtbl);
                                }, onKeyDown: function (e) {
                                    if (e.key === 'Enter') {
                                        handleAbort();
                                        setIncludeNonPromoted(!includeNonPrmtbl);
                                    }
                                } })] }))] })), _jsxs(Box, __assign({ className: styles.inputRowContainer }, { children: [_jsxs(FormControl, __assign({ className: styles.inputContainer }, { children: [_jsxs(Typography, __assign({ className: definitionLoading ? styles.disabledLabel : styles.label }, { children: ["Catalog Type", required] })), _jsx(TextField, __assign({ required: true, select: true, size: "small", value: selectedCatType, disabled: definitionLoading, onChange: function (e) {
                                    handleAbort();
                                    setSelectedCatType(e.target.value);
                                }, className: styles.textField }, { children: Object.values(CatalogType).map(function (type) { return (_jsx(MenuItem, __assign({ value: type }, { children: type }), type)); }) }))] })), _jsxs(FormControl, __assign({ className: styles.inputContainer }, { children: [_jsxs(Typography, __assign({ className: definitionLoading ? styles.disabledLabel : styles.label }, { children: ["Lead Time", required] })), _jsx(TextField, __assign({ required: true, select: true, size: "small", value: selectedLeadTime, disabled: definitionLoading, onChange: function (e) {
                                    handleAbort();
                                    setSelectedLeadTime(+e.target.value);
                                }, className: styles.textField }, { children: LEAD_TIMES.map(function (lt) { return (_jsx(MenuItem, __assign({ value: lt }, { children: lt }), lt)); }) }))] })), _jsxs(FormControl, __assign({ className: styles.inputContainer }, { children: [_jsxs(Typography, __assign({ className: definitionLoading ? styles.disabledLabel : styles.label }, { children: ["Channel", required] })), _jsx(TextField, __assign({ required: true, select: true, size: "small", value: selectedChannel !== null && selectedChannel !== void 0 ? selectedChannel : '', disabled: definitionLoading, onChange: function (e) {
                                    handleAbort();
                                    setSelectedChannel(e.target.value);
                                }, className: styles.textField }, { children: CHANNELS.map(function (channel) { return (_jsx(MenuItem, __assign({ value: channel.channelCode }, { children: channel.channelCode }), channel.channelCode)); }) }))] })), _jsxs(FormControl, __assign({ className: styles.inputContainer }, { children: [_jsxs(Typography, __assign({ className: definitionLoading ? styles.disabledLabel : styles.label }, { children: ["Currency", required] })), _jsx(TextField, __assign({ required: true, select: true, size: "small", disabled: definitionLoading, SelectProps: {
                                    multiple: true,
                                    value: selectedCurrencies,
                                    renderValue: function (selected) { return selected.join(', '); },
                                    onChange: function (e) { return setSelectedCurrencies(e.target.value); },
                                    MenuProps: {
                                        sx: {
                                            li: {
                                                columnGap: '9px',
                                                span: {
                                                    padding: 0,
                                                },
                                            },
                                        },
                                    },
                                }, className: styles.textField }, { children: CURRENCIES.map(function (currency) { return (_jsxs(MenuItem, __assign({ value: currency }, { children: [_jsx(Checkbox, { className: styles.checkbox, checked: selectedCurrencies.includes(currency) }), currency] }), currency)); }) }))] }))] })), _jsxs(Box, __assign({ className: styles.searchTableContainer }, { children: [_jsxs(Box, __assign({ className: styles.searchBarButtonContainer }, { children: [_jsx(Autocomplete, { options: products, noOptionsText: "No matching products", open: searchOpen, loading: loading, loadingText: "Loading...", inputValue: queryDisplayText, multiple: true, autoHighlight: true, disableClearable: true, openOnFocus: true, freeSolo: true, clearOnBlur: false, forcePopupIcon: false, disableCloseOnSelect: true, disabled: definitionLoading, getOptionLabel: function (prod) {
                                    var _a;
                                    return "".concat(prod.body.prefix, " - ").concat((_a = prod.body.productName) === null || _a === void 0 ? void 0 : _a[0].desc);
                                }, filterOptions: function (options) { return (loading ? [] : options); }, isOptionEqualToValue: function (prod, selected) { return prod.key._id === selected.key._id; }, value: selectedProducts, renderTags: function () { return null; }, onOpen: function () { return setSearchOpen(true); }, onClose: function () { return setSearchOpen(false); }, onChange: function (_, prod) {
                                    setSelectedProducts(prod);
                                    setNonErrorSelectedProducts(prod);
                                }, renderOption: function (props, option, _a) {
                                    var _b;
                                    var selected = _a.selected;
                                    return (_createElement("li", __assign({}, props, { key: option.key._id }),
                                        _jsx(Checkbox, { className: styles.checkbox, icon: icon, checkedIcon: checkedIcon, style: { marginRight: 8 }, checked: selected }), "".concat(option.body.prefix, " - ").concat((_b = option.body.productName) === null || _b === void 0 ? void 0 : _b[0].desc)));
                                }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { size: "small", fullWidth: true, id: "descAppendText", value: productQuery, label: "Search Products (Hierarchy, Description, SIF or CET Filename, Channel, Lead Time, etc)", onInput: function (event) {
                                        var text = event.target.value;
                                        handleSearchInputDebounced(text);
                                        setQueryDisplayText(text);
                                    }, InputProps: __assign(__assign({}, params.InputProps), { endAdornment: loading ? _jsx(CircularProgress, { size: "24px" }) : _jsx(SearchIcon, {}) }) }))); } }), _jsx(Tooltip, __assign({ title: "Hierarchy Search", arrow: true }, { children: _jsxs(IconButton, __assign({ className: styles.hierarchyButton, onClick: function () { return setHierarchyModalOpen(true); } }, { children: [_jsx("div", { children: "H" }), _jsx(FilterListOutlinedIcon, {})] })) }))] })), selectedHierarchies.length > 0 && (_jsx(HierarchiesTable, { selectedHierarchies: selectedHierarchies, removeIndex: removeHierarchyByIndex })), _jsx(ProductsTable, { products: selectedProducts, selectedLeadTime: selectedLeadTime, channel: selectedChannel, effectiveDate: effectiveDate, selectedCatType: selectedCatType, includeNonPromotable: includeNonPrmtbl, nonErroredProducts: nonErrorSelectedProducts, removeIndex: handleRemoveProductByIndex, loading: productsLoading })] })), hierarchyModalOpen && (_jsx(HierarchyModal, { isOpen: hierarchyModalOpen, closeModal: function (s) {
                    setSelectedProducts(function (prev) { return __spreadArray(__spreadArray([], prev, true), s, true); });
                    setHierarchyModalOpen(false);
                }, validations: {
                    effectiveDate: effectiveDate,
                    channelCode: selectedChannel,
                    minLeadtime: selectedLeadTime,
                    sifPromotable: selectedCatType === CatalogType.MARKETING_SIF,
                    cetPromotable: selectedCatType === CatalogType.CET_XML,
                    priceBookPromotable: selectedCatType === CatalogType.PRICEBOOK,
                }, prevSelected: { products: selectedProductKeys(), hierarchies: selectedHierarchyKeys() }, selectProduct: handleProductSelect, selectHierarchy: handleHierarchySelect }))] })));
}

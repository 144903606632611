var formatDateString = function (dateString, includeTime) {
    if (!dateString)
        return '';
    // done to prevent date conversions and simply display the date passed in as is
    // ex: if '2024-01-01' is passed in '1/1/2024' will be returned instead of '12/31/23'
    var isoDateWithoutZ = new Date(dateString).toISOString().replace('Z', '');
    var d = new Date(isoDateWithoutZ);
    var date = d.toLocaleDateString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    if (includeTime) {
        var time = d.toLocaleTimeString(undefined, { timeStyle: 'short' });
        return "".concat(date, " ").concat(time);
    }
    return "".concat(date);
};
/**
 *
 * @param isoString date string in ISO format, ex: 2024-04-04T13:30:32.864Z
 * @param includeTime determine to include timestamp in format: hh:mm AM/PM
 * @param toLocalTime determine to convert date to local time or UTC
 * @returns a formatted date string in the format: yyyy-MM-dd or yyyy-MM-dd hh:mm AM/PM
 */
var formatIsoString = function (isoString, includeTime, toLocalTime) {
    var isoDateStringRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    if (!isoDateStringRegex.test(isoString))
        return isoString;
    var newDate = new Date(isoString);
    var dateRegex = /\b(\d{2})\/(\d{2})\/(\d{4})\b/g;
    var formattedDate;
    if (toLocalTime) {
        formattedDate = newDate
            .toLocaleString(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: undefined,
        })
            .replace(dateRegex, function (match, m, d, y) { return "".concat(y, "-").concat(m, "-").concat(d); });
    }
    else {
        formattedDate = newDate
            .toLocaleString(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'UTC',
        })
            .replace(dateRegex, function (match, m, d, y) { return "".concat(y, "-").concat(m, "-").concat(d); });
    }
    var _a = formattedDate.split(', '), date = _a[0], time = _a[1];
    return "".concat(date).concat(includeTime ? " ".concat(time) : '');
};
/**
 *
 * @param dateString date string in format yyyyMMdd
 * @returns date string in format yyyy-MM-dd
 */
var yyyyMMddHyphenated = function (dateString) { return dateString.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'); };
/**
 *
 * @param format Format for which the date should be returned
 * @returns Today's date in the desired format. Default: yyyyMMdd
 */
var todaysDateFormattedLocale = function (_a, dateVal) {
    var format = _a.format;
    var date = new Date();
    if (dateVal) {
        date = dateVal;
    }
    var year = date.toLocaleString('default', { year: 'numeric' });
    var month = date.toLocaleString('default', { month: '2-digit' });
    var day = date.toLocaleString('default', { day: '2-digit' });
    switch (format) {
        case 'yyyy-MM-dd':
            return "".concat(year, "-").concat(month, "-").concat(day);
        default:
            return "".concat(year).concat(month).concat(day);
    }
};
/**
 * @param isoString date string in ISO format, ex: 2024-04-04T13:30:32.864Z
 * @param includeTime determine to include timestamp in format: hh:mm AM/PM
 * @param toLocalTime determine to convert date to local time or UTC
 * @returns a formatted date string in the format: MM-dd-yyyy or MM-dd-yyyy hh:mm AM/PM
 */
var formatIsoStringMMDDYYYY = function (isoString, includeTime, toLocalTime) {
    var isoDateStringRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    if (!isoDateStringRegex.test(isoString))
        return isoString;
    var newDate = new Date(isoString);
    var dateRegex = /\b(\d{2})\/(\d{2})\/(\d{4})\b/g;
    var formattedDate;
    if (toLocalTime) {
        formattedDate = newDate
            .toLocaleString(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: undefined,
        })
            .replace(dateRegex, function (match, m, d, y) { return "".concat(m, "-").concat(d, "-").concat(y); });
    }
    else {
        formattedDate = newDate
            .toLocaleString(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'UTC',
        })
            .replace(dateRegex, function (match, m, d, y) { return "".concat(m, "-").concat(d, "-").concat(y); });
    }
    var _a = formattedDate.split(', '), date = _a[0], time = _a[1];
    return "".concat(date).concat(includeTime ? " ".concat(time) : '');
};
/**
 *
 * @param dateString date string in format yyyy-MM-dd
 * @returns date string in format yyyyMMdd
 */
var formattedDateInyyyyMMdd = function (dateString) {
    var dateObj = new Date(dateString);
    return dateObj.toISOString().slice(0, 10).replace(/-/g, '');
};
/**
 *
 * @param dateString date string in format yyyyMMdd or yyyy-MM-dd
 * @returns date string in format MM-dd-yyyy
 */
var formatDateMMddyyyyWithHyphen = function (dateString) {
    var cleanedDate = dateString.replace(/-/g, '');
    var year = cleanedDate.substring(0, 4);
    var month = cleanedDate.substring(4, 6);
    var day = cleanedDate.substring(6, 8);
    return "".concat(month, "-").concat(day, "-").concat(year);
};
var dateFormatHelper = {
    formatDateString: formatDateString,
    yyyyMMddHyphenated: yyyyMMddHyphenated,
    todaysDateFormattedLocale: todaysDateFormattedLocale,
    formatIsoString: formatIsoString,
    formatIsoStringMMDDYYYY: formatIsoStringMMDDYYYY,
    formattedDateInyyyyMMdd: formattedDateInyyyyMMdd,
    formatDateMMddyyyyWithHyphen: formatDateMMddyyyyWithHyphen,
};
export { dateFormatHelper };

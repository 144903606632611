var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { useLanguageCode } from 'services/helperFunctions/useLanguageCode';
import { useCallback, useEffect, useState } from 'react';
import { infoSelect } from 'services/api/product-service';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledButton } from 'components/Shared/StyledComponents/StyledButton';
import { JsonViewer } from 'components/Shared/JsonViewer/JsonViewer';
import { OptionRow } from './OptionRow/OptionRow';
import styles from './FeatureRow.module.scss';
export function FeatureRow(_a) {
    var _this = this;
    var feature = _a.feature, productVersion = _a.productVersion, bgColor = _a.bgColor, showBorderBottom = _a.showBorderBottom, featuresMap = _a.featuresMap;
    var DEFAULT_RULE_COUNT = 5;
    var RULE_COUNT_INCREMENT = 25;
    var languageCode = useLanguageCode();
    var _b = useState(false), isExpanded = _b[0], setIsExpanded = _b[1];
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useState(DEFAULT_RULE_COUNT), rulesToShow = _d[0], setRulesToShow = _d[1];
    var _e = useState('Show 5 Rules'), showMoreLabel = _e[0], setShowMoreLabel = _e[1];
    var _f = useState(), dependencyRules = _f[0], setDependencyRules = _f[1];
    var _g = useState(0), totalRuleCount = _g[0], setTotalRuleCount = _g[1];
    var _h = useState(0), totalCTypeRulesCount = _h[0], setTotalCTypeRulesCount = _h[1];
    var _j = useState({
        options: {},
        optionCount: 0,
        retrieved: false,
    }), options = _j[0], setOptions = _j[1];
    var _k = useState(), fullFeature = _k[0], setFullFeature = _k[1];
    var _l = useState(false), isModalOpen = _l[0], setIsModalOpen = _l[1];
    var handleModalClose = function () {
        setIsModalOpen(false);
    };
    var collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base',
    });
    var getDesc = function (description) {
        var _a;
        if (!description)
            return '';
        return (_a = description === null || description === void 0 ? void 0 : description[languageCode]) === null || _a === void 0 ? void 0 : _a.desc;
    };
    var transformRuleKey = function (key) {
        var keyParts = key.split(/(\d+)/);
        return "".concat(keyParts[0].substring(0, 1).toLocaleUpperCase()).concat(keyParts[0].substring(1)).concat((keyParts === null || keyParts === void 0 ? void 0 : keyParts[1]) ? " ".concat(keyParts[1]) : '');
    };
    useEffect(function () {
        setShowMoreLabel(getShowMoreLabel(Object.keys(dependencyRules !== null && dependencyRules !== void 0 ? dependencyRules : {}).length));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rulesToShow]);
    var handleShowMore = function (totalItems) {
        var nextIncrementAmount = rulesToShow + RULE_COUNT_INCREMENT;
        if (rulesToShow === totalItems) {
            setRulesToShow(DEFAULT_RULE_COUNT);
            return;
        }
        if (nextIncrementAmount > totalItems) {
            setRulesToShow(totalItems);
            return;
        }
        setRulesToShow(nextIncrementAmount);
    };
    var getShowMoreLabel = useCallback(function (totalItems) {
        var nextIncrementAmount = rulesToShow + RULE_COUNT_INCREMENT;
        if (rulesToShow === totalItems) {
            return "Show ".concat(DEFAULT_RULE_COUNT, " Rules");
        }
        if (nextIncrementAmount > totalItems) {
            return "Show ".concat(totalItems - rulesToShow, " More Rule").concat(totalItems - rulesToShow === 1 ? '' : 's');
        }
        return "Show ".concat(RULE_COUNT_INCREMENT, " More Rules");
    }, [rulesToShow]);
    var getRuleStepText = function (dependency) {
        var _a, _b, _c, _d, _e;
        var dependencyKey = (_a = Object.keys(dependency)) === null || _a === void 0 ? void 0 : _a[0];
        var featureStep;
        var featureName = '';
        if (dependencyKey) {
            var dependencyFeature = featuresMap === null || featuresMap === void 0 ? void 0 : featuresMap[dependencyKey];
            if (dependencyFeature) {
                featureStep = (_b = dependencyFeature === null || dependencyFeature === void 0 ? void 0 : dependencyFeature.step) !== null && _b !== void 0 ? _b : -1;
                featureName = (_e = (_d = (_c = dependencyFeature === null || dependencyFeature === void 0 ? void 0 : dependencyFeature.description) === null || _c === void 0 ? void 0 : _c[languageCode]) === null || _d === void 0 ? void 0 : _d.desc) !== null && _e !== void 0 ? _e : '';
            }
        }
        return "Step ".concat(featureStep, ": ").concat(featureName, " (").concat(dependencyKey, ") - ").concat(dependency[dependencyKey]);
    };
    var handleFeatureExpand = function () { return __awaiter(_this, void 0, void 0, function () {
        var rsp, optionObj, depRules, depRulesCount, cTypeCount;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __generator(this, function (_l) {
            switch (_l.label) {
                case 0:
                    setIsExpanded(!isExpanded);
                    if (!!options.retrieved) return [3 /*break*/, 2];
                    setIsLoading(true);
                    return [4 /*yield*/, infoSelect({ featureId: feature.featureId, productVersion: productVersion }, 'options,feature')];
                case 1:
                    rsp = _l.sent();
                    optionObj = (_d = (_c = (_b = (_a = rsp.body) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.feature) === null || _c === void 0 ? void 0 : _c.options) !== null && _d !== void 0 ? _d : {};
                    depRules = (_h = (_g = (_f = (_e = rsp.body) === null || _e === void 0 ? void 0 : _e.details) === null || _f === void 0 ? void 0 : _f.feature) === null || _g === void 0 ? void 0 : _g.dependencyRules) !== null && _h !== void 0 ? _h : {};
                    depRulesCount = Object.keys(depRules !== null && depRules !== void 0 ? depRules : {}).length;
                    cTypeCount = Object.entries(depRules).filter(function (rule) { var _a; return ((_a = rule[1]) === null || _a === void 0 ? void 0 : _a.ruleType) === 'C'; }).length;
                    setFullFeature((_k = (_j = rsp === null || rsp === void 0 ? void 0 : rsp.body) === null || _j === void 0 ? void 0 : _j.details) === null || _k === void 0 ? void 0 : _k.feature);
                    setOptions({ options: optionObj, optionCount: Object.keys(optionObj).length, retrieved: true });
                    setDependencyRules(depRules);
                    setShowMoreLabel(getShowMoreLabel(depRulesCount));
                    setTotalRuleCount(depRulesCount);
                    setTotalCTypeRulesCount(cTypeCount);
                    setIsLoading(false);
                    _l.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsxs(TableRow, __assign({ className: styles.featureRow, sx: {
                    backgroundColor: bgColor,
                    borderBottom: showBorderBottom || isExpanded ? '1px solid gray' : 'none',
                } }, { children: [_jsx(TableCell, __assign({ sx: {
                            borderBottom: showBorderBottom || isExpanded ? '1px solid gray' : 'none',
                        } }, { children: _jsx(Typography, { children: feature === null || feature === void 0 ? void 0 : feature.step }) })), _jsx(TableCell, __assign({ sx: {
                            borderBottom: showBorderBottom || isExpanded ? '1px solid gray' : 'none',
                        } }, { children: _jsx(Typography, { children: feature === null || feature === void 0 ? void 0 : feature.featureId }) })), _jsxs(TableCell, { children: [_jsx(Typography, { children: getDesc(feature === null || feature === void 0 ? void 0 : feature.description) }), !options.retrieved && isLoading ? (_jsx(IconButton, { children: _jsx(CircularProgress, { size: "1.5rem" }) })) : (_jsxs(IconButton, __assign({ onClick: function () { return handleFeatureExpand(); } }, { children: [!isExpanded && _jsx(KeyboardArrowDownIcon, {}), isExpanded && _jsx(KeyboardArrowUpIcon, {})] })))] })] })), isExpanded && !isLoading && (_jsx(TableRow, { children: _jsxs(TableCell, __assign({ colSpan: 3, sx: { borderBottom: '1px solid gray' } }, { children: [_jsx(JsonViewer, { isOpen: isModalOpen, closeModal: function () { return handleModalClose(); }, stringifiedJson: JSON.stringify(__assign(__assign({}, fullFeature), { options: undefined }), null, 4) }), fullFeature && (_jsx(Box, __assign({ className: styles.jsonButtonContainer }, { children: _jsxs(IconButton, __assign({ onClick: function () { return setIsModalOpen(true); } }, { children: [_jsx(Typography, { children: "View Feature Details" }), _jsx(DataObjectIcon, {})] })) }))), _jsx(Collapse, __assign({ in: isExpanded }, { children: options.optionCount > 0 ? (_jsxs(_Fragment, { children: [dependencyRules !== undefined &&
                                        totalRuleCount > 0 &&
                                        totalCTypeRulesCount > 0 && (_jsxs(Box, __assign({ className: styles.depRulesButtonContainer, marginBottom: totalRuleCount > DEFAULT_RULE_COUNT ? 0 : '16px' }, { children: [_jsx(Box, __assign({ className: styles.depRulesContainer }, { children: Object.entries(dependencyRules)
                                                    .filter(function (rule) { var _a; return ((_a = rule[1]) === null || _a === void 0 ? void 0 : _a.ruleType) === 'C'; })
                                                    .sort(function (a, b) { return collator.compare(a[0], b[0]); })
                                                    .slice(0, rulesToShow)
                                                    .map(function (_a, i, depArr) {
                                                    var k = _a[0], v = _a[1];
                                                    return (_jsxs(Box, __assign({ className: styles.depRule }, { children: [_jsxs(Typography, { children: [transformRuleKey(k), ":"] }), _jsx(Typography, { children: getRuleStepText(v === null || v === void 0 ? void 0 : v.dependencies) }), i !== depArr.length - 1 && _jsx("hr", {})] })));
                                                }) })), totalRuleCount > DEFAULT_RULE_COUNT && (_jsx(StyledButton, { label: showMoreLabel, handleClick: function () { return handleShowMore(totalRuleCount); }, widthFromProps: 160, className: styles.showMoreBtn }))] }))), _jsx(Box, __assign({ sx: { width: '100%' } }, { children: Object.entries(options.options).map(function (_a, i) {
                                            var k = _a[0], v = _a[1];
                                            return (_jsx(Box, __assign({ className: styles.optionRowContainer }, { children: _jsx(OptionRow, { option: v, optionCode: k, isLast: i === options.optionCount - 1 }) }), k));
                                        }) }))] })) : (_jsx(Typography, __assign({ width: "100%", textAlign: "center" }, { children: "No Feature Options" }))) }))] })) }))] }));
}

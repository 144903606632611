var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/EditOutlined';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Link } from 'react-router-dom';
import { dateFormatHelper } from 'utils/date-format-helper';
import { InputFieldType } from 'models/Input/InputModels';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { tryCopy } from 'utils/clipboardHelper';
import { PubTableActionCell } from '../PubTableActionCell/PubTableActionCell';
import { TableRowDecorator } from '../TableRowDecorator/TableRowDecorator';
import { PubDeleteActionCell } from '../PubDeleteActionCell/PubDeleteActionCell';
export function PubTableDisplayRow(_a) {
    var _b;
    var def = _a.def, row = _a.row, editDisabled = _a.editDisabled, extraIconIsAction = _a.extraIconIsAction, tableData = _a.tableData, indexOfItem = _a.indexOfItem, isHovering = _a.isHovering, origin = _a.origin, handleEditClick = _a.handleEditClick, handleDeleteClick = _a.handleDeleteClick, handleExtraIconClick = _a.handleExtraIconClick;
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var handleCopyClick = function (code) {
        if (code === -1) {
            setSnackbarAlert({
                message: "Looks like your browser does not support automatic copying. Please copy the JSON.",
                type: 'error',
            });
        }
    };
    var linkableRow = function (column, i) {
        var _a, _b, _c;
        if (!column.clickToFireAction) {
            return (_jsx(TableCell, __assign({ sx: {
                    paddingLeft: "".concat(!def.rowDecorator && def.firstColumnIndent && i === 0 ? 16 : 0, "px !important"),
                    textAlign: column.alignRight ? 'right !important' : '',
                    textDecoration: ((_a = def.underlineColumnRow) === null || _a === void 0 ? void 0 : _a.includes(column.heading)) ? 'underline' : '',
                    '&:hover': {
                        button: {
                            display: 'inline-flex',
                        },
                    },
                } }, { children: column.allowCopy ? (_jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Box, __assign({ sx: {
                                width: '70%',
                                overflowWrap: column.setOverflowWrap ? column.setOverflowWrap : 'initial',
                            } }, { children: column.formatter
                                ? column.formatter(row[column.dataAttribute])
                                : column.fieldType === InputFieldType.DATE
                                    ? dateFormatHelper.formatIsoString(row[column.dataAttribute])
                                    : row[column.dataAttribute] })), _jsx(Box, __assign({ sx: { width: '30%', display: 'flex', justifyContent: 'center' } }, { children: _jsx(IconButton, __assign({ onClick: function () { return handleCopyClick(tryCopy(row[column.dataAttribute])); }, sx: {
                                    display: 'none',
                                    height: '20px',
                                    width: '20px',
                                    color: 'gray',
                                } }, { children: _jsx(ContentCopyIcon, { sx: { height: '20px' } }) })) }))] }))) : (_jsx(Box, __assign({ sx: {
                        overflowWrap: column.setOverflowWrap ? column.setOverflowWrap : 'initial',
                        fontFamily: isHovering
                            ? 'UntitledSansBold, UntitledSans, sans-serif, system-ui'
                            : 'UntitledSans, sans-serif, system-ui',
                    } }, { children: column.formatter
                        ? column.formatter(row[column.dataAttribute])
                        : column.fieldType === InputFieldType.DATE
                            ? dateFormatHelper.formatIsoString(row[column.dataAttribute])
                            : row[column.dataAttribute] }))) }), column.heading));
        }
        return (_jsx(TableCell, __assign({ sx: {
                paddingLeft: "".concat(!def.rowDecorator && def.firstColumnIndent && i === 0 ? 16 : 0, "px !important"),
                textAlign: column.alignRight ? 'right !important' : '',
                textDecoration: ((_b = def.underlineColumnRow) === null || _b === void 0 ? void 0 : _b.includes(column.heading)) ? 'underline' : '',
            } }, { children: _jsx(Link, __assign({ to: "/product-build-v2/build?productNbr=".concat(row[def.columns[0].dataAttribute], "&date=").concat(column.params.date, "&time=").concat(column.params.time, "&channel=").concat(column.params.channel, "&pid=").concat((_c = row.childProductId) !== null && _c !== void 0 ? _c : row.id, "&productVersion=").concat(row.version), style: { color: 'black' }, reloadDocument: true }, { children: row[column.dataAttribute] })) }), column.heading));
    };
    return (_jsxs(_Fragment, { children: [def.rowDecorator && (_jsx(TableRowDecorator, { show: !def.rowDecorator.hide(row), value: def.rowDecorator.value, margin: "0 16px 0 8px !important" })), def.columns.map(function (column, i) { return linkableRow(column, i); }), def.extraIcon && (!def.extraIcon.hide || !def.extraIcon.hide(row)) ? (_jsx(PubTableActionCell, { handleActionClick: handleExtraIconClick, icon: def.extraIcon.icon, def: def, extraIconIsAction: extraIconIsAction, tableData: tableData, indexOfItem: indexOfItem, isHovering: isHovering, origin: origin })) : (def.extraIcon && _jsx(TableCell, {})), def.editHandler && (_jsx(PubTableActionCell, { disabled: editDisabled, handleActionClick: function () { return handleEditClick(); }, icon: _jsx(EditIcon, {}) })), ((_b = def.deleteProps) === null || _b === void 0 ? void 0 : _b.deleteHandler) && handleDeleteClick && (_jsx(PubDeleteActionCell, { deleteProps: def.deleteProps, deleteAction: function () { return handleDeleteClick(); } }))] }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo, useReducer } from 'react';
import { cpaProductContextInitialState, cpaProductReducer, } from './CpaProductReducer';
var CpaProductContext = createContext(null);
export function CpaProductContextProvider(_a) {
    var children = _a.children;
    var _b = useReducer(cpaProductReducer, cpaProductContextInitialState), data = _b[0], dispatch = _b[1];
    var value = useMemo(function () { return ({
        data: data,
        dispatch: dispatch,
    }); }, [data, dispatch]);
    return _jsx(CpaProductContext.Provider, __assign({ value: value }, { children: children }));
}
export var useCpaProduct = function () { return useContext(CpaProductContext); };

// returns false if there is a space detected
export var noSpaces = function (val) { return !/[\s]/g.test(val); };
// returns false if there is empty string detected
export var noSpacesAll = function (val) { return val.trim() !== ''; };
// returns true if space, underscore, or special char detected, limit amount of chars passed in
export var alphanumericHyphen = function (val, limit) {
    if (limit)
        return new RegExp("^[a-zA-Z0-9-]{0,".concat(limit, "}$"), 'g').test(val);
    return /^[a-zA-Z0-9-]*$/.test(val);
};
export var alphanumericHyphenUnderscore = function (val, limit) {
    if (limit)
        return new RegExp("^[a-zA-Z0-9-_]{0,".concat(limit, "}$"), 'g').test(val);
    return /^[a-zA-Z0-9-_]*$/.test(val);
};
// returns true for numbers only, no spaces, special characters, or letters
export var numbersOnly = function (val) { return /^[0-9]*$/g.test(val); };
export var wholeNumbersOnly = function (val) { return /^-?\d*$/.test(val); };
export var numbersGreaterThanZeroOnly = function (val) { return /^[1-9]\d*$/.test(val); };
export var alphaNumericOnly = function (val) { return /^[a-z0-9]+$/i.test(val); };
export var dateFormat = function (val) { return /^\d{4}-\d{2}-\d{2}$/.test(val); };
export var isImageType = function (type) { return /^image\//.test(type); };
export var generateFileTypeRegex = function (fileType) {
    var checkedType = fileType;
    if (fileType.charAt(0) === '.') {
        checkedType = fileType.substring(1);
    }
    var _a = checkedType.split('/'), type = _a[0], extension = _a[1];
    return new RegExp("".concat(type).concat(checkedType.includes('/') ? '/' : '').concat(extension && extension !== '*' ? extension : ''), 'g');
};

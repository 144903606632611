var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { useSearchProduct } from 'context/searchProduct/SearchProductProvider';
import { SearchProductPropertyVal } from '@millerknoll/pub-api-schema';
import debounce from '@mui/material/utils/debounce';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { numbersOnly } from 'services/helperFunctions/handleRegex';
import { PRODUCT_VERSION_SEARCH_DEFAULTS, PRODUCT_VERSION_SEARCH_FIELD_TYPE, DISPLAY_NAMES, OPERATOR_DEFAULT_SELECTION, DEFAULT_DATE_ROWS, } from '../../../utils/search-product-field-mapping';
import styles from './InputField.module.scss';
export function InputField(_a) {
    var showRemoveIcon = _a.showRemoveIcon, groupKey = _a.groupKey, fieldData = _a.fieldData, _b = _a.isFieldSelected, isFieldSelected = _b === void 0 ? false : _b, isInitialDateDefault = _a.isInitialDateDefault, boldFilledRows = _a.boldFilledRows, handleRemoveInputField = _a.handleRemoveInputField;
    var _c = useSearchProduct(), conditionGroups = _c.conditionGroups, setConditionGroups = _c.setConditionGroups;
    var _d = useState(false), isHover = _d[0], setIsHover = _d[1];
    var _e = useState(''), fieldInput = _e[0], setFieldInput = _e[1];
    var _f = useState(OPERATOR_DEFAULT_SELECTION["".concat(fieldData.fieldType)]), currOperator = _f[0], setCurrOperator = _f[1];
    var _g = useState(false), isDisabled = _g[0], setIsDisabled = _g[1];
    var DEBOUNCE_TIME = 0;
    useEffect(function () {
        setCurrOperator(OPERATOR_DEFAULT_SELECTION["".concat(fieldData.fieldType)]);
    }, [fieldData.fieldType]);
    var updateConditionGroups = useMemo(function () {
        return debounce(function (input, operator) {
            var _a, _b;
            var parentConditionGroup = conditionGroups === null || conditionGroups === void 0 ? void 0 : conditionGroups[groupKey];
            var propertyVal = {
                val: input,
                operator: fieldData.fieldType === PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN
                    ? SearchProductPropertyVal.operator.EQ
                    : operator !== null && operator !== void 0 ? operator : currOperator,
            };
            if (parentConditionGroup === null || parentConditionGroup === void 0 ? void 0 : parentConditionGroup[fieldData.rowKey]) {
                var parentFieldRow = parentConditionGroup === null || parentConditionGroup === void 0 ? void 0 : parentConditionGroup[fieldData.rowKey];
                parentConditionGroup[fieldData.rowKey] = __assign(__assign({}, parentFieldRow), (_a = {}, _a[fieldData.fieldKey] = propertyVal, _a));
            }
            else if (parentConditionGroup === null || parentConditionGroup === void 0 ? void 0 : parentConditionGroup[fieldData.selectedProperty]) {
                var parentFieldRow = parentConditionGroup === null || parentConditionGroup === void 0 ? void 0 : parentConditionGroup[fieldData.selectedProperty];
                parentConditionGroup[fieldData.selectedProperty] = __assign(__assign({}, parentFieldRow), (_b = {}, _b[fieldData.fieldKey] = propertyVal, _b));
            }
            setConditionGroups(parentConditionGroup, groupKey, 'add');
        }, DEBOUNCE_TIME);
    }, [
        conditionGroups,
        currOperator,
        fieldData.fieldKey,
        fieldData.fieldType,
        fieldData.rowKey,
        fieldData.selectedProperty,
        groupKey,
        setConditionGroups,
    ]);
    var handleFieldInput = function (input, operator) {
        switch (fieldData.fieldType) {
            case PRODUCT_VERSION_SEARCH_FIELD_TYPE.DATE:
                setFieldInput(input.substring(0, 10));
                break;
            case PRODUCT_VERSION_SEARCH_FIELD_TYPE.NUMBER:
                if (!numbersOnly(input)) {
                    return;
                }
                setFieldInput(input);
                break;
            default:
                setFieldInput(input);
                break;
        }
        updateConditionGroups(input, operator);
        if (operator) {
            setCurrOperator(operator);
        }
    };
    useEffect(function () {
        setIsDisabled(currOperator === SearchProductPropertyVal.operator.EXISTS ||
            currOperator === SearchProductPropertyVal.operator.NOT_EXISTS);
    }, [currOperator]);
    useEffect(function () {
        if (isInitialDateDefault) {
            switch (fieldData.rowKey) {
                case DEFAULT_DATE_ROWS.startDate.key:
                    setCurrOperator(DEFAULT_DATE_ROWS.startDate.operator);
                    setFieldInput(DEFAULT_DATE_ROWS.startDate.value.substring(0, 10));
                    break;
                case DEFAULT_DATE_ROWS.endDate.key:
                    setCurrOperator(DEFAULT_DATE_ROWS.endDate.operator);
                    setFieldInput(DEFAULT_DATE_ROWS.endDate.value.substring(0, 10));
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(Box, __assign({ className: styles.inputFieldContainer }, { children: [fieldData.fieldType === PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN && (_jsx(FormControl, { children: _jsxs(RadioGroup, __assign({ className: styles.booleanField, value: currOperator !== null && currOperator !== void 0 ? currOperator : '' }, { children: [_jsx(FormControlLabel, { value: "true", control: _jsx(Radio, { size: "small", onChange: function (e) {
                                    return handleFieldInput(e.target.value, e.target.value);
                                } }), label: "True", slotProps: {
                                typography: {
                                    fontSize: '14px',
                                },
                            } }, "true"), _jsx(FormControlLabel, { value: "false", control: _jsx(Radio, { size: "small", onChange: function (e) {
                                    return handleFieldInput(e.target.value, e.target.value);
                                } }), label: "False", slotProps: {
                                typography: {
                                    fontSize: '14px',
                                },
                            } }, "false")] })) })), fieldData.fieldType === PRODUCT_VERSION_SEARCH_FIELD_TYPE.DATE && (_jsxs(Box, __assign({ className: styles.dateField, onMouseEnter: function () { return setIsHover(true); }, onMouseLeave: function () { return setIsHover(false); }, onFocus: function () { return setIsHover(true); }, onBlur: function () { return setIsHover(false); } }, { children: [_jsx(IconButton, __assign({ className: styles.closeIcon, sx: {
                            display: isFieldSelected && showRemoveIcon && isHover ? 'flex' : 'none',
                        }, onClick: function () { return handleRemoveInputField(); } }, { children: _jsx(CloseIcon, {}) })), _jsx(TextField, { disabled: !isFieldSelected, value: fieldInput, fullWidth: true, InputProps: {
                            type: 'date',
                            style: {
                                height: '40px',
                                paddingRight: showRemoveIcon && isHover ? '4px' : '14px',
                            },
                            className: boldFilledRows ? 'Mui-focused' : '',
                        }, onChange: function (e) {
                            handleFieldInput(new Date(e.target.value).toISOString());
                        } })] }))), fieldData.fieldType !== PRODUCT_VERSION_SEARCH_FIELD_TYPE.DATE &&
                fieldData.fieldType !== PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN && (_jsxs(Box, __assign({ className: styles.textField, onMouseEnter: function () { return setIsHover(true); }, onMouseLeave: function () { return setIsHover(false); }, onFocus: function () { return setIsHover(true); }, onBlur: function () { return setIsHover(false); } }, { children: [_jsx(IconButton, __assign({ className: styles.closeIcon, sx: {
                            display: isFieldSelected && showRemoveIcon && isHover ? 'flex' : 'none',
                        }, onClick: function () { return handleRemoveInputField(); } }, { children: _jsx(CloseIcon, {}) })), _jsx(TextField, { disabled: !isFieldSelected || isDisabled, size: "small", fullWidth: true, value: !isFieldSelected ? 'Select field to enter text' : fieldInput, onChange: function (e) { return handleFieldInput(e.target.value); }, error: fieldData.fieldType === PRODUCT_VERSION_SEARCH_FIELD_TYPE.NUMBER &&
                            !numbersOnly(fieldInput), InputProps: {
                            className: fieldInput && boldFilledRows ? 'Mui-focused' : '',
                        } })] }))), (fieldData === null || fieldData === void 0 ? void 0 : fieldData.fieldType) && fieldData.fieldType !== PRODUCT_VERSION_SEARCH_FIELD_TYPE.BOOLEAN && (_jsx(Box, __assign({ className: styles.radioButtonsContainer }, { children: _jsx(FormControl, { children: _jsx(RadioGroup, __assign({ className: styles.radioGroup, value: currOperator !== null && currOperator !== void 0 ? currOperator : '' }, { children: PRODUCT_VERSION_SEARCH_DEFAULTS.operators[fieldData.fieldType].map(function (operator) { return (_jsx(FormControlLabel, { value: operator, control: _jsx(Radio, { disableRipple: true, className: styles.radioButton, size: "small", sx: {
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 20,
                                    },
                                    backgroundColor: 'white',
                                    '&:hover': {
                                        backgroundColor: 'white',
                                    },
                                }, onChange: function (e) {
                                    return handleFieldInput(fieldInput, e.target.value);
                                } }), label: DISPLAY_NAMES["".concat(operator)], slotProps: {
                                typography: {
                                    fontSize: '14px',
                                    fontFamily: "UntitledSansMedium, sans-serif, system-ui",
                                },
                            } }, operator)); }) })) }) })))] })));
}

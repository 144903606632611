var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var getUpdatedFieldValues = function (previousFieldValues, property, value) {
    previousFieldValues[property].value = value;
    return __assign({}, previousFieldValues);
};
var inputFieldsContainerHelper = {
    getUpdatedFieldValues: getUpdatedFieldValues,
};
export { inputFieldsContainerHelper };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputFieldType } from 'models/Input/InputModels';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { InputFieldsContainer } from '../InputFieldsContainer/InputFieldsContainer';
import { PubTable } from '../PubTable/PubTable';
import styles from './DerivedFeaturesDetails.module.scss';
var STEP = 'discountCode';
var FEATURE = 'inputProductNbr';
var OPTIONTYPE = 'minLeadtime';
export function DerivedFeaturesDetails(_a) {
    var _b;
    var rowFeature = _a.rowFeature, derivedOptions = _a.derivedOptions, showOptionalColumns = _a.showOptionalColumns;
    var colDef = {
        fields: (_b = {},
            _b[STEP] = {
                fieldName: 'Step',
                displaySeq: 1,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[OPTIONTYPE] = {
                fieldName: 'Option Type',
                displaySeq: 2,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[FEATURE] = {
                fieldName: 'Feature',
                displaySeq: 3,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b),
    };
    var buildFieldValues = function (dataBody) {
        var _a;
        return (_a = {},
            _a[STEP] = {
                value: dataBody === null || dataBody === void 0 ? void 0 : dataBody.step,
            },
            _a[OPTIONTYPE] = {
                value: dataBody === null || dataBody === void 0 ? void 0 : dataBody.type,
            },
            _a[FEATURE] = {
                value: dataBody === null || dataBody === void 0 ? void 0 : dataBody.featureName,
            },
            _a);
    };
    var fieldValues = useState(buildFieldValues(rowFeature))[0];
    var createTableDefinition = function () {
        var defaultTable = {
            firstColumnIndent: true,
            columns: [
                {
                    dataAttribute: 'childFeatureName',
                    fieldType: InputFieldType.NUMBER,
                    heading: 'Feature',
                    width: '100px',
                    showHelpTooltip: true,
                },
                {
                    dataAttribute: 'childOptionCode',
                    fieldType: InputFieldType.STRING,
                    heading: 'Option Code / Description',
                    width: '200px',
                },
            ],
        };
        if (showOptionalColumns === 'Engineering Model Codes') {
            defaultTable.columns.splice(1, 0, {
                dataAttribute: 'childMfgFeatureModelCode',
                fieldType: InputFieldType.STRING,
                heading: 'Feature Model Code',
                width: '100px',
            });
            defaultTable.columns.splice(3, 0, {
                dataAttribute: 'childMfgOptionModelCode',
                fieldType: InputFieldType.STRING,
                heading: 'Option Model Code',
                width: '100px',
            });
        }
        else if (showOptionalColumns === 'Price Model Codes') {
            defaultTable.columns.splice(1, 0, {
                dataAttribute: 'childPrcFeatureModelCode',
                fieldType: InputFieldType.STRING,
                heading: 'Feature Model Code',
                width: '100px',
            });
            defaultTable.columns.splice(3, 0, {
                dataAttribute: 'childPrcOptionModelCode',
                fieldType: InputFieldType.STRING,
                heading: 'Option Model Code',
                width: '100px',
            });
        }
        return defaultTable;
    };
    var derivedOptionDataDefination = useState(createTableDefinition())[0];
    return (_jsxs(Box, __assign({ sx: { marginBottom: '50px' } }, { children: [_jsx(Box, __assign({ className: styles.headerContainer }, { children: _jsx(Box, __assign({ className: styles.headerLeft, sx: { width: '48%' } }, { children: _jsx(InputFieldsContainer, { colDef: colDef, fieldValues: fieldValues, minHeight: "10px" }) })) })), _jsx(PubTable, { def: derivedOptionDataDefination, tableData: derivedOptions, alternateRowBackgroundColor: true })] })));
}

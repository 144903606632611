var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { InputFieldsContainer } from 'components/Shared/InputFieldsContainer/InputFieldsContainer';
import { useCpaProduct } from 'components/Cpa/CpaProduct/CpaProductContextProvider';
import { ActionType, InputFieldType } from 'models/Input/InputModels';
import { inputFieldsContainerHelper } from 'components/Shared/InputFieldsContainer/InputFieldsContainerHelper';
import { useLookupSearch } from 'hooks/useLookupSearch';
import { LookupType } from 'services/api/search-service';
import { EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES, } from 'utils/cpa-utils';
export function CpaExtraDataSalesCategorySpecials() {
    var _a;
    var _b, _c, _d, _e, _f;
    var cpaProductContext = useCpaProduct();
    /**
     * Declare state to hold field values of all columns , key as column heading
     */
    var _g = useState(buildFieldValues(undefined)), fieldValues = _g[0], setFieldValues = _g[1];
    var brandCodeValidate = useLookupSearch(LookupType.SalesbrandCode, (_b = fieldValues[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_BRAND_CODE]) === null || _b === void 0 ? void 0 : _b.value);
    var categoryCodeValidate = useLookupSearch(LookupType.CategoryCode, (_c = fieldValues[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_CATEGORY_CODE]) === null || _c === void 0 ? void 0 : _c.value);
    var productLineCodeValidate = useLookupSearch(LookupType.SalesproductLineCode, (_d = fieldValues[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_PRODUCT_LINE_CODE]) === null || _d === void 0 ? void 0 : _d.value);
    var subCategoryCodeValidate = useLookupSearch(LookupType.SubCategoryCode, (_e = fieldValues[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_SUB_CATEGORY_CODE]) === null || _e === void 0 ? void 0 : _e.value);
    var typeCodeValidate = useLookupSearch(LookupType.TypeCode, (_f = fieldValues[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_TYPE_CODE]) === null || _f === void 0 ? void 0 : _f.value);
    /**
     * Holds Column Definition and Initial Values of the Column
     */
    var colDef = {
        title: { displayText: 'Sales Category:' },
        rowGap: '24px',
        showActions: true,
        preventReadOnly: true,
        style: { width: '900px' },
        fields: (_a = {},
            _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_BRAND_CODE] = {
                fieldName: 'Sales Brand Code:',
                displaySeq: 0,
                input: {
                    type: InputFieldType.STRING,
                },
                hasTextField: false,
                hasLookup: true,
            },
            _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_CATEGORY_CODE] = {
                fieldName: 'Sales Category Code:',
                displaySeq: 1,
                input: {
                    type: InputFieldType.STRING,
                },
                hasTextField: false,
                hasLookup: true,
            },
            _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_PRODUCT_LINE_CODE] = {
                fieldName: 'Sales Product Line Code:',
                displaySeq: 2,
                input: {
                    type: InputFieldType.STRING,
                },
                hasTextField: false,
                hasLookup: true,
            },
            _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_SUB_CATEGORY_CODE] = {
                fieldName: 'Sales Sub-Category Code:',
                displaySeq: 3,
                input: {
                    type: InputFieldType.STRING,
                },
                hasTextField: false,
                hasLookup: true,
            },
            _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_TYPE_CODE] = {
                fieldName: 'Sales Type Code:',
                displaySeq: 4,
                input: {
                    type: InputFieldType.STRING,
                },
                hasTextField: false,
                hasLookup: true,
            },
            _a),
    };
    useEffect(function () {
        setFieldValues(function (cur) {
            cur[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_BRAND_CODE].loading = brandCodeValidate.loading;
            cur[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_BRAND_CODE].customErrorMsg = brandCodeValidate.errorMessage;
            return __assign({}, cur);
        });
    }, [brandCodeValidate.loading, brandCodeValidate.errorMessage]);
    useEffect(function () {
        setFieldValues(function (cur) {
            cur[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_SUB_CATEGORY_CODE].loading = subCategoryCodeValidate.loading;
            cur[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_SUB_CATEGORY_CODE].customErrorMsg = subCategoryCodeValidate.errorMessage;
            return __assign({}, cur);
        });
    }, [subCategoryCodeValidate.loading, subCategoryCodeValidate.errorMessage]);
    useEffect(function () {
        setFieldValues(function (cur) {
            cur[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_TYPE_CODE].loading = typeCodeValidate.loading;
            cur[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_TYPE_CODE].customErrorMsg = typeCodeValidate.errorMessage;
            return __assign({}, cur);
        });
    }, [typeCodeValidate.loading, typeCodeValidate.errorMessage]);
    useEffect(function () {
        setFieldValues(function (cur) {
            cur[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_CATEGORY_CODE].loading = categoryCodeValidate.loading;
            cur[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_CATEGORY_CODE].customErrorMsg = categoryCodeValidate.errorMessage;
            return __assign({}, cur);
        });
    }, [categoryCodeValidate.loading, categoryCodeValidate.errorMessage]);
    useEffect(function () {
        setFieldValues(function (cur) {
            cur[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_PRODUCT_LINE_CODE].loading = productLineCodeValidate.loading;
            cur[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_PRODUCT_LINE_CODE].customErrorMsg = productLineCodeValidate.errorMessage;
            return __assign({}, cur);
        });
    }, [productLineCodeValidate.loading, productLineCodeValidate.errorMessage]);
    useEffect(function () {
        var _a;
        if (!((_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _a === void 0 ? void 0 : _a.currentProduct)) {
            return;
        }
        var initialValues = buildFieldValues(cpaProductContext.data.currentProduct);
        setFieldValues(initialValues);
    }, [cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct]);
    var handleAction = function (actionType) {
        var _a;
        if (!((_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _a === void 0 ? void 0 : _a.currentProduct)) {
            return;
        }
        if (actionType === ActionType.CONFIRM) {
            postConfirmationUpdate();
        }
        else if (actionType === ActionType.REVERT) {
            setFieldValues(buildFieldValues(cpaProductContext.data.currentProduct));
        }
    };
    var postConfirmationUpdate = function () {
        updateCurrentProductCustomFields(colDef);
        cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.dispatch({
            type: 'UPDATE_CURRENT_PRODUCT',
            payload: __assign({}, cpaProductContext.data.currentProduct),
        });
    };
    function updateCurrentProductCustomFields(def) {
        if (!cpaProductContext || !cpaProductContext.data.currentProduct) {
            return;
        }
        Object.keys(fieldValues).forEach(function (propertyName) {
            if (cpaProductContext.data.currentProduct) {
                var currVal = fieldValues[propertyName].value;
                if (!currVal) {
                    return;
                }
                if (!cpaProductContext.data.currentProduct.salesCategory) {
                    cpaProductContext.data.currentProduct.salesCategory = {};
                }
                var _a = currVal.split(' '), code = _a[0], descriptionParts = _a.slice(1);
                var description = descriptionParts.join(' ');
                if (propertyName === EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_BRAND_CODE) {
                    cpaProductContext.data.currentProduct.salesCategory.brandCode = code || cpaProductContext.data.currentProduct.salesCategory.brandCode;
                    cpaProductContext.data.currentProduct.salesCategory.brandDesc = description || cpaProductContext.data.currentProduct.salesCategory.brandDesc;
                }
                if (propertyName === EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_CATEGORY_CODE) {
                    cpaProductContext.data.currentProduct.salesCategory.categoryCode = code || cpaProductContext.data.currentProduct.salesCategory.categoryCode;
                    cpaProductContext.data.currentProduct.salesCategory.categoryDesc = description || cpaProductContext.data.currentProduct.salesCategory.categoryDesc;
                }
                if (propertyName === EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_SUB_CATEGORY_CODE) {
                    cpaProductContext.data.currentProduct.salesCategory.subCategoryCode = code || cpaProductContext.data.currentProduct.salesCategory.subCategoryCode;
                    cpaProductContext.data.currentProduct.salesCategory.subCategoryDesc = description || cpaProductContext.data.currentProduct.salesCategory.subCategoryDesc;
                }
                if (propertyName === EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_TYPE_CODE) {
                    cpaProductContext.data.currentProduct.salesCategory.typeCode = code || cpaProductContext.data.currentProduct.salesCategory.typeCode;
                    cpaProductContext.data.currentProduct.salesCategory.typeDesc = description || cpaProductContext.data.currentProduct.salesCategory.typeDesc;
                }
                if (propertyName === EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_PRODUCT_LINE_CODE) {
                    cpaProductContext.data.currentProduct.salesCategory.productLineCode = code || cpaProductContext.data.currentProduct.salesCategory.productLineCode;
                    cpaProductContext.data.currentProduct.salesCategory.productLineDesc = description || cpaProductContext.data.currentProduct.salesCategory.productLineDesc;
                }
            }
        });
    }
    return (_jsx(InputFieldsContainer, { colDef: colDef, handleAction: handleAction, fieldValues: fieldValues, setFieldValue: function (property, value) {
            setFieldValues(inputFieldsContainerHelper.getUpdatedFieldValues(fieldValues, property, value));
        } }));
}
var buildFieldValues = function (currentProduct) {
    var _a;
    var _b, _c, _d, _e, _f;
    return (_a = {},
        _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_BRAND_CODE] = {
            value: (_b = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.salesCategory) === null || _b === void 0 ? void 0 : _b.brandCode,
        },
        _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_PRODUCT_LINE_CODE] = {
            value: (_c = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.salesCategory) === null || _c === void 0 ? void 0 : _c.productLineCode,
        },
        _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_CATEGORY_CODE] = {
            value: (_d = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.salesCategory) === null || _d === void 0 ? void 0 : _d.categoryCode
        },
        _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_SUB_CATEGORY_CODE] = {
            value: (_e = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.salesCategory) === null || _e === void 0 ? void 0 : _e.subCategoryCode,
        },
        _a[EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES.SALES_TYPE_CODE] = {
            value: (_f = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.salesCategory) === null || _f === void 0 ? void 0 : _f.typeCode,
        },
        _a);
};

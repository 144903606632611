import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ProductSearchNewHeader } from 'components/ProductSearchNew/ProductSearchNewHeader/ProductSearchNewHeader';
import { useCallback } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
export function NewSearchOutlet() {
    var location = useLocation();
    var getPage = useCallback(function () {
        if (location.pathname.includes('simple')) {
            return 'basic';
        }
        if (location.pathname.includes('advanced')) {
            return 'advanced';
        }
        if (location.pathname.includes('hierarchy')) {
            return 'hierarchy';
        }
        return 'basic';
    }, [location.pathname]);
    return (_jsxs(_Fragment, { children: [_jsx(ProductSearchNewHeader, { page: getPage() }, location.pathname), _jsx(Outlet, {})] }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { SearchProductCondition, SearchProductPropertyVal, } from '@millerknoll/pub-api-schema';
import { apiService } from './api-service';
export function searchProducts(requestBody) {
    return __awaiter(this, void 0, Promise, function () {
        var rsp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiService.post('search/product', requestBody)];
                case 1:
                    rsp = _a.sent();
                    return [2 /*return*/, { data: rsp.body, status: rsp.status }];
            }
        });
    });
}
export function getCurrentProducts(searchText, isKnollOnly, selectedSort, additionalGlobalCondition) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return __awaiter(this, void 0, Promise, function () {
        var currDateISO, knollProductsCondition, conditionGroupStart, request, response;
        return __generator(this, function (_j) {
            switch (_j.label) {
                case 0:
                    currDateISO = new Date().toISOString();
                    knollProductsCondition = {
                        productVersion: {
                            values: [
                                {
                                    val: 'KNOLL_',
                                    operator: SearchProductPropertyVal.operator.BEGINS,
                                },
                            ],
                        },
                    };
                    conditionGroupStart = {
                        productVersion: isKnollOnly ? knollProductsCondition.productVersion : undefined,
                        endDate: {
                            values: [
                                {
                                    val: currDateISO,
                                    operator: SearchProductPropertyVal.operator.GTE,
                                },
                            ],
                        },
                    };
                    request = {
                        pageNumber: 1,
                        pageSize: 500,
                        fields: ['productVersion', 'prefix', 'productName', 'startDate'],
                        sort: selectedSort,
                        conditions: [
                            __assign(__assign(__assign({}, conditionGroupStart), (additionalGlobalCondition !== null && additionalGlobalCondition !== void 0 ? additionalGlobalCondition : undefined)), { prefix: {
                                    values: __spreadArray(__spreadArray([], ((_b = (_a = additionalGlobalCondition === null || additionalGlobalCondition === void 0 ? void 0 : additionalGlobalCondition.prefix) === null || _a === void 0 ? void 0 : _a.values) !== null && _b !== void 0 ? _b : []), true), [
                                        {
                                            val: searchText,
                                            operator: SearchProductPropertyVal.operator.BEGINS,
                                        },
                                    ], false),
                                    logicalOperator: SearchProductCondition.logicalOperator.AND,
                                } }),
                            __assign(__assign(__assign({}, conditionGroupStart), (additionalGlobalCondition !== null && additionalGlobalCondition !== void 0 ? additionalGlobalCondition : undefined)), { productNbr: {
                                    values: __spreadArray(__spreadArray([], ((_d = (_c = additionalGlobalCondition === null || additionalGlobalCondition === void 0 ? void 0 : additionalGlobalCondition.productNbr) === null || _c === void 0 ? void 0 : _c.values) !== null && _d !== void 0 ? _d : []), true), [
                                        {
                                            val: searchText,
                                            operator: SearchProductPropertyVal.operator.BEGINS,
                                        },
                                    ], false),
                                } }),
                            __assign(__assign(__assign({}, conditionGroupStart), (additionalGlobalCondition !== null && additionalGlobalCondition !== void 0 ? additionalGlobalCondition : undefined)), { hierarchyAnyLevel: {
                                    values: __spreadArray(__spreadArray([], ((_f = (_e = additionalGlobalCondition === null || additionalGlobalCondition === void 0 ? void 0 : additionalGlobalCondition.hierarchyAnyLevel) === null || _e === void 0 ? void 0 : _e.values) !== null && _f !== void 0 ? _f : []), true), [
                                        {
                                            val: searchText,
                                            operator: SearchProductPropertyVal.operator.MATCH_ANY,
                                        },
                                    ], false),
                                } }),
                            __assign(__assign(__assign({}, conditionGroupStart), (additionalGlobalCondition !== null && additionalGlobalCondition !== void 0 ? additionalGlobalCondition : undefined)), { productName: {
                                    values: __spreadArray(__spreadArray([], ((_h = (_g = additionalGlobalCondition === null || additionalGlobalCondition === void 0 ? void 0 : additionalGlobalCondition.productName) === null || _g === void 0 ? void 0 : _g.values) !== null && _h !== void 0 ? _h : []), true), [
                                        {
                                            val: searchText,
                                            operator: SearchProductPropertyVal.operator.MATCH_ANY,
                                        },
                                    ], false),
                                } }),
                        ],
                    };
                    return [4 /*yield*/, searchProducts(request)];
                case 1:
                    response = _j.sent();
                    return [2 /*return*/, response];
            }
        });
    });
}
export function getProductsByProductVersions(productVersions, pageSize) {
    return __awaiter(this, void 0, void 0, function () {
        var rsp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiService
                        .post('search?indexName=product-version', {
                        size: pageSize,
                        query: {
                            bool: {
                                must: [
                                    {
                                        terms: {
                                            productVersion: productVersions,
                                        },
                                    },
                                ],
                            },
                        },
                        _source: [
                            'productVersion',
                            'productName.desc',
                            'prefix',
                            'startDate',
                            'endDate',
                            'minLeadtime',
                            'channels',
                            'hierarchy',
                            'productId',
                        ],
                    })
                        .catch(function (error) { return ({ body: [], status: error.code }); })];
                case 1:
                    rsp = _a.sent();
                    return [2 /*return*/, { body: rsp.body.data, status: rsp.status }];
            }
        });
    });
}
export function getProductsV2(searchText, date, time, channel, page, sort) {
    return __awaiter(this, void 0, Promise, function () {
        var combinedDateTime, sortList, conditionGroupStart, preConditions, completeConditions, request, rsp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    combinedDateTime = "".concat(date, "T").concat(time, ".000Z");
                    sortList = [{ field: 'prefix', direction: sort === null || sort === void 0 ? void 0 : sort.direction }];
                    conditionGroupStart = {
                        endDate: {
                            values: [
                                {
                                    val: combinedDateTime,
                                    operator: SearchProductPropertyVal.operator.GTE,
                                },
                            ],
                        },
                        startDate: {
                            values: [
                                {
                                    val: combinedDateTime,
                                    operator: SearchProductPropertyVal.operator.LTE,
                                },
                            ],
                        },
                        channelCode: {
                            values: [
                                {
                                    val: channel,
                                    operator: SearchProductPropertyVal.operator.EQ,
                                },
                            ],
                        },
                    };
                    preConditions = [
                        __assign(__assign({}, conditionGroupStart), { prefix: {
                                values: [
                                    {
                                        val: searchText,
                                        operator: SearchProductPropertyVal.operator.BEGINS,
                                    },
                                ],
                            } }),
                        __assign(__assign({}, conditionGroupStart), { productName: {
                                values: [
                                    {
                                        val: searchText,
                                        operator: SearchProductPropertyVal.operator.MATCH_PHRASE,
                                    },
                                ],
                            } }),
                        __assign(__assign({}, conditionGroupStart), { productNbr: {
                                values: [
                                    {
                                        val: searchText.toUpperCase(),
                                        operator: SearchProductPropertyVal.operator.BEGINS,
                                    },
                                ],
                            } }),
                        __assign(__assign({}, conditionGroupStart), { productName: {
                                values: [
                                    {
                                        val: searchText,
                                        operator: SearchProductPropertyVal.operator.WILDCARD,
                                    },
                                ],
                            } }),
                    ];
                    completeConditions = __spreadArray([], preConditions, true);
                    request = {
                        pageNumber: page !== null && page !== void 0 ? page : 1,
                        pageSize: 50,
                        fields: [
                            'productVersion',
                            'prefix',
                            'productName',
                            'startDate',
                            'discountCode',
                            'discountDesc',
                            'productId',
                            'productNbr',
                            'standardProdRef',
                            'deviationDesc',
                        ],
                        conditions: __spreadArray([], completeConditions, true),
                    };
                    if (sort === null || sort === void 0 ? void 0 : sort.present) {
                        request.sortList = sortList;
                    }
                    return [4 /*yield*/, apiService.post('search/product', request)];
                case 1:
                    rsp = _a.sent();
                    return [2 /*return*/, { data: rsp.body, status: rsp.status }];
            }
        });
    });
}

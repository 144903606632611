var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { HierarchyDropdown } from 'components/ProductHierarchy/HierarchyDropdown/HierarchyDropdown';
import { PageContainer } from 'components/Shared/PageContainer/PageContainer';
import { StyledButton } from 'components/Shared/StyledComponents/StyledButton';
import { StyledDialog } from 'components/Shared/StyledDialog/StyledDialog';
import { useProductHierarchy } from 'hooks/useProductHierarchy';
import { Loading } from 'components/Shared/Loading/AppLoading/Loading/Loading';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { classes } from 'utils/styleHelper';
import { catalogHelper } from 'utils/catalog-helper';
import Tooltip from '@mui/material/Tooltip';
import styles from './HierarchyModal.module.scss';
export function HierarchyModal(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var isOpen = _a.isOpen, validations = _a.validations, prevSelected = _a.prevSelected, closeModal = _a.closeModal, selectProduct = _a.selectProduct, selectHierarchy = _a.selectHierarchy;
    var _k = useState({}), selectedProducts = _k[0], setSelectedProducts = _k[1];
    var _l = useState(0), totalSelected = _l[0], setTotalSelected = _l[1];
    var _m = useState({
        primaryOnly: true,
        level1: undefined,
    }), hierarchyRequest = _m[0], setHierarchyRequest = _m[1];
    var _o = useState({}), levelOptions = _o[0], setLevelOptions = _o[1];
    var _p = useState(1), currLevel = _p[0], setCurrLevel = _p[1];
    var _q = useProductHierarchy(hierarchyRequest, validations.effectiveDate, validations.sifPromotable ? validations.sifPromotable : undefined, validations.cetPromotable ? validations.cetPromotable : undefined, validations.priceBookPromotable ? validations.priceBookPromotable : undefined, validations.channelCode, validations.minLeadtime), loading = _q.loading, body = _q.body;
    useEffect(function () {
        setLevelOptions(function (prev) {
            var _a;
            var _b;
            return (__assign(__assign({}, prev), (_a = {}, _a["level".concat(currLevel)] = {
                keys: ((_b = body === null || body === void 0 ? void 0 : body.aggregations) !== null && _b !== void 0 ? _b : []).map(function (agg) { var _a; return (_a = agg === null || agg === void 0 ? void 0 : agg.key) !== null && _a !== void 0 ? _a : ''; }),
            }, _a)));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [body === null || body === void 0 ? void 0 : body.aggregations]);
    var handleLevelSelect = function (selected, levelNum, key) {
        var _a, _b, _c, _d;
        if (currLevel < 10) {
            if (hierarchyRequest[key]) {
                var newRequest = {
                    primaryOnly: hierarchyRequest.primaryOnly,
                };
                var newLevelOptions = {};
                for (var index = 1; index <= levelNum; index += 1) {
                    var indexKey = "level".concat(index);
                    newLevelOptions = __assign(__assign({}, newLevelOptions), (_a = {}, _a[indexKey] = levelOptions === null || levelOptions === void 0 ? void 0 : levelOptions[indexKey], _a));
                    if (key !== indexKey) {
                        newRequest = __assign(__assign({}, newRequest), (_b = {}, _b[indexKey] = hierarchyRequest === null || hierarchyRequest === void 0 ? void 0 : hierarchyRequest[indexKey], _b));
                    }
                    else {
                        newRequest = __assign(__assign({}, newRequest), (_c = {}, _c[key] = selected, _c["level".concat(levelNum + 1)] = undefined, _c));
                    }
                }
                setHierarchyRequest(newRequest);
                setLevelOptions(newLevelOptions);
                setCurrLevel(levelNum + 1);
            }
            else {
                setHierarchyRequest(__assign(__assign({}, hierarchyRequest), (_d = {}, _d[key] = selected, _d["level".concat(currLevel + 1)] = undefined, _d)));
                setCurrLevel(currLevel + 1);
            }
        }
    };
    var handleUnsetLevel = function (level) {
        var _a;
        var updatedRequest = __assign({}, hierarchyRequest);
        var updatedLevels = __assign({}, levelOptions);
        var count = level;
        while ((updatedLevels === null || updatedLevels === void 0 ? void 0 : updatedLevels["level".concat(count)]) !== undefined) {
            delete updatedRequest["level".concat(count)];
            updatedLevels === null || updatedLevels === void 0 ? true : delete updatedLevels["level".concat(count)];
            count += 1;
        }
        setHierarchyRequest(__assign(__assign({}, updatedRequest), (_a = {}, _a["level".concat(level)] = undefined, _a)));
        setLevelOptions(updatedLevels);
        setCurrLevel(level);
    };
    var handleClearProductSelections = function () {
        setTotalSelected(0);
        setSelectedProducts({});
    };
    var handleReset = function () {
        setCurrLevel(1);
        setHierarchyRequest({
            primaryOnly: true,
            level1: undefined,
        });
        setLevelOptions({});
        handleClearProductSelections();
    };
    var getHierarchyKeyToLevel = function (request, level) {
        var key = request.level1 + catalogHelper.hierarchyKeyLevelDelimiter;
        if (level > 1) {
            for (var i = 2; i <= level; i += 1) {
                key = "".concat(key, "_").concat(request === null || request === void 0 ? void 0 : request["level".concat(i)]).concat(catalogHelper.hierarchyKeyLevelDelimiter);
            }
        }
        return key !== null && key !== void 0 ? key : '';
    };
    var trimRequest = function (request, level) {
        var trimmed = __assign({}, request);
        for (var i = level + 1; i <= 10; i += 1) {
            if (!(trimmed === null || trimmed === void 0 ? void 0 : trimmed["level".concat(i)])) {
                break;
            }
            trimmed["level".concat(i)] = undefined;
        }
        return trimmed;
    };
    var handleSelectAll = function () {
        var _a, _b, _c, _d;
        var products = Object.entries(selectedProducts !== null && selectedProducts !== void 0 ? selectedProducts : {});
        if ((products === null || products === void 0 ? void 0 : products.length) === ((_a = body === null || body === void 0 ? void 0 : body.data) === null || _a === void 0 ? void 0 : _a.length)) {
            handleClearProductSelections();
        }
        else {
            var allProducts_1 = {};
            (_b = body === null || body === void 0 ? void 0 : body.data) === null || _b === void 0 ? void 0 : _b.forEach(function (product) {
                allProducts_1[product.productVersion] = product;
            });
            setTotalSelected((_d = (_c = body === null || body === void 0 ? void 0 : body.data) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0);
            setSelectedProducts(allProducts_1);
        }
    };
    var handleClose = function () {
        var selected = Object.values(selectedProducts).map(function (p) { return ({
            key: { _id: p.productVersion },
            body: p,
        }); });
        closeModal(selected);
    };
    return (_jsx(StyledDialog, __assign({ isOpen: isOpen, closeModal: handleClose }, { children: _jsxs(PageContainer, { children: [_jsxs(Box, __assign({ className: styles.titleResetContainer }, { children: [_jsx(Typography, __assign({ variant: "h1", className: styles.pageTitle }, { children: "Product Hierarchy Search" })), _jsx(StyledButton, { handleClick: function () { return handleReset(); }, label: "Reset Selections", widthFromProps: 160 })] })), _jsxs(Box, __assign({ className: styles.primaryContainer }, { children: [_jsx(Typography, __assign({ variant: "h5", fontWeight: "bold" }, { children: "Primary Only:" })), _jsx(FormControl, { children: _jsxs(RadioGroup, __assign({ className: styles.primarySelect, value: (_b = hierarchyRequest.primaryOnly) !== null && _b !== void 0 ? _b : true }, { children: [_jsx(FormControlLabel, { value: "true", control: _jsx(Radio, { size: "small", onChange: function () {
                                                return setHierarchyRequest(__assign(__assign({}, hierarchyRequest), { primaryOnly: true }));
                                            } }), label: "True", slotProps: {
                                            typography: {
                                                fontSize: '14px',
                                            },
                                        } }, "true"), _jsx(FormControlLabel, { value: "false", control: _jsx(Radio, { size: "small", onChange: function () {
                                                return setHierarchyRequest(__assign(__assign({}, hierarchyRequest), { primaryOnly: false }));
                                            } }), label: "False", slotProps: {
                                            typography: {
                                                fontSize: '14px',
                                            },
                                        } }, "false")] })) })] })), _jsx(Box, __assign({ className: styles.dropdownsContainer }, { children: Object.entries(levelOptions).map(function (_a, i, arr) {
                        var _b, _c;
                        var k = _a[0], v = _a[1];
                        return v.keys.length > 0 ? (_jsxs(Box, __assign({ className: styles.levelContainer }, { children: [_jsx(HierarchyDropdown, { levelData: v, loading: false, levelNum: i + 1, dropdownKey: k, handleLevelSelect: handleLevelSelect, unsetLevel: handleUnsetLevel }, k), currLevel > 1 && i !== arr.length - 1 && ((_b = body === null || body === void 0 ? void 0 : body.data) !== null && _b !== void 0 ? _b : []).length > 0 && (_jsx(Tooltip, __assign({ title: "Select/Deselect Level", arrow: true }, { children: _jsx(FormControl, { children: _jsx(FormGroup, { children: _jsx(FormControlLabel, { label: "", control: _jsx(Radio, { checked: ((_c = prevSelected.hierarchies) === null || _c === void 0 ? void 0 : _c[getHierarchyKeyToLevel(hierarchyRequest, i + 1)]) !== undefined, onClick: function () {
                                                        return selectHierarchy(catalogHelper.convertHierarchyRequestToSearchHierarchType(trimRequest(hierarchyRequest, i + 1)));
                                                    } }) }) }) }) })))] }), k)) : (_jsx(Box, __assign({ width: "100%", display: "flex", justifyContent: "center" }, { children: (body === null || body === void 0 ? void 0 : body.aggregations) && body.aggregations.length === 0 && (_jsx(Typography, { children: "No more levels for selected set of hierarchies" })) }), k));
                    }) })), (body === null || body === void 0 ? void 0 : body.data) && !loading ? ((hierarchyRequest === null || hierarchyRequest === void 0 ? void 0 : hierarchyRequest.level1) !== undefined && (_jsxs(_Fragment, { children: [_jsx("hr", {}), _jsxs(Box, __assign({ className: styles.selectAllContainer, sx: {
                                display: !(body === null || body === void 0 ? void 0 : body.data) || body.data.length === 0 ? 'none !important' : 'flex',
                            } }, { children: [_jsx(FormControl, { children: _jsx(FormGroup, __assign({ onChange: handleSelectAll }, { children: _jsx(FormControlLabel, { label: "", control: _jsx(Checkbox, { checked: totalSelected === ((_c = body === null || body === void 0 ? void 0 : body.data) === null || _c === void 0 ? void 0 : _c.length) }) }) })) }), _jsx(Typography, { children: "Select All/None" })] })), _jsx(TableContainer, __assign({ className: classes(((_d = body === null || body === void 0 ? void 0 : body.data) === null || _d === void 0 ? void 0 : _d.length) === 0 && styles.noResultsTableContainer, styles.tableContainer) }, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [body.data.length > 0 && (_jsx(TableCell, __assign({ className: styles.checkboxCell }, { children: " " }))), _jsx(TableCell, __assign({ className: classes(((_e = body === null || body === void 0 ? void 0 : body.data) === null || _e === void 0 ? void 0 : _e.length) === 0 && styles.noResultsHeaderCell, styles.colMedium) }, { children: "Product Prefix" })), _jsx(TableCell, __assign({ className: classes(((_f = body === null || body === void 0 ? void 0 : body.data) === null || _f === void 0 ? void 0 : _f.length) === 0 && styles.noResultsHeaderCell, styles.colDescription) }, { children: "Product Name" })), _jsx(TableCell, __assign({ className: classes(((_g = body === null || body === void 0 ? void 0 : body.data) === null || _g === void 0 ? void 0 : _g.length) === 0 && styles.noResultsHeaderCell, styles.colMedium) }, { children: "Channels" })), _jsx(TableCell, __assign({ className: classes(((_h = body === null || body === void 0 ? void 0 : body.data) === null || _h === void 0 ? void 0 : _h.length) === 0 && styles.noResultsHeaderCell, styles.colSmall) }, { children: "Lead Time" }))] }) }), _jsxs(TableBody, { children: [body === null || body === void 0 ? void 0 : body.data.map(function (product) {
                                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                                                return (_jsxs(TableRow, { children: [((_a = body === null || body === void 0 ? void 0 : body.data) !== null && _a !== void 0 ? _a : []).length > 0 && (_jsx(TableCell, __assign({ className: styles.checkboxCell }, { children: _jsx(FormControl, { children: _jsx(FormGroup, __assign({ onChange: function () {
                                                                        var _a;
                                                                        return selectProduct({
                                                                            key: { _id: product.productVersion },
                                                                            body: product,
                                                                        }, ((_a = prevSelected === null || prevSelected === void 0 ? void 0 : prevSelected.products) === null || _a === void 0 ? void 0 : _a[product.productVersion]) !== undefined);
                                                                    } }, { children: _jsx(FormControlLabel, { label: "", control: _jsx(Checkbox, { checked: (selectedProducts === null || selectedProducts === void 0 ? void 0 : selectedProducts[product.productVersion]) !== undefined ||
                                                                                ((_b = prevSelected === null || prevSelected === void 0 ? void 0 : prevSelected.products) === null || _b === void 0 ? void 0 : _b[product.productVersion]) !== undefined }) }) })) }) }))), _jsx(TableCell, __assign({ className: ((_c = body === null || body === void 0 ? void 0 : body.data) === null || _c === void 0 ? void 0 : _c.length) === 0 ? styles.noCheckboxColumn : '' }, { children: product.prefix })), _jsx(TableCell, __assign({ className: ((_d = body === null || body === void 0 ? void 0 : body.data) === null || _d === void 0 ? void 0 : _d.length) === 0 ? styles.noCheckboxColumn : '' }, { children: (_f = (_e = product.productName) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.desc })), _jsx(TableCell, __assign({ className: ((_g = body === null || body === void 0 ? void 0 : body.data) === null || _g === void 0 ? void 0 : _g.length) === 0 ? styles.noCheckboxColumn : '' }, { children: (_h = product.channels) === null || _h === void 0 ? void 0 : _h.map(function (ch) { return ch.code; }).join(',\n') })), _jsx(TableCell, __assign({ className: ((_j = body === null || body === void 0 ? void 0 : body.data) === null || _j === void 0 ? void 0 : _j.length) === 0 ? styles.noCheckboxColumn : '' }, { children: (_k = product.minLeadtime) !== null && _k !== void 0 ? _k : 99 }))] }, product.productVersion));
                                            }), ((_j = body === null || body === void 0 ? void 0 : body.data) === null || _j === void 0 ? void 0 : _j.length) === 0 && (_jsx(TableRow, { children: _jsx(TableCell, __assign({ className: styles.noResultsCell, colSpan: body.data.length > 0 ? 5 : 4 }, { children: "No products matching Ad Hoc criteria for this hierarchy level" })) }))] })] }) }))] }))) : (_jsx(Loading, {}))] }) })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLanguageCode } from 'services/helperFunctions/useLanguageCode';
import DataObjectIcon from '@mui/icons-material/DataObject';
import IconButton from '@mui/material/IconButton';
import { JsonViewer } from 'components/Shared/JsonViewer/JsonViewer';
import { useState } from 'react';
import styles from './OptionRow.module.scss';
export function OptionRow(_a) {
    var option = _a.option, optionCode = _a.optionCode, isLast = _a.isLast;
    var languageCode = useLanguageCode();
    var _b = useState(false), isModalOpen = _b[0], setIsModalOpen = _b[1];
    var handleModalClose = function () {
        setIsModalOpen(false);
    };
    var getDesc = function (description) {
        var _a;
        if (!description)
            return '';
        return (_a = description === null || description === void 0 ? void 0 : description[languageCode]) === null || _a === void 0 ? void 0 : _a.desc;
    };
    return (_jsxs(Box, { children: [_jsx(JsonViewer, { isOpen: isModalOpen, closeModal: function () { return handleModalClose(); }, stringifiedJson: JSON.stringify(option, null, 4) }), _jsxs(Box, __assign({ display: "flex", flexDirection: "row", alignItems: "center", columnGap: "16px" }, { children: [_jsx(Box, __assign({ className: styles.jsonButton }, { children: _jsx(IconButton, __assign({ onClick: function () { return setIsModalOpen(true); } }, { children: _jsx(DataObjectIcon, {}) })) })), _jsxs(Box, __assign({ className: styles.optionContainer }, { children: [_jsxs(Box, __assign({ className: styles.labelContainer }, { children: [_jsx(Typography, { children: "Option: " }), _jsx(Typography, { children: optionCode })] })), _jsx(Box, __assign({ className: styles.labelContainer }, { children: _jsx(Typography, { children: getDesc(option === null || option === void 0 ? void 0 : option.description) }) }))] }))] })), _jsx("hr", { className: styles.optionHr, style: {
                    display: isLast ? 'none' : 'block',
                } })] }));
}

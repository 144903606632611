var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var CpaProductMode;
(function (CpaProductMode) {
    CpaProductMode["ADD"] = "ADD";
    CpaProductMode["EDIT"] = "EDIT";
})(CpaProductMode || (CpaProductMode = {}));
export var cpaProductContextInitialState = {
    cpn: undefined,
    standardProduct: undefined,
    currentProduct: undefined,
    currentProductOriginal: undefined,
    currentProductMetadata: undefined,
    mode: undefined,
    productType: undefined,
    standardProductPrice: undefined,
    pricingTableData: {},
    pricingTableDataOriginal: {},
    drawingFile: undefined,
};
export var cpaProductReducer = function (state, action) {
    switch (action.type) {
        case 'UPDATE_CURRENT_PRODUCT':
            return __assign(__assign({}, state), { currentProduct: action.payload });
        case 'UPDATE_DEVIATION_DESC':
            if (state.currentProduct) {
                state.currentProduct.deviationDesc = action.payload;
                return __assign(__assign({}, state), { currentProduct: __assign({}, state.currentProduct) });
            }
            return __assign({}, state);
        case 'UPDATE_PRODUCT_NOTES':
            if (state.currentProduct) {
                state.currentProduct.productNotes = action.payload;
                return __assign(__assign({}, state), { currentProduct: __assign({}, state.currentProduct) });
            }
            return __assign({}, state);
        case 'UPDATE_CURRENT_PRODUCT_ORIGINAL':
            return __assign(__assign({}, state), { currentProductOriginal: action.payload, mode: CpaProductMode.EDIT });
        case 'UPDATE_FULL':
            return action.payload;
        case 'UPDATE_PRICING_TABLE_DATA':
            return __assign(__assign({}, state), { pricingTableData: action.payload });
        case 'UPDATE_PRICING_TABLE_DATA_ORIGINAL':
            return __assign(__assign({}, state), { pricingTableDataOriginal: action.payload });
        case 'UPDATE_STANDARD_PRODUCT_PRICING':
            return __assign(__assign({}, state), { standardProductPrice: action.payload });
        case 'UPDATE_PRODUCT_ASSETS':
            if (state.currentProduct) {
                state.currentProduct.assets = action.payload.productAssets;
                return __assign(__assign({}, state), { currentProduct: __assign({}, state.currentProduct), drawingFile: action.payload.drawing });
            }
            return __assign({}, state);
        default:
            return state;
    }
};

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ExtractTracker;
(function (ExtractTracker) {
    var status;
    (function (status) {
        status["REQUESTED"] = "REQUESTED";
        status["COMPLETED"] = "COMPLETED";
        status["FAILED"] = "FAILED";
    })(status = ExtractTracker.status || (ExtractTracker.status = {}));
})(ExtractTracker || (ExtractTracker = {}));

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { RangeOptionTextField } from 'components/ProductValidation/RangeOptionTextField/RangeOptionTextField';
import styles from './StyledValidationTableCell.module.scss';
var ValidationTableCellWithStyling = styled.td.attrs({
    colSpan: 1,
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: black solid 1px;\n    height: 57px;\n    padding-left: 15px;\n"], ["\n    border: black solid 1px;\n    height: 57px;\n    padding-left: 15px;\n"])));
var OptionSKUTableCellWithStyling = styled.td.attrs({
    colSpan: 1,
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: black solid 1px;\n    height: 57px;\n    padding-left: 15px;\n"], ["\n    border: black solid 1px;\n    height: 57px;\n    padding-left: 15px;\n"])));
export function StyledValidationTableCell(_a) {
    var _b, _c;
    var label = _a.label, productBuild = _a.productBuild, feature = _a.feature, options = _a.options, handleNewOptionItemClick = _a.handleNewOptionItemClick;
    var _d = useState(false), editMode = _d[0], setIsEditMode = _d[1];
    var _e = useState([]), optionsToPass = _e[0], setOptionsToPass = _e[1];
    var _f = useState({
        showRangeTextField: false,
    }), rangeOptionData = _f[0], setRangeOptionData = _f[1];
    var clearRangeOptionData = function () {
        setRangeOptionData({
            showRangeTextField: false,
        });
    };
    useEffect(function () {
        if (options) {
            setOptionsToPass(options === null || options === void 0 ? void 0 : options.filter(function (optionArray) {
                return (optionArray === null || optionArray === void 0 ? void 0 : optionArray.key) === (feature === null || feature === void 0 ? void 0 : feature.featureId);
            }));
        }
    }, [feature === null || feature === void 0 ? void 0 : feature.featureId, options]);
    var handleEditClick = function (option) {
        if ((option === null || option === void 0 ? void 0 : option.range) && !editMode) {
            setRangeOptionData(__assign(__assign({}, rangeOptionData), { showRangeTextField: true, option: option }));
        }
        else {
            clearRangeOptionData();
        }
        setIsEditMode(function (prevVal) { return !prevVal; });
    };
    var handleRangeOptionSubmit = function (option, rangeVal) {
        var _a;
        setRangeOptionData({
            showRangeTextField: false,
        });
        handleNewOptionItemClick === null || handleNewOptionItemClick === void 0 ? void 0 : handleNewOptionItemClick(__assign(__assign({}, option), { productNbr: (_a = option.productNbr) === null || _a === void 0 ? void 0 : _a.replace('{}', rangeVal) }));
        setIsEditMode(false);
    };
    var handleNewOptionClick = function (option) {
        if (option.range) {
            setRangeOptionData({
                showRangeTextField: true,
                option: option,
            });
        }
        else {
            clearRangeOptionData();
            handleNewOptionItemClick === null || handleNewOptionItemClick === void 0 ? void 0 : handleNewOptionItemClick(option);
            setIsEditMode(false);
        }
    };
    if (!productBuild) {
        return (_jsx(ValidationTableCellWithStyling, { children: _jsx(Typography, { children: label }) }));
        // eslint-disable-next-line no-else-return
    }
    else if (productBuild && !editMode) {
        return (_jsx(ValidationTableCellWithStyling, { children: _jsxs(Box, __assign({ className: styles.unopenedContainer }, { children: [_jsx(Typography, __assign({ className: styles.label }, { children: label })), options && options[0] && (_jsx(IconButton, __assign({ className: styles.buttonContainer, onClick: function () { return handleEditClick === null || handleEditClick === void 0 ? void 0 : handleEditClick(options[0].options[0]); } }, { children: _jsx(EditIcon, {}) })))] })) }));
    }
    else if (editMode) {
        return (_jsx(OptionSKUTableCellWithStyling, __assign({ style: { paddingLeft: 'unset' } }, { children: _jsx(Box, __assign({ className: styles.container }, { children: rangeOptionData.showRangeTextField && rangeOptionData.option ? (_jsx(Box, __assign({ width: "100%", display: "flex", padding: "0 15px" }, { children: _jsx(RangeOptionTextField, { option: rangeOptionData.option, handleRangeOptionSubmit: handleRangeOptionSubmit }) }))) : (_jsxs(_Fragment, { children: [_jsx(FormControl, __assign({ fullWidth: true, className: styles.dropdownContainer }, { children: _jsx(Select, __assign({ className: styles.selectComponent, value: "" }, { children: ((_b = optionsToPass[0]) === null || _b === void 0 ? void 0 : _b.options) &&
                                    ((_c = optionsToPass[0]) === null || _c === void 0 ? void 0 : _c.options.map(function (option, i, arr) {
                                        var _a;
                                        return (_jsxs(Box, { children: [(option === null || option === void 0 ? void 0 : option.range) && !((_a = arr[i - 1]) === null || _a === void 0 ? void 0 : _a.range) && (_jsx("hr", { className: styles.optionSeparator })), _jsx(MenuItem, __assign({ value: option === null || option === void 0 ? void 0 : option.optionCode, onClick: function () { return handleNewOptionClick(option); } }, { children: "".concat(option.optionDescription, " (").concat(option.optionCode, " - ").concat(option.skuSelection, ")") }))] }, "".concat(option === null || option === void 0 ? void 0 : option.optionCode, "_").concat(option === null || option === void 0 ? void 0 : option.productNbr)));
                                    })) })) })), _jsx(IconButton, __assign({ className: styles.buttonContainer, onClick: function () { return handleEditClick === null || handleEditClick === void 0 ? void 0 : handleEditClick(); } }, { children: _jsx(EditIcon, {}) }))] })) })) })));
    }
    return null;
}
var templateObject_1, templateObject_2;

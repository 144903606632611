var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useProductHierarchy } from 'hooks/useProductHierarchy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import Typography from '@mui/material/Typography';
import { SEARCH_PRODUCT_PAGE_SIZE } from 'utils/constants';
import { StyledButton } from 'components/Shared/StyledComponents/StyledButton';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { toUTCTimestamp } from 'services/helperFunctions/toUTCTimestamp';
import { InputFieldType } from 'models/Input/InputModels';
import { PubTable } from 'components/Shared/PubTable/PubTable';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { HierarchyDropdown } from 'components/ProductHierarchy/HierarchyDropdown/HierarchyDropdown';
import Checkbox from '@mui/material/Checkbox';
import { JsonViewer } from 'components/Shared/JsonViewer/JsonViewer';
import { getProducts } from 'services/api/product-hierarchy-service';
import Pagination from '@mui/material/Pagination';
import { Form } from 'react-router-dom';
import { getDiscountString } from '../ProductSearchNewListSearch/ProductSearchNewListSearch';
import styles from './ProductSearchHierarchy.module.scss';
export function ProductSearchHierarchy() {
    var _this = this;
    var _a, _b;
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var handle500Error = function () {
        setSnackbarAlert({
            message: 'Server Error - Please report the error to the PLS support board/help desk with details',
            type: 'error',
        });
    };
    var _c = useState({
        primaryOnly: true,
        level1: undefined,
        pageNumber: 1,
        pageSize: SEARCH_PRODUCT_PAGE_SIZE,
    }), hierarchyRequest = _c[0], setHierarchyRequest = _c[1];
    var _d = useState({}), levelOptions = _d[0], setLevelOptions = _d[1];
    var _e = useState(1), currLevel = _e[0], setCurrLevel = _e[1];
    var _f = useState(false), showRequest = _f[0], setShowRequest = _f[1];
    var _g = useState(false), isPaginating = _g[0], setIsPaginating = _g[1];
    var sortLabels = useMemo(function () { return ['Relevance', 'Product Prefix Asc', 'Product Prefix Desc']; }, []);
    var _h = useState('Relevance'), selectedSortLabel = _h[0], setSelectedSortLabel = _h[1];
    var date = new Date();
    var newParsedTimeStamp = toUTCTimestamp(date.toISOString());
    var _j = useState(newParsedTimeStamp.parsedDateString), effectiveDate = _j[0], setEffectiveDate = _j[1];
    var _k = useState(false), allowFutureDates = _k[0], setAllowFutureDates = _k[1];
    var _l = useProductHierarchy(hierarchyRequest, effectiveDate, undefined, undefined, undefined, undefined, undefined, allowFutureDates), loading = _l.loading, body = _l.body, status = _l.status;
    var formattedData = useCallback(function (response) {
        var mappedData = response === null || response === void 0 ? void 0 : response.map(function (p) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            var splitDate = (_a = p === null || p === void 0 ? void 0 : p.startDate) === null || _a === void 0 ? void 0 : _a.split('T')[0].split('-');
            var splitTime = (_b = p === null || p === void 0 ? void 0 : p.startDate) === null || _b === void 0 ? void 0 : _b.split('T')[1].split(':');
            var version = (_c = p === null || p === void 0 ? void 0 : p.productVersion) === null || _c === void 0 ? void 0 : _c.split('_');
            return {
                prefix: (_d = p === null || p === void 0 ? void 0 : p.prefix) !== null && _d !== void 0 ? _d : '',
                name: (_g = (_f = (_e = p === null || p === void 0 ? void 0 : p.productName) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.desc) !== null && _g !== void 0 ? _g : '',
                discountString: (_h = getDiscountString(p === null || p === void 0 ? void 0 : p.discountDesc, p === null || p === void 0 ? void 0 : p.discountCode)) !== null && _h !== void 0 ? _h : '',
                startDate: "".concat(splitDate[0], "/").concat(splitDate[1], "/").concat(splitDate[2], " @ ").concat(splitTime[0], ":").concat(splitTime[1].replace('Z', '')),
                version: (_j = p.productVersion) !== null && _j !== void 0 ? _j : '',
                id: (_k = "".concat(version[0], "_").concat(version[1])) !== null && _k !== void 0 ? _k : '',
            };
        });
        return mappedData;
    }, []);
    var _m = useState(formattedData(body === null || body === void 0 ? void 0 : body.data)), tableData = _m[0], setTableData = _m[1];
    var handleSortLabelsSelect = function (col) { return __awaiter(_this, void 0, void 0, function () {
        var sortObj, sortList, rsp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsPaginating(true);
                    setSelectedSortLabel(col);
                    sortObj = getSort(col);
                    setSort(sortObj);
                    setPageNo(1);
                    sortList = [
                        { field: 'prefix', direction: sortObj === null || sortObj === void 0 ? void 0 : sortObj.direction },
                    ];
                    return [4 /*yield*/, getProducts(__assign(__assign({}, hierarchyRequest), { pageSize: SEARCH_PRODUCT_PAGE_SIZE, pageNumber: 1, effectiveDate: effectiveDate, sortList: col === 'Relevance' ? undefined : sortList, includeFuture: allowFutureDates }))];
                case 1:
                    rsp = _a.sent();
                    if (rsp.status >= 500) {
                        handle500Error();
                        return [2 /*return*/];
                    }
                    setTableData(formattedData(rsp.body.data));
                    setIsPaginating(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (!loading && status === 200) {
            setTableData(formattedData(body === null || body === void 0 ? void 0 : body.data));
            setLevelOptions(function (prev) {
                var _a;
                var _b;
                return (__assign(__assign({}, prev), (_a = {}, _a["level".concat(currLevel)] = {
                    keys: ((_b = body === null || body === void 0 ? void 0 : body.aggregations) !== null && _b !== void 0 ? _b : []).map(function (agg) { var _a; return (_a = agg === null || agg === void 0 ? void 0 : agg.key) !== null && _a !== void 0 ? _a : ''; }),
                }, _a)));
            });
        }
        if (!loading && status !== 200) {
            setSnackbarAlert({
                message: 'Hierarchy search error.',
                type: 'error',
            });
        }
    }, [body === null || body === void 0 ? void 0 : body.aggregations, body === null || body === void 0 ? void 0 : body.data, currLevel, formattedData, loading, setSnackbarAlert, status]);
    var handleLevelSelect = function (selected, levelNum, key) {
        var _a, _b, _c, _d;
        if (currLevel < 10) {
            setPageNo(1);
            setSelectedSortLabel('Relevance');
            if (hierarchyRequest[key]) {
                var newRequest = {
                    primaryOnly: hierarchyRequest.primaryOnly,
                };
                var newLevelOptions = {};
                for (var index = 1; index <= levelNum; index += 1) {
                    var indexKey = "level".concat(index);
                    newLevelOptions = __assign(__assign({}, newLevelOptions), (_a = {}, _a[indexKey] = levelOptions === null || levelOptions === void 0 ? void 0 : levelOptions[indexKey], _a));
                    if (key !== indexKey) {
                        newRequest = __assign(__assign({}, newRequest), (_b = {}, _b[indexKey] = hierarchyRequest === null || hierarchyRequest === void 0 ? void 0 : hierarchyRequest[indexKey], _b));
                    }
                    else {
                        newRequest = __assign(__assign({}, newRequest), (_c = {}, _c[key] = selected, _c["level".concat(levelNum + 1)] = undefined, _c));
                    }
                }
                setHierarchyRequest(newRequest);
                setLevelOptions(newLevelOptions);
                setCurrLevel(levelNum + 1);
            }
            else {
                setHierarchyRequest(__assign(__assign({}, hierarchyRequest), (_d = {}, _d[key] = selected, _d["level".concat(currLevel + 1)] = undefined, _d)));
                setCurrLevel(currLevel + 1);
            }
        }
    };
    var handleUnsetLevel = function (level) {
        var _a;
        var updatedRequest = __assign({}, hierarchyRequest);
        var updatedLevels = __assign({}, levelOptions);
        var count = level;
        while ((updatedLevels === null || updatedLevels === void 0 ? void 0 : updatedLevels["level".concat(count)]) !== undefined) {
            delete updatedRequest["level".concat(count)];
            updatedLevels === null || updatedLevels === void 0 ? true : delete updatedLevels["level".concat(count)];
            count += 1;
        }
        setHierarchyRequest(__assign(__assign({}, updatedRequest), (_a = {}, _a["level".concat(level)] = undefined, _a)));
        setLevelOptions(updatedLevels);
        setCurrLevel(level);
        setPageNo(1);
        setSelectedSortLabel('Relevance');
    };
    useEffect(function () {
        setLevelOptions(function (prev) {
            var _a;
            var _b;
            return (__assign(__assign({}, prev), (_a = {}, _a["level".concat(currLevel)] = {
                keys: ((_b = body === null || body === void 0 ? void 0 : body.aggregations) !== null && _b !== void 0 ? _b : []).map(function (agg) { var _a; return (_a = agg === null || agg === void 0 ? void 0 : agg.key) !== null && _a !== void 0 ? _a : ''; }),
            }, _a)));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [body === null || body === void 0 ? void 0 : body.aggregations]);
    var handleReset = function () {
        setCurrLevel(1);
        setHierarchyRequest({
            primaryOnly: true,
            level1: undefined,
            pageNumber: 1,
            pageSize: SEARCH_PRODUCT_PAGE_SIZE,
        });
        setLevelOptions({});
        setShowRequest(false);
        setEffectiveDate(newParsedTimeStamp.parsedDateString);
        setAllowFutureDates(false);
        setSelectedSortLabel('Relevance');
    };
    function handleDateValueChange(e) {
        setSelectedSortLabel('Relevance');
        setPageNo(1);
        setEffectiveDate(e);
    }
    var _o = useState(false), isModalOpen = _o[0], setIsModalOpen = _o[1];
    var _p = useState(1), pageNo = _p[0], setPageNo = _p[1];
    var handleModalClose = function () {
        setIsModalOpen(false);
    };
    var tableDataDefinition = useMemo(function () { return ({
        firstColumnIndent: true,
        noDataMessage: 'No Products found',
        columns: [
            {
                dataAttribute: 'prefix',
                fieldType: InputFieldType.STRING,
                heading: 'Product Prefix',
                width: '10%',
            },
            {
                dataAttribute: 'name',
                fieldType: InputFieldType.STRING,
                heading: 'Product Name',
                width: '40%',
            },
            {
                dataAttribute: 'discountString',
                fieldType: InputFieldType.STRING,
                heading: 'Discount',
                width: '30%',
            },
            {
                dataAttribute: 'startDate',
                fieldType: InputFieldType.STRING,
                heading: 'Start Date',
                width: '20%',
            },
        ],
        showAddRow: false,
        extraIconIsActionText: true,
        extraIcon: {
            icon: null,
            dateAndTimeStrings: {
                date: effectiveDate,
                time: '23:59:59',
            },
        },
    }); }, [effectiveDate]);
    var _q = useState({ present: false, direction: '' }), sort = _q[0], setSort = _q[1];
    var getSort = function (col) {
        var sortObj = { present: false, direction: '' };
        if (col.toLowerCase().includes('prefix')) {
            sortObj.present = true;
            if (col.toLowerCase().includes('asc')) {
                sortObj.direction = 'asc';
            }
            else {
                sortObj.direction = 'desc';
            }
        }
        return sortObj;
    };
    var handlePageChange = function (event, value) { return __awaiter(_this, void 0, void 0, function () {
        var sortList, rsp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setPageNo(value);
                    loading = true;
                    setIsPaginating(true);
                    sortList = [{ field: 'prefix', direction: sort.direction }];
                    return [4 /*yield*/, getProducts(__assign(__assign({}, hierarchyRequest), { pageSize: SEARCH_PRODUCT_PAGE_SIZE, pageNumber: value, effectiveDate: effectiveDate, sortList: selectedSortLabel === 'Relevance' ? undefined : sortList, includeFuture: allowFutureDates }))];
                case 1:
                    rsp = _a.sent();
                    if (rsp.status >= 500) {
                        handle500Error();
                    }
                    body = __assign({}, rsp.body);
                    setTableData(formattedData(body === null || body === void 0 ? void 0 : body.data));
                    status = rsp.status;
                    loading = false;
                    setIsPaginating(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var paginationCount = Math.ceil((body === null || body === void 0 ? void 0 : body.resultsCount) / SEARCH_PRODUCT_PAGE_SIZE);
    var resultsText = useMemo(function () {
        return "".concat(loading ? 'Loading' : 'Showing', " ").concat(pageNo * SEARCH_PRODUCT_PAGE_SIZE - (SEARCH_PRODUCT_PAGE_SIZE - 1), " - ").concat(pageNo * SEARCH_PRODUCT_PAGE_SIZE > (body === null || body === void 0 ? void 0 : body.resultsCount)
            ? body === null || body === void 0 ? void 0 : body.resultsCount
            : pageNo * SEARCH_PRODUCT_PAGE_SIZE, " of ").concat(body === null || body === void 0 ? void 0 : body.resultsCount, " results");
    }, [pageNo, body === null || body === void 0 ? void 0 : body.resultsCount, loading]);
    return (_jsx(Form, { children: _jsx(Box, __assign({ className: styles.container }, { children: _jsxs(Box, __assign({ className: styles.pageContainer }, { children: [_jsxs(Box, __assign({ className: styles.primaryContainer }, { children: [_jsxs(Box, __assign({ className: styles.radioContainer }, { children: [_jsx(Typography, __assign({ variant: "h5", fontWeight: "bold", className: styles.primaryTitle }, { children: "Primary Hierarchy Only:" })), _jsx(FormControl, { children: _jsxs(RadioGroup, __assign({ className: styles.primarySelect, value: (_a = hierarchyRequest.primaryOnly) !== null && _a !== void 0 ? _a : true }, { children: [_jsx(FormControlLabel, { value: "true", control: _jsx(Radio, { size: "small", onChange: function () {
                                                            setSelectedSortLabel('Relevance');
                                                            setPageNo(1);
                                                            setHierarchyRequest(__assign(__assign({}, hierarchyRequest), { primaryOnly: true }));
                                                        } }), label: "True", slotProps: {
                                                        typography: {
                                                            fontSize: '14px',
                                                            fontFamily: "UntitledSansMedium, sans-serif, system-ui",
                                                        },
                                                    } }, "true"), _jsx(FormControlLabel, { value: "false", control: _jsx(Radio, { size: "small", onChange: function () {
                                                            setSelectedSortLabel('Relevance');
                                                            setPageNo(1);
                                                            setHierarchyRequest(__assign(__assign({}, hierarchyRequest), { primaryOnly: false }));
                                                        } }), label: "False", slotProps: {
                                                        typography: {
                                                            fontSize: '14px',
                                                            fontFamily: "UntitledSansMedium, sans-serif, system-ui",
                                                        },
                                                    } }, "false")] })) })] })), _jsxs(Box, __assign({ className: styles.titleResetContainer }, { children: [_jsx(StyledButton, { handleClick: function () { return handleReset(); }, label: "Reset Selections", widthFromProps: 170, heightFromProps: 47 }), _jsx(Typography, __assign({ className: styles.viewLink, onClick: function () {
                                            setIsModalOpen(true);
                                        } }, { children: "View JSON" })), _jsx(JsonViewer, { isOpen: isModalOpen, closeModal: function () { return handleModalClose(); }, stringifiedJson: JSON.stringify(__assign(__assign({}, hierarchyRequest), { effectiveDate: effectiveDate }), null, 4), maxWidth: "40%" })] }))] })), _jsxs(Box, __assign({ className: styles.dateContainer }, { children: [_jsx(Typography, __assign({ variant: "h5", fontWeight: "bold", className: styles.primaryTitle }, { children: "Effective Date:" })), _jsxs(Box, __assign({ className: styles.dateField }, { children: [_jsx(TextField, { type: "date", size: "small", value: effectiveDate, onChange: function (event) { return handleDateValueChange(event.target.value); }, InputLabelProps: {
                                            shrink: true,
                                        }, inputProps: {
                                            style: { width: '200px' },
                                        }, className: styles.dateTextField }), _jsxs(Box, __assign({ className: styles.checkBoxField }, { children: [_jsx(Checkbox, { checked: allowFutureDates, onChange: function (e) {
                                                    setSelectedSortLabel('Relevance');
                                                    setPageNo(1);
                                                    setAllowFutureDates(e.target.checked);
                                                }, sx: { paddingRight: '2px' } }), "Include Future"] }))] }))] })), _jsx(Box, __assign({ className: styles.dropdownsContainer }, { children: Object.entries(levelOptions).map(function (_a, i) {
                            var k = _a[0], v = _a[1];
                            return v.keys.length > 0 ? (_jsx(HierarchyDropdown, { levelData: v, loading: loading && i > Object.keys(levelOptions).length, levelNum: i + 1, dropdownKey: k, handleLevelSelect: handleLevelSelect, unsetLevel: handleUnsetLevel, newHierarchy: true }, k)) : (_jsx(Box, __assign({ width: "100%", display: "flex", justifyContent: "center" }, { children: (body === null || body === void 0 ? void 0 : body.aggregations) && body.aggregations.length === 0 && (_jsx(Typography, { children: "No more levels for selected set of hierarchies" })) }), k));
                        }) })), !loading ? ((body === null || body === void 0 ? void 0 : body.data) &&
                        ((_b = body === null || body === void 0 ? void 0 : body.data) === null || _b === void 0 ? void 0 : _b.length) > 0 && (_jsxs(_Fragment, { children: [_jsx("hr", {}), _jsx(Box, { children: _jsxs(Box, __assign({ className: styles.resultsContainer }, { children: [_jsxs(Box, __assign({ className: styles.container2 }, { children: [!loading && (_jsx("h3", __assign({ className: styles.resultsCount }, { children: body.resultsCount > 0 && resultsText }))), body.resultsCount > SEARCH_PRODUCT_PAGE_SIZE && (_jsx(Pagination, { count: paginationCount, onChange: function (e, num) { return handlePageChange(e, num); }, page: pageNo }))] })), _jsx(Box, __assign({ className: styles.sortContainer }, { children: _jsx(FormControl, __assign({ sx: { m: 1, width: 250, margin: '0px !important' } }, { children: _jsx(Select, __assign({ value: selectedSortLabel, className: styles.selectField }, { children: sortLabels === null || sortLabels === void 0 ? void 0 : sortLabels.map(function (col, index) { return (_jsx(MenuItem, __assign({ value: col, onClick: function () { return handleSortLabelsSelect(col); } }, { children: _jsx(ListItemText, { primary: col }) }), col)); }) })) })) }))] })) }), _jsx("hr", {}), !loading && !isPaginating ? (_jsx(PubTable, { def: tableDataDefinition, tableData: tableData !== null && tableData !== void 0 ? tableData : [], renderTableHeader: tableData && (tableData === null || tableData === void 0 ? void 0 : tableData.length) > 0, origin: "hierarchy" })) : (_jsx(LoadingSpinner, { indicatorSize: 10 }))] }))) : (_jsx(LoadingSpinner, { indicatorSize: 10 }))] })) })) }));
}

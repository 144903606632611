var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable no-console */
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { router } from 'components/App/App';
import { CpaRole } from 'models/auth/User';
var getCognitoUserOrSignIn = function () { return __awaiter(void 0, void 0, void 0, function () {
    var user, e_1, routeState;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 4]);
                return [4 /*yield*/, Auth.currentAuthenticatedUser({
                        bypassCache: true,
                    })];
            case 1:
                user = _a.sent();
                return [2 /*return*/, user];
            case 2:
                e_1 = _a.sent();
                routeState = {
                    pathname: window.location.pathname,
                    search: window.location.search,
                    hash: window.location.hash,
                };
                return [4 /*yield*/, Auth.federatedSignIn({
                        customState: JSON.stringify(routeState),
                        customProvider: process.env.REACT_APP_COGNITO_IDENTITY_PROVIDER_NAME || 'oneLogin',
                    })];
            case 3:
                _a.sent();
                throw e_1;
            case 4: return [2 /*return*/];
        }
    });
}); };
export var listenForAuthRedirect = function () {
    Hub.listen('auth', function (_a) {
        var payload = _a.payload;
        if (payload.event === 'customOAuthState') {
            var _b = JSON.parse(payload.data), pathname = _b.pathname, search = _b.search, hash = _b.hash;
            if (pathname) {
                router.navigate(pathname + search + hash);
            }
        }
        switch (payload.event) {
            case 'signIn':
                console.log('user signed in');
                break;
            case 'signUp':
                console.log('user signed up');
                break;
            case 'signOut':
            case 'signIn_failure':
            case 'customState_failure':
            case 'cognitoHostedUI_failure':
                console.log('user sign in failed sending to onelogin');
                break;
            case 'tokenRefresh':
                console.log('token refresh succeeded');
                break;
            case 'tokenRefresh_failure':
                console.log('token refresh failed');
                break;
            case 'configured':
                console.log('the Auth module is configured');
                break;
            case 'customOAuthState':
                console.log('customOAuthState is configured');
                break;
            default:
                console.log('Something Else', payload.event);
        }
    });
};
export function getUserOrSignIn() {
    return __awaiter(this, void 0, Promise, function () {
        var cognitoUser, user;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCognitoUserOrSignIn()];
                case 1:
                    cognitoUser = _a.sent();
                    user = getUserFromCognitoUser(cognitoUser);
                    return [2 /*return*/, user];
            }
        });
    });
}
var getUserFromCognitoUser = function (cognitoUser) {
    var _a, _b, _c, _d;
    var jwt = (_b = (_a = cognitoUser === null || cognitoUser === void 0 ? void 0 : cognitoUser.getSignInUserSession()) === null || _a === void 0 ? void 0 : _a.getIdToken()) === null || _b === void 0 ? void 0 : _b.decodePayload();
    if (!jwt)
        throw new Error('decoded token was null');
    var groups = JSON.parse(jwt['custom:adGroups']);
    var upnDetails = JSON.parse(jwt['custom:params'] || '{}');
    var upnValue = (_d = (_c = upnDetails === null || upnDetails === void 0 ? void 0 : upnDetails.UPN) === null || _c === void 0 ? void 0 : _c.split('@')) === null || _d === void 0 ? void 0 : _d[0];
    var user = {
        email: jwt.email,
        name: jwt.name,
        userId: upnValue,
        groups: groups,
        cpaRole: deriveCpaRole(groups),
    };
    return user;
};
function deriveCpaRole(groups) {
    var _a;
    var env = (_a = process.env.REACT_APP_ENV_NAME) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    var cpaRole;
    if ((groups === null || groups === void 0 ? void 0 : groups.includes("pub_ui_admin_".concat(env))) ||
        ((groups === null || groups === void 0 ? void 0 : groups.includes("pub_ui_cpa_wo_".concat(env))) && (groups === null || groups === void 0 ? void 0 : groups.includes("pub_ui_cpa_sp_".concat(env)))))
        cpaRole = CpaRole.ALL;
    else if (groups === null || groups === void 0 ? void 0 : groups.includes("pub_ui_cpa_wo_".concat(env)))
        cpaRole = CpaRole.WORKORDER;
    else if (groups === null || groups === void 0 ? void 0 : groups.includes("pub_ui_cpa_sp_".concat(env)))
        cpaRole = CpaRole.SPECIALS;
    else
        cpaRole = CpaRole.NONE;
    return cpaRole;
}
export var logoutSession = function () { return __awaiter(void 0, void 0, void 0, function () {
    var user;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Auth.currentAuthenticatedUser()];
            case 1:
                user = (_a.sent());
                user.signOut();
                return [2 /*return*/];
        }
    });
}); };
export function getCognitoIdToken() {
    return __awaiter(this, void 0, Promise, function () {
        var session;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Auth.currentSession()];
                case 1:
                    session = _a.sent();
                    return [2 /*return*/, session.getIdToken().getJwtToken()];
            }
        });
    });
}
export function checkForCognitoError() {
    var url = new URL(window.location.href);
    var error = url.searchParams.get('error');
    var hasError = !!error;
    return hasError;
}

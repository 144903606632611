var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useRef, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import { PubTableEditRow } from '../PubTableEditRow/PubTableEditRow';
import { PubTableDisplayRow } from '../PubTableDisplayRow/PubTableDisplayRow';
import styles from './PubTableRow.module.scss';
export function PubTableRow(_a) {
    var _b, _c;
    var uuid = _a.uuid, row = _a.row, def = _a.def, backgroundColorVariation = _a.backgroundColorVariation, extraIconIsAction = _a.extraIconIsAction, tableData = _a.tableData, indexOfItem = _a.indexOfItem, boldRowOnHover = _a.boldRowOnHover, origin = _a.origin, nsoMetadata = _a.nsoMetadata, searchText = _a.searchText, lengthOfTable = _a.lengthOfTable, fireApiInParent = _a.fireApiInParent;
    var myRef = useRef(null);
    var checkIfApiShouldBeDisabled = useMemo(function () {
        if (!nsoMetadata)
            return undefined;
        if (!nsoMetadata.startAt || lengthOfTable < 500) {
            // should enable
            return true;
        }
        // should disable
        return false;
    }, [nsoMetadata, lengthOfTable]);
    var _d = useState(checkIfApiShouldBeDisabled), isDisabledApi = _d[0], setIsDisabledApi = _d[1]; // true disabled, false enabled
    var _e = useState(false), inEdit = _e[0], setInEdit = _e[1];
    var _f = useState(false), isHovering = _f[0], setIsHovering = _f[1];
    function handleEditClick() {
        setInEdit(function (prevInEdit) { return !prevInEdit; });
    }
    function deleteHandler() {
        var _a;
        if ((_a = def.deleteProps) === null || _a === void 0 ? void 0 : _a.deleteHandler)
            def.deleteProps.deleteHandler(uuid, row);
    }
    function handleCloseClick() {
        setInEdit(false);
    }
    function handleDoneClick(updatedRow) {
        if (def.editHandler)
            def.editHandler(uuid, updatedRow, row);
        setInEdit(false);
    }
    function extraIconHandler(e) {
        var _a, _b;
        if ((_a = def.extraIcon) === null || _a === void 0 ? void 0 : _a.handler)
            (_b = def.extraIcon) === null || _b === void 0 ? void 0 : _b.handler(uuid, row, e);
    }
    function rowClickHandler() {
        if (def.rowClickHandler)
            def.rowClickHandler(row);
    }
    var getBackgroundColor = function (variation) {
        switch (variation) {
            case 'primary':
                return '#F1F0EF';
            default:
                return 'white';
        }
    };
    var handleMouseOver = function (val) {
        setIsHovering(val);
    };
    useEffect(function () {
        if (myRef.current) {
            var obsrvr_1 = new IntersectionObserver(function (entries, obs) {
                if (entries[0].isIntersecting) {
                    if (!isDisabledApi) {
                        fireApiInParent === null || fireApiInParent === void 0 ? void 0 : fireApiInParent(nsoMetadata);
                        setIsDisabledApi(true);
                        obsrvr_1.unobserve(myRef.current);
                    }
                }
            }, tableData);
            obsrvr_1.observe(myRef.current);
        }
    }, [myRef, tableData, nsoMetadata, fireApiInParent, isDisabledApi]);
    return (_jsx(TableRow, __assign({ ref: row.isLast ? myRef : null, sx: {
            backgroundColor: getBackgroundColor(backgroundColorVariation),
            td: { color: !def.rowDecorator || ((_b = def.rowDecorator) === null || _b === void 0 ? void 0 : _b.hide(row)) ? 'black' : '#017a9a' },
            cursor: boldRowOnHover ? 'pointer' : 'auto',
        }, onClick: function () { return rowClickHandler(); }, onMouseOver: boldRowOnHover ? function () { return handleMouseOver(true); } : undefined, onMouseOut: boldRowOnHover ? function () { return handleMouseOver(false); } : undefined, hover: def.showHover, className: def.showHover ? styles.rowHover : '' }, { children: inEdit ? (_jsx(PubTableEditRow, { def: def, inputRow: row, closeHandler: function () { return handleCloseClick(); }, doneHandler: function (updatedRow) { return handleDoneClick(updatedRow); }, validateRow: def.validateRow })) : (_jsx(PubTableDisplayRow, { handleEditClick: function () { return handleEditClick(); }, handleDeleteClick: function () { return deleteHandler(); }, handleExtraIconClick: function (e) { return extraIconHandler(e); }, editDisabled: (_c = def.disableEdit) === null || _c === void 0 ? void 0 : _c.call(def, uuid), def: def, row: row, extraIconIsAction: extraIconIsAction, tableData: tableData, indexOfItem: indexOfItem, isHovering: isHovering, origin: origin })) }), uuid));
}

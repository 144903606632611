var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
import { InputFieldType } from 'models/Input/InputModels';
import { useMemo, useState } from 'react';
import { PubInputField } from 'components/Shared/PubInputField/PubInputField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import { FabricsLookupModal } from './FabricsLookupModal';
export function FabricOptionsInput(_a) {
    var _b = _a.isEditMode, isEditMode = _b === void 0 ? true : _b, featureDescription = _a.featureDescription, featureStep = _a.featureStep, changeCodeHandler = _a.changeCodeHandler, options = _a.options, selectedValue = _a.selectedValue, showOptionalColumns = _a.showOptionalColumns, fromInfo = _a.fromInfo, modelOpenHandler = _a.modelOpenHandler;
    var _c = useState(false), isFabricModalOpen = _c[0], setIsFabricModelOpen = _c[1];
    var _d = useState(), isFabricsLoading = _d[0], setIsFabricsLoading = _d[1];
    var fabricOptions = useMemo(function () {
        return options === null || options === void 0 ? void 0 : options.map(function (option) {
            var optionCode = option.optionCode, optionDescription = option.optionDescription, minLeadTime = option.minLeadTime, gsaInd = option.gsaInd, pbSubHeading = option.pbSubHeading, prcOptionModelCode = option.prcOptionModelCode, mfgOptionModelCode = option.mfgOptionModelCode;
            return {
                optionCode: optionCode,
                optionDescription: optionDescription,
                subheading: pbSubHeading !== null && pbSubHeading !== void 0 ? pbSubHeading : '',
                gsaInd: gsaInd ? 'Y' : 'N',
                minLeadTime: minLeadTime,
                prcOptionModelCode: prcOptionModelCode,
                mfgOptionModelCode: mfgOptionModelCode,
            };
        });
    }, [options]);
    return (_jsxs(_Fragment, { children: [isEditMode ? (_jsx(Box, { children: _jsx(PubInputField, { fieldType: InputFieldType.STRING, fieldValue: selectedValue, fieldValueUpdateHandler: undefined, borderFocus: true, makeCaratTransparent: true }) })) : null, _jsx(Button, __assign({ sx: {
                    fontSize: '12px',
                    color: 'black',
                    textDecoration: 'underline',
                    textWrap: 'nowrap',
                    ':hover': {
                        backgroundColor: !isFabricsLoading || options ? 'white' : 'transparent',
                    },
                    padding: '0px',
                }, onClick: function () {
                    if (modelOpenHandler) {
                        modelOpenHandler();
                        setIsFabricsLoading(true);
                    }
                    setIsFabricModelOpen(true);
                } }, { children: !isEditMode ? ('View Fabric Option') : isFabricsLoading && !options ? (_jsx(LoadingSpinner, { indicatorSize: 1.5, overridePosition: true })) : ('Select Fabric Option') })), isFabricModalOpen && options && (_jsx(FabricsLookupModal, { showOptionalColumns: showOptionalColumns, fromInfo: fromInfo, isEditMode: isEditMode, featureDescription: featureDescription, featureStep: featureStep, isOpen: isFabricModalOpen, setIsOpen: setIsFabricModelOpen, rowData: fabricOptions, changeCodeHandler: function (row) {
                    if (row && isEditMode) {
                        changeCodeHandler(row.code);
                        setIsFabricModelOpen(false);
                    }
                }, widthOverride: showOptionalColumns !== 'Show None' ? 50 : undefined }))] }));
}

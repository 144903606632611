var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FeatureLookupModal } from 'components/Shared/FeatureModalLookup/FeatureLookupModal';
import { InputFieldType } from 'models/Input/InputModels';
import { useMemo } from 'react';
import styles from './FabricsLookupModal.module.scss';
export function FabricsLookupModal(_a) {
    var _b = _a.isEditMode, isEditMode = _b === void 0 ? true : _b, isOpen = _a.isOpen, rowData = _a.rowData, setIsOpen = _a.setIsOpen, featureDescription = _a.featureDescription, featureStep = _a.featureStep, showOptionalColumns = _a.showOptionalColumns, widthOverride = _a.widthOverride, fromInfo = _a.fromInfo, changeCodeHandler = _a.changeCodeHandler;
    var tableData = useMemo(function () {
        return rowData === null || rowData === void 0 ? void 0 : rowData.reduce(function (agg, el, index) {
            var _a;
            return (__assign(__assign({}, agg), (_a = {}, _a[index] = {
                code: "".concat(el.optionCode, " - ").concat(el.optionDescription),
                subheading: el.subheading,
                leadTime: el.minLeadTime ? el.minLeadTime.toString() : null,
                gsaInd: el.gsaInd,
                prcOptionModelCode: el.prcOptionModelCode,
                mfgOptionModelCode: el.mfgOptionModelCode,
            }, _a)));
        }, {});
    }, [rowData]);
    var tableDefinition = useMemo(function () {
        var def = {
            noDataMessage: 'No Options met the search criteria',
            columns: [
                {
                    dataAttribute: 'code',
                    fieldType: InputFieldType.READONLY,
                    heading: 'Option Code - Description',
                    width: showOptionalColumns === 'Show None' && fromInfo ? '30%' : '20%',
                },
                {
                    dataAttribute: 'subheading',
                    fieldType: InputFieldType.READONLY,
                    heading: 'Sub Heading',
                    width: showOptionalColumns === 'Show None' && fromInfo ? '30%' : '20%',
                },
                {
                    dataAttribute: 'leadTime',
                    fieldType: InputFieldType.READONLY,
                    heading: 'Lead Time',
                    width: showOptionalColumns === 'Show None' && fromInfo ? '20%' : '10%',
                },
                {
                    dataAttribute: 'gsaInd',
                    fieldType: InputFieldType.READONLY,
                    heading: 'GSA',
                    width: showOptionalColumns === 'Show None' && fromInfo ? '20%' : '10%',
                },
            ],
            showHover: isEditMode,
            underlineColumnRow: ['Option Code - Description'],
        };
        if (showOptionalColumns !== 'Show None' && fromInfo) {
            var optionalCol = {
                dataAttribute: showOptionalColumns === 'Engineering Model Codes' ? 'mfgOptionModelCode' : 'prcOptionModelCode',
                fieldType: InputFieldType.READONLY,
                heading: 'Option Model Code',
                width: '40%',
            };
            def.columns = __spreadArray(__spreadArray(__spreadArray([], def.columns.slice(0, 2), true), [optionalCol], false), def.columns.slice(2), true);
        }
        return def;
    }, [isEditMode, showOptionalColumns, fromInfo]);
    return (_jsx(FeatureLookupModal, __assign({ isOpen: isOpen, setIsOpen: setIsOpen, title: "".concat(isEditMode ? 'Select' : 'View', " Fabric Options"), tableDefinition: tableDefinition, tableData: tableData, filterLabel: "Search by Code or Keyword", changeCodeHandler: changeCodeHandler, tooltip: "Select a fabric option from the table below", widthOverride: widthOverride, minHeightOverride: 400 }, { children: _jsxs(Box, __assign({ className: styles.labelContainer }, { children: [_jsxs(Box, __assign({ className: styles.labelRow, sx: { paddingBottom: '6px' } }, { children: [_jsx(Box, __assign({ className: styles.labelColumn }, { children: _jsx(Typography, __assign({ className: styles.labelFont }, { children: "Product Feature Step" })) })), _jsx(Typography, { children: featureStep })] })), _jsxs(Box, __assign({ className: styles.labelRow }, { children: [_jsx(Box, __assign({ className: styles.labelColumn }, { children: _jsx(Typography, __assign({ className: styles.labelFont }, { children: "Feature" })) })), _jsx(Typography, { children: featureDescription })] }))] })) })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { StyledTableContainer } from 'components/Shared/StyledTableContainer/StyledTableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ErrorAdornment } from 'components/Shared/ErrorAdornment/ErrorAdornment';
import { catTypeKey } from 'utils/constants';
import { catalogHelper } from 'utils/catalog-helper';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Loading } from 'components/Shared/Loading/AppLoading/Loading/Loading';
import styles from './ProductsTable.module.scss';
export function ProductsTable(_a) {
    var products = _a.products, selectedLeadTime = _a.selectedLeadTime, channel = _a.channel, effectiveDate = _a.effectiveDate, selectedCatType = _a.selectedCatType, includeNonPromotable = _a.includeNonPromotable, nonErroredProducts = _a.nonErroredProducts, removeIndex = _a.removeIndex, loading = _a.loading;
    var catType = catTypeKey[selectedCatType];
    var effDate = catalogHelper.setDateToActualValue(effectiveDate);
    return (_jsxs(StyledTableContainer, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ className: styles.colMedium }, { children: "Product Prefix" })), _jsx(TableCell, __assign({ className: styles.colDescription }, { children: "Product Name" })), _jsx(TableCell, __assign({ className: styles.colMedium }, { children: "Channels" })), _jsx(TableCell, __assign({ className: styles.colSmall }, { children: "Lead Time" })), _jsx(TableCell, { children: " " }), _jsx(TableCell, { children: " " })] }) }), _jsx(TableBody, { children: loading ? (_jsx(TableRow, { children: _jsx(TableCell, __assign({ colSpan: 6 }, { children: _jsx(Loading, {}) })) })) : products.length === 0 ? (_jsx(TableRow, { children: _jsx(TableCell, __assign({ className: styles.noResultsCell, colSpan: 6 }, { children: "No selected products" })) })) : (products.map(function (product, i) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                    return (_jsxs(TableRow, __assign({ className: nonErroredProducts.find(function (p) { return p.key._id === product.key._id; })
                            ? styles.validProduct
                            : styles.invalidProduct }, { children: [_jsx(TableCell, { children: product.body.prefix }), _jsx(TableCell, { children: (_b = (_a = product.body.productName) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.desc }), _jsx(TableCell, { children: (_c = product.body.channels) === null || _c === void 0 ? void 0 : _c.map(function (ch) { return ch.code; }).join(',\n') }), _jsx(TableCell, { children: (_d = product.body.minLeadtime) !== null && _d !== void 0 ? _d : 99 }), _jsx(TableCell, __assign({ size: "small", className: styles.colIcon }, { children: _jsx(ErrorAdornment, { errors: [
                                        {
                                            condition: ((_e = product.body) === null || _e === void 0 ? void 0 : _e.minLeadtime) !== undefined &&
                                                product.body.minLeadtime > selectedLeadTime,
                                            message: "This product's Lead Time is greater than the selected Lead Time: '".concat(selectedLeadTime, "'"),
                                        },
                                        {
                                            condition: ((_f = product.body) === null || _f === void 0 ? void 0 : _f.channels) !== undefined &&
                                                !product.body.channels.find(function (ch) { return ch.code.toLowerCase() === channel.toLowerCase(); }),
                                            message: "This product's channels does not contain the selected Channel: '".concat(channel, "'"),
                                        },
                                        {
                                            condition: catalogHelper.setDateToActualValue(product.body.startDate).getTime() >
                                                effDate.getTime(),
                                            message: "This product's start date is greater than the selected Effective Date: '".concat(effDate.toLocaleString(undefined, { dateStyle: 'medium' }), "'"),
                                        },
                                        {
                                            condition: ((_g = product.body) === null || _g === void 0 ? void 0 : _g.endDate) !== undefined &&
                                                catalogHelper.setDateToActualValue(product.body.endDate).getTime() <
                                                    effDate.getTime(),
                                            message: "This product's end date is less than the selected Effective Date: '".concat(effDate.toLocaleString(undefined, { dateStyle: 'medium' }), "'"),
                                        },
                                        {
                                            condition: !includeNonPromotable &&
                                                !((_h = product.body.channels) === null || _h === void 0 ? void 0 : _h.find(function (ch) { return ch === null || ch === void 0 ? void 0 : ch[catType]; })),
                                            message: "This product doesn't contain a channel matching the selected Catalog Type: '".concat(selectedCatType, "'"),
                                        },
                                        {
                                            condition: nonErroredProducts.findIndex(function (p) { return p.key._id === product.key._id; }) ===
                                                -1,
                                            message: 'This product will not be included on submission.',
                                        },
                                    ], display: ((_j = product.body) === null || _j === void 0 ? void 0 : _j.minLeadtime) !== undefined &&
                                        product.body.minLeadtime > selectedLeadTime }) })), _jsx(TableCell, __assign({ size: "small", className: styles.colIcon }, { children: _jsx(IconButton, __assign({ onClick: function () { return removeIndex(i); }, sx: { padding: 0 } }, { children: _jsx(DeleteOutlineIcon, {}) })) }))] }), product.key._id));
                })) })] }));
}

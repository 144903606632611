var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { StyledButton } from 'components/Shared/StyledComponents/StyledButton';
import { useState } from 'react';
import { validateRangeInput } from 'utils/productBuildHelper';
export function RangeOptionTextField(_a) {
    var _b, _c, _d;
    var option = _a.option, rangeValFromParent = _a.rangeValFromParent, v2 = _a.v2, fieldHasValue = _a.fieldHasValue, handleRangeOptionSubmit = _a.handleRangeOptionSubmit;
    var _e = useState(rangeValFromParent !== null && rangeValFromParent !== void 0 ? rangeValFromParent : ''), rangeText = _e[0], setRangeText = _e[1];
    var _f = useState(false), rangeError = _f[0], setRangeError = _f[1];
    var optionRange = option.range;
    var setToFixed = function (val) {
        if (!val)
            return undefined;
        var result;
        switch (typeof val) {
            case 'string':
                result = parseFloat(val).toFixed(option.range.rangeScale);
                break;
            case 'number':
                result = val.toFixed(option.range.rangeScale);
                break;
            default:
                break;
        }
        return result;
    };
    var notAvailable = function (val) { return val !== null && val !== void 0 ? val : 'N/A'; };
    var handleRangeTextChange = function (text, range) {
        var isValid = validateRangeInput(range, text);
        setRangeText(text);
        setRangeError(!isValid);
    };
    var handleSubmit = function () {
        handleRangeOptionSubmit(option, parseFloat(rangeText).toFixed(option.range.rangeScale));
    };
    return (_jsxs(Box, __assign({ display: "flex", flexDirection: "row", width: "100%", columnGap: "15px" }, { children: [_jsxs(Box, __assign({ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }, { children: [_jsx(Typography, __assign({ sx: { whiteSpace: 'nowrap' } }, { children: "".concat(option === null || option === void 0 ? void 0 : option.optionDescription, " (") })), _jsx(TextField, { label: "Range: ".concat(notAvailable(setToFixed((_b = option.range) === null || _b === void 0 ? void 0 : _b.start)), " - ").concat(notAvailable(setToFixed((_c = option.range) === null || _c === void 0 ? void 0 : _c.end)), "; Increment By: ").concat(notAvailable(setToFixed((_d = option.range) === null || _d === void 0 ? void 0 : _d.increment))), size: "small", sx: { margin: '0 10px', width: '100%', height: !v2 ? '40px !important' : '35px !important' }, onChange: function (event) { return handleRangeTextChange(event.target.value.trim(), optionRange); }, onKeyUp: function (e) { return e.code === 'Enter' && handleSubmit(); }, error: rangeError, value: rangeText, InputProps: {
                            className: fieldHasValue ? 'Mui-focused !important' : '',
                        } }), !v2 && _jsx(Typography, __assign({ sx: { whiteSpace: 'nowrap' } }, { children: ") - (".concat(option === null || option === void 0 ? void 0 : option.skuSelection, ")") }))] })), _jsx(StyledButton, { label: "Submit", handleClick: handleSubmit, heightFromProps: 40, disabledFromProps: rangeError || rangeText === '' })] })));
}

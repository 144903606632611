import { jsx as _jsx } from "react/jsx-runtime";
import { StyledSearchWithDateAndTimeInput } from 'components/Shared/StyledComponents/StyledSearchWithDateAndTimeInput/StyledSearchWithDateAndTimeInput';
import { useState } from 'react';
import { toUTCTimestamp } from 'services/helperFunctions/toUTCTimestamp';
export function ProductValidationSearch(_a) {
    var handleSearchButtonClick = _a.handleSearchButtonClick;
    var _b = useState(''), searchText = _b[0], setSearchText = _b[1];
    var handleInputValueChange = function (value) {
        setSearchText(value);
    };
    var handleButtonClick = function (_a) {
        var _b;
        var timestamp = _a.timestamp;
        var newParsedTimestamp = toUTCTimestamp(timestamp);
        handleSearchButtonClick({
            searchTerm: searchText,
            timestamp: (_b = newParsedTimestamp.parsedTimeStamp) !== null && _b !== void 0 ? _b : '',
        });
    };
    return (_jsx(StyledSearchWithDateAndTimeInput, { searchTerm: searchText, handleSearchButtonClick: handleButtonClick, handleInputValueChange: handleInputValueChange }));
}

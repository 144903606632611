var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ProductSpecialRestrictionCode } from '@millerknoll/pub-api-schema';
import { useCpaProduct } from 'components/Cpa/CpaProduct/CpaProductContextProvider';
import { ActionModal } from 'components/Shared/ActionModal/ActionModal';
import { PubInputField } from 'components/Shared/PubInputField/PubInputField';
import { InputFieldType } from 'models/Input/InputModels';
import { DEFAULT_WORKFLOW } from 'utils/cpa-utils';
var YES_NO;
(function (YES_NO) {
    YES_NO["YES"] = "Yes";
    YES_NO["NO"] = "No";
})(YES_NO || (YES_NO = {}));
export function DeleteRowWorkflowModal(_a) {
    var _b, _c, _d, _e, _f, _g;
    var deleteProps = _a.deleteProps, deleteAction = _a.deleteAction;
    var cpaProductContext = useCpaProduct();
    var _h = useState(false), showWorkflowInput = _h[0], setShowWorkflowInput = _h[1];
    var _j = useState(''), workflow = _j[0], setWorkflow = _j[1];
    var _k = useState(YES_NO.NO), releaseHold = _k[0], setReleaseHold = _k[1];
    var _l = useState(true), formInError = _l[0], setFormInError = _l[1];
    var isPreRelease = useRef(((_e = (_d = (_c = (_b = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _b === void 0 ? void 0 : _b.currentProduct) === null || _c === void 0 ? void 0 : _c.restrictions) === null || _d === void 0 ? void 0 : _d[ProductSpecialRestrictionCode === null || ProductSpecialRestrictionCode === void 0 ? void 0 : ProductSpecialRestrictionCode.PRERELEASE]) === null || _e === void 0 ? void 0 : _e.isActive) ===
        true);
    return deleteProps.modalProps ? (_jsx(ActionModal, __assign({ isOpen: true, setIsOpen: function () { return deleteAction('cancel', undefined); }, title: deleteProps.modalProps.titleText, buttonProps: [
            {
                labelText: (_f = deleteProps.modalProps.buttonProps.confirmText) !== null && _f !== void 0 ? _f : 'Confirm',
                backgroundColorVariation: 'primary',
                width: '120px',
                disabled: !isPreRelease.current && formInError,
                buttonAction: function () {
                    var _a, _b, _c;
                    if (isPreRelease.current &&
                        !((_c = (_b = (_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProductOriginal) === null || _a === void 0 ? void 0 : _a.restrictions) === null || _b === void 0 ? void 0 : _b[ProductSpecialRestrictionCode.PRERELEASE]) === null || _c === void 0 ? void 0 : _c.isActive) &&
                        releaseHold === YES_NO.YES &&
                        !showWorkflowInput) {
                        setShowWorkflowInput(true);
                    }
                    else {
                        deleteAction('confirm', workflow.trim() === '' ? DEFAULT_WORKFLOW : workflow, isPreRelease.current && releaseHold === YES_NO.YES);
                    }
                },
            },
            {
                labelText: (_g = deleteProps.modalProps.buttonProps.cancelText) !== null && _g !== void 0 ? _g : 'Cancel',
                backgroundColorVariation: 'secondary',
                width: '120px',
                buttonAction: function () {
                    deleteAction('cancel', undefined);
                },
            },
        ] }, { children: isPreRelease.current && !showWorkflowInput ? (_jsxs(Box, __assign({ my: "32px", sx: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                columnGap: '24px',
                alignItems: 'flex-end',
            } }, { children: [_jsx(Typography, __assign({ sx: {
                        width: 'fit-content',
                        fontFamily: "UntitledSansMedium, sans-serif, system-ui",
                        alignSelf: 'flex-start',
                    } }, { children: "Product is on Prerelease Hold. Do you want to remove the Hold?" })), _jsx(PubInputField, { fieldType: InputFieldType.RADIO, fieldValue: releaseHold, fieldValueUpdateHandler: function (val) {
                        setReleaseHold(val);
                    }, labels: { left: 'Release Hold?' }, fieldOptions: Object.values(YES_NO), fieldContainerWidthOverride: "fit-content" })] }))) : (_jsx(Box, __assign({ my: "32px" }, { children: _jsx(PubInputField, { fieldType: InputFieldType.STRING, validations: {
                    required: 'Please enter value.',
                }, inError: function (val) {
                    if (formInError !== val) {
                        setFormInError(val);
                    }
                }, fieldValue: workflow, labels: {
                    left: 'Workflow Text',
                    right: 'Required',
                }, dirtyCheck: true, fieldValueUpdateHandler: function (val) {
                    setWorkflow(val);
                } }) }))) }))) : (_jsx(_Fragment, { children: " " }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { InputFieldType } from 'models/Input/InputModels';
import { useMemo, useState } from 'react';
import { PubInputField } from 'components/Shared/PubInputField/PubInputField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useSearchParams } from 'react-router-dom';
import { NonStdsLookupModal } from './NonStdLookupModal';
export function NonStdOptionsInput(_a) {
    var featureDescription = _a.featureDescription, featureStep = _a.featureStep, options = _a.options, selectedValue = _a.selectedValue, nsoMetadata = _a.nsoMetadata, isEditMode = _a.isEditMode, showOptionalColumns = _a.showOptionalColumns, changeCodeHandler = _a.changeCodeHandler, modelOpenHandler = _a.modelOpenHandler, updateRowOptions = _a.updateRowOptions;
    var _b = useState(false), isNonStdModalOpen = _b[0], setIsNonStdModelOpen = _b[1];
    var searchParams = useSearchParams()[0];
    var nonStdOptions = useMemo(function () {
        var _a;
        return (_a = options === null || options === void 0 ? void 0 : options.map(function (option) {
            var optionCode = option.optionCode, optionDescription = option.optionDescription, minLeadTime = option.minLeadTime, gsaInd = option.gsaInd;
            return {
                optionCode: optionCode,
                optionDescription: optionDescription,
                gsaInd: gsaInd ? 'Y' : 'N',
                minLeadTime: minLeadTime,
                prcOptionModelCode: option.prcOptionModelCode,
                mfgOptionModelCode: option.mfgOptionModelCode,
                channels: option.channels,
                productNbr: option.productNbr,
                nsoGroupCode: option.nsoGroupCode,
            };
        })) === null || _a === void 0 ? void 0 : _a.filter(function (thing) {
            var _a;
            var channelVals = [];
            (_a = thing === null || thing === void 0 ? void 0 : thing.channels) === null || _a === void 0 ? void 0 : _a.forEach(function (channel) {
                channelVals.push(channel.channelCode);
            });
            var index = 0;
            if (searchParams.get('channel') === 'Commercial') {
                index = channelVals.findIndex(function (val) { return val === 'NA Contract'; });
            }
            else if (searchParams.get('channel') === 'B2B') {
                index = channelVals.findIndex(function (val) { return val === 'B2B'; });
            }
            else if (searchParams.get('channel') === 'GSA') {
                index = channelVals.findIndex(function (val) { return val === 'GSA'; });
            }
            if (index > -1)
                return true;
            return false;
        });
    }, [options, searchParams]);
    return (_jsxs(_Fragment, { children: [isEditMode && (_jsx(Box, { children: _jsx(PubInputField, { fieldType: InputFieldType.STRING, fieldValue: selectedValue, fieldValueUpdateHandler: undefined, borderFocus: true, makeCaratTransparent: true }) })), _jsx(Button, __assign({ sx: {
                    fontSize: '12px',
                    color: 'black',
                    textDecoration: 'underline',
                    textWrap: 'nowrap',
                    ':hover': {
                        backgroundColor: 'white',
                    },
                    padding: '0px',
                }, onClick: function () {
                    if (modelOpenHandler) {
                        modelOpenHandler();
                    }
                    setIsNonStdModelOpen(true);
                } }, { children: "Select Non-Standard Option" })), isNonStdModalOpen && (_jsx(NonStdsLookupModal, { featureDescription: featureDescription, featureStep: featureStep, isOpen: isNonStdModalOpen, setIsOpen: setIsNonStdModelOpen, rowData: nonStdOptions, changeCodeHandler: function (row) {
                    if (row && isEditMode) {
                        changeCodeHandler(row.code);
                        setIsNonStdModelOpen(false);
                    }
                }, nsoMetadata: nsoMetadata, updateRowOptions: function (e) {
                    updateRowOptions === null || updateRowOptions === void 0 ? void 0 : updateRowOptions(e);
                }, showOptionalColumns: showOptionalColumns, widthOverride: showOptionalColumns !== 'Show None' ? 50 : undefined }))] }));
}

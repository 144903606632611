var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useCpaProduct } from 'components/Cpa/CpaProduct/CpaProductContextProvider';
import { CpaProductType } from 'utils/cpa-utils';
import { CpaExtraDataCustomFieldsSpecials } from './CpaExtraDataCustomFields/CpaExtraDataCustomFieldsSpecials';
import { CpaExtraDataCustomFieldsWorkOrder } from './CpaExtraDataCustomFields/CpaExtraDataCustomFieldsWorkOrder';
import { CpaExtraDataSalesCategorySpecials } from './CpaExtraDataSalesCategory/CpaExtraDataSalesCategorySpecials';
import { CpaExtraDataSalesCategoryWorkOrder } from './CpaExtraDataSalesCategory/CpaExtraDataSalesCategoryWorkOrder';
import { CpaExtraDataRestrictions } from './CpaExtraDataRestrictions/CpaExtraDataRestrictions';
import styles from './CpaExtraData.module.scss';
export function CpaExtraData() {
    var cpaProductContext = useCpaProduct();
    return (cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.productType) === CpaProductType.WORK_ORDER ? (_jsxs(Box, __assign({ className: styles.workOrderContainer }, { children: [_jsx(CpaExtraDataCustomFieldsWorkOrder, {}), _jsx(CpaExtraDataSalesCategoryWorkOrder, {})] }))) : (_jsxs(Box, __assign({ className: styles.specialsContainer }, { children: [_jsx(CpaExtraDataCustomFieldsSpecials, {}), _jsx(CpaExtraDataSalesCategorySpecials, {}), _jsx(CpaExtraDataRestrictions, {})] })));
}

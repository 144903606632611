var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { PubTable } from 'components/Shared/PubTable/PubTable';
import { InputFieldType } from 'models/Input/InputModels';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getFilteredProducts } from 'services/api/search-filter-build-products';
import { determineChannelVal } from 'components/ProductBuildNew/ProductBuildSearchFormV2/ProductBuildSearchFormV2';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import styles from './ProductBuildSearchResultsV2.module.scss';
export function mapTableData(data) {
    var arr = [];
    data.forEach(function (record) {
        var _a, _b;
        var formattedStartDate = record.startDate.split('T');
        var splitDate = formattedStartDate[0].split('-');
        var splitTime = formattedStartDate[1].split(':');
        var startDateToUse = "".concat(splitDate[0], "/").concat(splitDate[1], "/").concat(splitDate[2], " @ ").concat(splitTime[0], ":").concat(splitTime[1].replace('Z', ''));
        var passedRecord = {
            prefix: record.prefix,
            name: (_a = record === null || record === void 0 ? void 0 : record.productName) === null || _a === void 0 ? void 0 : _a[0].desc,
            startDate: startDateToUse,
            productId: "".concat((_b = record.productVersion) === null || _b === void 0 ? void 0 : _b.split('_')[1], " / ").concat(record.productVersion),
        };
        arr.push(passedRecord);
    });
    return arr;
}
export function ProductBuildSearchResultsV2Multi(_a) {
    var _this = this;
    var rawTableData = _a.rawTableData, totalCount = _a.totalCount;
    var navigate = useNavigate();
    var location = useLocation();
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(mapTableData(!rawTableData ? location.state.data.data : rawTableData)), tableData = _c[0], setTableData = _c[1];
    var count = useMemo(function () { return (!totalCount ? location.state.totalCount : totalCount); }, [location, totalCount]);
    var _d = useState(1), pageNo = _d[0], setPageNo = _d[1];
    var indicatorSize = 10;
    var paginationCount = Math.ceil(count / 50);
    var resultsText = useMemo(function () {
        return "".concat(isLoading ? 'Loading' : 'Showing', " ").concat(pageNo * 50 - 49, " - ").concat(pageNo * 50 > count ? count : pageNo * 50, " of ").concat(count, " results");
    }, [pageNo, count, isLoading]);
    var searchParams = useSearchParams()[0];
    var params = useMemo(function () {
        var _a, _b, _c, _d;
        var sp = {
            text: (_a = searchParams.get('productNbr')) !== null && _a !== void 0 ? _a : '',
            date: (_b = searchParams.get('date')) !== null && _b !== void 0 ? _b : '',
            time: (_c = searchParams.get('time')) !== null && _c !== void 0 ? _c : '',
            rbv: (_d = searchParams.get('channel')) !== null && _d !== void 0 ? _d : '',
        };
        return sp;
    }, [searchParams]);
    var routeToBuild = useCallback(function (item, idArr) {
        setIsLoading(true);
        return navigate("/product-build-v2/build?productNbr=".concat(item.prefix, "&date=").concat(params.date, "&time=").concat(params.time, "&channel=").concat(params.rbv, "&pid=").concat(idArr[0], "_").concat(idArr[1], "&productVersion=").concat(item.productId.split('/ ')[1], "&search=").concat(params.text), {
            state: { item: item },
        });
    }, [params, navigate]);
    var handleRowClick = useCallback(function (k) {
        setIsLoading(true);
        var indexOfItem = tableData.findIndex(function (item) { return item.productId === k.productId; });
        sessionStorage.removeItem('buildGateway');
        sessionStorage.setItem('buildGateway', 'multi');
        var splitProductId = tableData[indexOfItem].productId.split('/ ')[1].split('_');
        routeToBuild(tableData[indexOfItem], splitProductId);
    }, [tableData, routeToBuild]);
    var tableDataDefinition = useMemo(function () { return ({
        firstColumnIndent: true,
        noDataMessage: 'No Products found',
        columns: [
            {
                dataAttribute: 'prefix',
                fieldType: InputFieldType.STRING,
                heading: 'Product Prefix',
                width: '10%',
            },
            {
                dataAttribute: 'name',
                fieldType: InputFieldType.STRING,
                heading: 'Name',
                width: '40%',
            },
            {
                dataAttribute: 'startDate',
                fieldType: InputFieldType.STRING,
                heading: 'Start Date',
                width: '25%',
            },
            {
                dataAttribute: 'productId',
                fieldType: InputFieldType.STRING,
                heading: 'Product ID',
                width: '25%',
            },
        ],
        showAddRow: false,
        extraIcon: {
            icon: _jsx(ArrowRightAltIcon, { sx: { color: 'black' } }),
            handler: function (e) {
                // e is the index of the row clicked
                setIsLoading(true);
                sessionStorage.removeItem('buildGateway');
                sessionStorage.setItem('buildGateway', 'multi');
                var splitProductId = tableData[+e].productId.split('/ ')[1].split('_');
                routeToBuild(tableData[+e], splitProductId);
            },
        },
        rowClickHandler: function (k) {
            return handleRowClick(k);
        },
    }); }, [handleRowClick, tableData, routeToBuild]);
    var handlePageChange = function (event, value) { return __awaiter(_this, void 0, void 0, function () {
        var searchResultRsp;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setPageNo(value);
                    setIsLoading(true);
                    return [4 /*yield*/, getFilteredProducts(params.text, params.date, params.time, determineChannelVal(params.rbv), value)];
                case 1:
                    searchResultRsp = _b.sent();
                    if (searchResultRsp.status === 200 && ((_a = searchResultRsp.data) === null || _a === void 0 ? void 0 : _a.data)) {
                        setTableData(mapTableData(searchResultRsp.data.data));
                        setIsLoading(false);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Box, { children: _jsxs(Box, __assign({ className: styles.container }, { children: [_jsx(Box, __assign({ className: styles.container1 }, { children: _jsxs(Box, __assign({ className: styles.container2 }, { children: [!isLoading && _jsx("h3", __assign({ className: styles.resultsCount }, { children: count > 0 && resultsText })), count > 50 && (_jsx(Pagination, { count: paginationCount, onChange: function (e, num) { return handlePageChange(e, num); } }))] })) })), !isLoading ? (_jsx(Box, __assign({ style: { marginTop: paginationCount > 0 ? '' : '-20px' } }, { children: _jsx(PubTable, { def: tableDataDefinition, tableData: tableData, renderTableHeader: tableData.length > 0, boldRowsOnHover: true }) }))) : (_jsx(LoadingSpinner, { indicatorSize: indicatorSize }))] })) }));
}

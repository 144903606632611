var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useProductHierarchy } from 'hooks/useProductHierarchy';
import { useEffect, useState } from 'react';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import Typography from '@mui/material/Typography';
import { SEARCH_HIERARCHY_PAGE_SIZE } from 'utils/constants';
import { StyledButton } from 'components/Shared/StyledComponents/StyledButton';
import { RequestDisplay } from 'components/SearchProduct/RequestDisplay/RequestDisplay';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { HierarchyDropdown } from './HierarchyDropdown/HierarchyDropdown';
import { ResultsTable } from './ResultsTable/ResultsTable';
import styles from './ProductHierarchy.module.scss';
export function ProductHierarchy() {
    var _a, _b;
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var _c = useState({
        primaryOnly: true,
        level1: undefined,
    }), hierarchyRequest = _c[0], setHierarchyRequest = _c[1];
    var _d = useState({}), levelOptions = _d[0], setLevelOptions = _d[1];
    var _e = useState(1), currLevel = _e[0], setCurrLevel = _e[1];
    var _f = useState(false), showRequest = _f[0], setShowRequest = _f[1];
    var _g = useProductHierarchy(hierarchyRequest), loading = _g.loading, body = _g.body, status = _g.status;
    useEffect(function () {
        if (!loading && status !== 200) {
            setSnackbarAlert({
                message: 'Hierarchy search error.',
                type: 'error',
            });
        }
    }, [loading, setSnackbarAlert, status]);
    var handleLevelSelect = function (selected, levelNum, key) {
        var _a, _b, _c, _d;
        if (currLevel < 10) {
            if (hierarchyRequest[key]) {
                var newRequest = {
                    primaryOnly: hierarchyRequest.primaryOnly,
                };
                var newLevelOptions = {};
                for (var index = 1; index <= levelNum; index += 1) {
                    var indexKey = "level".concat(index);
                    newLevelOptions = __assign(__assign({}, newLevelOptions), (_a = {}, _a[indexKey] = levelOptions === null || levelOptions === void 0 ? void 0 : levelOptions[indexKey], _a));
                    if (key !== indexKey) {
                        newRequest = __assign(__assign({}, newRequest), (_b = {}, _b[indexKey] = hierarchyRequest === null || hierarchyRequest === void 0 ? void 0 : hierarchyRequest[indexKey], _b));
                    }
                    else {
                        newRequest = __assign(__assign({}, newRequest), (_c = {}, _c[key] = selected, _c["level".concat(levelNum + 1)] = undefined, _c));
                    }
                }
                setHierarchyRequest(newRequest);
                setLevelOptions(newLevelOptions);
                setCurrLevel(levelNum + 1);
            }
            else {
                setHierarchyRequest(__assign(__assign({}, hierarchyRequest), (_d = {}, _d[key] = selected, _d["level".concat(currLevel + 1)] = undefined, _d)));
                setCurrLevel(currLevel + 1);
            }
        }
    };
    var handleUnsetLevel = function (level) {
        var _a;
        var updatedRequest = __assign({}, hierarchyRequest);
        var updatedLevels = __assign({}, levelOptions);
        var count = level;
        while ((updatedLevels === null || updatedLevels === void 0 ? void 0 : updatedLevels["level".concat(count)]) !== undefined) {
            delete updatedRequest["level".concat(count)];
            updatedLevels === null || updatedLevels === void 0 ? true : delete updatedLevels["level".concat(count)];
            count += 1;
        }
        setHierarchyRequest(__assign(__assign({}, updatedRequest), (_a = {}, _a["level".concat(level)] = undefined, _a)));
        setLevelOptions(updatedLevels);
        setCurrLevel(level);
    };
    useEffect(function () {
        setLevelOptions(function (prev) {
            var _a;
            var _b;
            return (__assign(__assign({}, prev), (_a = {}, _a["level".concat(currLevel)] = {
                keys: ((_b = body === null || body === void 0 ? void 0 : body.aggregations) !== null && _b !== void 0 ? _b : []).map(function (agg) { var _a; return (_a = agg === null || agg === void 0 ? void 0 : agg.key) !== null && _a !== void 0 ? _a : ''; }),
            }, _a)));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [body === null || body === void 0 ? void 0 : body.aggregations]);
    var getResultsLabelCount = function (resultsCount) {
        if (!resultsCount)
            return 0;
        if (resultsCount < SEARCH_HIERARCHY_PAGE_SIZE)
            return resultsCount;
        return SEARCH_HIERARCHY_PAGE_SIZE;
    };
    var handleReset = function () {
        setCurrLevel(1);
        setHierarchyRequest({
            primaryOnly: true,
            level1: undefined,
        });
        setLevelOptions({});
        setShowRequest(false);
    };
    return (_jsxs(Box, __assign({ className: styles.pageContainer }, { children: [_jsxs(Box, __assign({ className: styles.titleResetContainer }, { children: [_jsx(Typography, __assign({ variant: "h1", className: styles.pageTitle }, { children: "Product Hierarchy Search" })), _jsx(StyledButton, { handleClick: function () { return handleReset(); }, label: "Reset Selections", widthFromProps: 160 })] })), _jsxs(Box, __assign({ className: styles.primaryContainer }, { children: [_jsx(Typography, __assign({ variant: "h5", fontWeight: "bold", className: styles.primaryTitle }, { children: "Primary Only:" })), _jsx(FormControl, { children: _jsxs(RadioGroup, __assign({ className: styles.primarySelect, value: (_a = hierarchyRequest.primaryOnly) !== null && _a !== void 0 ? _a : true }, { children: [_jsx(FormControlLabel, { value: "true", control: _jsx(Radio, { size: "small", onChange: function () {
                                            return setHierarchyRequest(__assign(__assign({}, hierarchyRequest), { primaryOnly: true }));
                                        } }), label: "True", slotProps: {
                                        typography: {
                                            fontSize: '14px',
                                            fontFamily: "UntitledSansMedium, sans-serif, system-ui",
                                        },
                                    } }, "true"), _jsx(FormControlLabel, { value: "false", control: _jsx(Radio, { size: "small", onChange: function () {
                                            return setHierarchyRequest(__assign(__assign({}, hierarchyRequest), { primaryOnly: false }));
                                        } }), label: "False", slotProps: {
                                        typography: {
                                            fontSize: '14px',
                                            fontFamily: "UntitledSansMedium, sans-serif, system-ui",
                                        },
                                    } }, "false")] })) })] })), _jsx(Box, __assign({ className: styles.dropdownsContainer }, { children: Object.entries(levelOptions).map(function (_a, i) {
                    var k = _a[0], v = _a[1];
                    return v.keys.length > 0 ? (_jsx(HierarchyDropdown, { levelData: v, loading: loading && i > Object.keys(levelOptions).length, levelNum: i + 1, dropdownKey: k, handleLevelSelect: handleLevelSelect, unsetLevel: handleUnsetLevel }, k)) : (_jsx(Box, __assign({ width: "100%", display: "flex", justifyContent: "center" }, { children: (body === null || body === void 0 ? void 0 : body.aggregations) && body.aggregations.length === 0 && (_jsx(Typography, { children: "No more levels for selected set of hierarchies" })) }), k));
                }) })), !loading ? ((body === null || body === void 0 ? void 0 : body.data) &&
                body.data.length > 0 && (_jsxs(_Fragment, { children: [_jsx("hr", {}), _jsxs(Box, { children: [_jsxs(Box, __assign({ className: styles.resultsContainer }, { children: [_jsx(StyledButton, { handleClick: function () { return setShowRequest(!showRequest); }, label: "Show/Hide Request", widthFromProps: 168 }), !loading && (_jsx(Typography, __assign({ className: styles.resultsTitle }, { children: (body === null || body === void 0 ? void 0 : body.resultsCount)
                                            ? "Showing Top ".concat(getResultsLabelCount(body.resultsCount), " of ").concat((_b = body === null || body === void 0 ? void 0 : body.resultsCount) !== null && _b !== void 0 ? _b : 0).concat((body === null || body === void 0 ? void 0 : body.resultsCount) === 10000 ? '+' : '')
                                            : '' })))] })), showRequest && _jsx(RequestDisplay, { text: JSON.stringify(hierarchyRequest, null, 2) })] }), _jsx(ResultsTable, { data: body.data })] }))) : (_jsx(LoadingSpinner, { indicatorSize: 10 }))] })));
}

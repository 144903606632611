var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { StyledButtonV2 } from '../StyledButtonV2/StyledButtonV2';
import styles from './StyledDialogV2.module.scss';
export function StyledDialogV2(_a) {
    var _b, _c, _d;
    var children = _a.children, rowGap = _a.rowGap, padding = _a.padding, buttonProps = _a.buttonProps, sizeProps = _a.sizeProps, title = _a.title, isOpen = _a.isOpen, tooltipText = _a.tooltipText, minHeightOverride = _a.minHeightOverride, setIsOpen = _a.setIsOpen, isNso = _a.isNso;
    return (_jsxs(Box, __assign({ sx: { position: 'relative' } }, { children: [buttonProps && (_jsx(StyledButtonV2, { labelText: buttonProps.labelText, backgroundColorVariation: buttonProps.backgroundColorVariation, buttonAction: function () {
                    if (buttonProps.buttonAction)
                        buttonProps.buttonAction();
                    setIsOpen === null || setIsOpen === void 0 ? void 0 : setIsOpen(true);
                }, colorOverride: buttonProps.colorOverride, disabled: buttonProps.disabled, disabledColorOverride: buttonProps.disabledColorOverride, height: buttonProps.height, hoverColorOverride: buttonProps.hoverColorOverride, width: buttonProps.width }, buttonProps.labelText)), isOpen && (_jsx(Dialog, __assign({ open: isOpen, onClose: function () { return setIsOpen === null || setIsOpen === void 0 ? void 0 : setIsOpen(false); }, slotProps: { backdrop: { sx: { backgroundColor: 'rgba(255, 255, 255, 0.65)' } } }, PaperProps: {
                    sx: {
                        width: (_b = sizeProps === null || sizeProps === void 0 ? void 0 : sizeProps.width) !== null && _b !== void 0 ? _b : '100%',
                        height: (_c = sizeProps === null || sizeProps === void 0 ? void 0 : sizeProps.height) !== null && _c !== void 0 ? _c : 'fit-content',
                        maxWidth: (_d = sizeProps === null || sizeProps === void 0 ? void 0 : sizeProps.maxWidth) !== null && _d !== void 0 ? _d : '90%',
                        '::-webkit-scrollbar-track': { background: 'transparent' },
                        scrollbarColor: '#acacac transparent',
                    },
                } }, { children: _jsx(Box, __assign({ className: styles.contentContainer, sx: {
                        rowGap: rowGap !== null && rowGap !== void 0 ? rowGap : '',
                        padding: padding !== null && padding !== void 0 ? padding : '24px',
                        minHeight: minHeightOverride ? "".concat(minHeightOverride, "px !important") : 'initial',
                    } }, { children: _jsxs(_Fragment, { children: [title && (_jsxs(Box, { children: [_jsx(Typography, __assign({ sx: {
                                            fontSize: '20px',
                                            fontFamily: 'UntitledSansMedium, sans-serif, system-ui',
                                            marginBottom: '12px',
                                        } }, { children: title })), _jsx(Divider, { sx: { borderColor: 'black' } })] })), _jsxs(Box, __assign({ className: styles.iconContainer }, { children: [_jsx(Box, __assign({ sx: { display: 'inline-flex', justifyContent: 'center' } }, { children: tooltipText && (_jsx(Tooltip, __assign({ title: tooltipText }, { children: _jsx(HelpIcon, { fontSize: "small", sx: { height: 'auto', width: '1.75rem' } }) }))) })), _jsx(IconButton, __assign({ className: styles.closeIcon, onClick: function () { return setIsOpen === null || setIsOpen === void 0 ? void 0 : setIsOpen(false); } }, { children: _jsx(CloseIcon, { fontSize: "large" }) }))] })), children] }) })) })))] })));
}

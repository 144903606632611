var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { tryCopy } from 'utils/clipboardHelper';
import { PRODUCT_VERSION_SEARCH_FIELD_MAP, } from '../../../utils/search-product-field-mapping';
import styles from './ResultsTable.module.scss';
import { PageLinksCell } from '../../Shared/PageLinksCell/PageLinksCell';
export function ResultsTable(_a) {
    var data = _a.data, fieldNames = _a.fieldNames;
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var handleCopy = function (code) {
        if (code === -1) {
            setSnackbarAlert({
                message: "Looks like your browser does not support automatic copying. Please copy the cell content.",
                type: 'error',
            });
        }
    };
    return (_jsx(TableContainer, __assign({ className: styles.tableContainer }, { children: _jsxs(Table, __assign({ stickyHeader: true }, { children: [_jsx(TableHead, __assign({ style: { fontFamily: "UntitledSansMedium, sans-serif, system-ui" } }, { children: _jsxs(TableRow, { children: [fieldNames.map(function (label) {
                                var _a;
                                return (_jsx(TableCell, __assign({ className: styles.headerTableCell }, { children: (_a = PRODUCT_VERSION_SEARCH_FIELD_MAP[label]) === null || _a === void 0 ? void 0 : _a.displayName }), label));
                            }), _jsx(TableCell, { className: styles.pageLinkHeader })] }) })), _jsx(TableBody, { children: data.map(function (product) { return (_jsxs(TableRow, { children: [fieldNames.map(function (field) {
                                var displayText;
                                var fieldInfo = product[field];
                                if (Array.isArray(fieldInfo)) {
                                    displayText = fieldInfo[0].desc;
                                }
                                else {
                                    displayText = fieldInfo;
                                }
                                return (_jsx(TableCell, __assign({ className: styles.resultsTableCell }, { children: _jsxs(Box, __assign({ className: styles.cellContentContainer }, { children: [_jsx(Typography, __assign({ className: styles.cellText }, { children: displayText !== null && displayText !== void 0 ? displayText : '' })), displayText && displayText.trim() !== '' && (_jsx(IconButton, __assign({ className: styles.copyButton, onClick: function () { return handleCopy(tryCopy(displayText)); } }, { children: _jsx(ContentCopyIcon, {}) })))] })) }), "".concat(field, "_").concat(fieldInfo)));
                            }), _jsx(PageLinksCell, { productVersion: product.productVersion, prefix: product.prefix })] }, JSON.stringify(product))); }) })] })) })));
}

import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { logoutSession } from 'services/auth/AuthService';
export function Logout() {
    useEffect(function () {
        logoutSession();
        window.location.replace('https://hermanmiller.onelogin.com/portal');
    });
    return _jsx("div", { children: "Logging You Out" });
}

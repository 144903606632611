var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { PubTable } from 'components/Shared/PubTable/PubTable';
import { InputFieldType } from 'models/Input/InputModels';
import { useEffect, useState } from 'react';
export function RoyaltiesTab(_a) {
    var latestValidateResponse = _a.latestValidateResponse;
    useEffect(function () {
        if (latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.royalties) {
            var royalties = latestValidateResponse.royalties;
            var royaltiesData_1 = {};
            royalties.forEach(function (royalty, i) {
                var _a, _b, _c, _d;
                royaltiesData_1[i] = {
                    startDate: (_a = royalty.startDate) !== null && _a !== void 0 ? _a : '',
                    endDate: (_b = royalty.endDate) !== null && _b !== void 0 ? _b : '',
                    royaltyDesc: (_c = royalty.royaltyDesc) !== null && _c !== void 0 ? _c : '',
                    royaltyId: (_d = royalty.royaltyId) !== null && _d !== void 0 ? _d : '',
                };
            });
            setRoyaltiesData(royaltiesData_1);
        }
    }, [latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.royalties, latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.restrictions]);
    var _b = useState({}), royaltiesData = _b[0], setRoyaltiesData = _b[1];
    var royaltiesTableDataDefinition = {
        firstColumnIndent: true,
        columns: [
            {
                dataAttribute: 'royaltyId',
                fieldType: InputFieldType.NUMBER,
                heading: 'Royalty ID',
                width: '80px',
            },
            {
                dataAttribute: 'royaltyDesc',
                fieldType: InputFieldType.STRING,
                heading: 'Description',
                width: '600px',
            },
            {
                dataAttribute: 'startDate',
                fieldType: InputFieldType.STRING,
                heading: 'Start Date',
                width: '80px',
            },
            {
                dataAttribute: 'endDate',
                fieldType: InputFieldType.STRING,
                heading: 'End Date',
                width: '80px',
            },
        ],
    };
    return (_jsxs(Box, { children: [_jsx(Typography, __assign({ sx: { marginBottom: '10px', fontSize: '20px' } }, { children: "Product-Level Royalties" })), (latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.royalties) && (latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.royalties).length > 0 ? (_jsx(PubTable, { def: royaltiesTableDataDefinition, tableData: royaltiesData })) : (_jsx(Typography, __assign({ sx: { marginBottom: '10px', fontSize: '15px' } }, { children: "No Royalties present" })))] }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-underscore-dangle */
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { classes } from 'utils/styleHelper';
import { useState } from 'react';
import { catalogHelper } from 'utils/catalog-helper';
import { JsonViewer } from '../JsonViewer/JsonViewer';
import styles from './CatalogsTable.module.scss';
export function CatalogsTable(_a) {
    var catalogs = _a.catalogs, _b = _a.selectedCatalogs, selectedCatalogs = _b === void 0 ? {} : _b, _c = _a.hideCheckBoxColumn, hideCheckBoxColumn = _c === void 0 ? false : _c, _d = _a.showStartDateColumn, showStartDateColumn = _d === void 0 ? false : _d, _e = _a.handleSelect, handleSelect = _e === void 0 ? function () { return null; } : _e;
    var _f = useState(false), isModalOpen = _f[0], setIsModalOpen = _f[1];
    var _g = useState(0), indexOpened = _g[0], setIndexOpened = _g[1];
    var handleModalClose = function () {
        setIsModalOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(TableContainer, __assign({ className: classes(styles.tableContainer, (catalogs.length === 0 || hideCheckBoxColumn) && styles.noResultsTableContainer) }, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [catalogs.length > 0 && !hideCheckBoxColumn && (_jsx(TableCell, __assign({ className: styles.checkboxCell }, { children: " " }))), _jsx(TableCell, __assign({ className: classes(styles.colMedium, (catalogs.length === 0 || hideCheckBoxColumn) && styles.noResultsHeaderCell) }, { children: "Type" })), _jsx(TableCell, __assign({ className: classes(styles.colSmall, (catalogs.length === 0 || hideCheckBoxColumn) && styles.noResultsHeaderCell) }, { children: "File Prefix" })), showStartDateColumn && (_jsx(TableCell, __assign({ className: classes(styles.colMedium, (catalogs.length === 0 || hideCheckBoxColumn) && styles.noResultsHeaderCell) }, { children: "Start Date" }))), _jsx(TableCell, __assign({ className: classes(styles.colDescription, (catalogs.length === 0 || hideCheckBoxColumn) && styles.noResultsHeaderCell) }, { children: "Description" })), _jsx(TableCell, __assign({ className: classes(styles.colChannel, (catalogs.length === 0 || hideCheckBoxColumn) && styles.noResultsHeaderCell) }, { children: "Channel" })), _jsx(TableCell, __assign({ className: classes(styles.colSmall, (catalogs.length === 0 || hideCheckBoxColumn) && styles.noResultsHeaderCell) }, { children: "LT" })), _jsx(TableCell, __assign({ className: classes(styles.colSmall, (catalogs.length === 0 || hideCheckBoxColumn) && styles.noResultsHeaderCell) }, { children: "Cat Nbr" })), _jsx(TableCell, __assign({ className: classes((catalogs.length === 0 || hideCheckBoxColumn) && styles.noResultsHeaderCell) }, { children: ' ' }))] }) }), _jsxs(TableBody, { children: [catalogs.map(function (catalog, i) {
                                    var _a, _b, _c, _d, _e;
                                    return (_jsxs(TableRow, { children: [catalogs.length > 0 && !hideCheckBoxColumn && (_jsx(TableCell, __assign({ className: styles.checkboxCell }, { children: _jsx(FormControl, { children: _jsx(FormGroup, __assign({ onChange: function () { return handleSelect(catalog.key._id, catalog); } }, { children: _jsx(FormControlLabel, { label: "", control: _jsx(Checkbox, { checked: (selectedCatalogs === null || selectedCatalogs === void 0 ? void 0 : selectedCatalogs[catalog.key._id]) !== undefined }) }) })) }) }))), _jsx(TableCell, __assign({ className: classes(styles.colMedium, hideCheckBoxColumn && styles.noCheckboxColumn) }, { children: (_a = catalog.body) === null || _a === void 0 ? void 0 : _a.catalogType })), _jsx(TableCell, __assign({ className: classes(styles.colSmall, hideCheckBoxColumn && styles.noCheckboxColumn) }, { children: catalog.body.fileNamePrefix })), showStartDateColumn && (_jsx(TableCell, __assign({ className: classes(styles.colMedium, hideCheckBoxColumn && styles.noCheckboxColumn) }, { children: ((_b = catalog.body) === null || _b === void 0 ? void 0 : _b.startDate)
                                                    ? catalogHelper.formatDateString(catalog.body.startDate)
                                                    : '' }))), _jsx(TableCell, __assign({ className: classes(styles.colDescription, hideCheckBoxColumn && styles.noCheckboxColumn) }, { children: catalog.body.catalogDesc })), _jsx(TableCell, __assign({ className: classes(styles.colChannel, hideCheckBoxColumn && styles.noCheckboxColumn) }, { children: (_d = (_c = catalog.body) === null || _c === void 0 ? void 0 : _c.channels) === null || _d === void 0 ? void 0 : _d.map(function (c) { return c.channelCode; }).join(', ') })), _jsx(TableCell, __assign({ className: classes(styles.colSmall, hideCheckBoxColumn && styles.noCheckboxColumn) }, { children: (_e = catalog.body) === null || _e === void 0 ? void 0 : _e.leadTime })), _jsx(TableCell, __assign({ className: classes(styles.colSmall, hideCheckBoxColumn && styles.noCheckboxColumn) }, { children: catalog.key._id })), _jsx(TableCell, __assign({ className: classes(styles.colSmall, hideCheckBoxColumn && styles.noCheckboxColumn) }, { children: _jsx(IconButton, __assign({ onClick: function () {
                                                        setIsModalOpen(true);
                                                        setIndexOpened(i);
                                                    } }, { children: _jsx(DataObjectIcon, {}) })) }))] }, catalog.key._id));
                                }), catalogs.length === 0 && (_jsx(TableRow, { children: _jsx(TableCell, __assign({ className: styles.noResultsCell, colSpan: showStartDateColumn ? 9 : 8 }, { children: "No results for filter criteria" })) }))] })] }) })), _jsx(JsonViewer, { isOpen: isModalOpen, closeModal: function () { return handleModalClose(); }, stringifiedJson: JSON.stringify(catalogs[indexOpened], null, 4) })] }));
}

import { dateFormatHelper } from 'utils/date-format-helper';
export function searchParamsHelper(isParsedProduct, text, paramObj) {
    var newDate = text ? new Date("".concat(paramObj === null || paramObj === void 0 ? void 0 : paramObj.date, "T").concat(paramObj === null || paramObj === void 0 ? void 0 : paramObj.time, ".000Z")) : new Date();
    var hours = newDate.getHours().toString().length > 1 ? newDate.getHours() : "0".concat(newDate.getHours());
    var timeString = "".concat(hours, ":").concat(newDate.getMinutes().toString().length > 1 ? newDate.getMinutes() : "0".concat(newDate.getMinutes()), ":").concat(newDate.getSeconds().toString().length > 1 ? newDate.getSeconds() : "0".concat(newDate.getSeconds()));
    var y = newDate.getFullYear();
    var m = (newDate.getMonth() + 1).toString().length > 1 ? newDate.getMonth() + 1 : "0".concat(newDate.getMonth() + 1);
    var d = newDate.getDate().toString().length > 1 ? newDate.getDate() : "0".concat(newDate.getDate());
    return {
        text: text !== null && text !== void 0 ? text : paramObj === null || paramObj === void 0 ? void 0 : paramObj.text,
        // eslint-disable-next-line no-nested-ternary
        date: text
            ? paramObj === null || paramObj === void 0 ? void 0 : paramObj.date
            : !isParsedProduct
                ? dateFormatHelper.todaysDateFormattedLocale({ format: 'yyyy-MM-dd' })
                : '',
        displayDate: !isParsedProduct ? "".concat(y, "-").concat(m, "-").concat(d) : '',
        // eslint-disable-next-line no-nested-ternary
        time: text ? paramObj === null || paramObj === void 0 ? void 0 : paramObj.time : !isParsedProduct ? new Date().toISOString().split('T')[1].split('.')[0] : '',
        displayTime: !isParsedProduct ? timeString : '',
        rbv: text ? paramObj === null || paramObj === void 0 ? void 0 : paramObj.rbv : 'Commercial',
        parsedProductId: paramObj === null || paramObj === void 0 ? void 0 : paramObj.parsedProductId,
    };
}

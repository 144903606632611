var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { PubTable } from 'components/Shared/PubTable/PubTable';
import { InputFieldType } from 'models/Input/InputModels';
import { useEffect, useState } from 'react';
export function RestrictionsTab(_a) {
    var latestValidateResponse = _a.latestValidateResponse;
    useEffect(function () {
        if (latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.restrictions) {
            var restrictions = latestValidateResponse.restrictions;
            var restrictionsData_1 = {};
            restrictions.forEach(function (restriction, i) {
                var _a, _b, _c, _d, _e;
                restrictionsData_1[i] = {
                    startDate: (_a = restriction.startDate) !== null && _a !== void 0 ? _a : '',
                    endDate: (_b = restriction.endDate) !== null && _b !== void 0 ? _b : '',
                    bulletinNbr: (_c = restriction.bulletinNbr) !== null && _c !== void 0 ? _c : '',
                    reasonCode: (_d = restriction.reasonCode) !== null && _d !== void 0 ? _d : '',
                    comment: (_e = restriction.comment) !== null && _e !== void 0 ? _e : '',
                };
            });
            setRestrictionsData(restrictionsData_1);
        }
    }, [latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.restrictions]);
    var _b = useState({}), restrictionsData = _b[0], setRestrictionsData = _b[1];
    var restrictionsTableDataDefinition = {
        firstColumnIndent: true,
        columns: [
            {
                dataAttribute: 'reasonCode',
                fieldType: InputFieldType.STRING,
                heading: 'Reason',
                width: '100px',
            },
            {
                dataAttribute: 'bulletinNbr',
                fieldType: InputFieldType.NUMBER,
                heading: 'Bulletin',
                width: '100px',
            },
            {
                dataAttribute: 'comment',
                fieldType: InputFieldType.STRING,
                heading: 'Comment',
                width: '400px',
            },
            {
                dataAttribute: 'startDate',
                fieldType: InputFieldType.STRING,
                heading: 'Start Date',
                width: '80px',
            },
            {
                dataAttribute: 'endDate',
                fieldType: InputFieldType.STRING,
                heading: 'End Date',
                width: '80px',
            },
        ],
    };
    return (_jsxs(Box, { children: [_jsx(Typography, __assign({ sx: { marginBottom: '10px', fontSize: '20px' } }, { children: "Product-Level Restrictions" })), (latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.restrictions) &&
                (latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.restrictions).length > 0 ? (_jsx(PubTable, { def: restrictionsTableDataDefinition, tableData: restrictionsData })) : (_jsx(Typography, __assign({ sx: { marginBottom: '10px', fontSize: '15px' } }, { children: "No Restrictions present" })))] }));
}
